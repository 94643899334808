import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TicketList from './components/TicketList';
import PaymentInfo from './components/PaymentInfo';
import StyledCarousel from 'app/components/StyledCarousel';
import { Park, I_CartItem } from './slice/types';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { getPromotionParkApi } from 'app/services/showApi';
import { selectIsLogged, selectTranslations } from '../App/slice/selectors';
import { RootState } from 'types';
import ModalContainer from 'app/components/ModalContainer';
import PromotionDetail from 'app/components/PromotionDetail';
import { ReactComponent as CloseIcon } from 'app/assets/img/bold_close_icon.svg';
import useWindowSize from 'app/hooks/useWindowSize';
import {
  OnMergeTicket,
  OnRemoveTicket,
  OnRemoveTicketList,
  OnSetCart,
  OnUpdateTicket,
  useCartNew,
} from './hooks/useCartNew';
import { LOCAL_STORAGE_KEY, getItem, setItem } from 'utils/localStorage';
import { createAnonymousCartApi } from 'app/services/cartApi';

interface Props {}

export interface I_TicketItemByPark {
  park: Park;
  tickets: I_CartItem[];
}

type FormCustomContextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  onUpdateTicket: OnUpdateTicket;
  onRemoveTicket: OnRemoveTicket;
  onMergeTicket: OnMergeTicket;
  listCart: I_CartItem[];
  ticketsSortedByPark: I_TicketItemByPark[];
  onSetCart: OnSetCart;
  totalAmountSelected: number;
  totalPriceSelected: number;
  onGoToCatologPage: () => void;
  onRemoveTicketList: OnRemoveTicketList;
  ticketsMergedNeedUpdateAmount: MutableRefObject<string[]>;
};

export const FormCustomContext = createContext<FormCustomContextType>(
  {} as FormCustomContextType,
);

export const Cart = memo((props: Props) => {
  const [promotionParkData, setPromotionParkData] = useState([]);
  const [isLoadingPromotionParkData, setIsLoadingPromotionParkData] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [promotionData, setPromotionData] = useState<any>(null);
  const [isLoadedCartData, setIsLoadedCartData] = useState<boolean>(false);
  const { device } = useWindowSize();
  const isLogged = useSelector(selectIsLogged);

  const isLoadingCartData = useSelector(
    (state: RootState) => state.cart?.loading,
  );
  const {
    ticketsSortedByPark,
    listCart,
    onSetCart,
    onUpdateTicket,
    onRemoveTicket,
    totalAmountSelected,
    totalPriceSelected,
    onGoToCatologPage,
    onMergeTicket,
    onRemoveTicketList,
    onGetCart,
  } = useCartNew();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const ticketsMergedNeedUpdateAmount = useRef([]);

  const parkIdsOfTickets = useMemo(() => {
    const result: string[] = [];

    listCart.forEach(ticket => {
      const parkId = ticket?.show?.park?.id;
      if (parkId && !result.includes(parkId)) result.push(parkId);
    });

    return result;
  }, [listCart]);

  const slidesToShow = useMemo(() => {
    switch (device) {
      case 'desktop':
        return 4.5;

      case 'tablet':
        return 2.5;

      case 'mobile':
      default:
        return 1.5;
    }
  }, [device]);

  const [disabledNextBtn, setDisabledNextBtn] = useState(
    promotionParkData.length <= slidesToShow,
  );

  const [disabledPrevBtn, setDisabledPrevBtn] = useState(true);

  const translations = useSelector(selectTranslations);

  const onGetPromotionData = useCallback(async () => {
    if (parkIdsOfTickets.length < 1) return setPromotionParkData([]);
    try {
      setIsLoadingPromotionParkData(true);
      const { data } = await getPromotionParkApi({
        park: parkIdsOfTickets.join(','),
      });
      const promotionParkData = data?.data ?? [];
      setPromotionParkData(
        promotionParkData?.map(i => ({
          title: i?.name,
          imgPath: i?.image?.original,
          data: { ...i },
        })),
      );
    } catch (err) {
    } finally {
      setIsLoadingPromotionParkData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(parkIdsOfTickets)]);

  const generatePromotionTicketUrl = useCallback(
    (promotionData: Record<string, any>) => {
      const query = {
        startDate: promotionData?.dateFrom?.dateOrigin,
        endDate: promotionData?.dateTo?.dateOrigin,
        park: promotionData?.park?.id,
      };
      const queryString = Object.keys(query)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]),
        )
        .join('&');
      return `/catalog/${promotionData?.id}?${queryString}`;
    },
    [],
  );

  useEffect(() => {
    setDisabledNextBtn(promotionParkData.length <= slidesToShow);
  }, [promotionParkData.length, slidesToShow]);

  const renderPromotionParkData = () => {
    if (isLoadingPromotionParkData) {
      return <Skeleton active />;
    }
    if (promotionParkData?.length > 0) {
      return (
        <StyledCarousel
          title={translations?.['b2c.txt.offers'] ?? ''}
          items={promotionParkData}
          onClickDetailItem={(_, data: any) => {
            setPromotionData(data);
          }}
          onClickOrderTicket={(_, data: any) => {
            window.open(generatePromotionTicketUrl(data), '_self');
          }}
          afterChange={currentIdx => {
            setDisabledPrevBtn(currentIdx <= 0);
            setDisabledNextBtn(
              currentIdx >= promotionParkData.length - slidesToShow,
            );
          }}
          slidesToShow={slidesToShow}
          disabledNextBtn={disabledNextBtn}
          disabledPrevBtn={disabledPrevBtn}
          switchBtnColor="#FAB317"
        />
      );
    }

    return null;
  };

  useEffect(() => {
    onGetPromotionData();
  }, [onGetPromotionData]);

  useEffect(() => {
    if (!isLoadingCartData) setIsFirstLoad(false);
  }, [isLoadingCartData]);

  useEffect(() => {
    const loadCartData = async () => {
      setIsLoadedCartData(false);
      const anonymousCartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId) ?? '';
      if (!anonymousCartId && !isLogged) {
        const { data } = await createAnonymousCartApi();
        const cartId = data?.cart_id;
        setItem(LOCAL_STORAGE_KEY.anonymousCartId, cartId);
      }
      onGetCart(() => {
        setIsLoadedCartData(true);
      });
    };

    loadCartData();
  }, [onGetCart, isLogged]);

  return (
    <>
      <FormCustomContext.Provider
        value={{
          isLoading,
          setIsLoading,
          isSubmitted,
          setIsSubmitted,
          listCart,
          ticketsSortedByPark,
          onSetCart,
          totalAmountSelected,
          totalPriceSelected,
          onGoToCatologPage,
          onMergeTicket,
          onRemoveTicket,
          onUpdateTicket,
          onRemoveTicketList,
          ticketsMergedNeedUpdateAmount,
        }}
      >
        <LoadingContainer
          loading={isLoading || !isLoadedCartData}
          containerCSS={{ zIndex: 1, position: 'relative' }}
        >
          <PageContainer className="container">
            {isLoadedCartData ? (
              <Row gutter={26}>
                <Col span={device === 'desktop' ? 16 : 24}>
                  <TicketList />
                </Col>
                <Col span={device === 'desktop' ? 8 : 24}>
                  {isFirstLoad || (
                    <PaymentInfo
                      listCart={listCart}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      onGoToCatologPage={onGoToCatologPage}
                      onSetCart={onSetCart}
                      setIsSubmitted={setIsSubmitted}
                      totalAmountSelected={totalAmountSelected}
                      totalPriceSelected={totalPriceSelected}
                    />
                  )}
                </Col>
              </Row>
            ) : null}
            <CarouselContainer>{renderPromotionParkData()}</CarouselContainer>
          </PageContainer>
        </LoadingContainer>
      </FormCustomContext.Provider>
      <ModalContainer
        isShow={!!promotionData}
        onCloseDialog={() => setPromotionData(null)}
        maskClosable={false}
        modalProps={{
          destroyOnClose: true,
          width: 'auto',
        }}
        modalPadding="32px 30px 24px"
        modalHeight="auto"
        CloseIcon={<CloseIcon />}
        styleSlots={{
          iconWrapperStyle: {
            margin: 0,
          },
          modalContentStyle: {
            width: 636,
            maxWidth: device === 'desktop' ? 'none' : '90vw',
          },
        }}
      >
        <PromotionDetail
          title={promotionData?.name}
          parkAddress={promotionData?.park?.address}
          description={promotionData?.description}
          note={promotionData?.note}
        />
      </ModalContainer>
    </>
  );
});

const PageContainer = styled.div`
  @media (max-width: 1199px) {
    overflow-x: hidden;
  }
`;

const CarouselContainer = styled.div`
  margin: 60px 0 90px;
`;
