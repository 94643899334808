import React, { ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Images from 'app/assets/img';
import StyledCarousel from 'app/components/StyledCarousel';
import AccountManager, {
  ACCOUNT_MNG_MENU,
} from 'app/pages/NewAccount/layouts/AccountManager';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { getTopBestSellingTicketsApi } from 'app/services/appApi';
import { ProductInNew } from 'app/pages/NewAccount/types';
import { useHistory } from 'react-router-dom';
import ModalContainer from 'app/components/ModalContainer';
import PromotionDetail from 'app/components/PromotionDetail';
import { ReactComponent as CloseIcon } from 'app/assets/img/bold_close_icon.svg';
import useWindowSize from 'app/hooks/useWindowSize';

interface LayoutProps {
  title: string;
  children: ReactNode;
  menuActive?: ACCOUNT_MNG_MENU;
}

export const Layout = memo((props: LayoutProps) => {
  const { title, children, menuActive } = props;
  const [topSale, setTopSale] = useState<Partial<ProductInNew>[]>([]);
  const [promotionData, setPromotionData] = useState<any>(null);
  const { device } = useWindowSize();

  const topSaleViewData = useMemo(() => {
    return topSale.map(item => ({
      imgPath: item.image?.thumb640 ?? '',
      title: item.name ?? '',
      data: item,
    }));
  }, [topSale]);

  useEffect(() => {
    const getNews = async () => {
      const { data: news } = await getTopBestSellingTicketsApi();
      setTopSale(news?.data ?? []);
    };
    getNews();
  }, []);

  return (
    <div style={{ maxWidth: 1920, margin: '0 auto' }}>
      <Row
        style={{
          marginTop: 16,
          marginBottom: 24,
          padding:
            device === 'mobile' || device === 'tablet' ? '0px 9px' : '0px',
        }}
        justify="center"
      >
        {device === 'desktop' && (
          <StyledColAccount span={4}>
            <AccountManager menuActive={menuActive} />
          </StyledColAccount>
        )}
        <Col
          span={device === 'desktop' ? 14 : 24}
          style={{ marginLeft: device === 'desktop' ? 25 : 0 }}
        >
          <div
            style={{
              borderRadius: 20,
              background: '#FFFFFF',
              padding: device === 'desktop' ? '40px 32px 32px' : '16px',
              boxShadow: '0px 20px 20px 0px #0000000D',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <StyledH4
                style={{
                  marginBottom: 14,
                }}
                device={device}
              >
                {title}
              </StyledH4>
            </div>
            {children}
          </div>
          {topSaleViewData.length > 0 && (
            <TopSaleCarousel
              topSaleViewData={topSaleViewData}
              setPromotionData={setPromotionData}
            />
          )}
        </Col>
      </Row>
      <ModalContainer
        isShow={!!promotionData}
        onCloseDialog={() => setPromotionData(null)}
        maskClosable={false}
        modalProps={{
          destroyOnClose: true,
        }}
        modalPadding="32px 30px 24px"
        modalHeight="auto"
        CloseIcon={<CloseIcon />}
        styleSlots={{
          iconWrapperStyle: {
            margin: 0,
          },
        }}
      >
        <PromotionDetail
          title={promotionData?.name}
          parkAddress={promotionData?.park?.name}
          description={promotionData?.description}
          note={promotionData?.note}
        />
      </ModalContainer>
    </div>
  );
});

const TopSaleCarousel = ({
  topSaleViewData,
  setPromotionData,
}: {
  topSaleViewData: {
    imgPath: string;
    title: string;
    data: Partial<ProductInNew>;
  }[];
  setPromotionData: (data?: Partial<ProductInNew>) => void;
}) => {
  const translations = useSelector(selectTranslations);
  const { device } = useWindowSize();
  const [disabledNextBtn, setDisabledNextBtn] = useState(
    topSaleViewData.length <= (device === 'mobile' ? 1.5 : 3.5),
  );
  const [disabledPrevBtn, setDisabledPrevBtn] = useState(true);
  const history = useHistory();

  const slideToShow = useMemo(() => {
    switch (device) {
      case 'desktop':
        return 3.5;

      case 'tablet':
        return 2.5;

      case 'mobile':
      default:
        return 1.5;
    }
  }, [device]);

  return (
    <div
      style={{
        paddingTop: device === 'desktop' ? 42 : 32,
      }}
    >
      <StyledCarousel
        title={translations?.['b2c.txt.topSellingTickets'] ?? ''}
        items={topSaleViewData}
        onClickDetailItem={(idx, data) => {
          setPromotionData(data);
        }}
        onClickOrderTicket={(_, data) => {
          history.push(
            `/catalog/${data?.id ?? ''}?startDate=${
              data?.dateFrom?.dateOrigin ?? ''
            }&endDate=${data?.dateTo?.dateOrigin ?? ''}&park=${
              data?.park?.id ?? ''
            }`,
          );
        }}
        afterChange={currentIdx => {
          setDisabledPrevBtn(currentIdx === 0);
          setDisabledNextBtn(
            currentIdx >=
              topSaleViewData.length - (device === 'mobile' ? 1.5 : 3.5),
          );
        }}
        disabledNextBtn={disabledNextBtn}
        disabledPrevBtn={disabledPrevBtn}
        slidesToShow={slideToShow}
      />
    </div>
  );
};

const StyledH4 = styled.h4<{ device?: 'mobile' | 'tablet' | 'desktop' }>`
  font-weight: 600;
  font-size: ${props => (props.device === 'desktop' ? '20px' : '18px')};
  text-transform: uppercase;
  color: #000 !important;
`;

const StyledColAccount = styled(Col)`
  @media (max-width: 1550px) {
    width: 23%;
    max-width: 23%;
    flex: 0 0 23%;
  }
`;
