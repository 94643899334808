/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import React, { ReactNode, memo } from 'react';

import Images from 'app/assets/img';
import ModalContainer from 'app/components/ModalContainer';
import { ReactComponent as SunLogo } from './../../../assets/img/logo-sunworld.svg';
import styled from 'styled-components';

interface Props {
  isShow: boolean;
  onCloseDialog?: () => void;
  children: ReactNode;
  title: string;
  description?: ReactNode;
  height?: string | number;
}

const AuthenticationModalWrapper = memo(
  ({ isShow, onCloseDialog, children, title, description, height }: Props) => {
    return (
      <ModalContainer
        backgroudImg={Images.ImgBanner}
        closable
        modalProps={{
          maskClosable: false,
        }}
        isShow={isShow}
        onCloseDialog={onCloseDialog}
        styleSlots={{
          modalContentStyle: {
            height: height ?? '',
          },
        }}
        maskClosable={false}
      >
        <div className="d-flex justify-content-center">
          <SunLogo />
        </div>
        <HeaderContainer>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        {children}
      </ModalContainer>
    );
  },
);

export default AuthenticationModalWrapper;

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
`;

const Description = styled.p`
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: left;
  margin: 0;
  margin-top: 14px;
`;

const HeaderContainer = styled.div`
  margin: 40px 0px 0px;
`;
