import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import ModalContainer from 'app/components/ModalContainer';
import useWindowSize from 'app/hooks/useWindowSize';
import {
  reSendRegisterOTP,
  verifyOTPApi,
  verifyOTPChangePassApi,
} from 'app/services/authApi';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useCountDown from 'utils/hooks/useCountDown';
import { parseSecondsToMinutes } from 'utils/time';
import { useGlobalFunction } from '../hooks/useGlobalFunction';
import { selectTranslations } from '../slice/selectors';
import { GradientBtn, PrimaryText, REGISTER_STEPS } from './DialogRegister';

interface OTPRegisterProps {
  setRegisterStep: (step: REGISTER_STEPS, token?: string) => void;
  mailRegister: string;
  type: 'CHANGE_PASS' | 'REGISTER' | 'LOGIN_INACTIVE' | 'PAYMENT';
  onVeriyOtpSuccess?: (otp: string) => void;
  verifyOtpApiImp?: (otp: string) => Promise<any> | any;
  resendOtpApiImp?: () => Promise<any> | any;
  onErrorVerifyOtp?: (err: any) => void;
  onErrorResendOtp?: (err: any) => void;
}

const OTPRegister = (props: OTPRegisterProps) => {
  const {
    setRegisterStep,
    mailRegister,
    type,
    onVeriyOtpSuccess,
    verifyOtpApiImp,
    resendOtpApiImp,
    onErrorVerifyOtp,
    onErrorResendOtp,
  } = props;
  const translations = useSelector(selectTranslations);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [OTP, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const {
    onOpenDialogNoti,
    onOpenDialogLogin,
    onCloseDialogNoti,
    onCloseDialogAuth,
  } = useGlobalFunction();
  const { timeRemaining, resetCountDown } = useCountDown({
    time: 5 * 60 * 1000,
  });

  const {
    timeRemaining: timeRemainingDisableReSendOTP,
    resetCountDown: resetCountDownReSendOTP,
  } = useCountDown({
    time: 0.5 * 60 * 1000,
  });

  const disableOTPResendRef = useRef<boolean>(false);
  const {
    windowSize: { width: screenWidth },
  } = useWindowSize();

  const [OTPErrorMessage, setOTPErrorMessage] = useState('');
  const [disableResendOTP, setDisableResendOTP] = useState(false);

  const { minutes, seconds } = parseSecondsToMinutes(timeRemaining / 1000);
  const timeRemainingDisplay = (minutes: number, seconds: number) => {
    const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
    const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutesDisplay}:${secondsDisplay}`;
  };
  const onVerifyOTP = async () => {
    if (OTP.length < 6)
      return setOTPErrorMessage(
        translations?.['b2c.message.OtpRequired'] ?? '',
      );

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('email', mailRegister);
      formData.append('otpCode', OTP);

      let data: any;

      if (verifyOtpApiImp) {
        const res = await verifyOtpApiImp(OTP);
        data = res.data;
      } else {
        if (
          type === 'REGISTER' ||
          type === 'LOGIN_INACTIVE' ||
          type === 'PAYMENT'
        ) {
          type === 'PAYMENT' && formData.append('payment', '1');
          const res = await verifyOTPApi(formData);
          data = res.data;
        } else {
          const res = await verifyOTPChangePassApi(formData);
          data = res.data;
        }
      }

      // const { data } = type === 'REGISTER' || type === 'LOGIN_INACTIVE' ? await verifyOTPApi(formData) : await verifyOTPChangePassApi(formData);

      const errorCode = data?.errorCode;
      if (errorCode) {
        switch (errorCode) {
          case 400: {
            setOTPErrorMessage(translations?.['b2c.message.wrongOTP'] ?? '');
            break;
          }

          default:
            throw new Error();
        }
        return false;
      }

      if (
        type === 'CHANGE_PASS' ||
        type === 'LOGIN_INACTIVE' ||
        type === 'PAYMENT'
      ) {
        setRegisterStep(REGISTER_STEPS.FILL_OTP, data.token);
        onVeriyOtpSuccess?.(OTP);
        return;
      }
      onOpenDialogNoti({
        type: 'SUCCESS',
        isShow: true,
        title: translations?.['b2c.auth.registerSuccessTitle'] ?? '',
        content: translations?.['b2c.auth.registerSuccessContent'] ?? '',
        buttons: [
          {
            text: translations?.['b2c.txt.login'] ?? '',
            bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
            color: 'white',
            onClick: () => {
              onOpenDialogLogin();
              onCloseDialogNoti();
            },
          },
        ],
        closable: false,
      });
    } catch (error: any) {
      if (onErrorVerifyOtp) {
        onErrorVerifyOtp(error);
        return;
      }
      if (error?.code === 400) {
        setDisableResendOTP(true);
        setTimeout(function () {
          setDisableResendOTP(false);
        }, timeRemaining);
        onOpenDialogNoti({
          type: 'ERROR',
          isShow: true,
          title: '',
          content: error?.message,
          onCloseDialog: onCloseDialogNoti,
          buttons: [
            {
              text: translations?.['b2c.txt.close'] ?? '',
              bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
              color: 'white',
              onClick: () => {
                onCloseDialogNoti();
              },
            },
          ],
          maskClosable: false,
          closable: false,
        });
      } else {
        onOpenDialogNoti({
          type: 'ERROR',
          isShow: true,
          title: translations?.['b2c.action.failed'] ?? '',
          content: translations?.['b2c.message.tryNetwork'] ?? '',
          onCloseDialog: onCloseDialogNoti,
          buttons: [
            {
              text: translations?.['b2c.btn.retry'] ?? '',
              bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
              color: 'white',
              onClick: () => {
                onCloseDialogNoti();
                onCloseDialogAuth();
              },
            },
          ],
          maskClosable: false,
          closable: false,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (!executeRecaptcha) return;
    const tokenCaptcha = await executeRecaptcha('resendOtp');
    if (timeRemainingDisableReSendOTP || disableOTPResendRef.current)
      return false;
    disableOTPResendRef.current = true;

    try {
      const formData = new FormData();
      formData.append('email', mailRegister);
      if (type === 'CHANGE_PASS') {
        formData.append('changePass', '1');
      } else {
        formData.append('register', '1');
      }
      formData.append('captcha', tokenCaptcha);
      if (resendOtpApiImp) {
        await resendOtpApiImp();
      } else {
        const { data } = await reSendRegisterOTP(formData);
      }

      resetCountDown();
      resetCountDownReSendOTP(0.5 * 60 * 1000);
    } catch (error) {
      if (onErrorResendOtp) {
        onErrorResendOtp(error);
        return;
      }
      onOpenDialogNoti({
        type: 'ERROR',
        isShow: true,
        title: translations?.['b2c.action.failed'] ?? '',
        content: translations?.['b2c.message.tryNetwork'] ?? '',
        onCloseDialog: onCloseDialogNoti,
        buttons: [
          {
            text: translations?.['b2c.btn.retry'] ?? '',
            bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
            color: 'white',
            onClick: () => {
              onCloseDialogNoti();
            },
          },
        ],
        maskClosable: false,
        closable: false,
      });
      resetCountDownReSendOTP(0);
    } finally {
      disableOTPResendRef.current = false;
    }
  };

  useEffect(() => {
    if (OTP && OTP.length === 6) setOTPErrorMessage('');
  }, [OTP]);
  return (
    <OTPContainer>
      <Row justify="center" gutter={8}>
        <Col>
          <NormalText style={{ opacity: 0.6 }}>
            {translations?.['b2c.CodeExpireIn'] ?? ''}
          </NormalText>
        </Col>
        <Col>
          <NormalText style={{ color: '#E92127' }}>
            {timeRemainingDisplay(minutes, seconds)}
          </NormalText>
        </Col>
      </Row>
      <OTPWrapper style={{ position: 'relative' }}>
        <OtpInput
          inputType="number"
          value={OTP}
          numInputs={6}
          renderInput={props => <OTPInput {...props} />}
          onChange={otp => setOTP(otp)}
        />
        {OTPErrorMessage && <ErrMess>{OTPErrorMessage}</ErrMess>}
      </OTPWrapper>
      <ConfirmOTPBlock>
        <GradientBtn
          className="btn-confirm"
          style={{ fontWeight: 600 }}
          onClick={onVerifyOTP}
          disabled={loading || !timeRemaining}
        >
          {loading ? 'Loading...' : translations?.['b2c.txt.confirm'] ?? ''}
        </GradientBtn>
        <PrimaryText
          className="btn-reSend"
          disabled={!!disableResendOTP || !!timeRemainingDisableReSendOTP}
          style={{ marginTop: 40, fontWeight: '500', color: '##1A64C3' }}
          onClick={
            !!disableResendOTP || !!timeRemainingDisableReSendOTP
              ? undefined
              : handleResendOTP
          }
        >
          {translations?.['b2c.txt.reSendCode'] ?? ''}
        </PrimaryText>
      </ConfirmOTPBlock>
    </OTPContainer>
  );
};

export default OTPRegister;

export const replaceDescription = (
  text: string,
  replaceText: string,
  replaceTo: string,
) => {
  // const text = "Hello, world!";
  // const partToReplace = "world";

  const parts = text.split(replaceText);

  const newText = parts.map((part, index) => (
    <>
      {part}
      {index < parts.length - 1 && (
        <span style={{ fontWeight: 'bold' }}>{replaceTo}</span>
      )}
    </>
  ));

  // Usage
  return <div>{newText}</div>;
};
export const OverModal = ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose?: () => void;
  message?: string;
}) => {
  return (
    <ModalContainer
      isShow={open}
      maskClosable={false}
      onCloseDialog={onClose}
      CloseIcon={<CloseIcon width={12} height={12} />}
      styleSlots={{
        iconWrapperStyle: {
          margin: '-8px 0px 0px 28px',
          width: 12,
          height: 12,
        },
        modalContentStyle: {
          height: 'auto',
          width: 'auto',
          padding: '38px 31px',
          borderRadius: '16px',
        },
        modalStyle: {
          width: 'auto',
          maxWidth: '280px',
        },
        bodyStyle: {
          width: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <div>{message}</div>
    </ModalContainer>
  );
};
const OTPContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NormalText = styled.p`
  font-weight: 400;
  color: #1c1b1f;
  font-size: 14px;
  text-align: left;
  margin: 0;
`;

const OTPInput = styled.input`
  outline: none;
  box-shadow: none;
  border: none;
  border: solid 1px lightgray;
  border-radius: 10px;
  height: 48px;
  width: 48px !important;
  text-align: center;
  color: #1c1b1f;
  font-size: 14px;
  font-weight: 400;
  &:not(:first-child) {
    margin-left: 8px;
  }
  &:focus {
    border-color: #f27b22;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  @media (max-width: 374px) {
    height: 40px;
    width: 40px !important;
  }
`;
const ConfirmOTPBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrMess = styled(Row)`
  color: #ff4d4f;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const OTPWrapper = styled.div`
  position: relative;
  margin: 20px 0 40px;
`;
