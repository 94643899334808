import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';

export interface CallbackOptions<R> {
  onBefore?: () => void | boolean;
  onSuccess?: (response: R) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
}

export interface GeneralConfig {
  payloadData?: any;
  IIFE?: boolean;
  takeFirstRequest?: boolean;
}

export type PublicExtendable<R> = GeneralConfig & CallbackOptions<R>;

type ActionAPI<R> = (
  data: any,
  config: { cancelToken: CancelToken },
) => AxiosPromise<R>;

export interface Params<R> extends PublicExtendable<R> {
  actionApi: ActionAPI<R>;
}

const useFetchData = <R>(params: Params<R>) => {
  const {
    actionApi,
    IIFE,
    takeFirstRequest,
    onBefore,
    onSuccess,
    onError,
    payloadData,
    onFinally,
  } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<R>();
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const onFetchData = useCallback(
    async (additionalOptions?: {
      payloadData?: any;
      callback?: CallbackOptions<R>;
    }) => {
      if (takeFirstRequest && isLoading) return;
      if (cancelTokenRef.current) cancelTokenRef.current.cancel();
      cancelTokenRef.current = axios.CancelToken.source();

      const PayloadData = additionalOptions?.payloadData ?? payloadData;
      const OnBefore = additionalOptions?.callback?.onBefore ?? onBefore;
      const OnSuccess = additionalOptions?.callback?.onSuccess ?? onSuccess;
      const OnError = additionalOptions?.callback?.onError ?? onError;
      const OnFinally = additionalOptions?.callback?.onFinally ?? onFinally;

      try {
        const beforeResult = OnBefore?.();
        if (beforeResult === false) return;
        setIsLoading(true);
        const response = await actionApi(PayloadData, {
          cancelToken: cancelTokenRef.current.token,
        });
        const responseData = response.data;
        OnSuccess?.(responseData);
        setResult(responseData);
        return responseData;
      } catch (err) {
        OnError?.(err);
      } finally {
        setIsLoading(false);
        OnFinally?.();
      }
    },
    [
      actionApi,
      isLoading,
      onBefore,
      onError,
      onFinally,
      onSuccess,
      payloadData,
      takeFirstRequest,
    ],
  );

  useEffect(() => {
    if (IIFE === undefined || IIFE) {
      onFetchData();
    }
  }, [onFetchData, IIFE]);

  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel();
    };
  }, []);

  return {
    isLoading,
    onFetchData,
    result,
  };
};

export default useFetchData;
