import React, {
  CSSProperties,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { Input, InputProps } from 'antd';
import { FUNCTION_KEYS } from 'utils/constants';
import styled from 'styled-components';

interface InputTextProps extends InputProps {
  trim?: boolean;
  onlyNumber?: boolean;
  disableWhiteSpace?: boolean;
  placeholderStyle?: CSSProperties;
}

const InputText = (props: InputTextProps) => {
  const {
    trim = true,
    disableWhiteSpace,
    onlyNumber,
    value,
    onBlur,
    onChange,
    placeholderStyle,
    ...restProps
  } = props;
  const [inputValue, setInputValue] = useState(value);

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = e => {
    let value = e.target.value;
    if (trim) value = value.trim();
    setInputValue(value);
    onChange?.(value as any);
    onBlur?.(e);
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = e => {
    let value = e.target.value;
    setInputValue(value);
    onChange?.(e);
  };

  const isValidInOnlyNumberCase = (e: KeyboardEvent) => {
    const key = e.key;
    return (
      !isNaN(parseInt(key)) ||
      e.ctrlKey ||
      e.altKey ||
      e.metaKey ||
      FUNCTION_KEYS.includes(key)
    );
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (
      (disableWhiteSpace && e.code === 'Space') ||
      (onlyNumber && !isValidInOnlyNumberCase(e))
    )
      return e.preventDefault();
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <StyledInput
      value={inputValue}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      placeholderStyle={placeholderStyle}
      inputMode={onlyNumber ? 'numeric' : 'text'}
      {...restProps}
    />
  );
};

export default InputText;

const StyledInput = styled(Input)<{ placeholderStyle?: CSSProperties }>`
  &.ant-input {
    line-height: 47px;
    padding-top: 4px;
  }
  &::placeholder {
    ${props => ({ ...props.placeholderStyle })}
  }
`;
