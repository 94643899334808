import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';
import Qs from 'qs';
import { DEFAULT_LANGUAGE, TYPE_COOKIE } from './constants';
import { store } from '../';
import { objectType } from 'types';
import { getCookie, removeCookie } from './cookies';
import { notify } from 'reapop';
import { appActions } from 'app/pages/App/slice';
import { escapeHTML } from 'utils/helper';
import { createAnonymousCartApi } from 'app/services/cartApi';
import { LOCAL_STORAGE_KEY, setItem } from 'utils/localStorage';
import { isNil } from 'lodash';
// import { appActions } from 'app/pages/App/slice';

export const KEY_NOTIFICATION = 'KEY';
export const KEY_NOTIFICATION_PUT = 'PUT';
export const KEY_NOTIFICATION_POST = 'POST';
export const KEY_NOTIFICATION_DELETE = 'DELETE';

const AIPM_TOKEN = process.env.REACT_APP_AIPM_TOKEN;

const onSuccessInterceptorRequest = async (
  config: InternalAxiosRequestConfig<any>,
) => {
  const token = await getCookie(TYPE_COOKIE.TOKEN);
  const language = (await getCookie(TYPE_COOKIE.LANGUAGE)) || DEFAULT_LANGUAGE;
  // config.headers = {};
  config.url = `/${language}/api/${config.url}`;

  if (!AIPM_TOKEN) {
    if (token && config && config.headers)
      config.headers.Authorization = `${token}`;
  } else {
    if (config && config.headers) {
      if (token) {
        config.headers.setAuthorization(`${token}`);
        config.headers.set(`apim-sub-key`, `${AIPM_TOKEN}`);
        // config.headers = {
        //   Authorization: `${token}`,
        //   [`apim-sub-key`]: `${AIPM_TOKEN}`,
        // };
      } else {
        config.headers.set(`apim-sub-key`, `${AIPM_TOKEN}`);
        // config.headers = {
        //   [`apim-sub-key`]: `${AIPM_TOKEN}`,
        // };
      }
    }
  }
  if (config.url.includes('/download-pdf/')) {
    config.headers['Content-Type'] = 'application/pdf';
    config.responseType = 'blob';
  }
  config.paramsSerializer = (params: any) =>
    Qs.stringify(params, {
      arrayFormat: 'indices',
    });
  return config;
};
const onErrorInterceptorRequest = (error: AxiosError) => Promise.reject(error);

let notif;

const onErrorInterceptorResponse = async (error: AxiosError<objectType>) => {
  if (
    error?.response?.status === 404 &&
    error?.response?.config.url?.includes('b2c/cart/')
  ) {
    return await retryCartRequest(error, 2);
  }
  if (error.response && error.response.status) {
    // error.response.data?.errors?.length &&
    //   store.dispatch(
    //     notify(error.response.data?.errors[0].msg, 'error', {
    //       title: 'Oops',
    //       dismissAfter: 5000,
    //     }),
    //   );
    if (
      error.response?.data?.action !== 'popup' &&
      error.response.data?.message !== 'Đăng nhập để tiếp tục' &&
      error.response.data?.message !== 'Sign in to continue' &&
      !error.response.config.url?.includes('/cart/get-price') &&
      !error.response.config.url?.includes('customer/confirm') &&
      !error.response.config.url?.includes('b2c/v1/account/change-password') &&
      !error.response.config.url?.includes(
        'b2c/v1/account/otp-change-password',
      ) &&
      !(
        window.location.pathname !== '/cart' &&
        error.response.config.url?.includes('b2c/cart/merge-new-item')
      ) &&
      !(
        window.location.pathname !== '/cart' &&
        error.response.config.url?.includes('b2c/cart/remove')
      ) &&
      !(
        window.location.pathname !== '/cart' &&
        error.response.config.url?.includes('b2c/cart/update')
      ) &&
      !(
        window.location.pathname !== '/cart' &&
        error.response.config.url?.includes('b2c/cart/add')
      ) &&
      !(
        window.location.pathname === '/cart' && error.response.data.code === 400
      )
    ) {
      store.dispatch(
        notify(
          escapeHTML(
            error.response?.data?.message ? error.response?.data?.message : '',
          ),
          'error',
          {
            title: 'Oops',
            dismissAfter: 5000,
          },
        ),
      );
    } else {
      if (error.response.status === 401) {
        removeCookie(TYPE_COOKIE.TOKEN);
        store.dispatch(appActions.logout());
      }
      if (
        error.response.config.url?.includes(
          'b2c/v1/account/otp-change-password',
        )
      ) {
        return Promise.reject(error);
      }
      return Promise.reject(error?.response?.data);
    }

    if (error.response.status === 401) {
      removeCookie(TYPE_COOKIE.TOKEN);
      store.dispatch(appActions.logout());
    }
  }
  return Promise.reject(error);
};
const onSuccessInterceptorResponse = (response: AxiosResponse) => {
  if (
    response.status === 200 &&
    response.config.method !== 'get' &&
    !response.config.url?.includes('customer') &&
    !response.config.url?.includes('/account/update') &&
    !response.config.url?.includes('/b2c/checkout/validate-payment') &&
    !response.config.url?.includes('/cart/') &&
    !response.config.url?.includes('/cart/add-to-card') &&
    !response.config.url?.includes('b2c/v1/account/change-password') &&
    !response.config.url?.includes('b2c/v1/account/otp-change-password') &&
    !response.config.url?.includes('/b2c/cart/get?') &&
    !response.config.url?.includes('/b2c/cart/create-cart') && 
    !response.config.url?.includes('show/performances-by-restaurant') &&
    !response.config.url?.includes('/show/get-used-area2') &&
    window.location.pathname !== '/cart' &&
    !(
      response.config.url?.includes('/cart/remove') &&
      window.location.pathname !== '/cart'
    ) &&
    !response?.data?.errorTickets?.length &&
    !response.config.url?.includes('cart/create-cart')
  ) {
    if (!notif) {
      const translate = (store.getState().app as any)?.translations;
      notif = store.dispatch(
        notify(translate?.['b2c.success'] ?? '', 'success', {
          title: 'Success',
          dismissAfter: 2000,
        }),
      );

      setTimeout(() => {
        notif = null;
      }, 2000);
    }
  }
  return response;
};

axios.defaults.headers.post['Accept'] = 'application/json';

const _axios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.$get = this.$get.bind(this);
    this.$post = this.$post.bind(this);
    this.$put = this.$put.bind(this);
    this.$delete = this.$delete.bind(this);
  }
  public $get<T = any>(
    url: string,
    params?: objectType,
    config?: objectType,
  ): AxiosPromise<T> {
    checkOnlineState();
    return this.axiosInstance.get(url, {
      ...{ params },
      ...config,
    });
  }
  public $post<T = any>(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise<T> {
    checkOnlineState();
    return this.axiosInstance.post(url, data, config);
  }
  public $put(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    checkOnlineState();
    return this.axiosInstance.put(url, data, config);
  }
  public $delete(url: string, data?: objectType): AxiosPromise {
    checkOnlineState();
    // return this.axiosInstance.delete(url, {
    //   data,
    // });

    /**
     * @hotfix {https://github.com/axios/axios/issues/3220}
     */
    return this.axiosInstance.request({
      method: 'delete',
      url,
      data,
    });
  }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);

export default _axios;

const checkOnlineState = () => {
  const isOnline = navigator.onLine;
  if (!isOnline) {
    const translation = (store.getState().app as any)?.translations;

    store.dispatch(
      appActions.openDialogNoti({
        type: 'ERROR',
        isShow: true,
        title: translation ? translation['b2c.action.failed'] : '',
        content: translation ? translation['b2c.message.tryNetwork'] : '',
        closable: false,
        onCloseDialog: () => {
          store.dispatch(appActions.closeDialogNoti());
        },
        buttons: [
          {
            text: translation ? translation['b2c.btn.retry'] : '',
            bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
            color: 'white',
            onClick: () => {
              store.dispatch(appActions.closeDialogNoti());
            },
          },
        ],
      }),
    );
    return Promise.reject();
  }
};

const retryCartRequest = async (
  error: AxiosError,
  retries: number,
): Promise<any> => {
  if (retries > 0 && error.response?.status === 404) {
    const { data } = await createAnonymousCartApi();
    const cartId = data?.cart_id;
    setItem(LOCAL_STORAGE_KEY.anonymousCartId, cartId);
    try {
      const fullUrl = new URL(`${error.config?.baseURL}${error.config?.url}`);
      const searchParams = new URLSearchParams(fullUrl.search);

      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      if (error.config) {
        if (!isNil(params['cart_id'])) {
          const newUrl = fullUrl.pathname.replace(
            `cart_id=${params['cart_id']}`,
            `cart_id=${cartId}`,
          );
          error.config.url = newUrl;
        }
        if (error.config.params instanceof FormData) {
          error.config.params.set('cart_id', cartId);
        } else {
          error.config.params = { ...error.config.params, cart_id: cartId };
        }
      }

      // error.config?.headers.set('cart_id', cartId);
      return await axios.request(error.config as any);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return await retryCartRequest(error, retries - 1);
      }
    }
  }
  return Promise.reject(error);
};
