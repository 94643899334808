import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from 'antd';

import { ReactComponent as CloseIcon } from '../../assets/img/close_icon.svg';
import { MOBILE_WIDTH, TABLET_WIDTH } from 'app/hooks/useWindowSize';

interface ModalContainerProps {
  children: ReactNode;
  backgroudImg?: string;
  isShow: boolean;
  onCloseDialog?: () => void;
  modalProps?: ModalProps;
  closable?: boolean;
  maskClosable?: boolean;
  CloseIcon?: ReactNode;
  modalPadding?: string;
  modalHeight?: string;
  styleSlots?: {
    bodyStyle?: CSSProperties;
    modalContentStyle?: CSSProperties;
    modalStyle?: CSSProperties;
    iconWrapperStyle?: CSSProperties;
  };
}

const ModalContainer = ({
  children,
  backgroudImg = '',
  isShow,
  onCloseDialog,
  closable,
  modalProps,
  styleSlots,
  CloseIcon: CloseIconProp,
  maskClosable,
  modalPadding,
  modalHeight,
}: ModalContainerProps) => {
  return (
    <Modal
      centered
      visible={isShow}
      closable={closable}
      onCancel={onCloseDialog}
      closeIcon={
        <CloseIconWrapper style={{ ...styleSlots?.iconWrapperStyle }}>
          {CloseIconProp ? CloseIconProp : <CloseIcon />}
        </CloseIconWrapper>
      }
      style={{ ...styleSlots?.modalStyle }}
      bodyStyle={{ ...bodyStyle, ...styleSlots?.bodyStyle }}
      footer={null}
      modalRender={modal => (
        <ModalContent
          padding={modalPadding}
          height={modalHeight}
          backgroudImg={backgroudImg}
          modalContentStyle={{ ...styleSlots?.modalContentStyle }}
        >
          {modal}
        </ModalContent>
      )}
      maskClosable={maskClosable}
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

export default ModalContainer;

const bodyStyle = {
  width: '100%',
  padding: 0,
};

const CloseIconWrapper = styled.div`
  margin: 16px 16px 0 0;
`;

const ModalContent = styled.div<{
  backgroudImg?: string;
  modalContentStyle: CSSProperties;
  padding?: string;
  height?: string;
}>`
  overscroll-behavior: none;
  .ant-modal-content {
    border-radius: 16px;
    background-image: url(${props => props.backgroudImg});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    justify-content: center;
    padding: ${props => (props.padding ? props.padding : '42px')};
    max-height: 779px;
    height: ${props => (props.height ? props.height : '90vh')};
    max-width: 461px;
    margin: auto;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    .ant-modal-body {
      /* &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none; */
      /* Define the styles for the scrollbar */
      ::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
      }

      /* Define the track (the area behind the thumb) */
      ::-webkit-scrollbar-track {
        background: transparent; /* Color of the track */
      }

      /* Define the scrollbar thumb */
      ::-webkit-scrollbar-thumb {
        background: #ececec; /* Color of the thumb */
        border-radius: 20px;
      }

      /* Define the scrollbar thumb on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #d6d6d6; /* Color of the thumb on hover */
      }
    }
    ${props => ({ ...props.modalContentStyle })}

    @media (max-width: ${props => TABLET_WIDTH}px) {
      .ant-modal-body {
        ::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }

    @media (max-width: ${_ => MOBILE_WIDTH}px) {
      padding: ${props => (props.padding ? props.padding : '42px 16px')};
    }
  }
`;
