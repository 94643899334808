import { selectTranslations } from 'app/pages/App/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NotFoundImg } from 'app/assets/img/not-found-img.svg';
import StyledButton from 'app/components/StyledButton';
import { useHistory } from 'react-router-dom';

interface Props {
  // Define your component's props here
}

const NotFound: React.FC<Props> = () => {
  const translation = useSelector(selectTranslations);
  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 8,
      }}
    >
      <Container>
        <NotFoundImg />
        <h1>Oops!</h1>
        <div className="content">
          {translation ? translation['b2c.txt.notFound'] : ''}
        </div>
      </Container>
      <StyledButton
        textColor="#808080"
        bgColor="white"
        style={{ width: 'auto' }}
        border="1px solid #808080"
        onClick={() => history.push('/')}
      >
        <i className="fa-solid fa-arrow-left"></i>
        <span
          className="--textBtn"
          style={{
            marginLeft: 8,
          }}
        >
          {translation ? translation['b2c.txt.goHome'] : ''}
        </span>
      </StyledButton>
    </div>
  );
};

export default NotFound;

const Container = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 66px 212px;
  box-shadow: 0px 20px 20px 0px #0000000d;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 18px;
  h1 {
    color: #e92127;
    font-size: 60px;
    font-weight: 500;
    margin: 20px 0px;
  }
  .content {
    color: #e92127;
    font-size: 18px;
    font-weight: 300;
    color: black;
  }
`;
