/**
 *
 * Asynchronously loads the component for InputNumberContainer
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InputNumberContainer = lazyLoad(
  () => import('./index'),
  module => module.InputNumberContainer,
);
