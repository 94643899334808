import { useEffect, useMemo } from 'react';

const useMomentLocaleLoader = locale => {
  const momentLocale = useMemo(() => {
    switch (locale) {
      case 'vi':
        return 'vi';
      case 'ru':
        return 'ru';
      case 'zh':
        return 'zh-cn';
      case 'ko':
        return 'ko';
      case 'th':
        return 'th';
      default:
        return 'en';
    }
  }, [locale]);

  useEffect(() => {
    const loadMomentLocale = async () => {
      const momentModule = await import('moment');
      if (momentLocale !== 'en') {
        await import('moment/locale/' + momentLocale);
      }
      momentModule.default.locale(momentLocale);
    };

    loadMomentLocale();
  }, [momentLocale]);
};

export default useMomentLocaleLoader;
