import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Row, Select } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CustomDatePicker from './partials/components/CustomDatePicker';
import { formatNumber } from 'utils/helper';
import NumberControl from './partials/components/NumberControl';
import GrandstandDiagram from './partials/components/GrandstandDiagram';
import { ReactComponent as TrashIcon } from 'app/assets/img/trash.svg';
import { StyledCheckbox } from '../TicketList';
import { I_CartItem } from '../../slice/types';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { MAP_VGS, TICKET_TYPES } from 'utils/constants';
import useWindowSize, {
  DESKTOP_WIDTH,
  MOBILE_WIDTH,
} from 'app/hooks/useWindowSize';
import { GET_TICKET_PRICE, cartActions } from '../../slice';
import { generateTicketIdentify } from '../../utils';
import DialogWarning from 'app/pages/Catalog/components/DialogWarning';
import { DefaultImg } from 'app/components/StyledCarousel';
import { ReactComponent as ArrowDownIcon } from 'app/assets/img/arrow_down.svg';
import useGetUsedArea2 from 'app/hooks/useGetUsedArea2';
import checkHaveTicketOnSelectedDate from './partials/helper';
import {
  OnMergeTicket,
  OnRemoveTicketList,
  OnSetCart,
} from '../../hooks/useCartNew';

export interface TicketItemProps extends I_CartItem {
  isSubmitted: boolean;
  onSetCart: OnSetCart;
  onMergeTicket: OnMergeTicket;
  onRemoveTicketList: OnRemoveTicketList;
  ticketsMergedNeedUpdateAmount: MutableRefObject<string[]>;
}
export enum CART_ERROR_TYPE {
  ERROR_TIME = 'errorTime',
  ERROR_TICKET = 'errorTicket',
}
export type ErrorData = {
  keyItem: string;
  message: string;
  errorType: CART_ERROR_TYPE;
};

type OnGetTicketPrice = (params: {
  ticketData?: {
    show_id?: number;
    date?: string;
    zone_id?: number;
    used_area2?: string | number;
    restaurant_id?: number;
  };
  onMergeTicket?: () => void;
  isUpdateCart?: boolean;
}) => void;

const TicketItem = (props: TicketItemProps) => {
  const {
    originalPrice,
    price,
    amount,
    catalog,
    selected,
    date,
    show,
    zone,
    identify,
    created_at,
    errorTicket,
    errorTime,
    inventory,
    max_inventory,
    itemKey,
    itemKeys,
    product,
    restaurant,
    usedArea2,
    usedAreas2,
    isSubmitted,
    onMergeTicket,
    onRemoveTicketList,
    onSetCart,
    ticketsMergedNeedUpdateAmount,
  } = props;

  const dispatch = useDispatch();
  const translations = useSelector(selectTranslations);
  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();

  const { device } = useWindowSize();
  const isDesktopScreen = useMemo(() => device === 'desktop', [device]);

  const [triggerValidateRestaurant, setTriggerValidateRestaurant] =
    useState(false);
  const [triggerValidateZone, setTriggerValidateZone] = useState(false);
  const [triggerValidateUsedArea2, setTriggerValidateUsedArea2] =
    useState(false);

  const selectRestaurantPopupContainer = useRef<HTMLDivElement>(null);
  const selectZonePopupContainer = useRef<HTMLDivElement>(null);
  const selectPerformancePopupContainer = useRef<HTMLDivElement>(null);

  const decrementActionRef = useRef<ReturnType<typeof setTimeout>>();
  const numberControlRef = useRef<{
    setInternalValue: (value: number) => void;
  }>({ setInternalValue: () => {} });
  const currentAmount = useRef(amount);
  const isChangingAmount = useRef(false);

  const [warningDialogMsg, setWarningDialogMsg] = useState('');
  const [ticketInventory, setTicketInventory] = useState<null | number>(
    inventory ?? null,
  );

  const isComboDifTicket = useMemo(
    () =>
      catalog?.mapVGS === MAP_VGS.COMBO &&
      product?.park?.workstationId === 'DIF',
    [catalog?.mapVGS, product?.park?.workstationId],
  );

  const isRestaurantTicket = useMemo(
    () => show?.restaurants && show?.restaurants?.length > 0,
    [show?.restaurants],
  );

  const isNotApplyUsedArea2 = useMemo(
    () => show?.materialType === TICKET_TYPES.CABLE_CAR,
    [show?.materialType],
  );

  const isNotApplyZone = useMemo(
    () =>
      show?.materialType === TICKET_TYPES.CABLE_CAR ||
      (show.zones?.length ?? 0) === 0,
    [show?.materialType, show.zones?.length],
  );

  const grandstandData = useMemo(() => {
    const zoneByIdSelected = show?.zones?.find(i => i?.id === zone?.id);
    return zoneByIdSelected ?? show?.zones?.[0];
  }, [show?.zones, zone?.id]);

  const isLoadedUsedArea2Data = useRef(true);
  const [enableGetUsedArea2, setEnableGetUsedArea2] = useState(false);

  const useGetUsedArea2Options = useMemo(
    () => ({
      payloadData: {
        date,
        show_id: show?.id,
        restaurant_id: restaurant?.id,
        zone_id: zone?.id,
      },
      applyZone: !isNotApplyZone,
      applyUsedZone2: !isNotApplyUsedArea2,
      isRestaurant: isRestaurantTicket,
      IIFE: enableGetUsedArea2,
      takeFirstRequest: true,
      onBefore: () => {
        dispatch(cartActions.onSetCartLoading(true));
      },
      onSuccess: response => {
        onSetCart(prev =>
          prev.map(item => {
            if (item.identify !== identify) return item;
            return { ...item, usedAreas2: response.data };
          }),
        );
      },
      onError: () => {
        onSetCart(prev =>
          prev.map(item => {
            if (item.identify !== identify) return item;
            return { ...item, usedAreas2: [] };
          }),
        );
      },
      onFinally: () => {
        dispatch(cartActions.onSetCartLoading(false));
        setEnableGetUsedArea2(false);
        isLoadedUsedArea2Data.current = true;
      },
    }),
    [
      date,
      dispatch,
      enableGetUsedArea2,
      identify,
      isNotApplyUsedArea2,
      isNotApplyZone,
      isRestaurantTicket,
      onSetCart,
      restaurant?.id,
      show?.id,
      zone?.id,
    ],
  );

  useGetUsedArea2(useGetUsedArea2Options);

  const isHaveTicketOnSelectedDate = useMemo(
    () =>
      checkHaveTicketOnSelectedDate({
        selectedDate: moment(date, 'YYYY-MM-DD'),
        dateFrom: moment(show?.dateFrom?.dateOrigin, 'YYYY-MM-DD'),
        dateTo: moment(show?.dateTo?.dateOrigin, 'YYYY-MM-DD'),
        isComboDifTicket,
        weekend: show?.weekend,
      }),
    [
      date,
      isComboDifTicket,
      show?.dateFrom?.dateOrigin,
      show?.dateTo?.dateOrigin,
      show?.weekend,
    ],
  );

  const generateIdentify = (ticketData: {
    zoneId?: number;
    date?: string;
    restaurantId?: number;
    usedArea2?: string | number;
  }): string => {
    let identify = show.id;
    const partOfIdentity = {
      performanceId: undefined,
      zoneId: zone?.id,
      date,
      restaurantId: restaurant?.id,
      usedArea2: usedArea2,
      ...ticketData,
    };

    for (let key in partOfIdentity) {
      const v = partOfIdentity[key];
      identify += '_';
      if (!!v && v !== 'undefined' && v !== 'null') identify += v;
    }

    return identify;
  };

  const handleToggleSelect = () => {
    const amountNum = parseFloat(amount);
    const isNotEnoughTickets = !!errorTicket || !!errorTime || !inventory;
    const isEmptyUsedAreas2 =
      !isNotApplyUsedArea2 && (usedAreas2 ?? []).length < 1;

    const isNotEnoughData =
      !amountNum ||
      isNotEnoughTickets ||
      isEmptyUsedAreas2 ||
      !isHaveTicketOnSelectedDate;

    if (isNotEnoughData && !selected) {
      setTriggerValidateUsedArea2(true);
      setTriggerValidateZone(true);
      setTriggerValidateRestaurant(true);
    }

    onSetCart(prev =>
      prev.map(item => {
        if (item.identify !== identify) return item;
        return { ...item, selected: isNotEnoughData ? false : !item.selected };
      }),
    );
  };

  const handleGetTicketPrice: OnGetTicketPrice = params => {
    const { ticketData, onMergeTicket, isUpdateCart } = params;
    const payloadData = {
      show_id: show?.id,
      date,
      zone_id: zone?.id,
      restaurant_id: restaurant?.id,
      used_area2: usedArea2,
      ...ticketData,
    };

    if (
      (!isNotApplyUsedArea2 && !payloadData.used_area2) ||
      (!isNotApplyZone && !payloadData?.zone_id) ||
      (isRestaurantTicket && !payloadData.restaurant_id)
    )
      return false;

    const newIdentify = generateTicketIdentify({
      showId: payloadData.show_id,
      performanceId: undefined,
      zoneId: payloadData.zone_id,
      date: payloadData.date,
      restaurantId: payloadData.restaurant_id,
      usedArea2: payloadData.used_area2,
    });

    const formData = new FormData();
    for (let k in payloadData) {
      formData.append(k, payloadData[k]);
    }
    const onError = (error: any) => {
      // if (error?.code === 400 && error?.message) {
      //   setWarningDialogMsg(error?.message);
      // }
    };

    const onSuccessFully = responseData => {
      onSetCart(prev =>
        prev.map(item => {
          if (item.identify !== newIdentify) return item;
          return {
            ...item,
            inventory: responseData?.inventory,
            max_inventory: responseData?.max_inventory,
            originalPrice: responseData?.originalPrice,
            price: responseData?.price,
          };
        }),
      );
      onMergeTicket?.();
    };

    dispatch(
      GET_TICKET_PRICE({
        data: formData,
        ticketIdentify: newIdentify,
        onSuccess: onSuccessFully,
        onError: onError,
        isCallUpdateCartApi: isUpdateCart,
      }),
    );
  };

  const onMergeTicketSuccess = response => {
    const responseIdentify = generateTicketIdentify({
      showId: response?.show?.id,
      performanceId: undefined,
      zoneId: response?.zone?.id,
      date: response?.date,
      restaurantId: response?.restaurant?.id,
      usedArea2: response?.usedArea2,
    });

    onSetCart(prev =>
      prev.map(item => {
        if (item.identify !== responseIdentify) return item;
        return {
          ...item,
          itemKey: response?.itemKey,
          itemKeys: [],
          errorTicket: response?.errorTicket,
          errorTime: response?.errorTime,
          inventory: response?.inventory,
          price: response?.price,
          originalPrice: response?.originalPrice,
          origin: undefined,
          usedAreas2,
        };
      }),
    );
  };

  const onMergeTicketError = err => {
    const dataRequest = err?.data?.dataRequest;
    if (dataRequest) {
      const errorIdentify = generateTicketIdentify({
        showId: dataRequest.showId,
        performanceId: undefined,
        zoneId: dataRequest?.zone,
        date: dataRequest?.date,
        restaurantId: dataRequest?.restaurant,
        usedArea2: dataRequest?.used_area2,
      });

      onSetCart(prev =>
        prev.map(item => {
          if (item.identify !== errorIdentify) return item;
          const errType = err?.data?.errorType;
          const errMsg = err?.data?.message ?? err?.message;
          return {
            ...item,
            inventory: err?.data?.inventory ?? 0,
            itemKey: err?.data?.keyItem ?? item.itemKey,
            errorTime:
              errType === CART_ERROR_TYPE.ERROR_TIME ? errMsg : item?.errorTime,
            errorTicket:
              errType === CART_ERROR_TYPE.ERROR_TICKET
                ? errMsg
                : item?.errorTicket,
          };
        }),
      );
    }
  };

  const handleDecrementNumber = () => {
    if (decrementActionRef.current) clearTimeout(decrementActionRef.current);
    if (parseFloat(amount) < 1) return false;
    let newAmount = 0;

    onSetCart(prev =>
      prev.map(item => {
        if (item.identify !== identify) return item;
        newAmount = parseFloat(item.amount) - 1;
        return { ...item, amount: newAmount.toString() };
      }),
    );

    currentAmount.current = newAmount.toString();

    if (newAmount === 0) handleRemove();

    decrementActionRef.current = setTimeout(() => {
      onMergeTicket?.(
        {
          show_id: show?.id,
          amount: newAmount,
          date: date,
          zone_id: zone?.id,
          restaurant_id: restaurant?.id,
          item_keys: itemKeys ?? [],
          used_area2: usedArea2,
        },
        onMergeTicketSuccess,
        onMergeTicketError,
      );
    }, 300);
  };

  const handleIncrementNumber = () => {
    let newAmount = 0;

    onSetCart(prev =>
      prev.map(item => {
        if (item.identify !== identify) return item;
        newAmount = parseFloat(item.amount) + 1;
        return { ...item, amount: newAmount.toString() };
      }),
    );

    onMergeTicket?.(
      {
        show_id: show?.id,
        amount: newAmount,
        date: date,
        zone_id: zone?.id,
        restaurant_id: restaurant?.id,
        item_keys: itemKeys ?? [],
        used_area2: usedArea2,
      },
      onMergeTicketSuccess,
      onMergeTicketError,
    );

    currentAmount.current = newAmount.toString();
  };

  const handleOnChangeNumber = (value: number) => {
    const isEmptyAmount = !value;
    isChangingAmount.current = true;
    onSetCart(prev =>
      prev.map(item => {
        if (item.identify !== identify) return item;
        return {
          ...item,
          amount:
            +value > inventory! ? inventory!.toString() : value.toString(),
          selected: isEmptyAmount ? false : item.selected,
          origin: !item?.origin ? { ...item } : item.origin,
        };
      }),
    );
  };

  const handleOnBlurInputNumber = () => {
    if (currentAmount.current.toString() === amount.toString()) return;
    currentAmount.current = amount;

    if (!+amount) {
      handleRemove();
      return false;
    }

    onMergeTicket?.(
      {
        show_id: show?.id,
        amount: +amount,
        date: date,
        zone_id: zone?.id,
        restaurant_id: restaurant?.id,
        item_keys: itemKeys ?? [],
        used_area2: usedArea2,
      },
      onMergeTicketSuccess,
      onMergeTicketError,
    );

    isChangingAmount.current = false;
  };

  const checkEnoughDataToSyncTicket = (ticketData: {
    amount: number;
    zoneId: number | null | undefined;
    restaurantId: number | null | undefined;
    usedArea2: number | string | null | undefined;
  }) => {
    if (
      !ticketData.amount ||
      (!isNotApplyZone && !ticketData.zoneId) ||
      (!isNotApplyUsedArea2 && !ticketData.usedArea2) ||
      (isRestaurantTicket && !ticketData.restaurantId)
    )
      return false;
    return true;
  };

  const handleChangeTime = (_, dateString: string) => {
    setEnableGetUsedArea2(true);
    const newIdentify = generateIdentify({
      date: dateString,
      usedArea2: undefined,
      zoneId: undefined,
      restaurantId: undefined,
    });
    let itemRemoved: I_CartItem | undefined = undefined;
    let newTicketData: I_CartItem | null = null;

    onSetCart(prev => {
      itemRemoved = prev.find(item => item.identify === newIdentify);

      return prev.reduce((result, item) => {
        switch (true) {
          case newIdentify === item.identify:
            break;
          case identify !== item.identify:
            result.push(item);
            break;
          case identify === item.identify:
          default:
            newTicketData = itemRemoved
              ? {
                  ...itemRemoved,
                  date: dateString,
                  identify: newIdentify,
                  amount: `${+itemRemoved.amount + +amount}`,
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  itemKey: item?.itemKey,
                  itemKeys: [
                    ...(itemRemoved?.itemKeys ?? []),
                    ...(item?.itemKeys ?? []),
                    item.itemKey!,
                  ],
                }
              : {
                  ...item,
                  identify: newIdentify,
                  date: dateString,
                  amount: '0',
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  selected: false,
                  zone: isNotApplyZone ? null : {},
                  restaurant: null,
                  usedArea2: undefined,
                  usedAreas2: [],
                  origin: item?.origin ?? item,
                  itemKeys: [...(item?.itemKeys ?? []), item?.itemKey!],
                };
            result.push(newTicketData);
        }
        return result;
      }, [] as I_CartItem[]);
    });

    if (!itemRemoved) isLoadedUsedArea2Data.current = false;

    currentAmount.current = newTicketData!.amount;
    ticketsMergedNeedUpdateAmount.current = [
      ...ticketsMergedNeedUpdateAmount.current,
      newIdentify,
    ];

    const isEnoughDataToSync = checkEnoughDataToSyncTicket({
      amount: +newTicketData!.amount,
      restaurantId: newTicketData!.restaurant?.id,
      usedArea2: newTicketData!.usedArea2,
      zoneId: newTicketData,
    });

    const handleOnMergeTicket = () => {
      if (!isEnoughDataToSync) return false;
      onMergeTicket?.(
        {
          show_id: newTicketData!?.show?.id,
          amount: +newTicketData!.amount,
          date: newTicketData!.date,
          used_area2: newTicketData!.usedArea2,
          zone_id: newTicketData!.zone?.id,
          restaurant_id: newTicketData!.restaurant?.id,
          item_keys: newTicketData!?.itemKeys ?? [],
        },
        onMergeTicketSuccess,
        onMergeTicketError,
      );
    };

    handleGetTicketPrice({
      ticketData: {
        date: dateString,
        zone_id: undefined,
        used_area2: undefined,
        restaurant_id: undefined,
      },
      onMergeTicket: handleOnMergeTicket,
      isUpdateCart: isEnoughDataToSync,
    });
  };

  const handleChangeRestaurant = (_value, option) => {
    const newIdentify = generateIdentify({
      restaurantId: option?.id,
      usedArea2: undefined,
    });
    let itemRemoved: I_CartItem | undefined = undefined;
    let newTicketData: I_CartItem | null = null;

    setEnableGetUsedArea2(true);

    onSetCart(prev => {
      itemRemoved = prev.find(item => item.identify === newIdentify);

      return prev.reduce((result, item) => {
        switch (true) {
          case newIdentify === item.identify:
            break;
          case identify !== item.identify:
            result.push(item);
            break;
          case identify === item.identify:
          default:
            newTicketData = itemRemoved
              ? {
                  ...itemRemoved,
                  restaurant: option,
                  identify: newIdentify,
                  amount: `${+itemRemoved.amount + +amount}`,
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  itemKey: item?.itemKey,
                  itemKeys: [
                    ...(itemRemoved?.itemKeys ?? []),
                    ...(item?.itemKeys ?? []),
                    item.itemKey!,
                  ],
                }
              : {
                  ...item,
                  restaurant: option,
                  identify: newIdentify,
                  amount: '0',
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  zone: null,
                  usedArea2: undefined,
                  usedAreas2: [],
                  origin: item?.origin ?? item,
                  itemKeys: [...(item?.itemKeys ?? []), item?.itemKey!],
                };
            result.push(newTicketData);
        }
        return result;
      }, [] as I_CartItem[]);
    });

    if (!itemRemoved) isLoadedUsedArea2Data.current = false;

    currentAmount.current = newTicketData!.amount;
    ticketsMergedNeedUpdateAmount.current = [
      ...ticketsMergedNeedUpdateAmount.current,
      newIdentify,
    ];
  };

  const handleChangeZone = (_value, option) => {
    const newIdentify = generateIdentify({
      zoneId: option?.id,
      usedArea2: undefined,
    });
    let itemRemoved: I_CartItem | undefined = undefined;
    let newTicketData: I_CartItem | null = null;

    setEnableGetUsedArea2(true);

    onSetCart(prev => {
      itemRemoved = prev.find(item => item.identify === newIdentify);

      return prev.reduce((result, item) => {
        switch (true) {
          case newIdentify === item.identify:
            break;
          case identify !== item.identify:
            result.push(item);
            break;
          case identify === item.identify:
          default:
            newTicketData = itemRemoved
              ? {
                  ...itemRemoved,
                  zone: option,
                  identify: newIdentify,
                  amount: `${+itemRemoved.amount + +amount}`,
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  itemKey: item?.itemKey,
                  itemKeys: [
                    ...(itemRemoved?.itemKeys ?? []),
                    ...(item?.itemKeys ?? []),
                    item.itemKey!,
                  ],
                }
              : {
                  ...item,
                  zone: option,
                  identify: newIdentify,
                  created_at: Date.now(),
                  errorTicket: '',
                  errorTime: '',
                  usedArea2: undefined,
                  usedAreas2: [],
                  origin: !item?.origin ? { ...item } : item.origin,
                  itemKeys: [...(item?.itemKeys ?? []), item?.itemKey!],
                };
            result.push(newTicketData);
        }
        return result;
      }, [] as I_CartItem[]);
    });

    if (!itemRemoved) isLoadedUsedArea2Data.current = false;

    currentAmount.current = newTicketData!.amount;
    ticketsMergedNeedUpdateAmount.current = [
      ...ticketsMergedNeedUpdateAmount.current,
      newIdentify,
    ];
  };

  const handleChangeUsedArea2 = (_value, option) => {
    let itemRemoved: I_CartItem | undefined = undefined;
    let newTicketData: I_CartItem | undefined = undefined;
    const newIdentify = generateIdentify({ usedArea2: option.value });

    onSetCart(prev => {
      itemRemoved = prev.find(item => item.identify === newIdentify);

      return prev.reduce((result, item) => {
        switch (true) {
          case newIdentify === item.identify:
            break;
          case identify !== item.identify:
            result.push({ ...item });
            break;
          case identify === item.identify:
          default:
            newTicketData = itemRemoved
              ? {
                  ...itemRemoved,
                  usedArea2: option.value,
                  identify: newIdentify,
                  amount: `${+itemRemoved.amount + +amount}`,
                  itemKey: item?.itemKey,
                  itemKeys: [
                    ...(itemRemoved?.itemKeys ?? []),
                    ...(item?.itemKeys ?? []),
                    item?.itemKey!,
                  ],
                }
              : {
                  ...item,
                  usedArea2: option.value,
                  identify: newIdentify,
                  origin: !item?.origin ? { ...item } : item.origin,
                  itemKeys: [...(item?.itemKeys ?? []), item?.itemKey!],
                };
            result.push(newTicketData);
        }
        return result;
      }, [] as I_CartItem[]);
    });

    currentAmount.current = newTicketData!.amount;
    ticketsMergedNeedUpdateAmount.current = [
      ...ticketsMergedNeedUpdateAmount.current,
      newIdentify,
    ];
    const isNotEnoughDataToSync =
      !+newTicketData!.amount ||
      (!isNotApplyUsedArea2 && !newTicketData!.usedArea2) ||
      (!isNotApplyZone && !newTicketData!.zone?.id) ||
      (isRestaurantTicket && !newTicketData!.restaurant?.id);
    const handleOnMergeTicket = () => {
      if (isNotEnoughDataToSync) return false;
      onMergeTicket?.(
        {
          show_id: newTicketData!?.show?.id,
          amount: +newTicketData!.amount,
          date: newTicketData!.date,
          used_area2: newTicketData!.usedArea2,
          zone_id: newTicketData!.zone?.id,
          restaurant_id: newTicketData!.restaurant?.id,
          item_keys: newTicketData!?.itemKeys ?? [],
        },
        onMergeTicketSuccess,
        onMergeTicketError,
      );
    };
    handleGetTicketPrice({
      ticketData: {
        used_area2: option?.value,
      },
      onMergeTicket: handleOnMergeTicket,
      isUpdateCart: !isNotEnoughDataToSync,
    });
  };

  const convertDataOption = (arr, type) => {
    let result = [];
    if (arr?.length) {
      result = arr.map(item => ({
        ...item,
        value: type === 'usedArea2' ? item.value : item.id,
        label: type === 'usedArea2' ? item.label : item?.name,
      }));
    }
    return result;
  };

  const handleRemove = () => {
    onOpenDialogNoti({
      type: 'ERROR',
      isShow: true,
      title: '',
      content: translations?.['b2c.txt.cfRemoveTicket'],
      closable: false,
      buttons: [
        {
          text: translations?.['b2c.btn.cancel'] ?? '',
          bg: '#ffff',
          border: 'solid 1px #808080',
          color: '#808080',
          onClick: () => {
            onCloseDialogNoti();
          },
        },
        {
          text: translations?.['b2c.btn.remove'],
          bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
          color: 'white',
          onClick: () => {
            const onRemoveSuccess = () => {
              onSetCart(prev => prev.filter(item => item.itemKey !== itemKey));
            };
            onRemoveTicketList?.(
              [props?.origin?.itemKey ?? itemKey ?? '', ...(itemKeys ?? [])],
              onRemoveSuccess,
            );
            onCloseDialogNoti();
          },
        },
      ],
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isSubmitted && selected) {
      setTriggerValidateUsedArea2(true);
      setTriggerValidateZone(true);
      setTriggerValidateRestaurant(true);
    }
  }, [isSubmitted, selected]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const setInventoryToUndefined = () => {
      if (inventory === undefined) return;
      onSetCart(prev =>
        prev.map(item => {
          if (item.identify !== identify) return item;
          return { ...item, inventory: undefined };
        }),
      );
    };

    if (
      !date ||
      (!isNotApplyUsedArea2 && !usedArea2) ||
      (!isNotApplyZone && !zone?.id)
    ) {
      setTicketInventory(null);
      setInventoryToUndefined();
    }
  }, [
    date,
    identify,
    inventory,
    isNotApplyUsedArea2,
    isNotApplyZone,
    onSetCart,
    zone?.id,
    usedArea2,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (typeof inventory === 'number') {
      setTicketInventory(inventory);
    }
  }, [inventory]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    const idx = ticketsMergedNeedUpdateAmount.current.indexOf(identify!);
    if (idx > -1) {
      numberControlRef.current.setInternalValue(+amount!);
      ticketsMergedNeedUpdateAmount.current.splice(idx, 1);
    }
  });

  return (
    <>
      <TicketContainer
        gutter={33}
        style={{ flexDirection: isDesktopScreen ? 'row' : 'column' }}
        id={identify!}
      >
        <Col span={isDesktopScreen ? 5 : 24}>
          {show?.image?.original ? (
            <Thumb src={show?.image?.original} />
          ) : (
            <StyledDefaultImg />
          )}
        </Col>
        <Col span={isDesktopScreen ? 19 : 24}>
          {isDesktopScreen ? (
            <Row
              justify="space-between"
              style={{
                flexWrap: 'nowrap',
                flexDirection: 'row',
              }}
            >
              <Col
                style={{
                  maxWidth: 'calc(100% - 216px)',
                }}
              >
                <TicketName>{catalog?.name}</TicketName>
              </Col>
              <Col
                style={{
                  flexShrink: 0,
                  marginLeft: 10,
                  display: 'block',
                  marginTop: 0,
                }}
              >
                {!isDesktopScreen && (
                  <Time>
                    <p className="time-title">
                      {translations?.['b2c.txt.date-order']}{' '}
                      <span className="booking-time">
                        {moment
                          .unix(
                            parseFloat((created_at ?? 0)?.toString()) / 1000,
                          )
                          .format('DD/MM/YYYY')}
                      </span>
                    </p>
                  </Time>
                )}
                <Time>
                  <StyledCheckbox
                    checked={selected}
                    onClick={handleToggleSelect}
                  >
                    <p className="time-title">
                      {translations?.['b2c.txt.date-order']}{' '}
                      <span className="booking-time">
                        {moment
                          .unix(
                            parseFloat((created_at ?? 0)?.toString()) / 1000,
                          )
                          .format('DD/MM/YYYY')}
                      </span>
                    </p>
                  </StyledCheckbox>
                </Time>
              </Col>
            </Row>
          ) : (
            <Row
              justify="space-between"
              style={{
                flexWrap: 'nowrap',
                flexDirection: 'column',
              }}
            >
              <Col
                style={{
                  flexShrink: 0,
                  marginLeft: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 16,
                }}
              >
                <Time>
                  <p className="time-title">
                    {translations?.['b2c.txt.date-order']}{' '}
                    <span className="booking-time">
                      {moment
                        .unix(parseFloat((created_at ?? 0)?.toString()) / 1000)
                        .format('DD/MM/YYYY')}
                    </span>
                  </p>
                </Time>
                <Time>
                  <TrashIcon
                    style={{ cursor: 'pointer', marginRight: 16 }}
                    onClick={handleRemove}
                  />
                  <StyledCheckbox
                    checked={selected}
                    onClick={handleToggleSelect}
                  />
                </Time>
              </Col>
              <Col
                style={{
                  maxWidth: 'auto',
                }}
              >
                <TicketName>{catalog?.name}</TicketName>
              </Col>
            </Row>
          )}
          <Catalog>{show?.name}</Catalog>
          <div>
            <Row align="middle" gutter={32}>
              <Col span={isDesktopScreen ? 9 : 24}>
                <div
                  style={{
                    position: 'relative',
                    marginTop: isDesktopScreen ? 0 : 8,
                  }}
                >
                  <CustomDatePicker
                    value={moment(date, 'YYYY-MM-DD')}
                    format="YYYY-MM-DD"
                    isComboDiffTicket={isComboDifTicket}
                    showDateFrom={show?.dateFrom}
                    showDateTo={show?.dateTo}
                    weekend={show?.weekend}
                    onChange={handleChangeTime}
                  />
                </div>
              </Col>
              {isDesktopScreen && (
                <PriceBLock>
                  <ListedPrice>
                    {formatNumber(originalPrice).replaceAll(',', '.') + 'đ'}
                  </ListedPrice>
                  <PromotionalPrice>
                    {formatNumber(price).replaceAll(',', '.') + 'đ'}
                  </PromotionalPrice>
                </PriceBLock>
              )}
              {isDesktopScreen && (
                <Col>
                  <NumberControl
                    number={parseFloat(amount)}
                    onDecrement={handleDecrementNumber}
                    onIncrement={handleIncrementNumber}
                    onChange={handleOnChangeNumber}
                    disabledMinus={parseFloat(amount) < 1}
                    disablePlus={
                      (isNotApplyZone ? false : !zone?.id) ||
                      (isNotApplyUsedArea2 ? false : !usedArea2) ||
                      ((usedAreas2 ?? []).length < 1 && !isNotApplyUsedArea2) ||
                      +amount >= (inventory ?? 0)
                    }
                    inputReadOnly={
                      (isNotApplyZone ? false : !zone?.id) ||
                      (isNotApplyUsedArea2 ? false : !usedArea2) ||
                      ((usedAreas2 ?? []).length < 1 && !isNotApplyUsedArea2)
                    }
                    onBlur={handleOnBlurInputNumber}
                    min={0}
                    max={
                      isChangingAmount.current ? ticketInventory! : undefined
                    }
                    ref={numberControlRef}
                  />
                </Col>
              )}
            </Row>
            <Row
              gutter={32}
              style={{
                flexWrap: 'nowrap',
                marginTop: isDesktopScreen ? 0 : 10,
              }}
            >
              <Col span={isDesktopScreen ? 9 : 24}>
                {(!isNotApplyUsedArea2 &&
                  isLoadedUsedArea2Data.current &&
                  (usedAreas2 ?? []).length < 1) ||
                errorTime ||
                !isHaveTicketOnSelectedDate ? (
                  <ErrMess>
                    {errorTime ||
                      translations?.['b2c.txt.detail-show-no-performance']}
                  </ErrMess>
                ) : null}
              </Col>
              {isDesktopScreen && (
                <>
                  <Col>
                    <div style={{ minWidth: 110 }}></div>
                  </Col>
                  <Col>
                    {errorTicket ? (
                      <div style={{ maxWidth: 180 }}>
                        <ErrMess>{errorTicket}</ErrMess>
                      </div>
                    ) : null}
                  </Col>
                </>
              )}
            </Row>
          </div>
          {!isNotApplyUsedArea2 || isDesktopScreen ? (
            <div>
              <PositionAndTimeBlock gutter={24}>
                {isRestaurantTicket ? (
                  <Col span={isDesktopScreen ? 6 : 12}>
                    <SelectPopupContainer
                      ref={selectRestaurantPopupContainer}
                    />
                    <StyledSelect
                      options={
                        convertDataOption(
                          show?.restaurants ?? [],
                          'restaurant',
                        ) ?? []
                      }
                      value={restaurant?.id}
                      onChange={handleChangeRestaurant}
                      placeholder={show.restaurants[0].label}
                      onBlur={() => setTriggerValidateRestaurant(true)}
                      getPopupContainer={() =>
                        selectRestaurantPopupContainer.current!
                      }
                      suffixIcon={<ArrowDownIcon />}
                    />
                  </Col>
                ) : null}
                {!isNotApplyUsedArea2 ? (
                  <>
                    {!isNotApplyZone ? (
                      <Col span={isDesktopScreen ? 6 : 12}>
                        {isNotApplyZone ? null : (
                          <>
                            <SelectPopupContainer
                              ref={selectZonePopupContainer}
                            />
                            <StyledSelect
                              options={
                                convertDataOption(show?.zones ?? [], 'zone') ??
                                []
                              }
                              value={zone?.id}
                              onChange={handleChangeZone}
                              placeholder={translations?.['b2c.txt.zone']}
                              onBlur={() => setTriggerValidateZone(true)}
                              getPopupContainer={() =>
                                selectZonePopupContainer.current!
                              }
                              suffixIcon={<ArrowDownIcon />}
                            />
                          </>
                        )}
                      </Col>
                    ) : (
                      <Col span={0}></Col>
                    )}

                    <Col span={isDesktopScreen ? 6 : 12}>
                      {isNotApplyUsedArea2 ? null : (
                        <>
                          <SelectPopupContainer
                            ref={selectPerformancePopupContainer}
                          />
                          <StyledSelect
                            options={
                              convertDataOption(
                                usedAreas2 ?? [],
                                'usedArea2',
                              ) ?? []
                            }
                            value={usedArea2}
                            onChange={handleChangeUsedArea2}
                            placeholder={
                              show?.usedArea2Label ||
                              translations?.['b2c.txt.hours']
                            }
                            onBlur={() => setTriggerValidateUsedArea2(true)}
                            getPopupContainer={() =>
                              selectPerformancePopupContainer.current!
                            }
                            suffixIcon={<ArrowDownIcon />}
                          />
                        </>
                      )}
                    </Col>
                  </>
                ) : null}
                {isDesktopScreen ? (
                  <Col
                    offset={
                      (2 -
                        [
                          !isNotApplyUsedArea2,
                          !isNotApplyZone,
                          isRestaurantTicket,
                        ].filter(i => i).length) *
                      6
                    }
                  >
                    <div
                      style={{
                        marginLeft: 65,
                      }}
                    >
                      {ticketInventory === null ? null : (
                        <span
                          style={{
                            color: '#29343D',
                            width: 'max-content',
                            textAlign: 'right',
                            maxWidth: 200,
                          }}
                        >
                          <span
                            style={{
                              color: '#808080',
                              fontWeight: 400,
                              textAlign: 'justify',
                            }}
                          >
                            {ticketInventory < (max_inventory ?? 0)
                              ? `(${formatNumber(ticketInventory || 0)} ${
                                  translations?.['b2c.txt.in-stock'] ?? ''
                                })`
                              : `(${
                                  translations?.['b2c.txt.stillHaveTickets'] ??
                                  ''
                                })`}
                          </span>
                        </span>
                      )}
                    </div>
                  </Col>
                ) : null}
              </PositionAndTimeBlock>
              {!isNotApplyUsedArea2 ? (
                <Row
                  gutter={24}
                  style={{
                    flexWrap: 'nowrap',
                    marginTop: isDesktopScreen ? 0 : 10,
                  }}
                >
                  <Col span={!isRestaurantTicket ? 0 : isDesktopScreen ? 6 : 8}>
                    <div style={{ width: isDesktopScreen ? 133 : 'auto' }}>
                      {triggerValidateRestaurant && !restaurant?.id && (
                        <ErrMess>
                          {`${translations?.['b2c.txt.plsChoose']} ${
                            show?.restaurants?.[0]?.label?.toLowerCase() ?? ''
                          }`}
                        </ErrMess>
                      )}
                    </div>
                  </Col>
                  <Col span={isNotApplyZone ? 0 : isDesktopScreen ? 6 : 12}>
                    {isNotApplyZone ? null : (
                      <div style={{ width: isDesktopScreen ? 133 : 'auto' }}>
                        {triggerValidateZone && !zone?.id && (
                          <ErrMess>
                            {translations?.['b2c.txt.validateZone']}
                          </ErrMess>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col span={isDesktopScreen ? 6 : 12}>
                    {isNotApplyUsedArea2 ? null : (
                      <div style={{ width: isDesktopScreen ? 133 : 'auto' }}>
                        {triggerValidateUsedArea2 && !usedArea2 && (
                          <ErrMess>
                            {`${translations?.['b2c.txt.plsChoose']} ${
                              show?.usedArea2Label?.toLowerCase() ?? ''
                            }`}
                          </ErrMess>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              ) : null}
            </div>
          ) : null}
          {!isDesktopScreen && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                marginTop: 6,
              }}
            >
              <PriceBLock>
                <ListedPrice>
                  {formatNumber(originalPrice).replaceAll(',', '.') + 'đ'}
                </ListedPrice>
                <PromotionalPrice>
                  {formatNumber(price).replaceAll(',', '.') + 'đ'}
                </PromotionalPrice>
              </PriceBLock>
              <div className="d-flex flex-column align-items-end">
                {ticketInventory === null || device === 'mobile' ? null : (
                  <span
                    style={{
                      color: '#29343D',
                      width: 'max-content',
                      textAlign: 'right',
                      margin: '10px 0 16px 0',
                    }}
                  >
                    <span
                      style={{
                        color: '#808080',
                        fontWeight: 400,
                        textAlign: 'justify',
                      }}
                    >
                      {ticketInventory < (max_inventory ?? 0)
                        ? `(${formatNumber(ticketInventory || 0)} ${
                            translations?.['b2c.txt.in-stock'] ?? ''
                          })`
                        : `(${
                            translations?.['b2c.txt.stillHaveTickets'] ?? ''
                          })`}
                    </span>
                  </span>
                )}
                <NumberControl
                  number={parseFloat(amount)}
                  onDecrement={handleDecrementNumber}
                  onIncrement={handleIncrementNumber}
                  onChange={handleOnChangeNumber}
                  disabledMinus={parseFloat(amount) < 1}
                  disablePlus={
                    (isNotApplyZone ? false : !zone?.id) ||
                    (isNotApplyUsedArea2 ? false : !usedArea2) ||
                    ((usedAreas2 ?? []).length < 1 && !isNotApplyUsedArea2) ||
                    +amount >= (inventory ?? 0)
                  }
                  inputReadOnly={
                    (isNotApplyZone ? false : !zone?.id) ||
                    (isNotApplyUsedArea2 ? false : !usedArea2) ||
                    ((usedAreas2 ?? []).length < 1 && !isNotApplyUsedArea2)
                  }
                  onBlur={handleOnBlurInputNumber}
                  min={0}
                  max={isChangingAmount.current ? ticketInventory! : undefined}
                  ref={numberControlRef}
                />
                {errorTicket ? (
                  <ErrMess
                    style={{
                      marginTop: 10,
                      width: 'initial',
                      textAlign: 'right',
                    }}
                  >
                    {errorTicket}
                  </ErrMess>
                ) : null}
              </div>
            </div>
          )}
          {isDesktopScreen ? null : (
            <div
              className="d-flex align-items-center"
              style={{
                justifyContent: isNotApplyZone ? 'flex-end' : 'space-between',
              }}
            >
              {isNotApplyZone ? null : (
                <GrandstandDiagram grandstandData={grandstandData} />
              )}
              {ticketInventory === null || device !== 'mobile' ? null : (
                <span
                  style={{
                    color: '#29343D',
                    width: 'max-content',
                    textAlign: 'right',
                    margin: '16px 0',
                  }}
                >
                  <span
                    style={{
                      color: '#808080',
                      fontWeight: 400,
                      textAlign: 'justify',
                    }}
                  >
                    {ticketInventory < (max_inventory ?? 0)
                      ? `(${formatNumber(ticketInventory || 0)} ${
                          translations?.['b2c.txt.in-stock'] ?? ''
                        })`
                      : `(${translations?.['b2c.txt.stillHaveTickets'] ?? ''})`}
                  </span>
                </span>
              )}
            </div>
          )}
          {isDesktopScreen && (
            <Row justify="space-between">
              <Col>
                {isNotApplyZone ? null : (
                  <GrandstandDiagram grandstandData={grandstandData} />
                )}
              </Col>
              <Col>
                <TrashIcon
                  style={{ cursor: 'pointer' }}
                  onClick={handleRemove}
                />
              </Col>
            </Row>
          )}
        </Col>
      </TicketContainer>
      <DialogWarning
        open={!!warningDialogMsg}
        message={warningDialogMsg}
        onClose={() => {
          setWarningDialogMsg('');
          onSetCart(prev =>
            prev.map(item => {
              if (item.identify !== identify) return item;
              return { ...item.origin! };
            }),
          );
        }}
      />
    </>
  );
};

export default React.memo(
  TicketItem,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

const TicketContainer = styled(Row)`
  padding: 24px 0;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    padding: 16px 0;
  }
`;

const Thumb = styled.img`
  border-radius: 16px;
  aspect-ratio: 7 / 9;
  width: 100%;
  object-fit: cover;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    height: 324px;
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    height: 237px;
  }
`;

const TicketName = styled.h3`
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-bottom: 5px;
  line-height: 23px;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    margin: 8px 0 4px 0;
  }
`;

const Catalog = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 30px;
  margin-bottom: 5px;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PriceBLock = styled(Col)`
  min-width: 140px;
`;

const ListedPrice = styled.del`
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    margin-left: -16px;
  }
`;

const PromotionalPrice = styled.p`
  color: #e92127;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    margin-left: -16px;
  }
`;

const PositionAndTimeBlock = styled(Row)`
  margin-top: 13px;
`;

const StyledSelect = styled(Select)`
  width: 133px;
  &.ant-select {
    .ant-select-selector {
      border: none;
      border-bottom: solid 1px #ededed;
      padding-left: 0;
    }
    &:hover {
      .ant-select-selector {
        border-color: #ededed;
      }
    }
    .ant-select-arrow {
      right: 4px;
    }
  }
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    width: 100%;
  }
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .time-title {
    margin: 0;
    font-size: 14px;
    color: #808080;
    .booking-time {
      font-weight: 600;
    }
  }
`;

const ErrMess = styled.p`
  margin: 0;
  color: #e55015;
  font-size: 14px;
  font-style: italic;
  width: max-content;
  max-width: 100%;
`;

const SelectPopupContainer = styled.div`
  & > div:first-child {
    z-index: 1;
  }
`;

const StyledDefaultImg = styled(DefaultImg)`
  aspect-ratio: 7 / 9;
  height: auto;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    height: 324px;
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    height: 237px;
  }
`;
