import React, { useMemo, useRef } from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';

import NewItems from './components/NewsItem';
import { ReactComponent as Arrow } from 'app/assets/img/arrow.svg';
import { selectListNew } from 'app/pages/Catalog/slice/selectors';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import moment from 'moment';
import useWindowSize, { DESKTOP_WIDTH } from 'app/hooks/useWindowSize';
const News = props => {
  const carouselRef = useRef<any>(null!);
  const translations = useSelector(selectTranslations);
  const { listNew } = props;
  const slidesToShow = props?.slidesToShow ?? 1;
  const nextAction = () => {
    carouselRef.current?.next();
  };
  const {
    windowSize: { width: screenWidth },
  } = useWindowSize();
  const isDesktopScreen = useMemo(
    () => screenWidth >= DESKTOP_WIDTH,
    [screenWidth],
  );

  const prevAction = () => {
    carouselRef.current?.prev();
  };
  return (
    <Container>
      <Title> {translations ? translations[`b2c.txt.newAndEvent`] : ''}</Title>
      <CarouselWrapper>
        <StyledCarousel
          ref={carouselRef}
          dots={!isDesktopScreen}
          slidesToShow={slidesToShow}
          arrows={listNew.length > 1 && isDesktopScreen}
          prevArrow={<NavigateButton onClick={prevAction} />}
          nextArrow={<NavigateButton onClick={nextAction} isNextBtn />}
        >
          {listNew.map((item, index) => (
            <React.Fragment key={index}>
              <NewItems
                slidesToShow={slidesToShow}
                title={item?.title}
                description={item?.shortText}
                href={item?.newLink}
                imgUrl={item?.image?.original}
                time={moment(item?.date).format('DD/MM/YYYY')}
                translations={translations}
              />
            </React.Fragment>
          ))}
        </StyledCarousel>
      </CarouselWrapper>
    </Container>
  );
};

export default News;

const Container = styled.div`
  width: 100%;
  margin-top: 52px;
  @media (max-width: 1199px) {
    order: 3;
    margin-top: 32px;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #424242;
  margin: 0;
`;

const CarouselWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  @media (max-width: 1199px) {
    margin-top: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const StyledCarousel = styled(Carousel)`
  .slick-dots {
    margin-left: 0;
    margin-right: 0;
    li {
      background: #c1c1c1;
      width: initial;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      overflow: hidden;
      button {
        opacity: 0 !important;
        height: 5px;
        width: 5px;
        background: #c1c1c1;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 25px;
        background: #fab317;
        border-radius: 99px;
      }
    }
  }
  .slick-dots-bottom {
    bottom: -16px;
  }
`;

interface NavigateButtonProps {
  isNextBtn?: boolean;
  onClick: () => void;
}

const NavigateButton = (props: NavigateButtonProps) => {
  const { isNextBtn, onClick } = props;

  return (
    <StyledButton isNextBtn={isNextBtn} onClick={onClick}>
      <Arrow style={{ transform: `rotate(${isNextBtn ? '180deg' : '0'})` }} />
    </StyledButton>
  );
};

const StyledButton = styled.button<{ isNextBtn?: boolean }>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  ${props => (props.isNextBtn ? 'right: 0;' : 'left: 0;')}
  transform: translate(${props => (props.isNextBtn ? '50%' : '-50%')}, -50%);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
  svg path {
    transition-duration: 0.25s;
  }
  &:hover {
    svg path {
      stroke: #fab317;
    }
  }
`;
