import AuthenticationModalWrapper from 'app/pages/App/components/AuthenticationModalWrapper';
import OTPRegister, {
  replaceDescription,
} from 'app/pages/App/components/OTPRegister';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import {
  selectInfoUser,
  selectTranslations,
} from 'app/pages/App/slice/selectors';
import { changePassApi, sendOtpChangePassApi } from 'app/services/authApi';
import axios from 'axios';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { objectType } from 'types';

interface Props {
  // Define your component's props here
  isShow: boolean;
  onCloseDialog?: () => void;
  values: objectType;
}

const ChangePassOtpModal: React.FC<Props> = ({
  isShow,
  onCloseDialog,
  values,
}) => {
  // Implement your component logic here
  const translations = useSelector(selectTranslations);
  const infoUser = useSelector(selectInfoUser);
  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();

  const verifyOtp = useCallback(
    (isResend: boolean) => {
      const payload = { ...values };

      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (!isResend) {
        return async (otp?: string) => {
          formData.append('otp', otp ?? '');
          return await changePassApi(formData);
        };
      }
      return async () => {
        return await sendOtpChangePassApi(formData);
      };
    },
    [values],
  );
  return (
    // JSX code for your component's UI
    <AuthenticationModalWrapper
      title={translations ? translations['b2c.auth.changePassOtp'] : ''}
      isShow={isShow}
      description={replaceDescription(
        translations?.['b2c.auth.enterChangePassOtpText'] ?? '',
        '${email}',
        infoUser?.email ?? '',
      )}
      height={'650px'}
      onCloseDialog={onCloseDialog}
    >
      {infoUser?.email && (
        <>
          <OTPRegister
            type="CHANGE_PASS"
            mailRegister={infoUser?.email ?? ''}
            verifyOtpApiImp={verifyOtp(false)}
            resendOtpApiImp={verifyOtp(true)}
            onVeriyOtpSuccess={otp => {
              onOpenDialogNoti({
                type: 'SUCCESS',
                isShow: true,
                title: translations?.['b2c.txt.changePassSuccessTitle'] || '',
                content: '',
                closable: true,
                onCloseDialog: onCloseDialogNoti,
                maskClosable: false,
              });
              onCloseDialog && onCloseDialog();
            }}
            setRegisterStep={() => {}}
            // onErrorVerifyOtp={err => {
            //   if (axios.isAxiosError(err)) {
            //     const resData = err.response?.data as {
            //       field?: string;
            //       message?: string;
            //     };
            //     if (
            //       err.response?.status === 400 &&
            //       resData?.field &&
            //       resData?.message
            //     ) {
            //       onOpenDialogNoti({
            //         type: 'ERROR',
            //         isShow: true,
            //         title: resData?.message ?? '',
            //         content: '',
            //         closable: true,
            //         onCloseDialog: onCloseDialogNoti,
            //         maskClosable: false,
            //       });
            //     }
            //   }
            // }}
            // onErrorResendOtp={err => {
            //   if (axios.isAxiosError(err)) {
            //     const resData = err.response?.data as {
            //       field?: string;
            //       message?: string;
            //     };
            //     if (
            //       err.response?.status === 400 &&
            //       resData?.field &&
            //       resData?.message
            //     ) {
            //       onOpenDialogNoti({
            //         type: 'ERROR',
            //         isShow: true,
            //         title: resData?.message ?? '',
            //         content: '',
            //         closable: true,
            //         onCloseDialog: onCloseDialogNoti,
            //         maskClosable: false,
            //       });
            //     }
            //   }
            // }}
          />
        </>
      )}
    </AuthenticationModalWrapper>
  );
};

export default ChangePassOtpModal;
