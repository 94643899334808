import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ADD_CART,
  GET_CART,
  GET_TICKET_PRICE,
  MERGE_TICKET,
  NEW_UPDATE_CART,
  REMOVE_CART,
  UPDATE_CART,
  cartActions,
  useCartSlice,
} from '../slice';
import { selectListCart } from '../slice/selectors';
import { I_CartItem, Park } from '../slice/types';

export type PaymentMethod = string | number | null;

export interface I_TicketItemByPark {
  park: Park;
  tickets: I_CartItem[];
}

export interface I_TicketDataToUpdate {
  show_id: string;
  amount: number;
  date: string;
  performance?: number;
  zone_id?: string;
  item_key: string;
  restaurant_id?: string;
  used_area2?: string;
}

export interface I_TicketDataToAdd {
  show_id?: string;
  amount?: number;
  date?: string;
  performance?: number;
  used_area2?: string;
  zone_id?: string;
  payment?: string;
  restaurant_id?: string;
}
export interface I_TicketDataToMerge {
  show_id: string;
  amount: number;
  date: string;
  performance?: number;
  zone_id?: string;
  restaurant_id?: number;
  used_area2?: string | number;
  item_keys: string[];
}

export interface I_TicketDataToGetPrice {
  show_id: string;
  performance?: number;
  date: string;
  zone_id?: string;
  amount?: string;
}

export type OnUpdateTicket = (
  dataToUpdate: I_TicketDataToUpdate,
  onSuccess?: (response, currentCartData) => void,
  onError?: (error, currentCartData) => void,
) => void;

export type OnRemoveTicket = (
  itemKey: string,
  onSuccess?: (response) => void,
  onError?: (error) => void,
) => void;

export type OnAddTicket = (
  dataOnAdd: I_TicketDataToAdd,
  onSuccess?: (response) => void,
  onError?: (error) => void,
) => void;

export type OnMergeTicket = (
  dataToMerge: I_TicketDataToMerge,
  onSuccess?: (response, currentCartData) => void,
  onError?: (error, currentCartData) => void,
) => void;

export type OnGetTicketPrice = (
  dataToGetPrice: I_TicketDataToGetPrice[],
  paymentMethod: PaymentMethod,
  onSuccess?: (response, currentCartData) => void,
  onError?: (error, currentCartData) => void,
) => void;

export type OnSetCart = (
  state: I_CartItem[] | ((prev: I_CartItem[]) => I_CartItem[]),
) => void;

export type OnRemoveTicketList = (
  itemKeys: string[],
  onSuccess?: (response) => void,
  onError?: (error) => void,
) => void;

export const useCartNew = () => {
  useCartSlice();
  const dispatch = useDispatch();
  const listCart = useSelector(selectListCart);
  const onUpdateTicketTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const onAddTicketTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const onMergeTicketTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const history = useHistory();

  const ticketsSortedByPark = useMemo(() => {
    const result: I_TicketItemByPark[] = [];

    listCart.forEach(ticket => {
      const {
        show: {
          park: { id: parkId },
        },
      } = ticket;

      const indexParkExists = result.findIndex(i => i.park.id === parkId);

      if (indexParkExists > -1) {
        result[indexParkExists].tickets.push(ticket);
        return true;
      }
      result.push({
        park: ticket.show.park,
        tickets: [ticket],
      });
    });

    return result;
  }, [listCart]);

  const totalAmountSelected = useMemo(
    () =>
      listCart.reduce((result, item) => {
        return item?.selected ? result + +item.amount : result;
      }, 0),
    [listCart],
  );

  const totalPriceOriginalSelected = useMemo(
    () =>
      listCart.reduce(
        (result, item) =>
          item?.selected ? result + item.originalPrice * +item.amount : result,
        0,
      ),
    [listCart],
  );

  const totalPriceSelected = useMemo(
    () =>
      listCart.reduce(
        (result, item) =>
          item?.selected ? result + item.price * +item.amount : result,
        0,
      ),
    [listCart],
  );

  const onSetCart = useCallback<OnSetCart>(
    cart => {
      dispatch(cartActions.onSetCart(cart));
    },
    [dispatch],
  );

  const onUpdateTicket = useCallback<OnUpdateTicket>(
    (dataToUpdate, onSuccess, onError) => {
      if (onUpdateTicketTimeoutRef.current)
        clearTimeout(onUpdateTicketTimeoutRef.current);

      onUpdateTicketTimeoutRef.current = setTimeout(() => {
        const formData = new FormData();
        for (let key in dataToUpdate) {
          formData.append(key, dataToUpdate[key]);
        }
        formData.append('created_at', Date.now().toString());
        dispatch(
          NEW_UPDATE_CART({
            data: formData,
            onSuccess,
            onError,
          }),
        );
      }, 1000);
    },
    [dispatch],
  );

  const onMergeTicket = useCallback<OnMergeTicket>(
    (dataToMerge, onSuccess, onError) => {
      if (onMergeTicketTimeoutRef.current)
        clearTimeout(onMergeTicketTimeoutRef.current);

      onMergeTicketTimeoutRef.current = setTimeout(() => {
        const formData = new FormData();
        for (let key in dataToMerge) {
          if (key === 'item_keys') {
            [...new Set(dataToMerge[key])]?.forEach(itemKey => {
              formData.append(`item_keys[]`, itemKey);
            });
          } else {
            formData.append(key, dataToMerge[key]);
          }
        }
        formData.append('created_at', Date.now().toString());

        dispatch(
          MERGE_TICKET({
            data: formData,
            onSuccess,
            onError,
          }),
        );
      }, 1000);
    },
    [dispatch],
  );

  const onRemoveTicket = useCallback<OnRemoveTicket>(
    (itemKey, onSuccess, onError) => {
      const formData = new FormData();
      formData.append('item_key', itemKey);
      dispatch(REMOVE_CART({ data: formData, onSuccess, onError }));
    },
    [dispatch],
  );

  const onRemoveTicketList = useCallback<OnRemoveTicketList>(
    (itemsKeys, onSuccess, onError) => {
      const formData = new FormData();
      [...new Set(itemsKeys)].forEach(itemKey => {
        formData.append('item_key[]', itemKey);
      });
      dispatch(REMOVE_CART({ data: formData, onSuccess, onError }));
    },
    [dispatch],
  );

  const onAddTicket = useCallback<OnAddTicket>(
    (dataToAdd, onSuccess, onError) => {
      if (onAddTicketTimeoutRef.current)
        clearTimeout(onAddTicketTimeoutRef.current);
      onAddTicketTimeoutRef.current = setTimeout(() => {
        const formData = new FormData();
        for (let key in dataToAdd) {
          formData.append(key, dataToAdd[key]);
        }
        formData.append('created_at', Date.now().toString());
        dispatch(
          ADD_CART({
            data: formData,
            onSuccess,
            onError,
          }),
        );
      }, 1000);
    },
    [dispatch],
  );

  const onGetCart = useCallback(
    (onSuccess?: (response) => void) => {
      dispatch(GET_CART({ onSuccess }));
    },
    [dispatch],
  );

  const onGoToCatologPage = useCallback(() => {
    history.push('/catalog');
  }, [history]);

  const onGetTicketPrice = useCallback<OnGetTicketPrice>(
    (dataToGetPrice, paymentMethod, onSuccess, onError) => {
      const formData = new FormData();
      dataToGetPrice.forEach((ticket, idx) => {
        for (let key in ticket) {
          formData.append(`items[${idx}][${key}]`, ticket[key]);
        }
        formData.append(
          `items[${idx}][payment]`,
          (paymentMethod ?? '').toString(),
        );
      });
      dispatch(
        GET_TICKET_PRICE({
          data: formData,
          onSuccess,
          onError,
        }),
      );
    },
    [dispatch],
  );

  return {
    ticketsSortedByPark,
    listCart,
    onSetCart,
    onUpdateTicket,
    onRemoveTicket,
    onAddTicket,
    totalAmountSelected,
    totalPriceSelected,
    totalPriceOriginalSelected,
    onGoToCatologPage,
    onGetCart,
    onMergeTicket,
    onGetTicketPrice,
    onRemoveTicketList,
  };
};
