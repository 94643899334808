import React from 'react';
import { ReactComponent as NoTicketIcon } from 'app/assets/img/no-ticket-icon.svg';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StyledButton from 'app/components/StyledButton';
import { useHistory } from 'react-router-dom';

const NodataView = ({ text }: { text: string }) => {
  const translations = useSelector(selectTranslations);
  const history = useHistory();
  return (
    <StyledDiv>
      <NoTicketIcon />
      <div className="title" style={{ textAlign: 'center' }}>
        {/* {translations ? translations['b2c.txt.nodataHistory'] : ''} */}
        {text}
      </div>
      <div>
        <StyledButton
          textColor="white"
          onClick={() => history.push('/catalog')}
          style={{ minWidth: '160px', width: 'initial' }}
        >
          {translations ? translations['b2c.txt.getTicket'] : ''}
        </StyledButton>
      </div>
    </StyledDiv>
  );
};

export default NodataView;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #6f8295;
    margin: 24px 0px;
  }
`;
