/**
 *
 * Asynchronously loads the component for PaymentCallback
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PaymentFail = lazyLoad(
  () => import('./PaymentFail'),
  module => module.PaymentFail,
);

export const PaymentSuccess = lazyLoad(
  () => import('./PaymentSuccess'),
  module => module.PaymentSuccess,
);
