export type FormatType =
  | 'YYYY-MM-DD'
  | 'DD-MM-YYYY'
  | 'MM-DD-YYYY'
  | 'HH:mm DD/MM/YYYY'
  | 'HH:mm:ss DD/MM/YYYY'
  | 'DD/MM/YYYY'
  | 'YYYY-MM-DD HH:mm';

export type FromToDateData = {
  fromDateString: string;
  toDateString: string;
  concatFromToDate: string;
  concatFromToDateWithDay: string;
};

export type Locale = 'vi' | 'en';

export interface IDateFormat {
  getMoment(): moment.Moment | undefined;
}

export interface IDateFromToFunction {
  getDateFromTo(): FromToDateData;
}

export interface IDateFromToBuilder {
  setFromDate(fromDate?: moment.Moment): IDateFromToBuilder;
  setToDate(toDate?: moment.Moment): IDateFromToBuilder;
  setLocale(locale: Locale): IDateFromToBuilder;
  setDateOutputFormat(dateOutputFormat: FormatType): IDateFromToBuilder;
  build(): IDateFromToFunction;
}

export abstract class DateFromTo {
  constructor(
    public locale: Locale,
    public dateOutputFormat: FormatType,
    public fromDate?: moment.Moment,
    public toDate?: moment.Moment,
  ) {}
}
