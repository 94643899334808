import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { cartSaga } from './saga';
import { CartState, I_CartItem } from './types';
import { generateTicketIdentify } from '../utils';

export const GET_CART = createRoutine('cart/getCart');
export const UPDATE_CART = createRoutine('cart/updateCart');
export const NEW_UPDATE_CART = createRoutine('cart/newUpdateCart');
export const ADD_CART = createRoutine('cart/addCart');
export const REMOVE_CART = createRoutine('cart/removeCart');
export const RESET_ERROR_WARNING = createRoutine('cart/resetErrorWarning');
export const RESET_ERROR_TICKET = createRoutine('cart/resetErrorTicket');
export const GET_TICKET_PRICE = createRoutine('cart/getTicketPrice');
export const MERGE_TICKET = createRoutine('cart/mergeTicket');

export const initialState: CartState = {
  loading: false,
  success: false,
  error: false,
  cart: [],
  datesSelectedToCheckout: [],
  isUpdateSuccess: false,
  errorWarning: null,
  errorTickets: null,
  errorTimeTicket: null,
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
    onSetCart(
      state,
      action: PayloadAction<
        I_CartItem[] | ((prev: I_CartItem[]) => I_CartItem[])
      >,
    ) {
      const payload = action.payload;
      state.cart =
        typeof payload === 'function' ? payload(state.cart) : payload;
    },
    onSetDatesSelectedToCheckout(state, action: PayloadAction<string[]>) {
      state.datesSelectedToCheckout = action.payload;
    },
    onResetError(state) {
      state.errorWarning = null;
    },
    onSetCartLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    onSetCartBySetter(
      state,
      action: PayloadAction<(prev: I_CartItem[]) => I_CartItem[]>,
    ) {
      state.cart = action.payload(state.cart);
    },
  },
  extraReducers: {
    /**
     * ! GET CART
     */
    [GET_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.errorWarning = null;
    },
    [GET_CART.SUCCESS]: (state, action: PayloadAction<I_CartItem[]>) => {
      state.cart = action.payload.map(ticket => ({
        ...ticket,
        identify: generateTicketIdentify({
          showId: ticket.show?.id,
          performanceId: ticket?.performance?.id,
          zoneId: ticket?.zone?.id,
          date: ticket?.date,
          restaurantId: ticket?.restaurant?.id,
          usedArea2: ticket?.usedArea2,
        }),
        // selected: ticket.errorTime || ticket.errorTicket ? false : true,
        selected: true,
      }));
      state.loading = false;
      state.success = true;
      state.error = false;
      state.errorWarning = null;
    },
    [GET_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! ADD CART
     */
    [ADD_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ADD_CART.SUCCESS]: (state, action: PayloadAction<I_CartItem[]>) => {
      // state.cart = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [ADD_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! REMOVE CART
     */
    [REMOVE_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [REMOVE_CART.SUCCESS]: (
      state,
      action: PayloadAction<string | string[]>,
    ) => {
      if (typeof action.payload === 'string') {
        state.cart = state.cart.filter(
          ticket => ticket.itemKey !== action.payload,
        );
      }
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [REMOVE_CART.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * NOTE: UPDATE CART
     */
    [UPDATE_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.isUpdateSuccess = false;
      state.errorWarning = null;
    },
    [UPDATE_CART.SUCCESS]: (state, action: any) => {
      const { itemAddToCartID } = action.payload;
      let mappedCart = action.payload?.cart.map(ticket => {
        const identify = generateTicketIdentify({
          showId: ticket.show?.id,
          performanceId: ticket?.performance?.id,
          zoneId: ticket?.zone?.id,
          date: ticket?.date,
          restaurantId: ticket?.restaurant?.id,
        });

        return {
          ...ticket,
          identify,
          // selected: window.location.pathname.includes('/catalog')
          //   ? true
          //   : ticket.errorTime || ticket.errorTicket
          //   ? false
          //   : state.cart.find(i => i.identify === identify)?.selected ?? false,
          // errorTickets: action.payload?.errorTickets || null,
          selected: window.location.pathname.includes('/catalog')
            ? true
            : state.cart.find(i => i.identify === identify)?.selected ?? false,
          errorTickets: action.payload?.errorTickets || null,
        };
      });
      if (itemAddToCartID) {
        mappedCart = mappedCart.filter(i => {
          if (i.identify !== itemAddToCartID) {
            return true;
          }
          if (i.errorTicket || i.errorTime) {
            return false;
          }
          return true;
        });
      }
      state.cart = mappedCart;
      const errorTemp = action.payload.cart.filter(i => i?.errorTicket);
      const errorTimeTemp = action.payload.cart.filter(i => i?.errorTime);
      state.loading = false;
      state.success = true;
      state.isUpdateSuccess = true;
      state.error = false;
      state.errorWarning = errorTimeTemp || null;
      state.errorTickets = errorTemp || null;
      state.errorTimeTicket = action.payload?.errorTimeTicket || null;
    },
    [UPDATE_CART.FAILURE]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.isUpdateSuccess = false;
      state.error = true;
      state.errorWarning = action.payload;
    },
    /**
     * NOTE: UPDATE CART
     */
    [NEW_UPDATE_CART.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.isUpdateSuccess = false;
      state.errorWarning = null;
    },
    [NEW_UPDATE_CART.SUCCESS]: (state, action: any) => {
      state.loading = false;
      state.success = true;
      state.isUpdateSuccess = true;
      state.error = false;
      state.errorWarning = null;
      state.errorTickets = null;
      state.errorTimeTicket = action.payload?.errorTimeTicket || null;
    },
    [NEW_UPDATE_CART.FAILURE]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.isUpdateSuccess = false;
      state.error = true;
      state.errorWarning = action.payload;
    },
    [RESET_ERROR_WARNING]: state => {
      state.errorWarning = null;
    },
    [RESET_ERROR_TICKET]: state => {
      state.errorTickets = null;
    },
    /**
     * ! GET TICKET PRICE
     */
    [GET_TICKET_PRICE.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_TICKET_PRICE.SUCCESS]: (
      state,
      action: PayloadAction<{
        data: any;
        ticketIdentify: string;
        isCallUpdateCartApi?: boolean;
      }>,
    ) => {
      const { data, ticketIdentify, isCallUpdateCartApi } = action.payload;
      state.loading = isCallUpdateCartApi ? isCallUpdateCartApi : false;
      state.success = true;
      state.error = false;
    },
    [GET_TICKET_PRICE.FAILURE]: (state, action) => {
      const { error, ticketIdentify } = action.payload;
      state.cart = state.cart.map(ticket => {
        if (ticket.identify !== ticketIdentify) return { ...ticket };
        return {
          ...ticket,
          inventory: 0,
          errorTime: error.message,
        };
      });
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    /**
     * NOTE: MERGE TICKET
     */
    [MERGE_TICKET.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.isUpdateSuccess = false;
      state.errorWarning = null;
    },
    [MERGE_TICKET.SUCCESS]: (state, action: any) => {
      const errorTemp = action.payload?.cart?.filter(i => i?.errorTicket);
      const errorTimeTemp = action.payload?.cart?.filter(i => i?.errorTime);
      state.loading = false;
      state.success = true;
      state.isUpdateSuccess = true;
      state.error = false;
      state.errorWarning = errorTimeTemp || null;
      state.errorTickets = errorTemp || null;
      state.errorTimeTicket = action.payload?.errorTimeTicket || null;
    },
    [MERGE_TICKET.FAILURE]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.isUpdateSuccess = false;
      state.error = true;
      state.errorWarning = action.payload;
    },
  },
});

export const { actions: cartActions } = slice;

export const useCartSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: cartSaga });
  return { actions: slice.actions };
};
