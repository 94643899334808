/**
 *
 * Checkout
 *
 */
import { Checkbox, Collapse, Form, Input } from 'antd';
import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import { ReactComponent as IconMap1Svg } from 'app/assets/img/location-icon.svg';
import { CheckoutOrderOverview } from 'app/components/CheckoutOrderOverview';
import ExitConfirm from 'app/components/ExitConfirm';
import { Type_RefFormFunc } from 'app/components/FormContainer';
import InputText from 'app/components/FormInput/InputText';
import ModalContainer from 'app/components/ModalContainer';
import OTPVerifyAccountModal from 'app/pages/Checkout/components/OTPVerifyAccountModal';
import PolicyModal from 'app/pages/Checkout/components/PolicyModal';
import { createUserPaymentApi } from 'app/services/authApi';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ruleEmailInput,
  ruleIndentityCardInput,
  ruleNameInput,
  rulePhoneInput,
} from 'utils/messages';
import { useGlobalFunction } from '../App/hooks/useGlobalFunction';
import {
  selectIsLogged,
  selectLayoutHeader,
  selectTranslations,
} from '../App/slice/selectors';
import { selectLengthCart } from '../Cart/slice/selectors';
import { useCheckout } from './hooks/useCheckout';
import { GET_LIST_PAYMENT_METHOD } from './slice';
import { selectListPaymentMethod } from './slice/selectors';
import { CheckoutWrapper } from './styled';
import { I_CheckoutCartItem, Park } from 'app/pages/Cart/slice/types';
import { I_Park } from 'types';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { PaymentMethods } from 'app/pages/Checkout/components/PaymentMethods';
import StyledButton from 'app/components/StyledButton';
import Images from 'app/assets/img';
import { set } from 'date-fns';
import { generateUUID } from 'utils/helper';
import useWindowSize from 'app/hooks/useWindowSize';
import { ReactComponent as AddIcon } from 'app/assets/img/add-icon.svg';
import { ReactComponent as MinusIcon } from 'app/assets/img/minus-icon.svg';

interface Props {}

const groupedTickets = (listTicketProvincial: I_CheckoutCartItem[]) => {
  return listTicketProvincial.reduce((grouped, ticket) => {
    const key = ticket.product.park.id;
    if (!grouped[key]) {
      grouped[key] = {
        tickets: [],
        park: ticket.product.park,
        total: 0,
      };
    }
    grouped[key].tickets.push(ticket);
    grouped[key].total += +ticket.amount;
    return grouped;
  }, {} as { [key: number]: { tickets: I_CheckoutCartItem[]; park: Park; total: number } });
};

export const Checkout = memo((props: Props) => {
  const [formInfoUser] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<Type_RefFormFunc>(null!);
  const { onOpenDialogLogin } = useGlobalFunction();

  const [isShowDialogPolicy, setIsShowDialogPolicy] = useState(false);
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [isShowTimeout, setIsShowTimeout] = useState(false);
  const [paymentMethodActive, setPaymentMethodActive] = useState(0);

  const isLogged = useSelector(selectIsLogged);
  const cartLength = useSelector(selectLengthCart);
  const layoutHeader = useSelector(selectLayoutHeader);
  const translations = useSelector(selectTranslations);
  const listPaymentMethod = useSelector(selectListPaymentMethod);
  const { device } = useWindowSize();
  const [infoFormForDialogPolicy, setInfoFormForDialogPolicy] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const onCloseDialogPolicy = useCallback(() => {
    setIsShowDialogPolicy(false);
  }, []);

  const onTriggerSubmit = useCallback(() => {
    formRef.current?.getFormInstance().submit();
    onCloseDialogPolicy();
  }, [onCloseDialogPolicy]);
  const { Panel } = Collapse;
  const {
    loading,
    INIT_FORM,
    onSubmitForm,
    totalAmount,
    totalPriceOriginal,
    totalPrice,
    listTicketProvincial,
    setEnableConfirm,
    orderCheckout,
    enableConfirm,
    totalDiscount,
    validateProvincial,
    groupCart,
    infoUser,
    setLoading,
    vouchers,
    listCart,
    mailReceiveInvoices,
    setMailReceiveInvoices,
    isShowInvoiceInfo,
    setIsShowInvoiceInfo,
  } = useCheckout({
    paymentMethodActive,
    onTriggerSubmit,
  });

  const onCheckout = useCallback(async () => {
    try {
      await formInfoUser.validateFields();

      if (listTicketProvincial && listTicketProvincial.length > 0) {
        const data = await validateProvincial(formInfoUser.getFieldsValue());
        if ((data?.errorField?.length ?? 0) > 0) {
          data.errorField.forEach(item => {
            formInfoUser.setFields([
              {
                name: item.field,
                errors: [item.message],
              },
            ]);
          });
          return;
        }
      }
      setIsShowDialogPolicy(true);
      setInfoFormForDialogPolicy({
        name: formInfoUser.getFieldValue('name'),
        email: formInfoUser.getFieldValue('email'),
        phone: formInfoUser.getFieldValue('phone'),
      });
    } catch (error) {}
  }, [formInfoUser, listTicketProvincial, validateProvincial]);

  useEffect(() => {
    if (listPaymentMethod.length)
      setPaymentMethodActive(
        listPaymentMethod?.find(i => i.default === true)?.id ??
          listPaymentMethod[0].id,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPaymentMethod]);

  useEffect(() => {
    if (isLogged) formInfoUser.setFieldsValue({ ...INIT_FORM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, INIT_FORM]);
  const newListTicketProvincial = Object.values(
    groupedTickets(listTicketProvincial),
  );

  useEffect(() => {
    dispatch(GET_LIST_PAYMENT_METHOD());
    // if (!cartLength) history.push('/');
    // else dispatch(GET_LIST_PAYMENT_METHOD());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const customHeader = title => (
    <div className="custom-panel-header">
      <div className="d-flex align-items-center">
        <div style={{ fontSize: 12, marginRight: 4 }}>
          <IconMap1Svg />
        </div>
        <span className="custom-panel-title">{title}</span>
      </div>
    </div>
  );
  return (
    <CheckoutWrapper id="bookticketspay" className="main">
      <div className="bookticketspayContent bookticketsContent checkOut">
        <div className="container">
          <LoadingContainer isFullPage loading={loading}>
            <div className="row">
              <div className="col-xl-8">
                <div className="checkOutInfo">
                  <div className="--title">
                    {translations ? translations['b2c.txt.info-customer'] : ''}
                  </div>
                  <span>
                    {translations
                      ? translations['b2c.txt.paymentTypeInfo']
                      : ''}{' '}
                    {!isLogged && translations
                      ? translations['b2c.txt.or']
                      : ''}
                    {!isLogged && (
                      <span
                        className="--btn-login"
                        onClick={onOpenDialogLogin}
                        style={{ cursor: 'pointer' }}
                      >
                        {translations ? translations['b2c.txt.loginNow'] : ''}
                      </span>
                    )}
                  </span>
                  <Form
                    form={formInfoUser}
                    onFinish={obj =>
                      onSubmitForm(
                        obj,
                        listPaymentMethod.find(
                          i => i.id === paymentMethodActive,
                        ),
                      )
                    }
                  >
                    <div className="--form-info-user">
                      <div className="row">
                        <div className="col-xl-6">
                          <Form.Item
                            className="custom-input"
                            label=""
                            name="name"
                            validateTrigger={['onBlur']}
                            validateFirst={true}
                            rules={ruleNameInput(translations)}
                          >
                            <InputText
                              maxLength={50}
                              value={formInfoUser.getFieldValue('name')}
                              onChange={event => {
                                formInfoUser.setFieldValue(
                                  'name',
                                  event.target.value.trim(),
                                );
                              }}
                              name="name"
                              placeholder={
                                translations
                                  ? translations['b2c.txt.fullName']
                                  : ''
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-xl-6">
                          <Form.Item
                            className="custom-input"
                            label=""
                            name="phone"
                            validateTrigger={['onBlur']}
                            validateFirst={true}
                            rules={rulePhoneInput(translations)}
                          >
                            <Input
                              // type="number"
                              maxLength={20}
                              value={formInfoUser.getFieldValue('phone')}
                              onChange={event => {
                                const value = event.target.value;

                                formInfoUser.setFieldValue(
                                  'phone',
                                  value.replace(/[^0-9]/g, ''),
                                );
                              }}
                              placeholder={
                                translations
                                  ? translations['b2c.txt.phone']
                                  : ''
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-xl-6">
                          <Form.Item
                            className="custom-input"
                            name="email"
                            label=""
                            validateTrigger={['onBlur']}
                            validateFirst={true}
                            rules={ruleEmailInput(true, translations)}
                          >
                            <InputText
                              disableWhiteSpace
                              maxLength={200}
                              placeholder="Email"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: '0px 0px 24px' }}>
                      <Checkbox
                        style={{ margin: '12px 0px' }}
                        value={isShowInvoiceInfo}
                        onChange={() =>
                          setIsShowInvoiceInfo(!isShowInvoiceInfo)
                        }
                      >
                        {translations
                          ? translations?.['b2c.txt.require-export-tax']
                          : ''}
                      </Checkbox>
                      {isShowInvoiceInfo && (
                        <>
                          <div
                            className="--sub-title"
                            style={{ margin: '17px 0px' }}
                          >
                            {translations
                              ? translations?.[
                                  'b2c.txt.invoice-export-description'
                                ]
                              : ''}
                          </div>
                          <div className="row">
                            <div className="col-xl-8">
                              <Form.Item
                                className="custom-input"
                                name="taxCode"
                                label=""
                                validateTrigger={['onBlur']}
                                validateFirst={true}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      (translations?.['b2c.txt.invoice-code'] ??
                                        '') +
                                      ' ' +
                                      (translations
                                        ? translations?.[
                                            'b2c.form.validate-require'
                                          ]
                                        : ''),
                                  },
                                  {
                                    min: 10,
                                    message:
                                      (translations?.['b2c.txt.invoice-code'] ??
                                        '') +
                                      ' ' +
                                      (translations
                                        ? translations?.[
                                            'b2c.form.validate-length'
                                          ]?.replaceAll('${length}', '10')
                                        : ''),
                                  },
                                ]}
                              >
                                <Input
                                  maxLength={10}
                                  placeholder={
                                    (translations?.['b2c.txt.invoice-code'] ??
                                      '') + ' *'
                                  }
                                  value={formInfoUser.getFieldValue('taxCode')}
                                  onChange={event => {
                                    const value = event.target.value;

                                    formInfoUser.setFieldValue(
                                      'taxCode',
                                      value.replace(/[^0-9]/g, ''),
                                    );
                                  }}
                                />
                              </Form.Item>
                            </div>
                            {device === 'desktop' && (
                              <div
                                className="col-xl-1"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: 9,
                                }}
                              >
                                -
                              </div>
                            )}
                            <div className="col-xl-3">
                              <Form.Item
                                className="custom-input"
                                name="branchTax"
                                label=""
                                validateTrigger={['onBlur']}
                                validateFirst={true}
                                rules={[
                                  {
                                    min: 3,
                                    message:
                                      (translations?.['b2c.txt.branch-tax'] ??
                                        '') +
                                      ' ' +
                                      (translations
                                        ? translations?.[
                                            'b2c.form.validate-length'
                                          ]?.replaceAll('${length}', '3')
                                        : ''),
                                  },
                                ]}
                              >
                                <Input
                                  maxLength={3}
                                  placeholder={
                                    translations?.['b2c.txt.branch-tax'] ?? ''
                                  }
                                  value={formInfoUser.getFieldValue(
                                    'branchTax',
                                  )}
                                  onChange={event => {
                                    const value = event.target.value;

                                    formInfoUser.setFieldValue(
                                      'branchTax',
                                      value.replace(/[^0-9]/g, ''),
                                    );
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-xl-12">
                              <Form.Item
                                className="custom-input"
                                name="companyName"
                                label=""
                                validateTrigger={['onBlur']}
                                validateFirst={true}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      (translations?.['b2c.txt.company-name'] ??
                                        '') +
                                      ' ' +
                                      (translations
                                        ? translations?.[
                                            'b2c.form.validate-require'
                                          ]
                                        : ''),
                                  },
                                ]}
                              >
                                <InputText
                                  maxLength={100}
                                  placeholder={
                                    (translations?.['b2c.txt.company-name'] ??
                                      '') + ' *'
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="col-xl-12">
                              <Form.Item
                                className="custom-input"
                                name="companyAddress"
                                label=""
                                validateTrigger={['onBlur']}
                                validateFirst={true}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      (translations?.[
                                        'b2c.txt.company-address'
                                      ] ?? '') +
                                      ' ' +
                                      (translations
                                        ? translations?.[
                                            'b2c.form.validate-require'
                                          ]
                                        : ''),
                                  },
                                ]}
                              >
                                <InputText
                                  maxLength={100}
                                  placeholder={
                                    (translations?.[
                                      'b2c.txt.company-address'
                                    ] ?? '') + ' *'
                                  }
                                />
                              </Form.Item>
                            </div>
                            {mailReceiveInvoices.map((mailId, index) => (
                              <Fragment key={`enable${mailId.id}`}>
                                <div
                                  className="col-xl-12"
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  <Form.Item
                                    className="custom-input"
                                    name={`emailTax[${mailId.id}]`}
                                    label=""
                                    validateTrigger={['onBlur']}
                                    validateFirst={true}
                                    rules={ruleEmailInput(false, translations)}
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    <InputText
                                      disableWhiteSpace
                                      maxLength={200}
                                      placeholder={
                                        translations?.[
                                          'b2c.txt.email-receive-invoices'
                                        ] ?? ''
                                      }
                                    />
                                  </Form.Item>
                                  <StyledButton
                                    bgColor="white"
                                    border="1px solid #F2F2F2"
                                    disabled={
                                      index === 0 &&
                                      mailReceiveInvoices.length === 5
                                    }
                                    onClick={() => {
                                      const isAdd = index === 0;
                                      if (isAdd) {
                                        setMailReceiveInvoices(prev => {
                                          return [
                                            ...prev,
                                            { id: generateUUID() },
                                          ];
                                        });
                                      } else {
                                        if (mailReceiveInvoices.length > 1) {
                                          formInfoUser.setFieldValue(
                                            `emailTax[${mailId.id}]`,
                                            undefined,
                                          );
                                          setMailReceiveInvoices(prev => {
                                            return [...prev].filter(
                                              item => item.id !== mailId.id,
                                            );
                                          });
                                        }
                                      }
                                    }}
                                    style={{
                                      width: 30,
                                      height: 30,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      display: 'flex',
                                      borderRadius: 3,
                                    }}
                                  >
                                    <div
                                      style={
                                        index === 0 &&
                                        mailReceiveInvoices.length === 5
                                          ? {
                                              color: '#F2F2F2',
                                            }
                                          : {}
                                      }
                                    >
                                      {index === 0 ? (
                                        <StyledAddIcon
                                          isDisabled={
                                            mailReceiveInvoices.length === 5
                                          }
                                        />
                                      ) : (
                                        <StyledMinusIcon isDisabled={false} />
                                      )}
                                    </div>

                                    {/* <img
                                      src={
                                        index === 0
                                          ? Images.AddIcon
                                          : Images.MinusIcon
                                      }
                                      alt=""
                                    /> */}
                                  </StyledButton>
                                </div>
                                {/* <div className="col-xl-1">
                                  
                                </div> */}
                              </Fragment>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    {newListTicketProvincial &&
                      newListTicketProvincial.length > 0 && (
                        <div className="--form-info-other">
                          <div className="--title">
                            {translations
                              ? translations['b2c.txt.provincialTitle']
                              : ''}
                          </div>
                          <div className="--sub-title">
                            {translations
                              ? translations['b2c.txt.provincialContent']
                              : ''}
                          </div>
                          <div className="mt-3">
                            <Collapse
                              defaultActiveKey={`1${
                                newListTicketProvincial?.[0]?.park?.id ?? ''
                              }`}
                              className="custom-collapse"
                            >
                              {newListTicketProvincial.map((item, index) => {
                                let amount = 0;
                                return (
                                  <Panel
                                    header={customHeader(
                                      item?.park?.name ?? '',
                                    )}
                                    forceRender
                                    key={`${index + 1}${item.park.id}`}
                                  >
                                    <div>
                                      {item.tickets.map((val, i) => {
                                        amount += item.tickets?.[i - 1]?.amount
                                          ? +item.tickets?.[i - 1]?.amount
                                          : 0;
                                        // const prevAmount = item.tickets?.[i - 1]
                                        //   ?.amount
                                        //   ? +item.tickets?.[i - 1]?.amount
                                        //   : 0;
                                        return Array.from({
                                          length: +val.amount,
                                        }).map((_, index) => (
                                          <div className="d-flex align-items-center">
                                            <div className="number-other">{`${
                                              index + amount + 1 < 10 ? '0' : ''
                                            }${index + amount + 1}`}</div>
                                            <div className="row w-100">
                                              <div className="col-xl-6">
                                                <Form.Item
                                                  className="custom-input"
                                                  name={`contacts[${val.product.id}][${index}][email]`}
                                                  label=""
                                                  initialValue={''}
                                                  validateTrigger={['onBlur']}
                                                  validateFirst={true}
                                                  rules={ruleEmailInput(
                                                    false,
                                                    translations,
                                                  )}
                                                >
                                                  <InputText
                                                    disableWhiteSpace
                                                    maxLength={200}
                                                    placeholder="Email"
                                                  />
                                                </Form.Item>
                                              </div>
                                              <div className="col-xl-6">
                                                <Form.Item
                                                  className="custom-input"
                                                  label=""
                                                  validateTrigger={['onBlur']}
                                                  validateFirst={true}
                                                  initialValue={''}
                                                  name={`contacts[${val.product.id}][${index}][identity_card]`}
                                                  rules={ruleIndentityCardInput(
                                                    false,
                                                    translations,
                                                  )}
                                                >
                                                  <Input
                                                    // type="number"
                                                    maxLength={20}
                                                    value={formInfoUser.getFieldValue(
                                                      `contacts[${val.product.id}][${index}][identity_card]`,
                                                    )}
                                                    onChange={event => {
                                                      const value =
                                                        event.target.value;
                                                      formInfoUser.setFieldValue(
                                                        `contacts[${val.product.id}][${index}][identity_card]`,
                                                        value.replace(
                                                          /[^0-9]/g,
                                                          '',
                                                        ),
                                                      );
                                                    }}
                                                    placeholder={
                                                      translations
                                                        ? translations[
                                                            'b2c.txt.identityCard'
                                                          ]
                                                        : ''
                                                    }
                                                  />
                                                </Form.Item>
                                              </div>
                                            </div>
                                          </div>
                                        ));
                                      })}
                                    </div>
                                  </Panel>
                                );
                              })}
                            </Collapse>
                          </div>
                        </div>
                      )}
                  </Form>
                  <PaymentMethods
                    listPaymentMethod={listPaymentMethod}
                    paymentMethodActive={paymentMethodActive}
                    setPaymentMethodActive={setPaymentMethodActive}
                  />
                </div>
              </div>
              <div className="col-xl-4">
                <CheckoutOrderOverview
                  vouchers={vouchers}
                  listCart={listCart}
                  orderCheckoutRef={orderCheckout}
                  listGroupCart={groupCart}
                  totalAmount={totalAmount}
                  totalDiscount={totalDiscount}
                  totalPrice={totalPrice}
                  totalPriceOriginal={totalPriceOriginal}
                  onTimeout={() => {
                    setIsShowTimeout(true);
                    setEnableConfirm(false);
                    setIsShowDialogPolicy(false);
                  }}
                  onGoBack={() => {
                    history.goBack();
                  }}
                  onCheckout={onCheckout}
                />
              </div>
            </div>
          </LoadingContainer>
        </div>
      </div>

      {isShowDialogPolicy && (
        <PolicyModal
          open={isShowDialogPolicy}
          info={infoFormForDialogPolicy}
          onClose={onCloseDialogPolicy}
          onSubmit={async () => {
            setIsShowDialogPolicy(false);
            if (isLogged) {
              if (formInfoUser.getFieldValue('email') === infoUser?.email) {
                setEnableConfirm(false);
                formInfoUser.submit();
              } else {
                try {
                  setLoading(true);
                  const userData = new FormData();
                  userData.append('email', formInfoUser.getFieldValue('email'));
                  userData.append('phone', formInfoUser.getFieldValue('phone'));
                  userData.append('name', formInfoUser.getFieldValue('name'));
                  await createUserPaymentApi(userData);
                  setIsShowOtp(true);
                } catch (error) {
                } finally {
                  setLoading(false);
                }
              }
            } else {
              try {
                setLoading(true);
                const userData = new FormData();
                userData.append('email', formInfoUser.getFieldValue('email'));
                userData.append('phone', formInfoUser.getFieldValue('phone'));
                userData.append('name', formInfoUser.getFieldValue('name'));
                await createUserPaymentApi(userData);
                setIsShowOtp(true);
              } catch (error) {
              } finally {
                setLoading(false);
              }
            }
            // setEnableConfirm(false);
            // formInfoUser.submit();
          }}
          // content={layoutHeader?.checkoutPolicy}
          // onConfirm={onTriggerSubmit}
        />
      )}
      <ExitConfirm
        message={translations ? translations['b2c.txt.directOut'] : ''}
        enable={enableConfirm}
      />
      <TimeoutModal
        open={isShowTimeout}
        onClose={() => {
          history.push(
            '/payment-callback?vnp_TxnRef=' + orderCheckout.current?.idHash,
          );
        }}
      />
      {isShowOtp && (
        <OTPVerifyAccountModal
          open={isShowOtp}
          onClose={() => setIsShowOtp(false)}
          getMailRegister={() => formInfoUser.getFieldValue('email')}
          onDoneOtp={async () => {
            setIsShowOtp(false);
            setEnableConfirm(false);
            formInfoUser.submit();
          }}
        />
      )}
    </CheckoutWrapper>
  );
});

export const TimeoutModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) => {
  const translations = useSelector(selectTranslations);
  return (
    <ModalContainer
      isShow={open}
      maskClosable={false}
      onCloseDialog={onClose}
      CloseIcon={<CloseIcon width={12} height={12} />}
      styleSlots={{
        iconWrapperStyle: {
          margin: '-8px 0px 0px 28px',
          width: 12,
          height: 12,
        },
        modalContentStyle: {
          height: 'auto',
          width: 'auto',
          padding: '38px 31px',
          borderRadius: '16px',
        },
        modalStyle: {
          width: 'auto',
          maxWidth: '280px',
        },
        bodyStyle: {
          width: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <div>{translations ? translations['b2c.txt.timeoutPayment'] : ''}</div>
      <div>{translations ? translations['b2c.txt.retry'] : ''}</div>
    </ModalContainer>
  );
};

const StyledAddIcon = styled(AddIcon)<{ isDisabled: boolean }>`
  path {
    fill: ${props => (props.isDisabled ? '#8a8a8a' : '#E92127')};
  }
`;

const StyledMinusIcon = styled(MinusIcon)<{ isDisabled: boolean }>`
  path {
    fill: ${props => (props.isDisabled ? '#8a8a8a' : '#E92127')};
  }
`;
