import { LoadingOutlined } from '@ant-design/icons';
import { Col, Collapse, Row, Skeleton } from 'antd';
import Images from 'app/assets/img';
import LinkButton from 'app/components/LinkButton';
import StyledButton from 'app/components/StyledButton';
import StyledGradientDivider from 'app/components/StyledGradientDivider';
import StyledPagination from 'app/components/StyledPagination';
import useWindowSize from 'app/hooks/useWindowSize';
import {
  I_Date,
  I_DetailTicket,
  I_Paginator,
  I_PayloadGetListOrder,
} from 'app/pages/Account/ListOrder/slice/types';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { TimeoutModal } from 'app/pages/Checkout';
import DetailTicketModal from 'app/pages/NewAccount/PurchaseHistory/modals/DetailTicketModal';
import ColTicketInfo from 'app/pages/NewAccount/components/ColTicketInfo';
import ListTicketContainer from 'app/pages/NewAccount/components/ListTicketContainer';
import NodataView from 'app/pages/NewAccount/components/NodataView';
import StyledDivider from 'app/pages/NewAccount/components/StyledDivider';
import TicketInfoTitle from 'app/pages/NewAccount/components/TicketInfoTitle';
import useListTicketLayout from 'app/pages/NewAccount/hooks/useListTicketLayout';
import { downloadPdfApi } from 'app/services/checkoutApi';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { objectType } from 'types';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { DateFormat } from 'utils/date-utils/date-format';
import { DateFromToUtils } from 'utils/date-utils/date-utils';
import { formatNumber } from 'utils/helper';
import { setSessionItem } from 'utils/localStorage';
import { formatDate, getDateStringForValidDate } from 'utils/time';

export type EvoucherViewType = {
  productName?: string;
  code?: string;
};

export interface TicketType {
  _id: string;
  orderInfo: OrderInfo;
  paymentInfo: PaymentInfo;
  ticketInfo?: TicketInfo;
  giftsInfo?: (TicketInfo & {
    orgTickets: I_DetailTicket[];
    amount: number;
  })[];
  ticketsInfo?: (TicketInfo & {
    orgTickets: I_DetailTicket[];
    amount: number;
  })[];
  vouchers?: EvoucherViewType[];
  pathTicketPdf?: string;
  totalTicket?: number;
  orderState?: any;
  payment?: any;
}
export interface TicketInfo {
  eventName: string;
  parkName: string;
  catalogName: string;
  serial: string;
  ticketName: string;
  useDate: string;
  useHour: string;
  position: string;
  restaurant: string;
  price: number;
  imgPath?: string;
  isComboTicket: boolean;
  isComboDiffTicket: boolean;
  useDateFrom: string;
  numberAddDays: number;
  dataBuyer?: {
    email: string;
    identityCode: string;
  }[];
  vgsUsageDateTo?: string;
  vgsUsageDate?: string;
  usedArea2Object?: any;
  usedArea2Label?: string;
}
interface PaymentInfo {
  customerName: string;
  phoneNumber: string;
  email: string;
}
interface OrderInfo {
  orderId: string;
  amountOfTicket: number;
  orderDate: string;
  orgOrderDate?: I_Date;
  totalDiscountValue: number;
  totalPriceValue: number;
  paymentUrl?: string;
}

export type ListTicketLayoutProps = {
  loading: boolean;
  listTicket: TicketType[];
  paginator?: I_Paginator;
  search?: (
    payload: Partial<I_PayloadGetListOrder>,
    onFinally?: () => void,
  ) => void;
  orderState: 'success' | 'paymentPending' | 'cancelled';
};

const getNodataText = (
  orderState: 'success' | 'paymentPending' | 'cancelled',
  translations: objectType | undefined,
) => {
  switch (orderState) {
    case 'success':
      return translations ? translations['b2c.txt.nodataSuccess'] : '';
    case 'paymentPending':
      return translations ? translations['b2c.txt.nodataPaymentPending'] : '';
    case 'cancelled':
      return translations ? translations['b2c.txt.nodataCancelled'] : '';

    default:
      break;
  }
};

const ListTicketLayout = ({
  listTicket,
  paginator,
  loading,
  search,
  orderState,
}: // onClickDetailTicket,
ListTicketLayoutProps) => {
  const formatString = 'HH:mm DD/MM/YYYY';
  const translations = useSelector(selectTranslations);
  const { device } = useWindowSize();
  const { showDetail, onClickShowDetail, setIsShowDetail, isGetingDetail } =
    useListTicketLayout();
  const [currentDetail, setCurrentDetail] = useState<string>('');
  return (
    <>
      {loading ? (
        <div style={{ cursor: 'progress' }}>
          <Skeleton active />
        </div>
      ) : (
        <>
          {listTicket.length > 0 ? (
            <ListTicketContainer
              style={{
                marginTop: 26,
              }}
            >
              {listTicket.map((ticket, idx) => {
                return (
                  <div key={`${idx}${ticket.orderInfo.orderId}`}>
                    <OrderInfoView
                      ticket={ticket}
                      formatString={formatString}
                      orderState={orderState}
                      reloadScreen={() => {
                        search?.({});
                      }}
                    />
                    {ticket.ticketInfo && (
                      <TicketView
                        ticket={ticket.ticketInfo}
                        formatString={formatString}
                        orderState={orderState}
                      />
                    )}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent:
                          device === 'desktop' ? 'flex-end' : 'flex-start',
                        marginTop: 29,
                      }}
                    >
                      <ActionButtonsView
                        ticket={ticket}
                        onClickShowDetail={ticket => {
                          onClickShowDetail(ticket._id);
                          setCurrentDetail(ticket._id);
                        }}
                        isGetingDetail={
                          currentDetail === ticket._id && isGetingDetail
                        }
                        orderState={orderState}
                      />
                    </div>
                    {idx !== listTicket.length - 1 && (
                      <StyledDivider
                        dashed={device === 'desktop'}
                        style={{
                          ...{
                            margin: '24px 0px',

                            width: '100%',
                          },
                          ...(device !== 'desktop' ? { borderWidth: 1.5 } : {}),
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {paginator &&
                paginator.totalCount > 0 &&
                paginator.pageCount > 1 && (
                  <>
                    <StyledGradientDivider style={{ marginTop: '40px' }} />
                    <StyledPagination
                      style={{
                        marginTop: '40px',
                      }}
                      showSizeChanger={false}
                      defaultCurrent={1}
                      current={paginator.current}
                      total={paginator.totalCount} // Total number of data items
                      pageSize={paginator.numItemsPerPage} // Number of items per page
                      onChange={(page, pageSize) => {
                        window.scrollTo(0, 0);
                        search &&
                          search({ page, limit: pageSize }, () =>
                            window.scrollTo(0, 0),
                          );
                      }} // Function to call when the page changes
                    />
                  </>
                )}
            </ListTicketContainer>
          ) : (
            <NodataView text={getNodataText(orderState, translations)} />
          )}
        </>
      )}
      <DetailTicketModal
        onCloseDialog={() => {
          setIsShowDetail({
            isShow: false,
            ticket: undefined,
          });
        }}
        orderState={orderState}
        isShow={showDetail.isShow}
        ticket={showDetail.ticket}
      />
    </>
  );
};
const ActionButtonsView = (props: {
  orderState: 'success' | 'paymentPending' | 'cancelled';
  onClickShowDetail: (value: TicketType) => void;
  ticket: TicketType;
  isGetingDetail: boolean;
}) => {
  const { orderState, onClickShowDetail, ticket, isGetingDetail } = props;
  const translations = useSelector(selectTranslations);
  const { device } = useWindowSize();
  const antIcon = useMemo(
    () => <LoadingOutlined style={{ fontSize: 16, color: '#F8B117' }} spin />,
    [],
  );
  const isHaveTicketSerial = useMemo(() => {
    const requireRender = Number(ticket?.totalTicket || 0) > 50;
    return (
      ticket.orderState?.value === 'success' &&
      ((requireRender && !!ticket.pathTicketPdf) || !requireRender)
    );
  }, [ticket]);

  return (
    <>
      {orderState === 'success' && (
        <>
          {device === 'desktop' ? (
            <LinkButton
              textColor="#F8B117"
              styleContainer={{
                filter: isHaveTicketSerial ? 'none' : 'contrast(0)',
                cursor: isHaveTicketSerial ? 'pointer' : 'no-drop',
              }}
              styleBtn={{
                cursor: isHaveTicketSerial ? 'pointer' : 'no-drop',
              }}
            >
              <a
                href={`/print-tickets/${ticket._id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#F8B117',
                  pointerEvents: isHaveTicketSerial ? 'auto' : 'none',
                }}
              >
                <img
                  src={Images.PdfViewIcon}
                  alt=""
                  style={{ marginRight: 8 }}
                />
                {translations ? translations['b2c.txt.viewPDF'] : ''}
              </a>
            </LinkButton>
          ) : (
            <StyledButton
              bgColor="white"
              border="1px solid #EDEDED"
              style={{
                width: 41,
                height: 41,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                cursor: isHaveTicketSerial ? 'pointer' : 'no-drop',
              }}
            >
              <a
                href={`/print-tickets/${ticket._id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#F8B117',
                  pointerEvents: isHaveTicketSerial ? 'auto' : 'none',
                }}
              >
                <img
                  src={Images.PdfViewIcon}
                  alt=""
                  style={{
                    filter: isHaveTicketSerial ? 'none' : 'contrast(0)',
                    cursor: 'no-drop',
                  }}
                />
              </a>
            </StyledButton>
          )}
          <div style={{ width: 24 }} />
        </>
      )}
      {device === 'desktop' ? (
        <LinkButton
          startIcon={
            isGetingDetail ? (
              antIcon
            ) : (
              <img src={Images.DetailTicketIcon} alt="" />
            )
          }
          textColor="#F8B117"
          disabled={isGetingDetail}
          onClick={() => {
            !isGetingDetail && onClickShowDetail(ticket);
          }}
        >
          {translations ? translations['b2c.txt.detailOrder'] : ''}
        </LinkButton>
      ) : (
        <StyledButton
          onClick={() => {
            onClickShowDetail(ticket);
          }}
          bgColor="white"
          disabled={isGetingDetail}
          border="1px solid #EDEDED"
          style={{
            width: 41,
            height: 41,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {isGetingDetail ? (
            antIcon
          ) : (
            <img src={Images.DetailTicketIcon} alt="" />
          )}
        </StyledButton>
      )}
    </>
  );
};

export const StyledCollapse = styled(Collapse)`
  border: none;
  background-color: #ffffff;
  .ticket-info-title {
    margin-bottom: 0px !important;
  }
  .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-collapse-header {
    background-color: #ffffff;
    margin-bottom: 24px;
  }
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const Key = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
`;

const TicketInfoContainer = styled.div`
  width: 100%;
  /* padding: 33px 34px 33px 34px; */
  background-color: #f7f7f7;
  border-radius: 20px;
  color: black;
`;

const TicketInfoContent = styled.div<{
  bgImg: string;
}>`
  display: flex;
  align-items: center;
  background-image: url(${props => props.bgImg});
  background-position: center right;
  background-repeat: no-repeat;
`;

export default ListTicketLayout;

export const OrderInfoView = ({
  ticket,
  formatString,
  orderState,
  reloadScreen,
}: {
  ticket: TicketType;
  formatString: string;
  orderState?: 'success' | 'paymentPending' | 'cancelled';
  reloadScreen?: () => void;
}) => {
  const { Panel } = Collapse;
  const { device } = useWindowSize();
  const [isShowTimeout, setIsShowTimeout] = useState(false);
  const translations = useSelector(selectTranslations);
  const history = useHistory();
  const onClickRepay = useCallback(() => {
    const startTime = moment(
      ticket.orderInfo.orgOrderDate?.datetimeDMYHIS,
      'HH:mm:ss DD/MM/YYYY',
    ).valueOf();
    const endTime = startTime + 15 * 60 * 1000;
    if (moment.now() > endTime) {
      setIsShowTimeout(true);
    } else {
      if (ticket.orderInfo.paymentUrl) {
        if (ticket?.payment?.code === 'ncb') {
          history.push('/ncb-payment', {
            id: ticket._id,
            qrImage: ticket.orderInfo.paymentUrl,
          });
        } else {
          window.open(ticket.orderInfo.paymentUrl, '_self');
        }
      } else {
        setSessionItem(TYPE_LOCAL_STORAGE.ORDER, JSON.stringify(ticket._id));
        window.open('/checkout', '_self');
      }
    }
  }, [ticket]);
  return (
    <>
      <Row gutter={device === 'desktop' ? 37 : 0}>
        <Col span={device !== 'mobile' ? 12 : 24}>
          {device === 'mobile' && (
            <StyledCollapse defaultActiveKey="1">
              <Panel
                key={'1'}
                header={
                  <TicketInfoTitle
                    className="ticket-info-title"
                    style={{
                      marginBottom: 24,
                    }}
                  >
                    {translations ? translations['b2c.txt.info-order'] : ''}
                  </TicketInfoTitle>
                }
              >
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.orderNumber'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    {ticket.orderInfo.orderId}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.ticketAmount'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    {ticket.orderInfo.amountOfTicket}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.date-order'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    {moment(ticket.orderInfo.orderDate, formatString).format(
                      'DD/MM/YYYY',
                    )}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.totalDiscount'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue
                    style={{
                      color: '#F8B117',
                    }}
                  >
                    {formatNumber(-ticket.orderInfo.totalDiscountValue)}
                    {'đ'}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.total-amount'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue
                    style={{
                      color: '#e92127',
                    }}
                  >
                    {formatNumber(ticket.orderInfo.totalPriceValue)}
                    {'đ'}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
              </Panel>
            </StyledCollapse>
          )}
          {(device === 'desktop' || device === 'tablet') && (
            <>
              <TicketInfoTitle
                className="ticket-info-title"
                style={{
                  marginBottom: 24,
                }}
              >
                {translations ? translations['b2c.txt.info-order'] : ''}
              </TicketInfoTitle>
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.orderNumber'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  {ticket.orderInfo.orderId}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.ticketAmount'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  {ticket.orderInfo.amountOfTicket}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.date-order'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  {moment(ticket.orderInfo.orderDate, formatString).format(
                    'DD/MM/YYYY',
                  )}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.totalDiscount'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue
                  style={{
                    color: '#F8B117',
                  }}
                >
                  {formatNumber(-ticket.orderInfo.totalDiscountValue)}
                  {'đ'}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.total-amount'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue
                  style={{
                    color: '#e92127',
                  }}
                >
                  {formatNumber(ticket.orderInfo.totalPriceValue)}
                  {'đ'}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
            </>
          )}
        </Col>
        <Col
          span={device !== 'mobile' ? 12 : 24}
          style={{
            marginTop: device !== 'mobile' ? 0 : 24,
          }}
        >
          {device === 'mobile' && (
            <StyledCollapse defaultActiveKey="1">
              <Panel
                key={'1'}
                header={
                  <TicketInfoTitle
                    className="ticket-info-title"
                    style={{
                      marginBottom: 24,
                    }}
                  >
                    {translations ? translations['b2c.txt.info-pay'] : ''}
                  </TicketInfoTitle>
                }
              >
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.customer'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    {ticket.paymentInfo.customerName}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    {translations ? translations['b2c.txt.phone'] : ''}
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    {ticket.paymentInfo.phoneNumber}
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                <Row>
                  <ColTicketInfo.ColTicketInfoKey>
                    Email
                  </ColTicketInfo.ColTicketInfoKey>
                  <ColTicketInfo.ColTicketInfoValue>
                    <div
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ticket.paymentInfo.email}
                    </div>
                  </ColTicketInfo.ColTicketInfoValue>
                </Row>
                <StyledDivider dashed />
                {orderState === 'paymentPending' && (
                  <Row>
                    <Col>
                      <StyledButton
                        style={{
                          marginTop: 8,
                        }}
                        textColor="white"
                        width={'auto'}
                        onClick={onClickRepay}
                      >
                        {translations?.['b2c.btn.payNow'] ?? ''}
                      </StyledButton>
                    </Col>
                  </Row>
                )}
              </Panel>
            </StyledCollapse>
          )}
          {(device === 'desktop' || device === 'tablet') && (
            <>
              <TicketInfoTitle
                style={{
                  marginBottom: 24,
                }}
              >
                {translations ? translations['b2c.txt.info-pay'] : ''}
              </TicketInfoTitle>
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.customer'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  {ticket.paymentInfo.customerName}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  {translations ? translations['b2c.txt.phone'] : ''}
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  {ticket.paymentInfo.phoneNumber}
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              <Row>
                <ColTicketInfo.ColTicketInfoKey>
                  Email
                </ColTicketInfo.ColTicketInfoKey>
                <ColTicketInfo.ColTicketInfoValue>
                  <div
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ticket.paymentInfo.email}
                  </div>
                </ColTicketInfo.ColTicketInfoValue>
              </Row>
              <StyledDivider dashed />
              {orderState === 'paymentPending' && (
                <Row>
                  <Col>
                    <StyledButton
                      style={{
                        marginTop: 8,
                      }}
                      textColor="white"
                      width={'auto'}
                      onClick={onClickRepay}
                    >
                      {translations?.['b2c.btn.payNow'] ?? ''}
                    </StyledButton>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
      <TimeoutModal
        open={isShowTimeout}
        onClose={() => {
          setIsShowTimeout(false);
          reloadScreen?.();
        }}
      />
    </>
  );
};
export const TicketView = ({
  ticket,
  formatString,
  orderState,
}: {
  ticket: TicketInfo;
  formatString: string;
  orderState: 'success' | 'paymentPending' | 'cancelled';
}) => {
  const { device } = useWindowSize();
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();
  return (
    <Row
      style={{
        marginTop: 36,
      }}
    >
      <Col flex={1}>
        <TicketInfoContainer>
          <Row
            justify="space-between"
            style={{
              padding:
                device === 'desktop'
                  ? '33px 34px 0px 34px'
                  : '16px 16px 0px 16px',
            }}
          >
            <ColTicketInfo.ColTicketInfoValue
              style={{
                textAlign: device === 'mobile' ? 'center' : 'start',
              }}
              span={device !== 'mobile' ? 12 : 24}
            >
              {device === 'mobile' ? (
                <span>
                  {ticket.parkName}
                  <br></br>
                  {ticket.catalogName}
                </span>
              ) : (
                ticket.eventName ?? ''
              )}
            </ColTicketInfo.ColTicketInfoValue>
            {device === 'mobile' && (
              <Col span={24}>
                <StyledDivider style={{ width: '100%' }} dashed />
              </Col>
            )}
            <Col
              span={device !== 'mobile' ? 12 : 24}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: device === 'mobile' ? 'center' : 'flex-end',
              }}
            >
              {ticket.serial ? (
                <>
                  <Key style={{ marginRight: 8 }}>
                    {translations ? translations['b2c.txt.serialCode'] : ''}
                  </Key>
                  <Value>{ticket.serial}</Value>
                </>
              ) : (
                <>
                  {orderState === 'success' && (
                    <>
                      <Key style={{ marginRight: 8 }}>
                        {translations ? translations['b2c.txt.serialCode'] : ''}
                      </Key>
                      <Value>
                        {translations ? translations['b2c.txt.updating'] : ''}
                      </Value>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
          {device === 'tablet' && (
            <Row>
              <Col span={24} style={{ padding: '0px 16px' }}>
                <StyledDivider style={{ width: '100%' }} dashed />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <TicketInfoContent
                bgImg={device === 'desktop' ? Images.TicketItemBG : ''}
                style={{
                  flexWrap: device !== 'mobile' ? 'nowrap' : 'wrap',
                  padding:
                    device === 'desktop'
                      ? '24px 34px 33px 34px'
                      : '16px 16px 16px 16px',
                }}
              >
                <div
                  style={{
                    width: device !== 'mobile' ? 270 : '100%',
                    background: '#fff',
                    borderRadius: 20,
                    height: 182,
                    minWidth: 270,
                  }}
                >
                  {ticket?.imgPath && (
                    <img
                      src={ticket.imgPath}
                      style={{
                        width: device !== 'mobile' ? 270 : '100%',
                        height: 182,
                        borderRadius: 20,
                        overflow: 'clip',
                        objectFit: 'cover',
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: device !== 'mobile' ? 28 : 0,
                    width: '100%',
                    marginTop: device !== 'mobile' ? 0 : 16,
                  }}
                >
                  <Row style={{ marginBottom: 16 }}>
                    <ColTicketInfo.ColTicketInfoValue
                      span={24}
                      style={{
                        fontSize: 16,
                        wordBreak: 'break-word',
                      }}
                    >
                      {ticket.ticketName}
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                  <Row style={{ marginBottom: 11 }}>
                    <ColTicketInfo.ColTicketInfoKey
                      span={device === 'mobile' ? 12 : 7}
                    >
                      {translations ? translations['b2c.txt.date-use'] : ''}
                    </ColTicketInfo.ColTicketInfoKey>
                    <ColTicketInfo.ColTicketInfoValue
                      span={device === 'mobile' ? 12 : 16}
                    >
                      {
                        new DateFromToUtils.Builder(locale, 'DD/MM/YYYY')
                          .setFromDate(
                            ticket?.vgsUsageDate
                              ? DateFormat.create(
                                  'YYYY-MM-DD HH:mm',
                                  ticket?.vgsUsageDate,
                                ).getMoment()
                              : DateFormat.create(
                                  'HH:mm DD/MM/YYYY',
                                  ticket?.useDateFrom,
                                ).getMoment(),
                          )
                          .setToDate(
                            DateFormat.create(
                              'YYYY-MM-DD HH:mm',
                              ticket?.vgsUsageDateTo,
                            ).getMoment(),
                          )
                          .build()
                          .getDateFromTo().concatFromToDate
                      }
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                  {ticket.usedArea2Object && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 7}
                      >
                        {ticket.usedArea2Label || ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {ticket.usedArea2Object?.label}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  {ticket.position && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 7}
                      >
                        {translations ? translations['b2c.txt.zone'] : ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {ticket.position}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  {ticket.restaurant && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 7}
                      >
                        {translations
                          ? translations['b2c.text.restaurants']
                          : ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {ticket.restaurant}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  <Row style={{ marginBottom: 11 }}>
                    <ColTicketInfo.ColTicketInfoKey
                      span={device === 'mobile' ? 12 : 7}
                    >
                      {translations ? translations['b2c.txt.unitPrice'] : ''}
                    </ColTicketInfo.ColTicketInfoKey>
                    <ColTicketInfo.ColTicketInfoValue
                      span={device === 'mobile' ? 12 : 16}
                      style={{
                        color: '#e92127',
                      }}
                    >
                      {formatNumber(ticket.price ?? 0)}
                      {'đ'}
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                </div>
              </TicketInfoContent>
            </Col>
          </Row>
        </TicketInfoContainer>
      </Col>
    </Row>
  );
};
