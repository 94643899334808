import { Tabs } from 'antd';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    display: none;
  }
  & .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    font-weight: 600;
    font-size: 16px; /* Red text color for the active tab */
  }

  & .ant-tabs-tab {
    padding: 8px 0px 8px 0px;
  }

  & .ant-tabs-tab .ant-tabs-tab-btn {
    color: #808080;
    font-weight: 600;
    font-size: 16px; /* Red text color for the active tab */
  }

  & .ant-tabs-ink-bar {
    background-color: #e92127; /* Red bottom border color for the active tab */
  }
`;

export default StyledTabs;
