/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import { Input } from 'antd';
import Password from 'antd/lib/input/Password';
import { FormItemProps, Type_RefFormFunc } from 'app/components/FormContainer';
import InputText from 'app/components/FormInput/InputText';
import { NewFormContainer } from 'app/components/NewFormContainer';
import StyledButton from 'app/components/StyledButton';
import AuthenticationModalWrapper from 'app/pages/App/components/AuthenticationModalWrapper';
import { REGISTER_STEPS } from 'app/pages/App/components/DialogRegister';
import {
  StyledVisibleOffIcon,
  StyledVisibleOnIcon,
} from 'app/pages/App/components/FormRegister';
import OTPRegister, {
  replaceDescription,
} from 'app/pages/App/components/OTPRegister';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { forgotPasswordApi, updatePassApi } from 'app/services/authApi';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { objectType } from 'types';

const INIT_FORM = {
  username: '',
  password: '',
};

interface Props {
  isShow: boolean;
  onCloseDialog: () => void;
  onSubmitAllDone?: () => void;
  // onOpenDialogForgetPassword: () => void;
}

const FormForgotPass = ({
  onSendForgotMailDone,
}: {
  onSendForgotMailDone: (email: string) => void;
}) => {
  const formRef = useRef<Type_RefFormFunc>(null!);
  const translations = useSelector(selectTranslations);

  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onTriggerSubmit = useCallback(() => {
    formRef.current?.getFormInstance().submit();
  }, []);

  const onSubmitForm = useCallback(
    async (values: objectType) => {
      setLoading(true);
      try {
        if (!executeRecaptcha) return;
        const tokenCaptcha = await executeRecaptcha('forgotPass');
        const payload = { ...values };
        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append('captcha', tokenCaptcha);
        const res = await forgotPasswordApi(formData);
        if (res.data?.errorCode === 400) {
          formRef.current?.getFormInstance().setFields([
            {
              name: 'email',
              errors: [res.data?.errorMessage ?? ''],
            },
          ]);
          return;
        }

        // onRegisterSuccess(data.token);
        // openMessageWithIcon('success', 'Đăng ký thành công');
        onSendForgotMailDone(values?.email ?? '');
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [onSendForgotMailDone],
  );

  const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
    () => [
      {
        label: 'Email',
        name: 'email',
        rules: [
          { required: true },
          {
            type: 'email',
            message:
              'Email ' + (translations ? translations['b2c.form.invalid'] : ''),
          },
          { max: 200 },
        ],
        validateFirst: true,
        validateTrigger: ['onBlur'],
        children: (
          <InputText
            disableWhiteSpace
            placeholder="Email"
            maxLength={200}
            size="large"
            onPressEnter={onTriggerSubmit}
          />
        ),
      },
    ],
    [onTriggerSubmit, translations],
  );
  return (
    <div>
      <div
        style={{
          margin: '40px 0px 40px 0px',
        }}
      >
        <NewFormContainer
          ref={formRef}
          layout="horizontal"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          colon={false}
          initialValues={INIT_FORM}
          listFields={LIST_FORM_FIELDS}
          onFinish={onSubmitForm}
        />
      </div>
      <div>
        <div>
          {/* <input type="checkbox" id="login" name="login" />
          <label htmlFor="login"> Duy trì đăng nhập</label> */}
        </div>

        {/* <a href="">Đăng nhập bằng SMS</a> */}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
        }}
      >
        <StyledButton
          disabled={loading}
          type="primary"
          onClick={onTriggerSubmit}
        >
          {loading
            ? 'Loading...'
            : translations
            ? translations['b2c.txt.confirm']
            : ''}
        </StyledButton>
      </div>
    </div>
  );
};

const FormChangePass = memo(
  ({
    onSendChangePassDone,
    email,
    otp,
  }: // onOpenDialogForgetPassword,
  {
    email: string;
    onSendChangePassDone?: () => void;
    otp: string;
  }) => {
    const formRef = useRef<Type_RefFormFunc>(null!);
    const translations = useSelector(selectTranslations);

    const [loading, setLoading] = useState(false);

    const onTriggerSubmit = useCallback(() => {
      formRef.current?.getFormInstance().submit();
    }, []);

    const onSubmitForm = useCallback(
      async (values: objectType) => {
        setLoading(true);
        try {
          const payload = { ...values };
          const formData = new FormData();
          Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append('email', email);
          formData.append('password_repeat', values['re-password']);
          formData.append('otpCode', otp);
          const res = await updatePassApi(formData);

          // onRegisterSuccess(data.token);
          // openMessageWithIcon('success', 'Đăng ký thành công');
          onSendChangePassDone?.();
        } catch (error) {
        } finally {
          setLoading(false);
        }
      },
      [email, onSendChangePassDone, otp],
    );

    const compareToFirstPassword = useCallback(
      (_, value: string) => {
        const { password } = formRef.current
          ?.getFormInstance()
          .getFieldsValue(true);
        if (value && value !== password) {
          return Promise.reject(
            new Error(translations ? translations['b2c.form.rePassValid'] : ''),
          );
        }
        return Promise.resolve();
      },
      [translations],
    );

    const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
      () => [
        {
          label: translations ? translations['b2c.form.password'] : '',
          name: 'password',
          rules: [
            { required: true },
            { min: 8 },
            { max: 20 },
            {
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
              message: translations ? translations['b2c.form.passValid'] : '',
            },
          ],
          validateFirst: true,
          validateTrigger: ['onBlur'],
          children: (
            <Password
              placeholder={
                translations ? translations['b2c.form.password'] : ''
              }
              maxLength={20}
              size="large"
              onPressEnter={onTriggerSubmit}
              iconRender={visible =>
                visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />
              }
            />
          ),
        },
        {
          label: translations ? translations['b2c.form.re-password'] : '',
          name: 're-password',
          rules: [
            { required: true },
            { max: 20 },
            {
              validator: compareToFirstPassword,
            },
          ],
          validateFirst: true,
          validateTrigger: ['onBlur'],
          children: (
            <Password
              placeholder={
                translations ? translations['b2c.form.re-password'] : ''
              }
              maxLength={20}
              size="large"
              onPressEnter={onTriggerSubmit}
              iconRender={visible =>
                visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />
              }
            />
          ),
        },
      ],
      [compareToFirstPassword, onTriggerSubmit, translations],
    );

    return (
      <div>
        <div
          style={{
            margin: '40px 0px 40px 0px',
          }}
        >
          <NewFormContainer
            ref={formRef}
            layout="horizontal"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            colon={false}
            initialValues={INIT_FORM}
            listFields={LIST_FORM_FIELDS}
            onFinish={onSubmitForm}
          />
        </div>
        <div>
          <div>
            {/* <input type="checkbox" id="login" name="login" />
                <label htmlFor="login"> Duy trì đăng nhập</label> */}
          </div>

          {/* <a href="">Đăng nhập bằng SMS</a> */}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          <StyledButton
            disabled={loading}
            type="primary"
            onClick={onTriggerSubmit}
          >
            {loading
              ? 'Loading...'
              : translations
              ? translations['b2c.txt.confirm']
              : ''}
          </StyledButton>
        </div>
      </div>
    );
  },
);

type STEP_FORGOT_PASS = 'SUBMIT_MAIL' | 'VERIFY_OTP' | 'SUBMIT_NEW_PASS';

const DialogForgotPass = memo(
  ({
    isShow,
    onCloseDialog,
    onSubmitAllDone,
  }: // onOpenDialogForgetPassword,
  Props) => {
    const { onOpenDialogNoti, onOpenDialogLogin, onCloseDialogNoti } =
      useGlobalFunction();
    const translations = useSelector(selectTranslations);
    const [stepForgotPass, setStepForgotPass] =
      useState<STEP_FORGOT_PASS>('SUBMIT_MAIL');
    const [email, setEmail] = useState<string>('');
    const [otp, setOtp] = useState('');
    return (
      <AuthenticationModalWrapper
        title={
          stepForgotPass === 'VERIFY_OTP'
            ? translations
              ? translations['b2c.auth.verifyOtpText']
              : ''
            : stepForgotPass === 'SUBMIT_MAIL'
            ? translations
              ? translations['b2c.txt.forgotPass']
              : ''
            : translations
            ? translations['b2c.txt.changePass']
            : ''
        }
        description={
          stepForgotPass === 'VERIFY_OTP'
            ? translations
              ? replaceDescription(
                  translations['b2c.txt.enterOtpChangePass'] ?? '',
                  '${email}',
                  email,
                )
              : ''
            : stepForgotPass === 'SUBMIT_MAIL'
            ? translations
              ? translations['b2c.txt.enterMailForgot']
              : ''
            : undefined
        }
        height={'650px'}
        isShow={isShow}
        onCloseDialog={onCloseDialog}
      >
        <div
          style={{
            display: stepForgotPass !== 'SUBMIT_MAIL' ? 'none' : undefined,
          }}
        >
          <FormForgotPass
            onSendForgotMailDone={email => {
              setStepForgotPass('VERIFY_OTP');
              setEmail(email);
            }}
          />
        </div>
        <div
          style={{
            display: stepForgotPass !== 'VERIFY_OTP' ? 'none' : undefined,
          }}
        >
          {stepForgotPass === 'VERIFY_OTP' && (
            <>
              <OTPRegister
                onVeriyOtpSuccess={otp => setOtp(otp)}
                type="CHANGE_PASS"
                mailRegister={email}
                setRegisterStep={val => {
                  val === REGISTER_STEPS.FILL_OTP
                    ? setStepForgotPass('SUBMIT_NEW_PASS')
                    : setStepForgotPass('SUBMIT_MAIL');
                }}
              />
            </>
          )}
        </div>
        <div
          style={{
            display: stepForgotPass !== 'SUBMIT_NEW_PASS' ? 'none' : undefined,
          }}
        >
          <FormChangePass
            otp={otp}
            email={email}
            onSendChangePassDone={() => {
              onOpenDialogNoti({
                type: 'SUCCESS',
                isShow: true,
                title: translations
                  ? translations['b2c.txt.changePassSuccessTitle']
                  : '',
                content: translations
                  ? translations['b2c.txt.changePassSuccessContent']
                  : '',
                closable: false,
                buttons: [
                  {
                    text: translations ? translations['b2c.txt.login'] : '',
                    bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
                    color: 'white',
                    onClick: () => {
                      onCloseDialogNoti();
                      onOpenDialogLogin();
                    },
                  },
                ],
              });
            }}
          />
        </div>
      </AuthenticationModalWrapper>
    );
  },
);

export default DialogForgotPass;
