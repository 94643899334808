/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  FormItemProps,
  Type_RefFormFunc,
} from 'app/components/FormContainer';
import { objectType } from 'types';
import { registerApi } from 'app/services/authApi';
import { selectTranslations } from '../slice/selectors';
import { NewFormContainer } from 'app/components/NewFormContainer';
import { PrimaryText, REGISTER_STEPS } from './DialogRegister';
import { ReactComponent as CalendarIcon } from '../../../assets/img/calendar.svg'
import { ReactComponent as ArrowDown } from '../../../assets/img/arrow_down.svg'
import StyledButton from 'app/components/StyledButton';
import InputText from 'app/components/FormInput/InputText';
import { useGlobalFunction } from '../hooks/useGlobalFunction';

import { ReactComponent as VisibleOffIcon } from 'app/assets/img/visibility_off.svg';
import { ReactComponent as VisibleOnIcon } from 'app/assets/img/visibility_on.svg';

interface FormRegisterProps {
  onRegisterSuccess: (token) => void
  setRegisterStep: Dispatch<SetStateAction<REGISTER_STEPS>>
  onOpenDialogLogin: () => void
  setMailRegister: Dispatch<SetStateAction<string>>
}

const { Password } = Input;

const INIT_FORM = {
  email: '',
  password: '',
  password_repeat: '',
  gender: 'male',
  address: '',
  name: '',
  phone: ''
};

const FormRegister = (props: FormRegisterProps) => {
  const {
    setRegisterStep,
    onOpenDialogLogin,
    setMailRegister
  } = props

  const [loading, setLoading] = useState(false);
  const translations = useSelector(selectTranslations);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();

  const LIST_GENDER = useMemo(() => [
    { id: 'male', label: translations?.['b2c.gender.male'] ?? '' },
    { id: 'female', label: translations?.['b2c.gender.female'] ?? '' },
  ], [translations]);

  const formRef = useRef<Type_RefFormFunc>(null!);

  const onTriggerSubmit = useCallback(() => {
    formRef.current?.getFormInstance().submit();
  }, []);

  const onSubmitForm = useCallback(
    async (values: objectType) => {
      setLoading(true);
      try {
        const payload = { ...values, register: '1' };
        if (!executeRecaptcha) return;
        const tokenCaptcha = await executeRecaptcha('register');
        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
          if (key !== 'dob' || (key === 'dob' && value)) formData.append(
            key,
            key === 'dob' ? moment(value).format('yyyy-MM-DD') : value,
          );
        });
        formData.append('captcha', tokenCaptcha);
        const { data } = await registerApi(formData);
        const errorCode = data?.errorCode;
        if (!errorCode) {
          setMailRegister(formData.get('email') as string)
          setRegisterStep(REGISTER_STEPS.FILL_OTP)
        } else if (errorCode === 400) {
          switch (errorCode) {
            case 400: {
              formRef.current.getFormInstance().setFields([
                { name: 'email', errors: [translations?.['b2c.message.mailExits'] ?? ''] }
              ])
              break;
            }
            default: {
              throw new Error();
            }
          }
        }
      } catch(error) {
        onOpenDialogNoti({
          type: 'ERROR',
          isShow: true,
          title: translations?.['b2c.action.failed'] ?? '',
          content: translations?.['b2c.message.tryNetwork'] ?? '',
          onCloseDialog: onCloseDialogNoti,
          buttons: [
            {
              text: translations?.['b2c.btn.retry'] ?? '',
              bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
              color: 'white',
              onClick: () => {
                onCloseDialogNoti()
              }
            },
          ],
          closable: false,
        });
      } finally {
        setLoading(false);
      }
    },
    [executeRecaptcha, setMailRegister, setRegisterStep, onOpenDialogNoti, translations, onCloseDialogNoti],
  );

  const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
    () => [
      {
        label: translations ? translations['b2c.form.name'] : '',
        name: 'name',
        rules: [{ required: true, max: 50 }],
        validateTrigger: ['onBlur'],
        children: <InputText size="large" onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.namePlaceholder'] ?? ''} maxLength={50} />,
      },
      {
        label: translations?.['b2c.form.email'] ?? '',
        name: 'email',
        validateFirst: true,
        rules: [
          { required: true },
          { max: 200 },
          { type: 'email', message: translations?.['b2c.invalid_email'] ?? '' },
          {
            pattern: /^[a-zA-Z0-9@.]+$/,
            message: translations ? translations['b2c.invalid_email'] : '',
          },
        ],
        validateTrigger: ['onBlur'],
        children: <InputText size="large" maxLength={200} onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.email'] ?? ''} disableWhiteSpace />,
      },
      {
        label: translations ? translations['b2c.form.password'] : '',
        name: 'password',
        rules: [
          { required: true },
          { min: 8 },
          { max: 20 },
          {
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*\-\s]).+/g,
            message: translations?.['b2c.form.passValid'] ?? '',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              const mail = getFieldValue('email');
              if (!value || !mail || !value.toLowerCase().includes(mail.toLowerCase())) {
                return Promise.resolve();
              }
              return Promise.reject(translations?.['b2c.form.passNotIncludeInfo'] ?? '');
            },
          }),
          ({ getFieldValue }) => ({
            validator(rule, value) {
              const phone = getFieldValue('phone');
              if (!value || !phone || !value.includes(phone)) {
                return Promise.resolve();
              }
              return Promise.reject(translations?.['b2c.form.passNotIncludeInfo'] ?? '');
            },
          }),
          // ({ getFieldValue }) => ({
          //   validator(rule, value) {
          //     if (!value) {
          //       return Promise.resolve();
          //     } else {
          //       const sequence = zxcvbn(value).sequence;
          //       console.log(sequence)
          //       let weakPass = sequence.find(i => i.pattern === 'dictionary');
          //       if (weakPass) return Promise.reject(`${translations?.['b2c.form.weakPassword']}: ${weakPass.token}` ?? '');
          //       weakPass = sequence.find(i => i.pattern === 'sequence');
          //       if (weakPass && weakPass.j - weakPass.i >= 3) return Promise.reject(`${translations?.['b2c.form.weakPassword']}: ${weakPass.token}` ?? '');
          //     }
          //     return Promise.resolve();
          //   },
          // }),
        ],
        validateTrigger: ['onBlur'],
        children: <Password maxLength={20} size="large" onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.password'] ?? ''} iconRender={visible => visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />} />,
      },
      {
        label: translations ? translations['b2c.form.re-password'] : '',
        name: 'password_repeat',
        rules: [
          { required: true },
          { max: 20 },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(translations?.['b2c.form.rePassValid'] ?? '');
            },
          }),
        ],
        validateTrigger: ['onBlur'],
        children: <Password maxLength={20} size="large" onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.re-password'] ?? ''} iconRender={visible => visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />} />,
      },

      {
        label: translations ? translations['b2c.form.phone'] : '',
        name: 'phone',
        rules: [
          { min: 10 },
          { max: 20 },
          {
            pattern: /^[0-9]+$/g,
            message: translations?.['b2c.form.wrongPhoneFormat'],
          },
        ],
        validateTrigger: ['onBlur'],
        children: <InputText onlyNumber size="large" onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.phone']} maxLength={20} />,
      },

      {
        label: translations ? translations['b2c.form.gender'] : '',
        name: 'gender',
        rules: [],
        validateTrigger: ['onBlur'],
        children: (
          <div id='gender-select-container'>
            <Select
              suffixIcon={<ArrowDown />}
              placeholder={translations?.['b2c.form.genderPlaceholder'] ?? ''}
              getPopupContainer={() => document.getElementById('gender-select-container')!}
            >
              {LIST_GENDER.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        label: translations ? translations['b2c.form.birth'] : '',
        name: 'dob',
        rules: [],
        validateTrigger: ['onBlur'],
        children: (
          <DOBSelectContainer id='dob-select-container'>
            <StyledDatePicker
              placeholder="dd/mm/yyyy"
              format={['DD/MM/YYYY']}
              size="large"
              suffixIcon={<CalendarIcon />}
              disabledDate={(current) => current && current > moment().endOf('day')}
              allowClear={false}
              getPopupContainer={() => document.getElementById('dob-select-container')!}
              popupStyle={{ top: '50px', background: 'lightblue' }}
            />
          </DOBSelectContainer>
        ),
      },
      {
        label: translations ? translations['b2c.form.address'] : '',
        name: 'address',
        rules: [{ max: 200 }],
        validateTrigger: ['onBlur'],
        children: (
          <InputText size="large" onPressEnter={onTriggerSubmit} placeholder={translations?.['b2c.form.address'] ?? ''} trim maxLength={200} />
        ),
      }
    ],
    [LIST_GENDER, onTriggerSubmit, translations],
  );

  return (
    <>
      <FormWrapper>
        <div style={{ paddingBottom: 80 }}>
          <NewFormContainer
            ref={formRef}
            layout="horizontal"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            colon={false}
            initialValues={INIT_FORM}
            listFields={LIST_FORM_FIELDS}
            onFinish={onSubmitForm}
          />
        </div>
      </FormWrapper>
      <RegisterFillInfoFooter
        loading={loading}
        onOpenDialogLogin={onOpenDialogLogin}
        onTriggerSubmit={onTriggerSubmit}
      />
    </>
  )
}

export default FormRegister;

const FormWrapper = styled.div`
  margin: 40px -42px 0;
  padding: 0 42px;
  height: calc(100% - 200px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

interface RegisterFillInfoFooterProps {
  loading: boolean
  onOpenDialogLogin: () => void
  onTriggerSubmit: () => void
}

const RegisterFillInfoFooter = (props: RegisterFillInfoFooterProps) => {
  const {
    loading,
    onOpenDialogLogin,
    onTriggerSubmit
  } = props

  const translations = useSelector(selectTranslations);

  return (
    <RegisterFillInfoFooterWrapper>
      <StyledButton
        disabled={loading}
        onClick={onTriggerSubmit}
        textColor='#fff'
      >
        <span>
          {loading
            ? 'Loading...'
            : translations
            ? translations['b2c.txt.register']
            : ''}
        </span>
      </StyledButton>
      <ConfirmLoginBlock>
        <p className="m-0" style={{ color: '#000000' }}>{translations ? translations['b2c.txt.txtRequestLogin'] : ''}{' '}</p>
        <PrimaryText onClick={onOpenDialogLogin}>
          {translations ? translations['b2c.txt.loginNow'] : ''}
        </PrimaryText>
      </ConfirmLoginBlock>
    </RegisterFillInfoFooterWrapper>
  )
}

const RegisterFillInfoFooterWrapper = styled.div`
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 16px 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;

const ConfirmLoginBlock = styled.div`
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }
`;

export const StyledVisibleOnIcon = styled(VisibleOnIcon)`
  cursor: pointer;
`;

export const StyledVisibleOffIcon = styled(VisibleOffIcon)`
  cursor: pointer;
`;

const DOBSelectContainer = styled.div`
  .ant-picker-dropdown {
    top: -314px !important;
  }
`

