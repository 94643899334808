/**
 *
 * Asynchronously loads the component for App
 *
 */

import { lazyLoad } from 'utils/loadable';

export const DialogLogin = lazyLoad(
  () => import('./DialogLogin'),
  module => module.DialogLogin,
);

export const DialogRegister = lazyLoad(
  () => import('./DialogRegister'),
  module => module.DialogRegister,
);

export const DialogForgotPass = lazyLoad(
  () => import('./DialogForgotPass'),
  module => module.default,
);

export const DialogChangePass = lazyLoad(
  () => import('./DialogChangePass'),
  module => module.default,
);
