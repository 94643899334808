import { isNil } from 'lodash';
import moment from 'moment';

export const parseSecondsToMinutes = (secondsInput: number) => {
  const duration = moment.duration(secondsInput, 'seconds');

  // Use asMinutes function to get the total minutes, which might be a floating point number
  const minutes = Math.floor(duration.asMinutes());
  const seconds = secondsInput - minutes * 60;

  return {
    minutes,
    seconds,
  };
};

export const formatDate = (
  date: moment.Moment,
  locale: 'vi' | 'en' | string,
  format: (dayOfWeek: string, date: moment.Moment) => string,
) => {
  const DAYS_OF_WEEK_VI = [
    'Chủ Nhật',
    'Thứ 2',
    'Thứ 3',
    'Thứ 4',
    'Thứ 5',
    'Thứ 6',
    'Thứ 7',
  ];
  const DAYS_OF_WEEK_EN = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let dayOfWeek = '';
  if (locale === 'vi' || locale === 'en')
    dayOfWeek =
      locale === 'vi'
        ? DAYS_OF_WEEK_VI[date.day()]
        : DAYS_OF_WEEK_EN[date.day()];
  else dayOfWeek = date.format('dddd');
  // Format the rest of the date
  return format(dayOfWeek, date);
};

export const getDateStringForValidDate = (
  fromDate: string,
  toDate: string,
  numberDateAddToFrom?: number,
  formatFunc?: (fromDate: moment.Moment, toDate: moment.Moment) => string,
) => {
  const standardDayAdd = isNil(numberDateAddToFrom) ? 0 : numberDateAddToFrom;
  const addDay = standardDayAdd <= 0 ? 1 : standardDayAdd;
  const fromDateMoment = moment(fromDate, 'DD/MM/YYYY');
  const toDateByAddDayMoment = moment(fromDate, 'DD/MM/YYYY').add(
    addDay - 1,
    'days',
  );
  // toDateByAddDayMoment.add(addDay - 1, 'days');
  const toDateMoment = moment(toDate, 'DD/MM/YYYY');
  const standardToDateMoment = toDateByAddDayMoment.isBefore(toDateMoment)
    ? toDateByAddDayMoment
    : toDateMoment;

  if (
    fromDateMoment.format('DD/MM/YYYY') ===
    standardToDateMoment.format('DD/MM/YYYY')
  ) {
    return formatFunc
      ? formatFunc(fromDateMoment, fromDateMoment)
      : fromDateMoment.format('DD/MM/YYYY');
  }
  return formatFunc
    ? formatFunc(fromDateMoment, standardToDateMoment)
    : `${fromDateMoment.format('DD/MM/YYYY')} - ${standardToDateMoment.format(
        'DD/MM/YYYY',
      )}`;
};

class BaseTimer {
  protected worker: Worker | null;
  protected listeners: (() => void)[];

  constructor() {
    this.worker = null;
    this.listeners = [];
  }

  start(interval: number, onTick: () => void) {
    if (this.worker) {
      return; // Timer is already running
    }
    // Create a new Worker
    this.worker = new Worker(
      URL.createObjectURL(
        new Blob(
          [
            `
    setInterval(() => {
      postMessage('tick');
    }, ${interval});
    `,
          ],
          { type: 'application/javascript' },
        ),
      ),
    );

    // Listen for messages from the Worker
    this.worker.onmessage = event => {
      onTick();
    };
  }

  stop() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }

  addListener(listener: () => void) {
    this.listeners.push(listener);
  }

  removeListener(listener: () => void) {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  protected fireEvent() {
    this.listeners.forEach(listener => listener());
  }
}

export class CountdownTimer extends BaseTimer {
  private countdown: number;

  constructor(countdown: number) {
    super();
    this.countdown = countdown;
  }

  start(interval: number, onTick?: (timeRemaining: number) => void) {
    super.start(interval, () => {
      this.countdown--;
      onTick && onTick(this.countdown);
      if (this.countdown <= 0) {
        this.stop();
        this.fireEvent();
      }
    });
  }

  reset() {
    this.stop();
    this.countdown = 0;
  }

  resetTo(countdown: number) {
    this.stop();
    this.countdown = countdown;
  }
}

export class IntervalTimer extends BaseTimer {
  private timeInterval: number;
  constructor(timeInterval: number) {
    super();
    this.timeInterval = timeInterval;
  }
  start() {
    super.start(this.timeInterval, () => {
      this.fireEvent();
    });
  }

  reset() {
    this.stop();
    this.start();
  }
}

export const isTimeValid = (hourFrom: any, hourTo: any) => {
  const parseHourFrom = moment(hourFrom, 'HH:mm').format('HH');
  const parseHourTo = moment(hourTo, 'HH:mm').format('HH');
  const parseMinuteFrom = moment(hourFrom, 'HH:mm').format('mm');
  const parseMinuteTo = moment(hourTo, 'HH:mm').format('mm');
  const currentDate = new Date();
  const currentHour = currentDate.getHours() + currentDate.getMinutes() / 60;
  const isValidHour =
    currentHour >=
      parseInt(parseHourFrom, 10) + parseInt(parseMinuteFrom, 10) / 60 &&
    currentHour < parseInt(parseHourTo, 10) + parseInt(parseMinuteTo, 10) / 60;
  return isValidHour;
};
