import styled from 'styled-components';

const StyledGradientDivider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0) 1%,
    #d9d9d9 51.5%,
    rgba(217, 217, 217, 0) 98%
  );
`;

export default StyledGradientDivider;
