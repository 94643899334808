/**
 *
 * LoadingContainerGlobal
 *
 */
import React, { memo, useMemo } from 'react';
import { LoadingContainerWrapper } from './styled';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

interface Props {
  loading?: boolean;
  description?: string;
  children?: React.ReactNode;
}

export const LoadingContainerGlobal = memo(
  ({ description = '', children }: Props) => {
    const antIcon = useMemo(
      () => (
        <LoadingOutlined
          style={{
            fontSize: 24,
            color: '#1890ff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
          spin
        />
      ),
      [],
    );
    const loading = useSelector((state: any) => state?.common?.loading);
    return (
      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          height: '100vh',
          width: '100vw',
          backgroundColor: '#FFFFFF',
          zIndex: 99999,
          opacity: 0.7,
          display: loading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin indicator={antIcon} tip={description} spinning={loading}>
          {children}
        </Spin>
      </div>
    );
  },
);
