import { Col, ColProps } from 'antd';
import useWindowSize from 'app/hooks/useWindowSize';
import React from 'react';
import styled from 'styled-components';

const ColTicketInfoKey = (props: ColProps) => {
  const StyledCol = styled(Col)`
    font-size: 14px;
    font-weight: 400;
    color: black;
  `;
  const { device } = useWindowSize();
  return <StyledCol span={device !== 'mobile' ? 8 : 10} {...props} />;
};

const ColTicketInfoValue = (props: ColProps) => {
  const StyledCol = styled(Col)`
    font-size: 14px;
    font-weight: 500;
    color: black;
  `;
  const { device } = useWindowSize();
  return <StyledCol span={device !== 'mobile' ? 16 : 14} {...props} />;
};

const ColTicketInfo = {
  ColTicketInfoKey,
  ColTicketInfoValue,
};

export default ColTicketInfo;
