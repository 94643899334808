import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState, DialogNotiState, I_Layout, I_User, Language } from './types';
import { objectType } from 'types';
import { setCookie } from 'utils/cookies';
import { TYPE_COOKIE } from 'utils/constants';

export const GET_ME = createRoutine('app/getMe');
export const GET_LAYOUT = createRoutine('app/getLayout');
export const GET_TRANS = createRoutine('app/getTranslations');
export const GET_LANGS = createRoutine('app/getLanguageList');

export const LOGIN = createRoutine('app/login');
export const LOGOUT = createRoutine('app/logout');
export const REGISTER = createRoutine('app/register');

const getStateAuthModal = (
  isShow = false,
  type?: 'LOGIN' | 'REGISTER' | 'FORGOT_PASS' | 'CHANGE_PASS',
): {
  isShowDialogLogin: boolean;
  isShowDialogRegister: boolean;
  isShowDialogForgotPass: boolean;
  isShowDialogChangePass: boolean;
} => {
  switch (type) {
    case 'LOGIN':
      return {
        isShowDialogLogin: isShow,
        isShowDialogRegister: false,
        isShowDialogForgotPass: false,
        isShowDialogChangePass: false,
      };
    case 'REGISTER':
      return {
        isShowDialogLogin: false,
        isShowDialogRegister: isShow,
        isShowDialogForgotPass: false,
        isShowDialogChangePass: false,
      };
    case 'FORGOT_PASS':
      return {
        isShowDialogLogin: false,
        isShowDialogRegister: false,
        isShowDialogForgotPass: isShow,
        isShowDialogChangePass: false,
      };
    case 'CHANGE_PASS':
      return {
        isShowDialogLogin: false,
        isShowDialogRegister: false,
        isShowDialogForgotPass: false,
        isShowDialogChangePass: isShow,
      };

    default:
      return {
        isShowDialogLogin: false,
        isShowDialogRegister: false,
        isShowDialogForgotPass: false,
        isShowDialogChangePass: false,
      };
  }
};

export const initialState: AppState = {
  loading: false,
  success: false,
  error: false,
  infoUser: undefined,
  translations: undefined,
  isLogged: undefined,
  language: 'vi',
  languageList: [],
  layout: {
    header: undefined,
    footer: undefined,
  },
  isShowDialogLogin: false,
  isShowDialogRegister: false,
  isShowDialogForgotPass: false,
  isShowDialogChangePass: false,
  dialogNotiState: {
    isShow: false,
    buttons: [],
    type: 'SUCCESS',
    title: '',
    content: '',
    maskClosable: true,
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openDialogLogin(state) {
      const newState = getStateAuthModal(true, 'LOGIN');
      const keys = Object.keys(newState);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        state[key] = newState[key];
      }
    },

    closeDialogAuth(state) {
      const newState = getStateAuthModal(false);
      const keys = Object.keys(newState);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        state[key] = newState[key];
      }
    },

    openDialogRegister(state) {
      const newState = getStateAuthModal(true, 'REGISTER');
      const keys = Object.keys(newState);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        state[key] = newState[key];
      }
    },

    onChangeLanguage(state, action: PayloadAction<'vi' | 'en'>) {
      state.language = action.payload;
      setCookie(TYPE_COOKIE.LANGUAGE, action.payload as string);
    },

    changeIsLogged(state, action: PayloadAction<{ isLogged: boolean }>) {
      state.isLogged = action.payload.isLogged;
    },

    changeStateLoading(state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },

    setInfoUser(state, action: PayloadAction<I_User | undefined>) {
      state.infoUser = state.infoUser
        ? { ...state.infoUser, ...action.payload }
        : action.payload;
    },

    logout(state) {
      state.infoUser = initialState.infoUser;
      state = initialState;
    },

    resetState(state) {
      Object.keys(state).forEach(item => {
        state[item] = initialState[item];
      });
    },
    openDialogForgotPass(state) {
      const newState = getStateAuthModal(true, 'FORGOT_PASS');
      const keys = Object.keys(newState);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        state[key] = newState[key];
      }
    },
    openDialogChangePass(state) {
      const newState = getStateAuthModal(true, 'CHANGE_PASS');
      const keys = Object.keys(newState);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        state[key] = newState[key];
      }
    },
    openDialogNoti(state, action: PayloadAction<DialogNotiState>) {
      state.dialogNotiState = action.payload;
    },

    closeDialogNoti(state) {
      state.dialogNotiState = {
        isShow: false,
        buttons: [],
        type: 'SUCCESS',
        title: '',
        content: '',
      };
    },
  },
  extraReducers: {
    /**
     * !GET_LAYOUT
     */
    [GET_LAYOUT.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [GET_LAYOUT.SUCCESS]: (state, action: PayloadAction<I_Layout>) => {
      state.layout = action.payload;
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [GET_LAYOUT.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },
    /**
     * !GET_ME
     */
    [GET_ME.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [GET_ME.SUCCESS]: (state, action: PayloadAction<I_User>) => {
      state.infoUser = action.payload;
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [GET_ME.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },

    /**
     * ! GET TRANS
     */
    [GET_TRANS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_TRANS.SUCCESS]: (state, action: PayloadAction<objectType>) => {
      state.translations = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_TRANS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    /**
     * ! GET LANGS
     */
    [GET_LANGS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_LANGS.SUCCESS]: (state, action: PayloadAction<Language[]>) => {
      state.languageList = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_LANGS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};
