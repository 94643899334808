import { Rule } from 'antd/lib/form';
import { objectType } from 'types';

/**
 * This function has two roles:
 * 1) If the `id` is empty it assings something so does i18next doesn't throw error. Typescript should prevent this anyway
 * 2) It has a hand-picked name `_t` (to be short) and should only be used while using objects instead of strings for translation keys
 * `internals/extractMessages/stringfyTranslations.js` script converts this to `t('a.b.c')` style before `i18next-scanner` scans the file contents
 * so that our json objects can also be recognized by the scanner.
 */
export const _t = (id: string, ...rest: any[]): [string, ...any[]] => {
  if (!id) {
    id = '_NOT_TRANSLATED_';
  }
  return [id, ...rest];
};

export const rulePhoneInput = (
  translations?: objectType,
): Rule[] | undefined => [
  {
    required: true,
    message: translations ? translations['b2c.txt.phoneRequired'] : '',
  },
  {
    min: 10,
    message: translations
      ? translations['b2c.form.invalid-phone'].replaceAll('${min}', 10)
      : '',
  },
  {
    pattern: /^[0-9]+$/g,
    message: translations ? translations['b2c.form.invalid-phone'] : '',
  },
];

export const ruleNameInput = (
  translations?: objectType,
): Rule[] | undefined => [
  {
    required: true,
    message: translations ? translations['b2c.txt.fullNameRequired'] : '',
  },
];

export const ruleEmailInput = (
  required: boolean,
  translations?: objectType,
): Rule[] | undefined => [
  {
    required,
    message:
      'Email ' +
      (translations ? translations['b2c.form.validate-require'] : ''),
  },
  {
    type: 'email',
    message: 'Email ' + (translations ? translations['b2c.form.invalid'] : ''),
  },
  { max: 200 },
  {
    pattern: /^[a-zA-Z0-9@.]+$/,
    message: 'Email ' + (translations ? translations['b2c.form.invalid'] : ''),
  },
];

export const ruleIndentityCardInput = (
  required: boolean,
  translations?: objectType,
): Rule[] | undefined => [
  {
    required,
    message:
      (translations ? translations['b2c.txt.identityCard'] : '') +
      ' ' +
      (translations ? translations['b2c.form.validate-require'] : ''),
  },
];
