import { Carousel, Col, Row } from 'antd';
import Images from 'app/assets/img';
import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import ModalContainer from 'app/components/ModalContainer';
import useWindowSize, { TABLET_WIDTH } from 'app/hooks/useWindowSize';
import { I_DetailTicket } from 'app/pages/Account/ListOrder/slice/types';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import ColTicketInfo from 'app/pages/NewAccount/components/ColTicketInfo';
import StyledDivider from 'app/pages/NewAccount/components/StyledDivider';
import {
  OrderInfoView,
  TicketInfo,
  TicketType,
} from 'app/pages/NewAccount/layouts/ListTicketLayout';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DateFormat } from 'utils/date-utils/date-format';
import { DateFromToUtils } from 'utils/date-utils/date-utils';
import { formatNumber } from 'utils/helper';
import { formatDate, getDateStringForValidDate } from 'utils/time';

const DetailTicketModal = ({
  isShow,
  ticket,
  onCloseDialog,
  orderState,
}: {
  isShow: boolean;
  ticket?: TicketType;
  onCloseDialog?: () => void;
  orderState: 'success' | 'paymentPending' | 'cancelled';
}) => {
  const formatString = 'HH:mm DD/MM/YYYY';
  const translations = useSelector(selectTranslations);
  const { device } = useWindowSize();
  const provincialCustomers = useMemo(() => {
    const provincialTicket =
      ticket?.ticketsInfo?.flatMap(val => val.dataBuyer) ?? [];
    const provincialGift =
      ticket?.giftsInfo?.flatMap(val => val.dataBuyer) ?? [];
    const mergedList = [...provincialTicket, ...provincialGift] ?? [];
    return mergedList.filter(
      val => Boolean(val?.email) || Boolean(val?.identityCode),
    );
  }, [ticket?.ticketsInfo, ticket?.giftsInfo]);
  return (
    <ModalContainer
      maskClosable={false}
      CloseIcon={
        device === 'mobile' || device === 'tablet' ? (
          <CloseIcon width={11} height={11} />
        ) : undefined
      }
      modalProps={{
        width: 'auto',
        style: {
          overscrollBehavior: 'none',
        },
      }}
      styleSlots={{
        modalContentStyle: {
          ...{
            width:
              device === 'desktop'
                ? '965px'
                : device === 'tablet'
                ? '730px'
                : 'calc(100vw - 36px)',
            maxWidth:
              device === 'desktop'
                ? '965px'
                : device === 'tablet'
                ? '730px'
                : 'auto',
          },
          ...(device === 'mobile' || device === 'tablet'
            ? { padding: '24px 16px' }
            : {}),
        },
        bodyStyle: {
          ...{
            minHeight: '680px',
            overflow: 'auto',
            padding: undefined,
            marginRight: '-42px',
            width: '98%',
            marginLeft: '-42px',
          },
          ...(device === 'mobile' || device === 'tablet' ? { padding: 0 } : {}),
        },
        iconWrapperStyle:
          device === 'mobile' || device === 'tablet'
            ? {
                margin: '0px 0px 0px 20px',
              }
            : {},
      }}
      closable
      isShow={isShow}
      onCloseDialog={onCloseDialog}
    >
      <div
      // style={{
      //   ...(device === 'mobile' || device === 'tablet'
      //     ? {
      //         position: 'sticky',
      //         top: -1,
      //         background: '#fff',
      //         zIndex: 1,
      //         height: 50,
      //       }
      //     : {}),
      // }}
      >
        <StyledH4>
          {translations ? translations['b2c.txt.detailOrder'] : ''}
        </StyledH4>
      </div>
      <div>
        {ticket && (
          <OrderInfoView ticket={ticket} formatString={formatString} />
        )}
      </div>
      {((ticket?.giftsInfo && ticket.giftsInfo.length > 0) ||
        (ticket?.vouchers?.length ?? 0) > 0) && (
        <div>
          <StyledH4 style={{ marginTop: 40, marginBottom: 24 }}>
            {translations ? translations['b2c.txt.giftInOrder'] : ''}
          </StyledH4>
        </div>
      )}
      {ticket?.giftsInfo && ticket.giftsInfo.length > 0 && (
        <>
          {device === 'desktop' ? (
            ticket.giftsInfo.map((val, valIdx) => {
              return (
                val.orgTickets.length > 0
                  ? val.orgTickets
                  : Array.from<any>({ length: val.amount })
              ).map(
                (ticket, idx) => (
                  <>
                    <TicketView
                      key={`${idx}${ticket?.vgsTicketCode ?? valIdx}`}
                      formatString={formatString}
                      ticket={ticket}
                      val={val}
                      orderState={orderState}
                    />
                    {idx < val.amount - 1 && (
                      <div
                        style={{
                          height: 24,
                        }}
                      />
                    )}
                  </>
                ),
                // TicketView(device, val, ticket, translations, formatString),
              );
            })
          ) : (
            <ListTicketCarousel
              infinite={false}
              dots={{ className: 'ticket-dots' }}
            >
              {ticket.giftsInfo.map((val, valIdx) => {
                return (
                  val.orgTickets.length > 0
                    ? val.orgTickets
                    : Array.from<any>({ length: val.amount })
                ).map(
                  (ticket, idx) => (
                    <>
                      <TicketView
                        key={`${idx}${ticket?.vgsTicketCode ?? valIdx}`}
                        formatString={formatString}
                        ticket={ticket}
                        val={val}
                        orderState={orderState}
                      />
                      {idx < val.amount - 1 && (
                        <div
                          style={{
                            height: 24,
                          }}
                        />
                      )}
                    </>
                  ),
                  // TicketView(device, val, ticket, translations, formatString),
                );
              })}
            </ListTicketCarousel>
          )}
        </>
      )}
      {ticket?.vouchers?.length
        ? ticket.vouchers.map((voucher, idx) => (
            <CouponContainer
              key={`${voucher.code}${idx}`}
              style={{
                marginTop: 24,
                marginBottom: device === 'desktop' ? 0 : 24,
                flexDirection: device === 'desktop' ? 'row' : 'column',
                justifyContent:
                  device === 'desktop' ? 'space-between' : 'center',
              }}
              bgImg={Images.BgCoupon}
            >
              <div
                className="title-voucher"
                style={{ marginBottom: device === 'desktop' ? 0 : 5 }}
              >
                {voucher.productName ?? ''}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#D9D9D980',
                  borderRadius: 100,
                  height: 30,
                  marginTop: device === 'desktop' ? 0 : 5,
                }}
              >
                <div style={{ color: '#808080', margin: '4px 23px 4px 23px' }}>
                  {translations ? translations['b2c.txt.codeUse'] : ''}
                </div>
                <div
                  style={{
                    backgroundColor: '#E92127',
                    borderRadius: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    height: 30,
                    color: 'white',
                    padding: '4px 35px 4px 35px',
                  }}
                >
                  {voucher.code ?? ''}
                </div>
              </div>
            </CouponContainer>
          ))
        : undefined}
      {ticket?.ticketsInfo && ticket.ticketsInfo.length > 0 && (
        <>
          <div>
            <StyledH4 style={{ marginTop: 40, marginBottom: 24 }}>
              {translations ? translations['b2c.txt.ticketList'] : ''}
            </StyledH4>
          </div>
          {device === 'mobile' || device === 'tablet' ? (
            <ListTicketCarousel
              infinite={false}
              dots={{ className: 'ticket-dots' }}
              slickDotsBottom="-4px"
            >
              {ticket.ticketsInfo.map((val, valIdx) => {
                return (
                  val.orgTickets.length > 0
                    ? val.orgTickets
                    : Array.from<any>({ length: val.amount })
                ).map((ticket, idx) => (
                  <TicketView
                    key={`${idx}${ticket?.vgsTicketCode ?? valIdx}`}
                    formatString={formatString}
                    ticket={ticket}
                    val={val}
                    orderState={orderState}
                  />
                ));
              })}
            </ListTicketCarousel>
          ) : (
            ticket.ticketsInfo.map((val, valIdx) => {
              return (
                val.orgTickets.length > 0
                  ? val.orgTickets
                  : Array.from<any>({ length: val.amount })
              ).map((ticket, idx) => (
                <>
                  <TicketView
                    key={`${idx}${ticket?.vgsTicketCode ?? valIdx}`}
                    formatString={formatString}
                    ticket={ticket}
                    val={val}
                    orderState={orderState}
                  />
                </>
              ));
            })
          )}
        </>
      )}
      {provincialCustomers.length > 0 && (
        <>
          <div>
            <StyledH4 style={{ marginTop: 40, marginBottom: 24 }}>
              {translations ? translations['b2c.txt.provincialCustomer'] : ''}
            </StyledH4>
          </div>
          {provincialCustomers.map((val, valIdx) => {
            return (
              <>
                <Row>
                  <Col
                    span={device === 'desktop' ? 12 : 24}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'flex-end',
                    }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={
                          device === 'desktop'
                            ? undefined
                            : device === 'tablet'
                            ? 5
                            : 9
                        }
                      >
                        <Key
                          style={{
                            marginRight: 16,
                          }}
                        >
                          Email
                        </Key>
                      </Col>
                      <Col
                        span={
                          device === 'desktop'
                            ? undefined
                            : device === 'tablet'
                            ? 19
                            : 15
                        }
                      >
                        <Value
                          style={{
                            fontWeight: 500,
                            marginRight: 16,
                          }}
                          title={val?.email ?? ''}
                        >
                          {val?.email ?? ''}
                        </Value>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={device === 'desktop' ? 12 : 24}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'flex-end',
                    }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={
                          device === 'desktop'
                            ? undefined
                            : device === 'tablet'
                            ? 5
                            : 9
                        }
                      >
                        <Key
                          style={{
                            marginRight: 16,
                          }}
                        >
                          {translations
                            ? translations['b2c.txt.identityCard']
                            : ''}
                        </Key>
                      </Col>
                      <Col
                        span={
                          device === 'desktop'
                            ? undefined
                            : device === 'tablet'
                            ? 19
                            : 15
                        }
                      >
                        <Value
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {val?.identityCode ?? ''}
                        </Value>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {valIdx < provincialCustomers.length - 1 && (
                  <StyledDivider style={{ width: '100%' }} dashed />
                )}
              </>
            );
          })}
        </>
      )}
    </ModalContainer>
  );
};

export default DetailTicketModal;

const Key = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CouponContainer = styled.div<{ bgImg: string }>`
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px 0px 32px;
  background-image: url(${props => props.bgImg});
  height: 84.5px;
  background-repeat: no-repeat;
  background-size: 100%; */
  height: 68px;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #fff8f8;
  position: relative;
  border: 1px solid #fdd7d7;
  padding: 14px 24px;
  margin: 14px 0px;
  display: flex;
  align-items: center;
  .title-voucher {
    font-size: 14px;
    font-weight: 500;
    color: #132639;
    margin-right: 12px;
  }
  ::after {
    position: absolute;
    content: '';
    height: 16px;
    right: -8px;
    border-radius: 8px;
    z-index: 1;
    top: 26px;
    background-color: #ffffff;
    width: 16px;
    border-left: 1px solid #fdd7d7;
  }

  ::before {
    position: absolute;
    content: '';
    height: 16px;
    left: -8px;
    border-radius: 8px;
    z-index: 1;
    top: 26px;
    background-color: #ffffff;
    width: 16px;
    border-right: 1px solid #fdd7d7;
  }

  @media (max-width: ${_ => TABLET_WIDTH}px) {
    height: 114px;
    padding: 0px;
    ::after {
      position: absolute;
      content: '';
      height: 16px;
      right: -8px;
      border-radius: 8px;
      z-index: 1;
      top: 49px;
      background-color: #ffffff;
      width: 16px;
      border-left: 1px solid #fdd7d7;
    }

    ::before {
      position: absolute;
      content: '';
      height: 16px;
      left: -8px;
      border-radius: 8px;
      z-index: 1;
      top: 49px;
      background-color: #ffffff;
      width: 16px;
      border-right: 1px solid #fdd7d7;
    }
  }
`;

const StyledH4 = styled.h4`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 32px;
  text-transform: uppercase;
`;

const TicketInfoContainer = styled.div`
  width: 100%;
  /* padding: 33px 34px 33px 34px; */
  background-color: #f7f7f7;
  border-radius: 20px;
`;

const TicketInfoContent = styled.div<{
  bgImg: string;
}>`
  display: flex;
  align-items: center;
  background-image: url(${props => props.bgImg});
  background-position: center right;
  background-repeat: no-repeat;
`;

const ListTicketCarousel = styled(Carousel)<{ slickDotsBottom?: string }>`
  .slick-dots {
    width: unset;
    /* button:before {
      content: '';
      width: 25px;
      height: 5px;
      border-radius: 10px;
      background: #fab317;
    } */
    /* .slick-active {
      button:before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #c1c1c1;
      }
    } */
  }
  .ticket-dots {
    margin-left: 0;
    margin-right: 0;
    li {
      background: #c1c1c1;
      width: initial;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      overflow: hidden;
      button {
        opacity: 0 !important;
        height: 5px;
        width: 5px;
        background: #c1c1c1;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 25px;
        background: #fab317;
        border-radius: 99px;
      }
    }
  }
  .slick-dots-bottom {
    bottom: ${props => props.slickDotsBottom ?? '-30px'};
  }
`;

function TicketView({
  formatString,
  val,
  ticket,
  orderState,
}: {
  val: TicketInfo & {
    orgTickets: I_DetailTicket[];
    amount: number;
  };
  ticket: any;
  formatString: string;
  orderState: 'success' | 'paymentPending' | 'cancelled';
}): JSX.Element {
  const { device } = useWindowSize();
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();

  const getDateCombo = (fromDate: moment.Moment, toDate: moment.Moment) => {
    const fromDateString = formatDate(
      fromDate,
      locale,
      (dayOfWeek, date) => `${dayOfWeek}, ${date.format('DD/MM/YYYY')}`,
    );
    const toDateString = formatDate(
      toDate,
      locale,
      (dayOfWeek, date) => `${dayOfWeek}, ${date.format('DD/MM/YYYY')}`,
    );
    if (fromDateString === toDateString) {
      return fromDateString;
    }
    return `${fromDateString} - ${toDateString}`;
  };

  const getDate = (
    fromDateInp: moment.Moment,
    toDateInp: moment.Moment,
    numberAddDay: number,
  ) => {
    return getDateStringForValidDate(
      fromDateInp.format('DD/MM/YYYY'),
      toDateInp.format('DD/MM/YYYY'),
      numberAddDay,
      (fromDate, toDate) => {
        const fromDateString = formatDate(
          fromDate,
          locale,
          (dayOfWeek, date) => `${dayOfWeek}, ${date.format('DD/MM/YYYY')}`,
        );
        const toDateString = formatDate(
          toDate,
          locale,
          (dayOfWeek, date) => `${dayOfWeek}, ${date.format('DD/MM/YYYY')}`,
        );
        if (fromDateString === toDateString) {
          return fromDateString;
        }
        return `${fromDateString} - ${toDateString}`;
      },
    );
  };
  return (
    <Row style={{ margin: '0px 4px 20px' }}>
      <Col flex={1}>
        <TicketInfoContainer>
          <Row
            justify="space-between"
            style={{
              padding:
                device === 'desktop'
                  ? '33px 34px 0px 34px'
                  : '16px 16px 0px 16px',
            }}
          >
            <ColTicketInfo.ColTicketInfoValue
              span={device !== 'mobile' ? 12 : 24}
              style={{
                textAlign: device === 'mobile' ? 'center' : 'start',
              }}
            >
              {device === 'mobile' ? (
                <span>
                  {val.parkName ?? ''}
                  <br></br>
                  {val.catalogName ?? ''}
                </span>
              ) : (
                val.eventName ?? ''
              )}
            </ColTicketInfo.ColTicketInfoValue>
            {device === 'mobile' && (
              <Col span={24}>
                <StyledDivider style={{ width: '100%' }} dashed />
              </Col>
            )}
            <Col
              span={device !== 'mobile' ? 12 : 24}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: device === 'mobile' ? 'center' : 'flex-end',
              }}
            >
              {ticket?.vgsTicketCode || ticket?.sapVerifyCode ? (
                <>
                  <Key style={{ marginRight: 8 }}>
                    {translations ? translations['b2c.txt.serialCode'] : ''}
                  </Key>
                  <Value>
                    {ticket?.sapVerifyCode
                      ? ticket?.sapVerifyCode
                      : ticket?.vgsTicketCode ?? ''}
                  </Value>
                </>
              ) : (
                <>
                  {orderState === 'success' && (
                    <>
                      <Key style={{ marginRight: 8 }}>
                        {translations ? translations['b2c.txt.serialCode'] : ''}
                      </Key>
                      <Value>
                        {translations ? translations['b2c.txt.updating'] : ''}
                      </Value>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
          {device === 'tablet' && (
            <Row>
              <Col span={24} style={{ padding: '0px 16px' }}>
                <StyledDivider
                  style={{ width: '100%', margin: '8px 0px 0px 0px' }}
                  dashed
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <TicketInfoContent
                bgImg={device === 'desktop' ? Images.TicketItemBG : ''}
                style={{
                  flexWrap: device !== 'mobile' ? 'nowrap' : 'wrap',
                  padding:
                    device === 'desktop'
                      ? '24px 34px 33px 34px'
                      : '8px 16px 16px 16px',
                }}
              >
                <div
                  style={{
                    width: device !== 'mobile' ? 270 : '100%',
                    height: 182,
                    background: '#fff',
                    border: '1px solid rgb(217, 217, 217)',
                    borderRadius: 20,
                    minWidth: 270,
                  }}
                >
                  {val.imgPath && (
                    <img
                      src={val.imgPath}
                      style={{
                        width: '100%',
                        height: 182,
                        borderRadius: 20,
                        overflow: 'clip',
                        objectFit: 'cover',
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: device !== 'mobile' ? 28 : 0,
                    width: '100%',
                    marginTop: device !== 'mobile' ? 0 : 16,
                  }}
                >
                  <Row style={{ marginBottom: 16 }}>
                    <ColTicketInfo.ColTicketInfoValue
                      span={24}
                      style={{
                        fontSize: 16,
                        wordBreak: 'break-word',
                      }}
                    >
                      {val.ticketName}
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                  <Row style={{ marginBottom: 11 }}>
                    <ColTicketInfo.ColTicketInfoKey
                      span={device === 'mobile' ? 12 : 8}
                    >
                      {translations ? translations['b2c.txt.date-use'] : ''}
                    </ColTicketInfo.ColTicketInfoKey>
                    <ColTicketInfo.ColTicketInfoValue
                      span={device === 'mobile' ? 12 : 16}
                    >
                      {
                        new DateFromToUtils.Builder(locale, 'DD/MM/YYYY')
                          .setFromDate(
                            ticket?.vgsUsageDate
                              ? DateFormat.create(
                                  'YYYY-MM-DD HH:mm',
                                  ticket?.vgsUsageDate,
                                ).getMoment()
                              : DateFormat.create(
                                  'HH:mm DD/MM/YYYY',
                                  val?.useDateFrom,
                                ).getMoment(),
                          )
                          .setToDate(
                            DateFormat.create(
                              'YYYY-MM-DD HH:mm',
                              ticket?.vgsUsageDateTo,
                            ).getMoment(),
                          )
                          .build()
                          .getDateFromTo().concatFromToDate
                      }
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                  {val.usedArea2Object && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 8}
                      >
                        {val.usedArea2Label || ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {val.usedArea2Object?.label}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  {val.position && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 8}
                      >
                        {translations ? translations['b2c.txt.zone'] : ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {val.position}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  {val.restaurant && (
                    <Row style={{ marginBottom: 11 }}>
                      <ColTicketInfo.ColTicketInfoKey
                        span={device === 'mobile' ? 12 : 8}
                      >
                        {translations
                          ? translations['b2c.text.restaurants']
                          : ''}
                      </ColTicketInfo.ColTicketInfoKey>
                      <ColTicketInfo.ColTicketInfoValue
                        span={device === 'mobile' ? 12 : 16}
                      >
                        {val.restaurant}
                      </ColTicketInfo.ColTicketInfoValue>
                    </Row>
                  )}
                  <Row style={{ marginBottom: 11 }}>
                    <ColTicketInfo.ColTicketInfoKey
                      span={device === 'mobile' ? 12 : 8}
                    >
                      {translations ? translations['b2c.txt.unitPrice'] : ''}
                    </ColTicketInfo.ColTicketInfoKey>
                    <ColTicketInfo.ColTicketInfoValue
                      span={device === 'mobile' ? 12 : 16}
                      style={{
                        color: '#e92127',
                      }}
                    >
                      {formatNumber(val.price)}
                      {'đ'}
                    </ColTicketInfo.ColTicketInfoValue>
                  </Row>
                </div>
              </TicketInfoContent>
            </Col>
          </Row>
        </TicketInfoContainer>
      </Col>
    </Row>
  );
}
