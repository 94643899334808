import { Input } from 'antd';
import styled from 'styled-components';

const StyledSearchInput = styled(Input)`
  width: ${props => props.width ?? 'auto'} !important;
  height: 44px;
  border-radius: 6px !important;
  .ant-input {
    &::placeholder {
      font-style: italic;
    }
  }
`;

export default StyledSearchInput;
