import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TicketListByPark from '../TicketListByPark';
import { ReactComponent as CartEmptyIcon } from 'app/assets/img/cart_empty.svg';
import StyledButton from 'app/components/StyledButton';
import { RootState } from 'types';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import useWindowSize from 'app/hooks/useWindowSize';
import { FormCustomContext } from '../..';

interface CartDetailProps {}

const TicketList = (props: CartDetailProps) => {
  const { ticketsSortedByPark, listCart, onSetCart } =
    useContext(FormCustomContext);
  const isLoading = useSelector((state: RootState) => state.cart?.loading);
  const translations = useSelector(selectTranslations);
  const { device } = useWindowSize();

  const isNotSellectAll = useMemo(
    () => listCart.some(item => !item.selected),
    [listCart],
  );

  const isEmpty = useMemo(() => listCart.length < 1, [listCart]);

  const handleSelectAll = () => {
    onSetCart(
      isNotSellectAll
        ? listCart.map(i => ({ ...i, selected: true }))
        : listCart.map(i => ({ ...i, selected: false })),
    );
  };

  return (
    <LoadingContainer loading={isLoading}>
      <TicketContainer>
        <div className="d-flex justify-content-between align-items-center">
          <StyledH3>
            {isLoading && listCart?.length < 1
              ? null
              : `${translations?.['b2c.txt.cart'] ?? ''} (${listCart.length})`}
          </StyledH3>
          {!isEmpty && (
            <StyledCheckbox
              checked={!isNotSellectAll}
              onClick={handleSelectAll}
            >
              <StyledP>{translations?.['b2c.text.all'] ?? ''}</StyledP>
            </StyledCheckbox>
          )}
        </div>
        {isEmpty && !isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              flexDirection: 'column',
              marginTop: device === 'desktop' ? 0 : 28,
            }}
          >
            <CartEmptyIcon />
            <StyledP style={{ marginTop: 24, color: '#6F8295' }}>
              {translations?.['b2c.txt.cartEmpty'] ?? ''}
            </StyledP>
            <Link to="catalog">
              <ChooseCartBtn textColor="#ffffff">
                {translations?.['b2c.txt.getTicket'] ?? ''}
              </ChooseCartBtn>
            </Link>
          </div>
        ) : (
          ticketsSortedByPark.map(ticket => {
            return (
              <div className="ticket-list" key={ticket.park.id}>
                <TicketListByPark
                  title={ticket.park.name}
                  tickets={ticket.tickets}
                />
              </div>
            );
          })
        )}
      </TicketContainer>
    </LoadingContainer>
  );
};

export default TicketList;

const TicketContainer = styled.div`
  border-radius: 20px;
  background: #ffff;
  padding: 40px 30px;
  min-height: 325px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 20px 0px;
  .ticket-list {
    margin-top: 24px;
    @media (max-width: 1199px) {
      margin-top: 16px;
    }
  }
  @media (max-width: 1199px) {
    padding: 16px;
    min-height: 280px;
  }
`;

const StyledH3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  flex-direction: row-reverse;
  &.ant-checkbox-wrapper {
    align-items: center;
  }
  .ant-checkbox {
    top: 0;
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        &::after {
          transform: rotate(45deg) scale(1) translate(-80%, -50%);
        }
      }
    }
  }
  .ant-checkbox-inner {
    height: 16px;
    width: 16px;
    border: solid 2px #c1c1c1;
  }
`;

const StyledP = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #132639;
  margin: 0;
`;

const ChooseCartBtn = styled(StyledButton)`
  padding: 13px 47px;
  width: auto;
  border-radius: 12px;
  margin-top: 24px;
`;
