/**
 *
 * styled
 *
 */
import styled from 'styled-components/macro';
import { CheckoutWrapper } from '../Checkout/styled';

export const PaymentCallbackWrapper = styled(CheckoutWrapper)`
  .payConfirm {
    padding: 36px 0;
    .--img {
      img {
        max-width: 280px;
      }
    }
    .--txt {
      width: 60%;
      margin: auto;
    }
    h4 {
      color: #598748;
      font-weight: 900;
    }
    h6 {
      margin: 0 auto;
      width: max-content;
      padding: 16px;
      border-radius: 10px;
      background: rgb(249 178 23 / 10%);
    }
    .--des {
      margin-bottom: 4px;
    }
    .bttn {
      margin-top: 24px;
      .--button {
        height: 40px;
        font-family: var(--font-sunheavy);
        &:nth-child(1) {
          background: var(--color-2);
          width: 160px;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
        }
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
      .--buttonticket {
        margin-left: 8px;
        padding: 0 20px;
        border: none;
        color: #fff;
        width: 160px;
        border-radius: 4px;
        background: var(--color-4);
      }
    }
    &.fail {
      height: calc(100vh - 276px);
      h4 {
        color: var(--color-1);
      }
    }
    @media (max-width: 768px) {
      .--txt {
        width: 100%;
      }
      h6 {
        width: 100%;
      }
    }
  }
  .payInfooder {
    padding: 3rem 0 80px;
    .Title {
      margin-bottom: 20px;
      h1 {
        font-weight: 900;
      }
      span {
        padding: 4px 6px 2px;
        margin-left: 10px;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 4px;
        color: #b1b1b1;
        font-weight: 900;
      }
    }
    .infopayment {
      background: var(--color-2);
      border: 1px dashed #bdbdbd;
      border-radius: 8px;
      padding: 16px;
      h5 {
        margin-bottom: 10px;
        font-weight: 900;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          display: flex;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          &:nth-child(1) {
            width: 100px;
            flex: 0 0 auto;
            margin-right: 8px;
          }
        }
        &.--info {
          border-bottom: 2px solid #e7e7e7;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        &.--payment {
          li {
            &:nth-child(1) {
              span {
                &:nth-child(2) {
                  font-weight: 900;
                }
              }
            }
          }
        }
      }
    }
  }
`;
