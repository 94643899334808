import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { listingOrderSaga } from './saga';
import { I_Order, I_PayloadGetListOrder, ListingOrderState } from './types';
import { objectType } from 'types';

export const GET_LIST_ORDER = createRoutine('listingorder/getListOrder');

export const initialState: ListingOrderState = {
  loading: false,
  success: false,
  error: false,
  listOrder: [],
  orderCount: undefined,
  optionsGetListOrder: {
    page: 1,
    limit: 50,
    search: undefined,
    orderState: 'success',
  },
};

const slice = createSlice({
  name: 'listingOrder',
  initialState,
  reducers: {
    onSetOrderCount(state, action: PayloadAction<objectType | undefined>) {
      state.orderCount = action.payload;
    },
    onSetOptionsGetListOrder(
      state,
      action: PayloadAction<Partial<I_PayloadGetListOrder>>,
    ) {
      const { page, search, orderState } = action.payload;
      if (search !== undefined) {
        state.optionsGetListOrder.search = search;
        state.optionsGetListOrder.page = 1;
      }
      if (orderState !== undefined) {
        state.optionsGetListOrder.orderState = orderState;
        state.optionsGetListOrder.page = 1;
      }
      if (page !== undefined) state.optionsGetListOrder.page = page;
    },
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    /**
     * ! GET LIST ORDER
     */
    [GET_LIST_ORDER.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_LIST_ORDER.SUCCESS]: (state, action: PayloadAction<I_Order[]>) => {
      state.listOrder = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_LIST_ORDER.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: listingOrderActions } = slice;

export const useListingOrderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: listingOrderSaga });
  return { actions: slice.actions };
};
