/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import { Button } from 'antd';
import Images from 'app/assets/img';
import ModalContainer from 'app/components/ModalContainer';
import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';

interface Props {
  isShow: boolean;
  height?: string | number;
  type: NotiType;
  title?: string;
  content?: ReactNode;
  onCloseDialog?: () => void;
  buttons?: ButtonType[];
  closable?: boolean;
  maskClosable?: boolean;
  icon?: ReactNode;
  hideIcon?: boolean;
  // onOpenDialogForgetPassword: () => void;
}

const getIcon = (type: NotiType, icon?: ReactNode) => {
  if (icon) return icon;

  switch (type) {
    case 'SUCCESS':
      return <img src={Images.SuccessIcon} alt="" />;
    case 'ERROR':
      return <img src={Images.ErrorIcon} alt="" />;

    default:
      return <div />;
  }
};

const StyledButton = styled(Button)<{
  bg: string;
  color: string;
  border?: string;
}>`
  background: ${props => props.bg};
  border: ${props => props.border ?? 'none'};
  width: 160px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  color: ${props => props.color};
  &:hover,
  &:active,
  &:focus {
    background: ${props =>
      props.bg ?? 'linear-gradient(79.97deg, #f06f24 -7.79%, #f8b117 107.61%)'};
    color: ${props => props.color};
    border: ${props => props.border ?? 'none'};
  }
`;

const NormalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
`;

const DialogNotification = memo(
  ({
    isShow,
    onCloseDialog,
    buttons = [],
    title,
    content = '',
    type,
    height,
    closable,
    maskClosable,
    icon,
    hideIcon,
  }: // onOpenDialogForgetPassword,
  Props) => {
    return (
      <ModalContainer
        closable={closable}
        isShow={isShow}
        onCloseDialog={onCloseDialog}
        styleSlots={{
          modalContentStyle: {
            height,
            maxWidth: 412,
            padding: '42px 18px',
          },
        }}
        maskClosable={maskClosable}
      >
        {!hideIcon && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 18,
            }}
          >
            {getIcon(type, icon)}
          </div>
        )}
        <div
          style={{
            margin: '40px 0px 00px 0px',
          }}
        >
          <h4
            style={{
              margin: 0,
              fontWeight: 600,
              fontSize: 20,
              textAlign: 'center',
              color: '#1C1B1F',
            }}
          >
            {title}
          </h4>
        </div>
        <div
          style={{
            margin: '12px 0px 40px 0px',
          }}
        >
          {typeof content === 'string' ? (
            <NormalText>{content}</NormalText>
          ) : (
            content
          )}
        </div>
        <ButtonGroup>
          {buttons.map((btn, idx) => (
            <StyledButton
              key={`${idx}`}
              onClick={btn.onClick}
              color={btn.color ?? 'white'}
              border={btn.border}
              bg={
                btn.bg ??
                'linear-gradient(79.97deg, #f06f24 -7.79%, #f8b117 107.61%)'
              }
            >
              {btn.text}
            </StyledButton>
          ))}
        </ButtonGroup>
      </ModalContainer>
    );
  },
);

export default DialogNotification;

export type NotiType = 'SUCCESS' | 'ERROR';
export type ButtonType = {
  text: string;
  bg: string;
  color: string;
  onClick?: () => void;
  border?: string;
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;
