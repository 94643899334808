import { Form, Input, Select } from 'antd';
import { FormItemProps } from 'app/components/FormContainer';
import {
  selectInfoUser,
  selectTranslations,
} from 'app/pages/App/slice/selectors';
import { I_Category, I_CheckoutCartItem } from 'app/pages/Cart/slice/types';
import {
  CheckoutDetail,
  I_PaymentMethod,
} from 'app/pages/Checkout/slice/types';
import {
  createCheckoutPaymentApi,
  getCheckoutDetailOrderApi,
  updateCheckoutPaymentApi,
  validateCheckoutPaymentApi,
} from 'app/services/checkoutApi';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I_Park, objectType } from 'types';
import { PROVINCES, TYPE_LOCAL_STORAGE } from 'utils/constants';
import { useCheckoutSlice } from '../slice';
import { selectListPaymentMethod } from '../slice/selectors';
import { getSessionItem } from 'utils/localStorage';
import { generateUUID } from 'utils/helper';

interface Props {
  paymentMethodActive?: number;
  onTriggerSubmit?: () => void;
}

export type GroupDatas = {
  park: I_Park;
  catalogs: Record<
    string,
    {
      catalog: any;
      items: I_CheckoutCartItem[];
    }
  >;
};

export const MATERRIAL_SHOW_TYPE = '9004'; //Vé show
export const MATERRIAL_PARK_TYPE = '9005'; //Vé công viên
export const MATERRIAL_EVOUCHER_TYPE = '9006'; //Evoucher
export const MATERRIAL_COMBO_TYPE = '9008'; //Combo

const groupItems = (
  items: I_CheckoutCartItem[],
): Record<
  string,
  {
    park: I_Park;
    catalogs: Record<string, { catalog: any; items: I_CheckoutCartItem[] }>;
  }
> => {
  return items.reduce((groupedItems, item) => {
    const parkKey = `${item.park.id}`;
    const catalogKey = `${item.catalog.id}`;

    if (!groupedItems[parkKey]) {
      groupedItems[parkKey] = { park: item.park, catalogs: {} };
    }

    if (!groupedItems[parkKey].catalogs[catalogKey]) {
      groupedItems[parkKey].catalogs[catalogKey] = {
        catalog: item.catalog,
        items: [],
      };
    }

    groupedItems[parkKey].catalogs[catalogKey].items.push(item);

    return groupedItems;
  }, {} as Record<string, { park: I_Park; catalogs: Record<string, { catalog: any; items: I_CheckoutCartItem[] }> }>);
};

export const useCheckout = ({
  paymentMethodActive,
  onTriggerSubmit,
}: Props) => {
  useCheckoutSlice();
  const [listCart, setListCart] = useState<I_CheckoutCartItem[]>([]);
  const [vouchers, setVouchers] = useState<Partial<I_CheckoutCartItem>[]>([]);
  const [groupCart, setGroupCart] = useState<GroupDatas[]>([]);

  const [loading, setLoading] = useState(false);
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [mailReceiveInvoices, setMailReceiveInvoices] = useState([
    { id: generateUUID() },
  ]);
  const [isShowInvoiceInfo, setIsShowInvoiceInfo] = useState(false);
  const orderCheckout = useRef<CheckoutDetail>();
  const history = useHistory();

  const infoUser = useSelector(selectInfoUser);
  const translations = useSelector(selectTranslations);
  const listPaymentMethod = useSelector(selectListPaymentMethod);
  // const datesSelectedToCheckout = useSelector(selectDatesSelectedToCheckout);
  const INIT_FORM = useMemo(
    () => ({
      name: infoUser?.name || '',
      phone: infoUser?.phone || '',
      email: infoUser?.email || '',
    }),
    [infoUser],
  );

  const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
    () => [
      {
        label: '',
        name: `group`,
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        children: (
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                name="name"
                label={translations ? translations['b2c.form.name'] : ''}
                rules={[{ required: true }]}
              >
                <Input size="large" onPressEnter={onTriggerSubmit} />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="phone"
                label={translations ? translations['b2c.form.phone'] : ''}
                rules={[
                  { required: true },
                  { min: 10 },
                  { max: 11 },
                  {
                    pattern: /^[0-9]+$/g,
                    message: translations
                      ? translations['b2c.form.invalid-phone']
                      : '',
                  },
                ]}
              >
                <Input size="large" onPressEnter={onTriggerSubmit} />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true }, { type: 'email' }]}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  onPressEnter={onTriggerSubmit}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="identificationCard"
                label={
                  translations
                    ? translations['b2c.form.identificationCard']
                    : ''
                }
                rules={[{ required: true }]}
              >
                <Input
                  size="large"
                  placeholder=""
                  onPressEnter={onTriggerSubmit}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="cityCode"
                label={translations ? translations['b2c.form.cityCode'] : ''}
                rules={[{ required: true }]}
              >
                <Select size="large" placeholder="">
                  <Select.Option key="" value="">
                    Chọn tỉnh thành
                  </Select.Option>
                  {PROVINCES?.map(item => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.province}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        ),
      },
    ],
    [onTriggerSubmit, translations],
  );

  const totalAmount = useMemo(
    () => listCart.reduce((result, item) => result + +item.amount, 0),
    [listCart],
  );

  const [totalPrice, setTotalPrice] = useState(0);

  const [totalPriceOriginal, setTotalPriceOriginal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const listTicketProvincial = useMemo(() => {
    return listCart.filter(
      item =>
        item.show?.areaType === '1' &&
        item.show.ageType === '1' &&
        !item.isGift,
    );
  }, [listCart]);

  const getFormPaymentValue = useCallback(
    (values: objectType) => {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (
          key === 'taxCode' ||
          key === 'branchTax' ||
          key === 'companyName' ||
          key === 'companyAddress'
        ) {
          return;
        }
        formData.append(key, value);
      });
      formData.append('idHash', `${orderCheckout.current?.idHash}`);
      formData.append('payment', `${paymentMethodActive}`);
      return formData;
    },
    [paymentMethodActive],
  );

  const validateProvincial = useCallback(
    async (values: objectType) => {
      try {
        setLoading(true);
        const formData = getFormPaymentValue(values);
        const { data } = await validateCheckoutPaymentApi(formData);
        return data;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [getFormPaymentValue],
  );

  const onSubmitForm = useCallback(
    async (values: objectType, payment?: I_PaymentMethod) => {
      try {
        setLoading(true);
        const formData = getFormPaymentValue(values);
        if (isShowInvoiceInfo) {
          const invoiceFormData = new FormData();
          invoiceFormData.append('taxNumberCompany', values?.['taxCode'] ?? '');
          invoiceFormData.append(
            'taxBranchCompany',
            values?.['branchTax'] ?? '',
          );
          invoiceFormData.append('nameCompany', values?.['companyName'] ?? '');
          invoiceFormData.append(
            'addressCompany',
            values?.['companyAddress'] ?? '',
          );
          const mailValues = mailReceiveInvoices.map((item, index) => {
            formData.delete(`emailTax[${item.id}]`);
            return {
              email: values[`emailTax[${item.id}]`] as string | undefined,
              id: `emailTax[${item.id}]`,
            };
          });
          mailValues
            .filter(item => Boolean(item.email))
            .forEach((item, index) => {
              invoiceFormData.append(`emailTaxs[${index}]`, item.email ?? '');
            });
          invoiceFormData.append('idHash', `${orderCheckout.current?.idHash}`);
          await updateCheckoutPaymentApi(invoiceFormData);
        }
        // return;

        const { data } = await createCheckoutPaymentApi(formData);
        sessionStorage.removeItem(TYPE_LOCAL_STORAGE.ORDER);
        if (payment?.code === 'ncb') {
          history.push('/ncb-payment', {
            id: orderCheckout.current?.idHash,
            qrImage: data.paymentUrl,
          });
        } else {
          window.open(data.paymentUrl, '_self');
        }
      } finally {
        setLoading(false);
      }
    },
    [getFormPaymentValue, history, isShowInvoiceInfo, mailReceiveInvoices],
  );
  useEffect(() => {
    const getDetail = async () => {
      try {
        setLoading(true);
        if (!getSessionItem(TYPE_LOCAL_STORAGE.ORDER)) {
          history.push('/');
          return;
        }
        setEnableConfirm(true);
        const id = JSON.parse(getSessionItem(TYPE_LOCAL_STORAGE.ORDER) ?? '');
        const { data: detailOrder } = await getCheckoutDetailOrderApi(id);

        orderCheckout.current = detailOrder;
        setTotalDiscount(detailOrder.totalDiscount);
        setTotalPrice(detailOrder.totalPrice);
        setTotalPriceOriginal(detailOrder.totalOriginalPrice);

        const items: I_CheckoutCartItem[] = detailOrder?.items.map(item => ({
          product: {
            id: item.id,
            name: item.productName,
            price: item.price,
            originalPrice: item.originalPrice,
            categories: [] as I_Category[],
            amount: `${item.amount}`,
            parks: [item.park],
            park: item.park,
            image: item.park.image,
            version: item?.product?.version,
            usedArea2Object: item?.product?.usedArea2Object,
          },
          amount: `${item.amount}`,
          date: item.date.dateDMY,
          zone: item.zone,
          show: item.show,
          restaurant: item?.product?.restaurant,
          price: item.price,
          originalPrice: item.originalPrice,
          performance: { ...item.performance, show: item.show },
          park: item.park,
          catalog: item.catalog,
          isGift: false,
        }));
        const giftItems: I_CheckoutCartItem[] =
          detailOrder?.giftItems
            ?.filter(
              val =>
                val.materialType === MATERRIAL_SHOW_TYPE ||
                val.materialType === MATERRIAL_PARK_TYPE,
            )
            .map(item => ({
              product: {
                id: item.id,
                name: item.productName ?? '',
                price: item.price,
                originalPrice: item.originalPrice,
                categories: [] as I_Category[],
                amount: `${item.amount}`,
                parks: [item.park],
                park: item.park,
                image: item.park?.image ?? '',
                version: item?.product?.version,
                usedArea2Object: item?.product?.usedArea2Object,
              },
              amount: `${item.amount ?? 0}`,
              date: item.date?.dateDMY ?? '',
              zone: item.zone,
              show: item.show,
              restaurant: item?.product?.restaurant,
              price: item.price,
              originalPrice: item.originalPrice,
              performance: { ...item.performance, show: item.show },
              park: item.park,
              catalog: item.catalog,
              isGift: true,
            })) ?? [];
        const vouchersData: Partial<I_CheckoutCartItem>[] =
          detailOrder?.giftItems
            ?.filter(val => val.materialType === MATERRIAL_EVOUCHER_TYPE)
            .map(item => ({
              product: {
                id: item.id,
                name: item.productName ?? '',
                price: item.price,
                originalPrice: item.originalPrice,
                categories: [] as I_Category[],
                amount: `${item.amount}`,
                parks: [item.park],
                park: item.park,
                image: item.park?.image ?? '',
                version: item?.product?.version,
              },
              amount: `${item.amount ?? 0}`,
              date: item.date?.dateDMY ?? '',
              zone: item.zone,
              show: item.show,
              restaurant: item?.product?.restaurant,
              price: item.price,
              originalPrice: item.originalPrice,
              performance: { ...item.performance, show: item.show },
              park: item.park,
              catalog: item.catalog,
              isGift: true,
            })) ?? [];
        const groupDatas = Object.values(groupItems([...items, ...giftItems]));
        setVouchers(vouchersData);
        console.log('order', detailOrder, groupItems([...items, ...giftItems]));
        setGroupCart(groupDatas);
        setListCart([...items, ...giftItems]);
        sessionStorage.removeItem(TYPE_LOCAL_STORAGE.ORDER);
      } finally {
        setLoading(false);
      }
    };
    getDetail();
  }, []);
  return useMemo(
    () => ({
      loading,
      INIT_FORM,
      onSubmitForm,
      LIST_FORM_FIELDS,
      listPaymentMethod,
      listTicketProvincial,
      // listCartSelectedToCheckout,
      totalAmount,
      totalPrice,
      totalPriceOriginal,
      orderCheckout,
      enableConfirm,
      setEnableConfirm,
      totalDiscount,
      validateProvincial,
      groupCart,
      infoUser,
      setLoading,
      vouchers,
      listCart,
      isShowInvoiceInfo,
      setIsShowInvoiceInfo,
      mailReceiveInvoices,
      setMailReceiveInvoices,
    }),
    [
      loading,
      INIT_FORM,
      onSubmitForm,
      LIST_FORM_FIELDS,
      listPaymentMethod,
      // listCartSelectedToCheckout,
      listTicketProvincial,
      totalAmount,
      totalPrice,
      totalPriceOriginal,
      orderCheckout,
      enableConfirm,
      setEnableConfirm,
      totalDiscount,
      validateProvincial,
      groupCart,
      infoUser,
      setLoading,
      vouchers,
      listCart,
      isShowInvoiceInfo,
      setIsShowInvoiceInfo,
      mailReceiveInvoices,
      setMailReceiveInvoices,
    ],
  );
};
