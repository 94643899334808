/**
 *
 * FormContainer
 *
 */
import React, { memo, forwardRef, Ref, useImperativeHandle } from 'react';
import { FormContainerWrapper } from './styled';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'app/pages/App/slice/selectors';

type NamePath = string | number | (string | number)[];
type ValidateStatusType = 'validating' | 'success' | 'warning' | 'error';

export interface FieldData {
  name: string[];
  value: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export interface FormItemProps {
  name?: string | number;
  colon?: boolean;
  dependencies?: NamePath[] | undefined;
  extra?: React.ReactNode | string;
  help?: React.ReactNode | string;
  hasFeedback?: boolean;
  label?: string | React.ReactNode;
  labelAlign?: 'left' | 'right';
  rules?: any;
  htmlFor?: string;
  required?: boolean;
  trigger?: string;
  initialValue?: any;
  validateStatus?: ValidateStatusType;
  validateTrigger?: string | string[];
  valuePropName?: string;
  shouldUpdate?: boolean | ((prevValue: any, currentValue: any) => boolean);
  normalize?: (value: any, prevValue: any, prevValues: any) => void;
  wrapperCol?: object;
  children?: React.ReactNode;
}

export interface FormProps {
  layout?: 'inline' | 'horizontal' | 'vertical' | undefined;
  colon?: boolean;
  name?: string;
  fields?: FieldData[];
  hideRequiredMark?: boolean;
  initialValues?: object;
  labelAlign?: 'left' | 'right';
  labelCol?: object;
  wrapperCol?: object;
  validateMessages?: any;
  size?: 'small' | 'middle' | 'large';
  onFinish: (values: any) => void;
  onFieldsChange?: (changeField: any, allFields: any) => void;
  onValuesChange?: (changeValue: any, allValues: any) => void;
}

interface Props extends FormProps {
  listFields: FormItemProps[];
  children?: React.ReactNode;
}

export type Type_RefFormFunc = {
  getFormInstance: () => FormInstance;
};

export const NewFormContainer = memo(
  forwardRef((props: Props, ref: Ref<Type_RefFormFunc>) => {
    const [form] = Form.useForm();
    const { listFields, validateMessages, ...formProps } = props;

    const translations = useSelector(selectTranslations);

    useImperativeHandle(ref, () => ({
      getFormInstance() {
        return form;
      },
    }));

    return (
      <FormContainerWrapper>
        <Form
          validateMessages={{
            ...{
              required:
                '${label} ' +
                (translations ? translations['b2c.form.validate-require'] : ''),
              string: {
                len: '${label} phải có ${len} kí tự',
                min:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-min-chars-1']
                    : '') +
                  ' ${min} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                max:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-max-chars-1']
                    : '') +
                  ' ${max} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                range: '${label} must be between ${min} and ${max} kí tự',
              },
              types: {
                email:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                number:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                string:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                date:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                method:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                array:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                object:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                boolean:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                integer:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                float:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                regexp:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                url:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                hex:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
              },
              number: {
                len: '${label} must equal ${len}',
                min: '${label} cannot be less than ${min}',
                max: '${label} cannot be greater than ${max}',
                range: '${label} must be between ${min} and ${max}',
              },
              array: {
                len: '${label} must be exactly ${len} in length',
                min: '${label} cannot be less than ${min} in length',
                max: '${label} cannot be greater than ${max} in length',
                range: '${label} must be between ${min} and ${max} in length',
              },
              pattern: {
                mismatch: '${label} does not match pattern ${pattern}',
              },
              enum: '${label} must be one of [${enum}]',
              whitespace: '${label} cannot be empty',
              date: {
                format: '${label} is invalid for format date',
                parse: '${label} could not be parsed as date',
                invalid: '${label} is invalid date',
              },
            },
            ...validateMessages,
          }}
          {...formProps}
          form={form}
        >
          {listFields.map(field => {
            const { children, ...fieldProps } = field;
            return (
              children && (
                <Form.Item
                  key={field.name || new Date().toString()}
                  {...fieldProps}
                >
                  {children}
                </Form.Item>
              )
            );
          })}
        </Form>
      </FormContainerWrapper>
    );
  }),
);
