import Images from 'app/assets/img';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as LogoutIcon } from 'app/assets/img/logout.svg';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'app/pages/App/slice/selectors';

export enum ACCOUNT_MNG_MENU {
  PURCHASE_HISTORY,
  PERSONAL_INFO,
  CHANGE_PASS,
  LOGOUT,
}

const AccountManager = (props: {
  onClickHistory?: () => void;
  onClickProfile?: () => void;
  onClickLogout?: () => void;
  menuActive?: ACCOUNT_MNG_MENU;
}) => {
  const { menuActive } = props;

  const { onLogout, onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();
  const translations = useSelector(selectTranslations);

  const renderMessCofirmLogout = (message: string) => {
    const partsOfMsg = message.split('\\n\\r');
    return partsOfMsg.map(i => <LogoutConfirmMsg>{i}</LogoutConfirmMsg>);
  };

  const onConfirmLogout = () => {
    onOpenDialogNoti({
      type: 'ERROR',
      isShow: true,
      title: translations?.['b2c.menu.logout'] ?? '',
      content: renderMessCofirmLogout(
        translations?.['b2c.text.logoutConfirm'] ?? '',
      ),
      buttons: [
        {
          text: translations?.['b2c.btn.cancel'] ?? '',
          bg: '#ffff',
          border: 'solid 1px #808080',
          color: '#808080',
          onClick: () => {
            onCloseDialogNoti();
          },
        },
        {
          text: translations?.['b2c.txt.confirm'] ?? '',
          bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
          color: 'white',
          onClick: () => {
            onCloseDialogNoti();
            onLogout();
          },
        },
      ],
      maskClosable: false,
      icon: <LogoutIcon />,
      onCloseDialog: onCloseDialogNoti,
    });
  };

  return (
    <div
      style={{
        borderRadius: 20,
        background: '#FFFFFF',
        padding: '40px 32px 32px 32px',
        boxShadow: '0px 20px 20px 0px #0000000D',
      }}
    >
      <div>
        <StyledH4
          style={{
            marginBottom: 36,
            textTransform: 'uppercase',
            color: '#000000',
          }}
        >
          {translations?.['b2c.account.mng'] ?? ''}
        </StyledH4>
      </div>
      <Link to="/account">
        <StyledAccountMenuItem
          isSelected={menuActive === ACCOUNT_MNG_MENU.PURCHASE_HISTORY}
        >
          <img src={Images.HistoryIcon} alt="" />
          {translations?.['b2c.txt.order-history'] ?? ''}
        </StyledAccountMenuItem>
      </Link>
      <Link to="/profile">
        <StyledAccountMenuItem
          isSelected={menuActive === ACCOUNT_MNG_MENU.PERSONAL_INFO}
        >
          <img src={Images.UserIcon} alt="" />
          {translations?.['b2c.menu.account'] ?? ''}
        </StyledAccountMenuItem>
      </Link>
      <Link to="/change-pass">
        <StyledAccountMenuItem
          isSelected={menuActive === ACCOUNT_MNG_MENU.CHANGE_PASS}
        >
          <img src={Images.ChangePassIcon} alt="" />
          {translations?.['b2c.menu.change-pass'] ?? ''}
        </StyledAccountMenuItem>
      </Link>
      <StyledAccountMenuItem
        onClick={onConfirmLogout}
        isSelected={menuActive === ACCOUNT_MNG_MENU.LOGOUT}
      >
        <img src={Images.LogoutIcon} alt="" />
        {translations?.['b2c.menu.logout'] ?? ''}
      </StyledAccountMenuItem>
    </div>
  );
};

export default AccountManager;

const StyledH4 = styled.h4`
  font-weight: 600;
  font-size: 20px;
`;

const StyledAccountMenuItem = styled.div<{
  isSelected: boolean;
}>`
  color: #000000;
  background-color: ${props =>
    props.isSelected ? '#FFEDEB80' : 'transparent'};
  border-radius: 20px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
`;

const LogoutConfirmMsg = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1c1b1f;
  text-align: center;
  margin: 0 auto;
  &:not(:first-child) {
    margin-top: 1px;
  }
`;
