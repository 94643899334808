/**
 *
 * PaymentCallback
 *
 */
import { Divider } from 'antd';
import Images from 'app/assets/img';
import { MOBILE_WIDTH } from 'app/hooks/useWindowSize';
import { I_Order } from 'app/pages/Account/ListOrder/slice/types';
import {
  selectIsLogged,
  selectLayoutFooter,
  selectTranslations,
} from 'app/pages/App/slice/selectors';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  detailOrder?: I_Order;
}

export const PaymentFail = memo(({ detailOrder }: Props) => {
  const history = useHistory();
  const isLogged = useSelector(selectIsLogged);
  const translations = useSelector(selectTranslations);
  const footer = useSelector(selectLayoutFooter);

  const onGoListingOrderPage = () => history.push('/account/list-order');

  return (
    <NotiContainer>
      <div className="icon-container">
        <img src={Images.ErrorIcon} alt="" />
      </div>
      <div className="title-container">
        <h3>{translations ? translations['b2c.txt.orderFailed'] : ''}</h3>
      </div>
      <div className="order-id">
        {translations ? translations['b2c.txt.orderNumber'] : ''}{' '}
        <span>{detailOrder?.id}</span>
      </div>
      <div className="content-container">
        {translations ? translations['b2c.txt.orderErrDesc'] : ''}
      </div>
      <div className="content-container">
        {translations ? translations['b2c.txt.listErrOrder'] : ''}
      </div>
      <StyledDivider dashed />
      <div className="contact-container">
        {translations ? translations['b2c.txt.contactHotline'] : ''}
      </div>
      <div className="contact-no-container">
        Hotline <span>{footer?.phoneNumber ?? ''}</span>
      </div>
    </NotiContainer>
  );
});

export const StyledDivider = styled(Divider)`
  margin: 14px 0px 14px 0px;
  width: 100%;
  min-width: 92%;
  border-color: #d9d9d9;
`;

export const NotiContainer = styled.div`
  border-radius: 20px;
  background-color: #fff;
  color: #000;
  padding: 60px 138px;
  box-shadow: 0px 20px 20px 0px #0000000d;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  .title-container {
    margin: 24px 0px 32px 0px;
    h3 {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .order-id {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    span {
      color: #e92127;
    }
  }
  .content-container {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 0px 40px;
  }
  .contact-container {
    margin: 20px 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .contact-no-container {
    font-size: 16px;
    font-weight: 500;
    span {
      color: #e92127;
    }
  }

  @media (max-width: ${_ => MOBILE_WIDTH}px) {
    padding: 24px 8px;
  }
`;
