export const generateTicketIdentify = (ticket: {
  showId: string;
  performanceId?: number | undefined;
  zoneId: string | undefined;
  date: string;
  restaurantId?: string;
  usedArea2?: string | number;
}) => {
  let identify = ticket.showId;
  for (let key in ticket) {
    if (key === 'showId') continue;
    const value = ticket[key];
    identify += '_';
    if (value && value !== 'undefined' && value !== 'null') identify += value;
  }
  return identify;
};
