/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import FormRegister from './FormRegister';
import AuthenticationModalWrapper from './AuthenticationModalWrapper';
import { selectTranslations } from '../slice/selectors';
import styled from 'styled-components';
import OTPRegister, { replaceDescription } from './OTPRegister';

interface Props {
  isShow: boolean;
  onCloseDialog: () => void;
  onRegisterSuccess: (token) => void;
  onOpenDialogLogin: () => void;
}

export enum REGISTER_STEPS {
  FILL_INFO,
  FILL_OTP
}

export const DialogRegister = memo(
  ({ isShow, onRegisterSuccess, onCloseDialog, onOpenDialogLogin }: Props) => {
    const [registerStep, setRegisterStep] = useState<REGISTER_STEPS>(REGISTER_STEPS.FILL_INFO)
    const isFillInfo = registerStep === REGISTER_STEPS.FILL_INFO
    const isFillOTP = registerStep === REGISTER_STEPS.FILL_OTP
    const translations = useSelector(selectTranslations);
    const [mailRegister, setMailRegister] = useState('');

    const desciption = isFillOTP ? replaceDescription(translations?.['b2c.fillOtpToCompleteRegister'] ?? '', '${email}',
    mailRegister,) : ''

    return (
      <AuthenticationModalWrapper
        isShow={isShow}
        title={isFillInfo ? translations ? translations['b2c.txt.register'] : '' : translations?.['b2c.auth.verifyOtpText'] ?? ''}
        description={desciption}
        onCloseDialog={onCloseDialog}
      >
        {
          isFillInfo ?
            <FormRegister
              onRegisterSuccess={onRegisterSuccess}
              setRegisterStep={setRegisterStep}
              onOpenDialogLogin={onOpenDialogLogin}
              setMailRegister={setMailRegister}
            /> : 
            <OTPRegister
              type='REGISTER'
              setRegisterStep={setRegisterStep}
              mailRegister={mailRegister}
            />
        }
      </AuthenticationModalWrapper>
    );
  },
);

export default DialogRegister;

export const GradientBtn = styled(Button)`
  background: linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%);
  border: none;
  width: 160px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%);
    border: none;
    color: #ffff;
  }
`;

export const PrimaryText = styled.p<{ disabled?: boolean }>`
  color: ${props => props.disabled ? 'gray' : '#1A64C3'};
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  margin: 0;
`;


