import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)<{
  bgColor?: string;
  textColor?: string;
  width?: string | number;
  height?: string | number;
  border?: string;
}>`
  background: ${props =>
    props.bgColor ??
    'linear-gradient(79.97deg, #f06f24 -7.79%, #f8b117 107.61%)'};
  color: ${props => props.textColor};
  border: ${props => props.border ?? 'none'};
  width: ${props => props.width ?? '160px'};
  height: ${props => props.height ?? '48px'};
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  &:hover,
  &:active,
  &:focus {
    background: ${props =>
      props.bgColor ??
      'linear-gradient(79.97deg, #f06f24 -7.79%, #f8b117 107.61%)'};
    color: ${props => props.textColor};
    border: ${props => props.border ?? 'none'};
  }
`;

export default StyledButton;
