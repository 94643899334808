import React, {
  MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Col, Dropdown, Form, Row } from 'antd';
import Images from 'app/assets/img';
import { ReactComponent as ArrowDownIcon } from 'app/assets/img/arrow-down-icon.svg';
import { ReactComponent as CalendarIcon } from 'app/assets/img/calendar-icon.svg';
import { ReactComponent as FilterIcon } from 'app/assets/img/filter-icon.svg';
import { LoadingContainer } from 'app/components/LoadingContainer';
import StyledButton from 'app/components/StyledButton';
import StyledTabs from 'app/components/StyledTabs';
import useWindowSize from 'app/hooks/useWindowSize';
import { selectOrderCount } from 'app/pages/Account/ListOrder/slice/selectors';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { GET_PARKS, useHomeSlice } from 'app/pages/Home/slice';
import { selectListPark } from 'app/pages/Home/slice/selectors';
import ListCancelTicket from 'app/pages/NewAccount/PurchaseHistory/components/ListCancelTicket';
import ListPendingTicket from 'app/pages/NewAccount/PurchaseHistory/components/ListPendingTicket';
import StyledDatePicker from 'app/pages/NewAccount/components/StyledDatePicker';
import StyledSearchInput from 'app/pages/NewAccount/components/StyledSearchInput';
import StyledSelect, {
  MobileStyledSelect,
} from 'app/pages/NewAccount/components/StyledSelect';
import { ACCOUNT_MNG_MENU } from 'app/pages/NewAccount/layouts/AccountManager';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { I_OptionsGetList } from 'types';
import ListSuccessTicket from './components/ListSuccessTicket';
import { Layout } from '../layouts';
import { formatDate as formatDateUtil } from 'utils/time';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { isNil } from 'lodash';

import styles from './styles.module.css';

type SearchComponentProps = {
  searchText: MutableRefObject<string>;
  searchParkID: string;
  setSearchParkID: (val: string) => void;
  travelLocation: {
    value: string;
    label: string;
  }[];
  searchDate?: moment.Moment;
  setSearchDate: (val?: moment.Moment) => void;
  searchSuccess: RefObject<{
    search: (params: Partial<I_OptionsGetList>) => void;
  }>;
  searchPending: RefObject<{
    search: (params: Partial<I_OptionsGetList>) => void;
  }>;
  searchCancel: RefObject<{
    search: (params: Partial<I_OptionsGetList>) => void;
  }>;
};

const DesktopSearchComponent = ({
  searchText,
  searchParkID,
  setSearchParkID,
  travelLocation,
  searchDate,
  setSearchDate,
  searchSuccess,
  searchPending,
  searchCancel,
}: SearchComponentProps) => {
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();
  const formatDate = (date: moment.Moment) => {
    return formatDateUtil(
      date,
      locale,
      (dayOfWeek, date) => `${dayOfWeek} ${date.format('(DD/MM/YYYY)')}`,
    );
    // Format the rest of the date
  };
  return (
    <Form
      style={{
        display: 'flex',
        marginBottom: 16,
      }}
    >
      <StyledSearchInput
        placeholder={translations ? translations['b2c.txt.orderNumber'] : ''}
        onBlur={e => {
          searchText.current = e.target.value;
        }}
        style={{
          marginRight: 18,
          flex: 1,
        }}
        suffix={<img src={Images.SearchIcon} alt="" />}
      />
      <StyledSelect
        value={searchParkID}
        suffixIcon={<ArrowDownIcon />}
        style={{ width: 242, marginRight: 18, flex: 1 }}
        placeholder={translations ? translations['b2c.txt.park'] : ''}
        dropdownStyle={{
          zIndex: 1,
        }}
        onChange={val => setSearchParkID(val as string)}
        options={travelLocation}
      />
      <StyledDatePicker
        style={{
          marginRight: 18,
          flex: 1,
        }}
        suffixIcon={<CalendarIcon />}
        // clearIcon={undefined}
        placeholder={translations ? translations['b2c.txt.date-order'] : ''}
        format={formatDate}
        popupStyle={{ zIndex: 1 }}
        value={searchDate}
        onChange={e => setSearchDate(!isNil(e) ? e : undefined)}
      />
      <StyledButton
        bgColor="white"
        border="1px solid #F8B117"
        textColor="#F8B117"
        width={'124px'}
        height={'44px'}
        onClick={() => {
          // setSearchValue({
          //   ...searchValue,
          //   searchText: searchText.current,
          // })
          const data = {
            search: searchText.current,
            orderDate: searchDate?.format('YYYY-MM-DD'),
            park: searchParkID,
            page: 1,
            limit: 10,
          };
          searchSuccess.current?.search(data);
          searchPending.current?.search(data);
          searchCancel.current?.search(data);
        }}
      >
        {translations ? translations['b2c.txt.search'] : ''}
      </StyledButton>
    </Form>
  );
};

const MobileTabletSearchComponent = (
  props: SearchComponentProps & { setActiveKeyTab: (val: string) => void },
) => {
  const { setActiveKeyTab } = props;
  const translations = useSelector(selectTranslations);
  const orderCount = useSelector(selectOrderCount);
  const [tab, setTab] = useState('tab-1');
  const [menuVisible, setMenuVisible] = useState(false);
  const { device } = useWindowSize();
  return (
    <Form
      style={{
        marginBottom: 16,
        display: 'flex',
      }}
    >
      <div
        style={{
          marginRight: 8,
          width: device !== 'mobile' ? '220px' : '186px',
        }}
      >
        <MobileStyledSelect
          value={tab}
          onChange={val => {
            setTab(val as string);
            setActiveKeyTab(val as string);
          }}
          suffixIcon={<ArrowDownIcon />}
          style={{ width: '100%' }}
          options={[
            {
              value: 'tab-1',
              label:
                (translations ? translations['b2c.txt.success'] : '') +
                ' (' +
                (orderCount?.success ?? 0) +
                ')',
            },
            {
              value: 'tab-2',
              label:
                (translations ? translations['b2c.txt.pending'] : '') +
                ' (' +
                (orderCount?.paymentPending ?? 0) +
                ')',
            },
            {
              value: 'tab-3',
              label:
                (translations ? translations['b2c.txt.cancelled'] : '') +
                ' (' +
                (orderCount?.cancelled ?? 0) +
                ')',
            },
          ]}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flex: device === 'mobile' ? 1 : undefined,
          width: device === 'mobile' ? '55%' : '85%',
          justifyContent: 'flex-end',
        }}
      >
        <Dropdown
          overlayStyle={{ zIndex: 1 }}
          visible={menuVisible}
          trigger={['click']}
          onVisibleChange={visible => setMenuVisible(visible)}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          overlay={
            device === 'mobile' ? (
              <MobileSearchOverlay {...props} setMenuVisible={setMenuVisible} />
            ) : (
              <TabletSearchOverlay {...props} setMenuVisible={setMenuVisible} />
            )
          }
        >
          <StyledButton
            bgColor="#fff"
            style={{
              border: '1px solid #EDEDED',
              borderRadius: 10,
              width: 41,
              height: 41,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
            }}
          >
            <FilterIcon width={16} height={16} color="#000" />
          </StyledButton>
        </Dropdown>
      </div>
    </Form>
  );
};

const MobileSearchOverlay = ({
  searchText,
  searchParkID,
  setSearchParkID,
  travelLocation,
  searchDate,
  setSearchDate,
  searchSuccess,
  searchPending,
  searchCancel,
  setMenuVisible,
}: SearchComponentProps & { setMenuVisible: (show: boolean) => void }) => {
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();
  const formatDate = (date: moment.Moment) => {
    return formatDateUtil(
      date,
      locale,
      (dayOfWeek, date) => `${dayOfWeek} ${date.format('(DD/MM/YYYY)')}`,
    );
    // Format the rest of the date
  };
  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: 8,
        boxShadow: '0px 20px 20px 0px #0000000D',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledSearchInput
        placeholder={translations ? translations['b2c.txt.orderNumber'] : ''}
        onBlur={e => {
          searchText.current = e.target.value;
        }}
        style={{
          marginBottom: 8,
          width: '100%',
        }}
        suffix={<img src={Images.SearchIcon} alt="" />}
      />
      <StyledSelect
        value={searchParkID}
        suffixIcon={<ArrowDownIcon />}
        style={{ width: 300, marginBottom: 8 }}
        placeholder={translations ? translations['b2c.txt.park'] : ''}
        onChange={val => setSearchParkID(val as string)}
        options={travelLocation}
      />
      <StyledDatePicker
        style={{
          marginBottom: 8,
          width: '100%',
        }}
        suffixIcon={<CalendarIcon />}
        // clearIcon={undefined}
        placeholder={translations ? translations['b2c.txt.date-order'] : ''}
        format={formatDate}
        value={searchDate}
        onChange={e => setSearchDate(!isNil(e) ? e : undefined)}
      />
      <StyledButton
        bgColor="white"
        border="1px solid #F8B117"
        textColor="#F8B117"
        width={'100%'}
        height={'44px'}
        onClick={() => {
          const data = {
            search: searchText.current,
            orderDate: searchDate?.format('YYYY-MM-DD'),
            park: searchParkID,
            page: 1,
            limit: 10,
          };
          searchSuccess.current?.search(data);
          searchPending.current?.search(data);
          searchCancel.current?.search(data);
          setMenuVisible(false);
        }}
      >
        {translations ? translations['b2c.txt.search'] : ''}
      </StyledButton>
    </div>
  );
};

const TabletSearchOverlay = ({
  searchText,
  searchParkID,
  setSearchParkID,
  travelLocation,
  searchDate,
  setSearchDate,
  searchSuccess,
  searchPending,
  searchCancel,
  setMenuVisible,
}: SearchComponentProps & { setMenuVisible: (show: boolean) => void }) => {
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();
  const formatDate = (date: moment.Moment) => {
    return formatDateUtil(
      date,
      locale,
      (dayOfWeek, date) => `${dayOfWeek} ${date.format('(DD/MM/YYYY)')}`,
    );
    // Format the rest of the date
  };
  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: 8,
        boxShadow: '0px 20px 20px 0px #0000000D',
        display: 'flex',
        flexDirection: 'column',
        width: 500,
      }}
    >
      <Row gutter={8}>
        <Col span={12}>
          <StyledSearchInput
            placeholder={
              translations ? translations['b2c.txt.orderNumber'] : ''
            }
            onBlur={e => {
              searchText.current = e.target.value;
            }}
            width={'100%'}
            style={{
              marginBottom: 8,
            }}
            suffix={<img src={Images.SearchIcon} alt="" />}
          />
        </Col>
        <Col span={12}>
          <StyledSelect
            value={searchParkID}
            suffixIcon={<ArrowDownIcon />}
            style={{ width: '100%', marginBottom: 8 }}
            placeholder={translations ? translations['b2c.txt.park'] : ''}
            onChange={val => setSearchParkID(val as string)}
            options={travelLocation}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <StyledDatePicker
            style={{
              width: '100%',
            }}
            suffixIcon={<CalendarIcon />}
            // clearIcon={undefined}
            placeholder={translations ? translations['b2c.txt.date-order'] : ''}
            format={formatDate}
            value={searchDate}
            onChange={e => setSearchDate(!isNil(e) ? e : undefined)}
          />
        </Col>
        <Col span={12}>
          <StyledButton
            bgColor="white"
            border="1px solid #F8B117"
            textColor="#F8B117"
            width={'100%'}
            height={'44px'}
            onClick={() => {
              const data = {
                search: searchText.current,
                orderDate: searchDate?.format('YYYY-MM-DD'),
                park: searchParkID,
                page: 1,
                limit: 10,
              };
              searchSuccess.current?.search(data);
              searchPending.current?.search(data);
              searchCancel.current?.search(data);
              setMenuVisible(false);
            }}
          >
            {translations ? translations['b2c.txt.search'] : ''}
          </StyledButton>
        </Col>
      </Row>
    </div>
  );
};

const PurcharseHistory = () => {
  const searchText = useRef('');
  const dispatch = useDispatch();
  const translations = useSelector(selectTranslations);
  const searchSuccess =
    useRef<{ search: (params: Partial<I_OptionsGetList>) => void }>(null);
  const searchPending =
    useRef<{ search: (params: Partial<I_OptionsGetList>) => void }>(null);
  const searchCancel =
    useRef<{ search: (params: Partial<I_OptionsGetList>) => void }>(null);
  const [searchDate, setSearchDate] = useState<moment.Moment>();
  const { device } = useWindowSize();
  const [searchParkID, setSearchParkID] = useState<string>('');
  const [activeKeyTab, setActiveKeyTab] = useState<string>('tab-1');
  const [loading, setLoading] = useState(false);
  const orderCount = useSelector(selectOrderCount);
  useHomeSlice();

  const listPark = useSelector(selectListPark);

  const travelLocation = useMemo(() => {
    return [
      ...[
        { value: '', label: translations ? translations['b2c.text.all'] : '' },
      ],
      ...listPark?.map(item => ({
        value: `${item.id}`,
        label: item.name,
      })),
    ];
  }, [listPark]);

  useEffect(() => {
    dispatch(GET_PARKS());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout
      title={translations ? translations['b2c.txt.order-history'] : ''}
      menuActive={ACCOUNT_MNG_MENU.PURCHASE_HISTORY}
    >
      <LoadingContainer loading={loading}>
        <div>
          {device === 'desktop' && (
            <DesktopSearchComponent
              searchText={searchText}
              searchParkID={searchParkID}
              searchDate={searchDate}
              setSearchParkID={setSearchParkID}
              setSearchDate={setSearchDate}
              searchCancel={searchCancel}
              searchPending={searchPending}
              searchSuccess={searchSuccess}
              travelLocation={travelLocation}
            />
          )}
          {(device === 'mobile' || device === 'tablet') && (
            <MobileTabletSearchComponent
              setActiveKeyTab={setActiveKeyTab}
              searchText={searchText}
              searchParkID={searchParkID}
              searchDate={searchDate}
              setSearchParkID={setSearchParkID}
              setSearchDate={setSearchDate}
              searchCancel={searchCancel}
              searchPending={searchPending}
              searchSuccess={searchSuccess}
              travelLocation={travelLocation}
            />
          )}
        </div>
        <div>
          <StyledTabs
            onChange={key => {
              // console.log(key);
              setActiveKeyTab(key);
            }}
            activeKey={activeKeyTab}
            tabBarStyle={{
              display: device !== 'desktop' ? 'none' : undefined,
            }}
          >
            <StyledTabs.TabPane
              forceRender
              tab={
                (translations ? translations['b2c.txt.success'] : '') +
                ' (' +
                (orderCount?.success ?? 0) +
                ')'
              }
              key="tab-1"
            >
              <ListSuccessTicket
                ref={searchSuccess}
                activeKeyTab={activeKeyTab}
                loading={loading}
                setLoading={setLoading}
              />
            </StyledTabs.TabPane>
            <StyledTabs.TabPane
              forceRender
              tab={
                (translations ? translations['b2c.txt.pending'] : '') +
                ' (' +
                (orderCount?.paymentPending ?? 0) +
                ')'
              }
              key="tab-2"
            >
              <ListPendingTicket
                ref={searchPending}
                activeKeyTab={activeKeyTab}
                loading={loading}
                setLoading={setLoading}
              />
            </StyledTabs.TabPane>
            <StyledTabs.TabPane
              forceRender
              tab={
                (translations ? translations['b2c.txt.cancelled'] : '') +
                ' (' +
                (orderCount?.cancelled ?? 0) +
                ')'
              }
              key="tab-3"
            >
              <ListCancelTicket
                ref={searchCancel}
                activeKeyTab={activeKeyTab}
                loading={loading}
                setLoading={setLoading}
              />
            </StyledTabs.TabPane>
          </StyledTabs>
        </div>
      </LoadingContainer>
    </Layout>
  );
};

export default PurcharseHistory;
