/**
 *
 * styled App
 *
 */
import styled from 'styled-components/macro';

export const AppWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  .reapop__notification-message {
    display: flex;
    word-break: break-word;
  }
`;
