import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { Layout } from '../layouts';
import { ACCOUNT_MNG_MENU } from '../layouts/AccountManager';
import { FormItemProps } from 'app/components/FormContainer';
import {
  selectInfoUser,
  selectTranslations,
} from 'app/pages/App/slice/selectors';
import { ReactComponent as CalendarIcon } from 'app/assets/img/calendar.svg';
import { ReactComponent as ArrowDown } from 'app/assets/img/arrow_down.svg';
import { objectType } from 'types';
import { FormContainerWrapper } from 'app/components/NewFormContainer/styled';
import StyledButton from 'app/components/StyledButton';
import InputText from 'app/components/FormInput/InputText';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { updateMeApi } from 'app/services/authApi';
import { appActions } from 'app/pages/App/slice';
import ExitConfirm from 'app/components/ExitConfirm';
import { LoadingContainer } from 'app/components/LoadingContainer';
import useWindowSize, { DESKTOP_WIDTH } from 'app/hooks/useWindowSize';
import { RESOLUTION_DEVIATION } from 'utils/constants';

const DEFAULT_ACTION_CONTAINER_DISPLAY = 'flex';

const PersonalInfo = () => {
  const [loading, setLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();
  const translations = useSelector(selectTranslations);
  const infoUser = useSelector(selectInfoUser);
  const dispatch = useDispatch();
  const { device } = useWindowSize();

  const retypeBtnRef = useRef<HTMLButtonElement>(null);
  const saveBtnRef = useRef<HTMLButtonElement>(null);
  const actionContainerRef = useRef<HTMLDivElement>(null);
  const [actionContainerDisplay, setActionContainerDisplay] = useState<
    'flex' | 'block'
  >(DEFAULT_ACTION_CONTAINER_DISPLAY);
  const defaultBtnWidth = useMemo(
    () => (device === 'desktop' ? '160px' : '50%'),
    [device],
  );
  const [btnWidth, setBtnWidth] = useState(defaultBtnWidth);
  const cachedWidth = useRef(0);

  const LIST_GENDER = useMemo(
    () => [
      { id: 'male', label: translations?.['b2c.gender.male'] ?? '' },
      { id: 'female', label: translations?.['b2c.gender.female'] ?? '' },
    ],
    [translations],
  );

  const [form] = Form.useForm();

  const initInfoUser = useMemo(
    () => ({
      name: infoUser?.name,
      phone: infoUser?.phone,
      email: infoUser?.email,
      cityCode: infoUser?.cityCode,
      address: infoUser?.address,
      gender: infoUser?.gender || null,
      dob: infoUser?.birthdate && moment(infoUser?.birthdate),
    }),
    [infoUser],
  );

  const onTriggerSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmitForm = async (values: objectType) => {
    setLoading(true);
    try {
      const payload = { ...values };
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (key === 'dob') {
          if (value) formData.append(key, moment(value).format('yyyy-MM-DD'));
        } else if (key === 'gender') {
          if (value) formData.append(key, value);
        } else formData.append(key, value);
      });
      const { data } = await updateMeApi(formData);

      dispatch(appActions.setInfoUser(data));
      setIsFormDirty(false);

      onOpenDialogNoti({
        type: 'SUCCESS',
        isShow: true,
        title: translations?.['b2c.message.updatePersonInfoSuccess'] ?? '',
        content: '',
        closable: true,
        onCloseDialog: onCloseDialogNoti,
        maskClosable: false,
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const resetUserInfo = () => {
    if (!loading) {
      form.setFieldsValue({
        ...initInfoUser,
        phone: '',
        address: '',
        gender: 'male',
        dob: '',
      });
      setIsFormDirty(true);
    }
  };

  const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
    () => [
      {
        label: translations ? translations['b2c.form.name'] : '',
        name: 'name',
        rules: [{ required: true }],
        validateTrigger: ['onBlur'],
        children: (
          <InputText
            size="large"
            onPressEnter={onTriggerSubmit}
            placeholder={translations?.['b2c.form.namePlaceholder'] ?? ''}
            disabled
            style={{
              fontStyle: 'italic',
              color: '#D9D9D9',
              lineHeight: '45px',
            }}
          />
        ),
      },
      {
        label: translations ? translations['b2c.form.phone'] : '',
        name: 'phone',
        rules: [
          { min: 10 },
          { max: 20 },
          {
            pattern: /^[0-9]+$/g,
            message: translations?.['b2c.form.wrongPhoneFormat'] ?? '',
          },
        ],
        validateTrigger: ['onBlur'],
        children: (
          <InputText
            size="large"
            onPressEnter={onTriggerSubmit}
            placeholder={translations?.['b2c.form.phone'] ?? ''}
            onlyNumber
            maxLength={20}
            style={{
              fontStyle: 'italic',
              color: '#808080',
              lineHeight: '45px',
            }}
            placeholderStyle={{ color: '#808080', opacity: 1 }}
          />
        ),
      },
      {
        label: translations?.['b2c.form.email'] ?? '',
        name: 'email',
        rules: [{ required: true }, { type: 'email' }],
        validateTrigger: ['onBlur'],
        children: (
          <InputText
            size="large"
            onPressEnter={onTriggerSubmit}
            placeholder={translations?.['b2c.form.email']}
            disabled
            style={{
              fontStyle: 'italic',
              color: '#D9D9D9',
              lineHeight: '45px',
            }}
          />
        ),
      },
      {
        label: translations ? translations['b2c.form.gender'] : '',
        name: 'gender',
        validateTrigger: ['onBlur'],
        children: (
          <StyledSelect
            suffixIcon={<ArrowDown />}
            placeholder={translations?.['b2c.form.genderPlaceholder'] ?? ''}
            style={{ fontStyle: 'italic', lineHeight: '45px' }}
          >
            {LIST_GENDER.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </StyledSelect>
        ),
      },
      {
        label: translations ? translations['b2c.form.birth'] : '',
        name: 'dob',
        validateTrigger: ['onBlur'],
        children: (
          <StyledDatePicker
            placeholder="dd/mm/yyyy"
            format={['DD/MM/YYYY']}
            size="large"
            suffixIcon={<CalendarIcon />}
            disabledDate={current => current && current.valueOf() > Date.now()}
            style={{ fontSize: 14, fontStyle: 'italic', lineHeight: '45px' }}
            allowClear={false}
          />
        ),
      },
      {
        label: translations ? translations['b2c.form.address'] : '',
        name: 'address',
        rules: [{ max: 200 }],
        validateTrigger: ['onBlur'],
        children: (
          <InputText
            size="large"
            onPressEnter={onTriggerSubmit}
            placeholder={translations?.['b2c.form.address'] ?? ''}
            trim
            maxLength={200}
            style={{
              fontStyle: 'italic',
              color: '#808080',
              lineHeight: '45px',
            }}
            placeholderStyle={{ color: '#808080', opacity: 1 }}
          />
        ),
      },
    ],
    [LIST_GENDER, onTriggerSubmit, translations],
  );

  useEffect(() => {
    const fillFormData = () => {
      form.setFieldsValue(initInfoUser);
    };

    fillFormData();
  }, [initInfoUser, form]);

  useLayoutEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const onSetBtnWidth = () => {
      if (
        !translations ||
        !retypeBtnRef.current ||
        !saveBtnRef.current ||
        !actionContainerRef.current ||
        (cachedWidth.current >= window.innerWidth - RESOLUTION_DEVIATION &&
          cachedWidth.current <= window.innerWidth + RESOLUTION_DEVIATION)
      )
        return;

      setBtnWidth(defaultBtnWidth);
      setActionContainerDisplay(DEFAULT_ACTION_CONTAINER_DISPLAY);
      cachedWidth.current = window.innerWidth;

      if (timer) clearInterval(timer);

      timer = setTimeout(() => {
        const { width: retypeBtnWidth } =
          retypeBtnRef.current!.getBoundingClientRect();
        const { width: retypeContentWidth } = retypeBtnRef
          .current!.querySelector('span')!
          .getBoundingClientRect();

        const { width: saveBtnWidth } =
          saveBtnRef.current!.getBoundingClientRect();
        const { width: saveContentWidth } = saveBtnRef
          .current!.querySelector('span')!
          .getBoundingClientRect();

        const { width: actionContainerWidth } =
          actionContainerRef.current!.getBoundingClientRect();
        const btnWidth = Math.max(retypeBtnWidth, saveContentWidth) + 30;

        if (device === 'desktop') {
          if (
            retypeBtnWidth - 20 < retypeContentWidth ||
            saveBtnWidth - 20 < saveContentWidth
          ) {
            if (actionContainerWidth < btnWidth * 2 + 20) {
              setBtnWidth('100%');
              setActionContainerDisplay('block');
            } else {
              setBtnWidth(btnWidth + 'px');
              setActionContainerDisplay('flex');
            }
          }
        } else {
          if (
            retypeBtnWidth - 20 < retypeContentWidth ||
            saveBtnWidth - 20 < saveContentWidth
          ) {
            setBtnWidth('100%');
            setActionContainerDisplay('block');
          }
        }
      }, 100);
    };

    onSetBtnWidth();

    window.addEventListener('resize', onSetBtnWidth);

    return () => {
      window.removeEventListener('resize', onSetBtnWidth);
      if (timer) clearInterval(timer);
    };
  }, [defaultBtnWidth, device, translations]);

  return (
    <Layout
      title={translations?.['b2c.menu.account'] ?? ''}
      menuActive={ACCOUNT_MNG_MENU.PERSONAL_INFO}
    >
      <ExitConfirm
        message={translations?.['b2c.txt.confirmExists']}
        enable={isFormDirty}
      />
      <LoadingContainer loading={loading} minHeight="auto">
        <StyledFormContainerWrapper>
          <Form
            form={form}
            validateMessages={{
              required:
                '${label} ' +
                (translations ? translations['b2c.form.validate-require'] : ''),
              string: {
                len: '${label} phải có ${len} kí tự',
                min:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-min-chars-1']
                    : '') +
                  ' ${min} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                max:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-max-chars-1']
                    : '') +
                  ' ${max} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                range: '${label} must be between ${min} and ${max} kí tự',
              },
              types: {
                email:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                number:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                string:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                date:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                method:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                array:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                object:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                boolean:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                integer:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                float:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                regexp:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                url:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                hex:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
              },
              number: {
                len: '${label} must equal ${len}',
                min: '${label} cannot be less than ${min}',
                max: '${label} cannot be greater than ${max}',
                range: '${label} must be between ${min} and ${max}',
              },
              array: {
                len: '${label} must be exactly ${len} in length',
                min: '${label} cannot be less than ${min} in length',
                max: '${label} cannot be greater than ${max} in length',
                range: '${label} must be between ${min} and ${max} in length',
              },
              pattern: {
                mismatch: '${label} does not match pattern ${pattern}',
              },
              enum: '${label} must be one of [${enum}]',
              whitespace: '${label} cannot be empty',
              date: {
                format: '${label} is invalid for format date',
                parse: '${label} could not be parsed as date',
                invalid: '${label} is invalid date',
              },
            }}
            onFinish={onSubmitForm}
            onValuesChange={() => setIsFormDirty(true)}
          >
            <Row gutter={device === 'desktop' ? 44 : 49}>
              {LIST_FORM_FIELDS.map((field, index) => {
                const { children, ...fieldProps } = field;
                return (
                  children && (
                    <Col span={device === 'mobile' ? 24 : 12} key={index}>
                      <Form.Item
                        key={field.name || new Date().toString()}
                        {...fieldProps}
                      >
                        {children}
                      </Form.Item>
                    </Col>
                  )
                );
              })}
            </Row>
            <Row>
              <ActionButton
                style={{ display: actionContainerDisplay }}
                ref={actionContainerRef}
              >
                <StyledButton
                  textColor="#808080"
                  border="solid 1px #808080"
                  bgColor="#fff"
                  onClick={resetUserInfo}
                  style={{ fontSize: 16, width: btnWidth }}
                  ref={retypeBtnRef}
                >
                  {translations?.['b2c.btn.reType']}
                </StyledButton>
                <StyledButton
                  textColor="#fff"
                  onClick={onTriggerSubmit}
                  disabled={loading}
                  style={{
                    fontSize: 16,
                    width: btnWidth,
                    marginLeft:
                      actionContainerDisplay !==
                      DEFAULT_ACTION_CONTAINER_DISPLAY
                        ? 0
                        : device === 'tablet'
                        ? 38
                        : 20,
                    marginTop:
                      actionContainerDisplay !==
                      DEFAULT_ACTION_CONTAINER_DISPLAY
                        ? 10
                        : 0,
                  }}
                  ref={saveBtnRef}
                >
                  {translations?.['b2c.btn.saveInfo']}
                </StyledButton>
              </ActionButton>
            </Row>
          </Form>
        </StyledFormContainerWrapper>
      </LoadingContainer>
    </Layout>
  );
};

export default PersonalInfo;

const StyledFormContainerWrapper = styled(FormContainerWrapper)`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    overflow: initial;
  }

  .ant-input,
  .ant-picker,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0 !important;
    border-bottom: solid 1px #d9d9d9;
  }
  .ant-input {
    &[disabled] {
      background: #fff;
    }
  }
  .ant-col {
    label {
      display: none;
    }
  }
`;

const ActionButton = styled.div`
  margin-top: 24px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    margin-top: 8px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input {
    & > input {
      font-style: italic;
      font-size: 14px;
      color: #808080;
      ::placeholder {
        color: #808080;
        opacity: 1;
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    color: #808080;
  }
`;
