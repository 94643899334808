import { DatePicker } from 'antd';
import styled from 'styled-components';

const StyledDatePicker = styled(DatePicker)`
  border-radius: 6px !important;
  height: 44px;
  .ant-picker-input > input {
    ::placeholder {
      font-style: italic;
    }
  }

  .ant-picker-suffix {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-picker-input:hover .ant-picker-clear {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default StyledDatePicker;
