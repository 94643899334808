import {
  I_Order,
  I_Paginator,
  I_PayloadGetListOrder,
} from 'app/pages/Account/ListOrder/slice/types';
import { BaseXHR } from 'utils/axios';

export const loginApi = (data: FormData) =>
  BaseXHR.$post('customer/login', data);

export const registerApi = (data: FormData) =>
  BaseXHR.$post('customer/register', data);

export const verifyOTPApi = (data: FormData) =>
  BaseXHR.$post('customer/confirm', data);

export const logoutApi = () => BaseXHR.$post('b2c/v1/account/logout');

export const getMeApi = () => BaseXHR.$get('b2c/v1/account/get');

export const updateMeApi = (payload: FormData) =>
  BaseXHR.$post('b2c/v1/account/update', payload);

export const getListOrderApi = (payload: I_PayloadGetListOrder) =>
  BaseXHR.$get<{
    data: I_Order[];
    orderCount: {
      cancelled: number;
      paymentPending: number;
      success: number;
    };
    paginator: I_Paginator;
  }>('v1/order/listing', payload);

export const forgotPasswordApi = (data: FormData) =>
  BaseXHR.$post('customer/send-otp-password', data);

export const verifyOTPChangePassApi = (data: FormData) =>
  BaseXHR.$post('customer/confirm-change-password', data);

export const updatePassApi = (data: FormData) =>
  BaseXHR.$post('customer/update-password', data);
export const reSendRegisterOTP = (data: FormData) =>
  BaseXHR.$post('customer/resend-otp', data);

export const createUserPaymentApi = (data: FormData) =>
  BaseXHR.$post('customer/create-user-payment', data);

export const sendOtpChangePassApi = (payload: FormData) =>
  BaseXHR.$post('b2c/v1/account/otp-change-password', payload);

export const changePassApi = (payload: FormData) =>
  BaseXHR.$post('b2c/v1/account/change-password', payload);
