import { TABLET_WIDTH } from 'app/hooks/useWindowSize';
import styled from 'styled-components';

const ListTicketContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 30px;
  @media (max-width: ${props => TABLET_WIDTH}px) {
    border: none;
    padding: 0px;
  }
`;

export default ListTicketContainer;
