import AuthenticationModalWrapper from 'app/pages/App/components/AuthenticationModalWrapper';
import { REGISTER_STEPS } from 'app/pages/App/components/DialogRegister';
import OTPRegister, {
  replaceDescription,
} from 'app/pages/App/components/OTPRegister';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

const OTPVerifyAccountModal = ({
  open,
  onClose,
  getMailRegister,
  onDoneOtp,
}: {
  open: boolean;
  onClose?: () => void;
  getMailRegister: () => string;
  onDoneOtp?: () => void;
}) => {
  const translations = useSelector(selectTranslations);
  return (
    <AuthenticationModalWrapper
      isShow={open}
      title={translations ? translations['b2c.auth.verifyOtpText'] : ''}
      onCloseDialog={onClose}
      description={
        translations
          ? replaceDescription(
              translations['b2c.txt.otpPayment'] ?? '',
              '${email}',
              getMailRegister(),
            )
          : ''
      }
    >
      <OTPRegister
        type="PAYMENT"
        setRegisterStep={(step, token) => {
          if (step === REGISTER_STEPS.FILL_OTP) {
            onDoneOtp && onDoneOtp();
          }
        }}
        mailRegister={getMailRegister()}
      />
    </AuthenticationModalWrapper>
  );
};

export default OTPVerifyAccountModal;
