import { useListingOrderSlice } from 'app/pages/Account/ListOrder/slice';
import {
  I_DetailTicket,
  I_Order,
  I_Paginator,
  I_PayloadGetListOrder,
  I_Ticket,
} from 'app/pages/Account/ListOrder/slice/types';
import {
  MATERRIAL_COMBO_TYPE,
  MATERRIAL_EVOUCHER_TYPE,
  MATERRIAL_PARK_TYPE,
} from 'app/pages/Checkout/hooks/useCheckout';
import {
  EvoucherViewType,
  TicketInfo,
  TicketType,
} from 'app/pages/NewAccount/layouts/ListTicketLayout';
import { getListOrderApi } from 'app/services/authApi';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export type SearchValueType = {
  searchText: string;
  parkId: string;
  date: string;
};

const calculateTotalTicket = (list: I_Ticket[]): number => {
  return list.reduce((total, item) => total + (item.amount || 0), 0);
};

const useListTicket = ({
  orderState,
  isActiveTab,
  setLoading,
}: {
  orderState: 'success' | 'paymentPending' | 'cancelled';
  isActiveTab: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { actions: listingOrderActions } = useListingOrderSlice();
  const [paginator, setPaginator] = useState<I_Paginator>();
  const [listOrder, setListOrder] = useState<I_Order[]>();
  const isNeededCallApi = useRef(true);
  const onFinallyGetListOrder = useRef<null | (() => void)>(null);
  const [optionsGetListOrder, setOptionsGetListOrder] =
    useState<I_PayloadGetListOrder>({
      page: 1,
      limit: 10,
      search: undefined,
      orderState,
    });

  const search = useCallback(
    (payload: Partial<I_PayloadGetListOrder>, onFinally?: () => void) => {
      isNeededCallApi.current = true;
      setOptionsGetListOrder({
        ...optionsGetListOrder,
        ...(payload as I_PayloadGetListOrder),
      });
      onFinallyGetListOrder.current = onFinally ?? null;
    },
    [optionsGetListOrder],
  );

  const getListOrder = useCallback(
    async (request: I_PayloadGetListOrder) => {
      try {
        setLoading(true);
        const { data } = await getListOrderApi(request);
        setListOrder(data.data);
        dispatch(
          listingOrderActions.onSetOrderCount(data.orderCount || undefined),
        );
        setPaginator(data.paginator);
      } catch (error) {
      } finally {
        setLoading(false);
        onFinallyGetListOrder.current?.();
        onFinallyGetListOrder.current = null;
      }
    },
    [dispatch, listingOrderActions, setLoading],
  );

  useEffect(() => {
    if (isActiveTab && isNeededCallApi.current) {
      getListOrder({ ...optionsGetListOrder });
      isNeededCallApi.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsGetListOrder, isActiveTab]);

  // useEffect(() => {
  //   const onFocus = () => {
  //     console.log('focus');

  //     if (isNeededCallApi.current) {
  //       getListOrder({ ...optionsGetListOrder });
  //       isNeededCallApi.current = false;
  //     }
  //   };

  //   window.addEventListener('focus', onFocus);

  //   return () => {
  //     window.removeEventListener('focus', onFocus);
  //   };
  // }, [optionsGetListOrder]);

  const newListTicket: TicketType[] =
    useMemo(() => {
      return listOrder?.map(order => {
        const ticketInfo =
          order.items.length > 0
            ? {
                ...order.items[0],
                eventName:
                  (order.items[0]?.park?.name ?? '').toUpperCase() +
                  ' | ' +
                  (order.items[0]?.catalog?.name ?? ''),
                parkName: order.items[0]?.park?.name ?? '',
                catalogName: order.items[0]?.catalog?.name ?? '',
                serial: order.items[0].tickets?.[0]?.sapVerifyCode
                  ? order.items[0].tickets?.[0]?.sapVerifyCode
                  : order.items[0].tickets?.[0]?.vgsTicketCode ?? '',
                ticketName: order.items[0].show?.name ?? '',
                useDate: order.items[0].show?.dateTo?.datetimeDMY ?? '',
                useHour:
                  (order.items[0].materialType !== MATERRIAL_PARK_TYPE
                    ? order.items[0].performance?.dateFrom?.time
                    : '') ?? '',
                position: order.items[0]?.zone?.name ?? '',
                restaurant: order.items[0]?.product?.restaurant?.name ?? '',
                price: order.items[0].tickets?.[0]?.unitPrice
                  ? +order.items[0].tickets?.[0]?.unitPrice
                  : order.items[0].originalPrice ?? 0,
                imgPath: order.items[0].show?.image?.original ?? '',
                isComboTicket: order.items[0].catalog?.mapVGS === 'S4',
                isComboDiffTicket:
                  order.items[0].catalog?.mapVGS === 'S4' &&
                  order.items[0].park?.workstationId === 'DIF',
                useDateFrom: order.items[0].date?.datetimeDMY ?? '',
                numberAddDays: order.items[0].product?.version || 0,
                vgsUsageDate: order.items[0].tickets?.[0]?.vgsUsageDate,
                vgsUsageDateTo: order.items[0].tickets?.[0]?.vgsUsageDateTo,
              }
            : undefined;
        const tickets = order.items.map(val => ({
          ...val,
          eventName:
            (val?.park?.name ?? '').toUpperCase() +
            ' | ' +
            (val?.catalog?.name ?? ''),
          parkName: order.items[0]?.park?.name ?? '',
          catalogName: order.items[0]?.catalog?.name ?? '',
          serial: val.tickets?.[0]?.sapVerifyCode
            ? val.tickets?.[0]?.sapVerifyCode
            : val.tickets?.[0]?.vgsTicketCode ?? '',
          ticketName: val.show?.name ?? '',
          useDate: val.show?.dateTo?.datetimeDMY ?? '',
          useHour:
            (val.materialType !== MATERRIAL_PARK_TYPE
              ? val.performance?.dateFrom?.time
              : '') ?? '',
          position: val?.zone?.name ?? '',
          restaurant: val?.product?.restaurant?.name ?? '',
          price: val.tickets?.[0]?.unitPrice
            ? +val.tickets?.[0]?.unitPrice
            : val.originalPrice ?? 0,
          imgPath: val.show?.image?.original ?? '',
          orgTickets: val.tickets ?? [],
          amount: val.amount ?? 0,
          dataBuyer: val.dataBuyer,
          isComboTicket: val.catalog?.mapVGS === 'S4',
          isComboDiffTicket:
            val.catalog?.mapVGS === 'S4' && val.park?.workstationId === 'DIF',
          useDateFrom: val.date?.datetimeDMY ?? '',
          numberAddDays: val.product?.version || 0,
        }));
        const vouchers: EvoucherViewType[] = order.giftItems
          .filter(val => val.materialType === MATERRIAL_EVOUCHER_TYPE)
          .flatMap(voucher =>
            (voucher.tickets ?? []).map(val => ({
              productName: voucher.productName ?? '',
              code: val.serialNumber ?? '',
            })),
          );
        const giftsInfo: (TicketInfo & {
          orgTickets: I_DetailTicket[];
          amount: number;
        })[] = order.giftItems
          .filter(
            val =>
              val.materialType !== MATERRIAL_EVOUCHER_TYPE &&
              val.materialType !== MATERRIAL_COMBO_TYPE,
          )
          .map(val => ({
            ...val,
            eventName:
              (val?.park?.name ?? '').toUpperCase() +
              ' | ' +
              (val?.catalog?.name ?? ''),
            parkName: order.items[0]?.park?.name ?? '',
            catalogName: order.items[0]?.catalog?.name ?? '',
            serial: val.tickets?.[0]?.sapVerifyCode
              ? val.tickets?.[0]?.sapVerifyCode
              : val.tickets?.[0]?.vgsTicketCode ?? '',
            ticketName: val.show?.name ?? '',
            useDate: val.show?.dateTo?.datetimeDMY ?? '',
            useHour:
              (val.materialType !== MATERRIAL_PARK_TYPE
                ? val.performance?.dateFrom?.time
                : '') ?? '',
            position: val.zone?.name ?? '',
            restaurant: val?.product?.restaurant?.name ?? '',
            price: val.tickets?.[0]?.unitPrice
              ? +val.tickets?.[0]?.unitPrice
              : val.originalPrice ?? 0,
            imgPath: val.show?.image?.original ?? '',
            orgTickets: val.tickets ?? [],
            amount: val.amount ?? 0,
            dataBuyer: val.dataBuyer,
            isComboTicket: val.catalog?.mapVGS === 'S4',
            isComboDiffTicket:
              val.catalog?.mapVGS === 'S4' && val.park?.workstationId === 'DIF',
            useDateFrom: val.date?.datetimeDMY ?? '',
            numberAddDays: val.product?.version || 0,
          }));
        return {
          ...order,
          _id: order.idHash,
          orderInfo: {
            orderId: `${order.id}`,
            amountOfTicket: order.totalTicket ?? 0,
            orderDate: order.orderdate.datetimeDMY,
            orgOrderDate: order.orderdate,
            totalDiscountValue: order.totalDiscount ?? 0,
            totalPriceValue: order.totalPrice,
            paymentUrl: order.paymentUrl,
          },
          paymentInfo: {
            customerName: order.name ?? '',
            phoneNumber: order.phone ?? '',
            email: order.email ?? '',
          },
          ticketInfo: ticketInfo,
          ticketsInfo: tickets,
          giftsInfo,
          vouchers,
        };
      });
    }, [listOrder]) ?? [];
  return {
    listTicket: newListTicket,
    paginator,
    search,
  };
};

export default useListTicket;
