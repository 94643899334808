/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Switch,
  Redirect,
  BrowserRouter,
  Route,
  useLocation,
} from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import { OverlayThumb } from 'app/components/OverlayThumb/Loadable';
import CheckNewVersion from './components/CheckNewVersion';
import { LoadingOutlined } from '@ant-design/icons';
import { AppWrapper } from './styled';
import { GET_LANGS, GET_LAYOUT, GET_ME, GET_TRANS, useAppSlice } from './slice';
import NotificationsSystem, {
  atalhoTheme,
  setUpNotifications,
  dismissNotification,
} from 'reapop';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsLogged,
  selectNotifications,
  selectIsLoggedNotEqualUndefined,
  selectLayoutCodeConfig,
} from './slice/selectors';
import { useGlobalFunction } from './hooks/useGlobalFunction';

import { auth } from 'utils/helper';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { NormalRoute, ProtectedRoute } from 'app/components/RouteGuard';
import { Home } from '../Home/Loadable';
import { Layout } from 'app/components/Layout/Loadable';
import { PaymentCallback } from '../PaymentCallback/Loadable';
import { PrintTickets } from 'app/pages/NewPaymentCallback/PrintTickets/Loadable';
import {
  DialogChangePass,
  DialogLogin,
  DialogRegister,
} from './components/Loadable';
import { CatalogDetailShow } from '../Catalog/CatalogDetailShow/Loadable';
import { Catalog } from '../Catalog/Loadable';
import { Account } from '../Account/Loadable';
import { Cart } from '../Cart';
import { getCookie } from 'utils/cookies';
import { DEFAULT_LANGUAGE, TYPE_COOKIE } from 'utils/constants';
import { Campaign } from '../Campaign/Loadable';
import DialogNotification from 'app/components/Modals/DialogNotification';
import PurcharseHistory from '../NewAccount/PurchaseHistory';
import PersonalInfo from '../NewAccount/PersonalInfo';
import DialogForgotPass from 'app/pages/App/components/DialogForgotPass';
import NotFound from 'app/pages/NotFound';
import PolicyAndTermScreen from 'app/pages/PolicyAndTermScreen';
import { Checkout } from 'app/pages/Checkout';
import { NewPaymentCallback } from 'app/pages/NewPaymentCallback';
import vi_VN from 'antd/lib/locale/vi_VN';
import en_US from 'antd/lib/locale/en_US';
import ru_RU from 'antd/lib/locale/ru_RU';
import zh_CN from 'antd/lib/locale/zh_CN';
import th_TH from 'antd/lib/locale/th_TH';
import ko_KR from 'antd/lib/locale/ko_KR';
import { ConfigProvider } from 'antd';
import { LoadingContainerGlobal } from 'app/components/LoadingGlobalScreen';
import DangerouslySetHtmlContent from 'app/components/DangerouslySetHtmlContent';
import ChangePass from 'app/pages/NewAccount/ChangePass';
import useMomentLocaleLoader from './hooks/useMomentLocaleLoader';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import vi from 'date-fns/locale/vi';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import zhCN from 'date-fns/locale/zh-CN';
import ko from 'date-fns/locale/ko';
import th from 'date-fns/locale/th';
import CollectInfoPolicyModal from 'app/pages/App/components/CollectInfoPolicyModal';
import { NcbPayment } from 'app/pages/Checkout/NcbPayment';

registerLocale('vi', vi);
registerLocale('en', en);
registerLocale('ru', ru);
registerLocale('zh', zhCN);
registerLocale('ko', ko);
registerLocale('th', th);
setDefaultLocale(DEFAULT_LANGUAGE);

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    allowHTML: true,
    dismissible: true,
    dismissAfter: 2000,
    transition: 'slide',
    position: 'bottom-right',
    showDismissButton: true,
  },
});

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { actions } = useAppSlice();

  const isLogged = useSelector(selectIsLogged);
  const isLoggedNotEqualUndefined = useSelector(
    selectIsLoggedNotEqualUndefined,
  );
  const notifications = useSelector(selectNotifications);
  const codeConfig = useSelector(selectLayoutCodeConfig);

  const {
    locale,
    isShowDialogLogin,
    onOpenDialogLogin,
    onOpenDialogRegister,
    onCloseDialogAuth,
    onLoginSuccess,
    isShowDialogRegister,
    isShowDialogForgotPass,
    onOpenDialogForgotPass,
    isShowDialogChangePass,
    onOpenDialogChangePass,
    dialogNotiState,
  } = useGlobalFunction();

  const antdLocale = useMemo(() => {
    switch (locale) {
      case 'en':
        return en_US;
      case 'ru':
        return ru_RU;
      case 'zh':
        return zh_CN;
      case 'ko':
        return ko_KR;
      case 'th':
        return th_TH;
      case 'vi':
      default:
        return vi_VN;
    }
  }, [locale]);

  useMomentLocaleLoader(locale);

  useEffect(() => {
    const language = getCookie(TYPE_COOKIE.LANGUAGE) || 'vi';
    dispatch(actions.onChangeLanguage(language));

    dispatch(GET_LANGS());
    dispatch(GET_TRANS());
    dispatch(GET_LAYOUT());

    dispatch(actions.changeIsLogged({ isLogged: auth() }));
    return () => {
      dispatch(actions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch]);

  useEffect(() => {
    if (isLoggedNotEqualUndefined) {
      isLogged && dispatch(GET_ME());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedNotEqualUndefined, isLogged]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
    }
  }, []);

  return (
    <>
      <ConfigProvider locale={antdLocale}>
        {codeConfig?.html && (
          <DangerouslySetHtmlContent
            className="analytics-html"
            html={codeConfig?.html}
          />
        )}
        {codeConfig?.js && <DangerouslySetHtmlContent html={codeConfig?.js} />}
        <Helmet
          titleTemplate="%s - Sun Tickets"
          defaultTitle="Sun Tickets"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="Sun Tickets" />
        </Helmet>
        <CheckNewVersion>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading)
              return (
                <OverlayThumb icon={<LoadingOutlined />} label="Loading..." />
              );
            if (!loading && !isLatestVersion) refreshCacheAndReload();
            return (
              <BrowserRouter>
                <AppWrapper>
                  <LoadingContainerGlobal />
                  <Layout>
                    <Switch>
                      <NormalRoute path="/" exact component={Home} />
                      <NormalRoute path="/cart" exact component={Cart} />
                      <NormalRoute path="/checkout" component={Checkout} />
                      <NormalRoute path="/catalog" exact component={Catalog} />
                      <NormalRoute
                        path="/catalog/:id"
                        exact
                        component={CatalogDetailShow}
                      />
                      <NormalRoute
                        path="/payment-callback"
                        component={NewPaymentCallback}
                      />
                      <NormalRoute
                        path="/print-tickets/:id"
                        component={PrintTickets}
                      />
                      <NormalRoute path="/campaign" component={Campaign} />
                      <ProtectedRoute
                        path="/account"
                        component={PurcharseHistory}
                      />
                      <NormalRoute
                        path="/policy-term"
                        component={PolicyAndTermScreen}
                      />
                      <ProtectedRoute
                        path="/profile"
                        component={PersonalInfo}
                      />
                      <ProtectedRoute
                        path="/change-pass"
                        component={ChangePass}
                      />
                      <Route path="/ncb-payment" component={NcbPayment} />
                      <Route component={NotFound} />
                      <Redirect to="/" />
                    </Switch>
                  </Layout>

                  <NotificationsSystem
                    theme={atalhoTheme}
                    notifications={notifications}
                    dismissNotification={id =>
                      dispatch(dismissNotification(id))
                    }
                  />
                </AppWrapper>

                {isShowDialogLogin && (
                  <DialogLogin
                    isShow={isShowDialogLogin}
                    onLoginSuccess={onLoginSuccess}
                    onCloseDialog={onCloseDialogAuth}
                    onOpenDialogRegister={onOpenDialogRegister}
                    openDialogForgotPass={onOpenDialogForgotPass}
                  />
                )}
                {isShowDialogRegister && (
                  <DialogRegister
                    isShow={isShowDialogRegister}
                    onRegisterSuccess={onLoginSuccess}
                    onCloseDialog={onCloseDialogAuth}
                    onOpenDialogLogin={onOpenDialogLogin}
                  />
                )}
                {isShowDialogForgotPass && (
                  <DialogForgotPass
                    isShow={isShowDialogForgotPass}
                    onCloseDialog={onCloseDialogAuth}
                  />
                )}
                {isShowDialogChangePass && (
                  <DialogChangePass
                    isShow={isShowDialogChangePass}
                    onCloseDialog={onCloseDialogAuth}
                    onSendChangePassDone={() => {}}
                  />
                )}
                {dialogNotiState.isShow && (
                  <DialogNotification height={'auto'} {...dialogNotiState} />
                )}
                <CollectInfoPolicyModal />
              </BrowserRouter>
            );
          }}
        </CheckNewVersion>
        <GlobalStyle />
      </ConfigProvider>
    </>
  );
}
