import {
  I_DetailTicket,
  I_Ticket,
} from 'app/pages/Account/ListOrder/slice/types';
import {
  MATERRIAL_COMBO_TYPE,
  MATERRIAL_EVOUCHER_TYPE,
  MATERRIAL_PARK_TYPE,
} from 'app/pages/Checkout/hooks/useCheckout';
import {
  EvoucherViewType,
  TicketInfo,
  TicketType,
} from 'app/pages/NewAccount/layouts/ListTicketLayout';
import { getDetailOrderApi } from 'app/services/checkoutApi';
import { set } from 'date-fns';
import { useCallback, useState } from 'react';

const calculateTotalTicket = (list: I_Ticket[]): number => {
  return list.reduce((total, item) => total + (item.amount || 0), 0);
};

const useListTicketLayout = () => {
  const [showDetail, setIsShowDetail] = useState<{
    isShow: boolean;
    ticket?: TicketType;
  }>({
    isShow: false,
    ticket: undefined,
  });
  const [isGetingDetail, setIsGetingDetail] = useState(false);

  const onClickShowDetail = useCallback(async (orderId: string) => {
    setIsGetingDetail(true);
    try {
      const { data: order } = await getDetailOrderApi(orderId, true);
      const ticketInfo =
        order.items.length > 0
          ? {
              eventName:
                (order.items[0]?.park?.name ?? '').toUpperCase() +
                ' | ' +
                (order.items[0]?.catalog?.name ?? ''),
              parkName: order.items[0]?.park?.name ?? '',
              catalogName: order.items[0]?.catalog?.name ?? '',
              serial: order.items[0].tickets?.[0]?.sapVerifyCode
                ? order.items[0].tickets?.[0]?.sapVerifyCode
                : order.items[0].tickets?.[0]?.vgsTicketCode ?? '',
              ticketName: order.items[0].show?.name ?? '',
              useDate: order.items[0].show?.dateTo?.datetimeDMY ?? '',
              useHour:
                (order.items[0].materialType !== MATERRIAL_PARK_TYPE
                  ? order.items[0].performance?.dateFrom?.time
                  : '') ?? '',
              position: order.items[0]?.zone?.name ?? '',
              restaurant: order.items[0]?.product?.restaurant?.name ?? '',
              price: order.items[0]?.tickets?.[0]?.unitPrice
                ? +order.items[0]?.tickets?.[0]?.unitPrice
                : order.items[0].originalPrice ?? 0,
              imgPath: order.items[0].show?.image?.original ?? '',
              isComboTicket: order.items[0].catalog?.mapVGS === 'S4',
              isComboDiffTicket:
                order.items[0].catalog?.mapVGS === 'S4' &&
                order.items[0].park?.workstationId === 'DIF',
              useDateFrom: order.items[0].date.datetimeDMY ?? '',
              numberAddDays: order.items[0].product?.version || 0,
            }
          : undefined;
      const tickets = order.items.map(val => ({
        eventName:
          (val?.park?.name ?? '').toUpperCase() +
          ' | ' +
          (val?.catalog?.name ?? ''),
        parkName: order.items[0]?.park?.name ?? '',
        catalogName: order.items[0]?.catalog?.name ?? '',
        serial: val.tickets?.[0]?.sapVerifyCode
          ? val.tickets?.[0]?.sapVerifyCode
          : val.tickets?.[0]?.vgsTicketCode ?? '',
        ticketName: val.show?.name ?? '',
        useDate: val.show?.dateTo?.datetimeDMY ?? '',
        useHour:
          (val.materialType !== MATERRIAL_PARK_TYPE
            ? val.performance?.dateFrom?.time
            : '') ?? '',
        position: val.zone?.name ?? '',
        restaurant: val?.product?.restaurant?.name ?? '',
        price: val.tickets?.[0]?.unitPrice
          ? +val.tickets?.[0]?.unitPrice
          : val.originalPrice ?? 0,
        imgPath: val.show?.image?.original ?? '',
        orgTickets: val.tickets ?? [],
        amount: val.amount ?? 0,
        dataBuyer: val.dataBuyer,
        isComboTicket: val.catalog?.mapVGS === 'S4',
        isComboDiffTicket:
          val.catalog?.mapVGS === 'S4' && val.park?.workstationId === 'DIF',
        useDateFrom: val.date?.datetimeDMY ?? '',
        numberAddDays: val.product?.version || 0,
        usedArea2Object: val?.product?.usedArea2Object,
        usedArea2Label: val?.show?.usedArea2Label,
      }));
      const vouchers: EvoucherViewType[] = order.giftItems
        .filter(val => val.materialType === MATERRIAL_EVOUCHER_TYPE)
        .flatMap(voucher =>
          (voucher.tickets ?? []).map(val => ({
            productName: voucher.productName ?? '',
            code: val.serialNumber ?? '',
          })),
        );
      const giftsInfo: (TicketInfo & {
        orgTickets: I_DetailTicket[];
        amount: number;
      })[] = order.giftItems
        .filter(
          val =>
            val.materialType !== MATERRIAL_EVOUCHER_TYPE &&
            val.materialType !== MATERRIAL_COMBO_TYPE,
        )
        .map(val => ({
          eventName:
            (val?.park?.name ?? '').toUpperCase() +
            ' | ' +
            (val?.catalog?.name ?? ''),
          serial: val.tickets?.[0]?.sapVerifyCode
            ? val.tickets?.[0]?.sapVerifyCode
            : val.tickets?.[0]?.vgsTicketCode ?? '',
          ticketName: val.show?.name ?? '',
          useDate: val.show?.dateTo?.datetimeDMY ?? '',
          useHour:
            (val.materialType !== MATERRIAL_PARK_TYPE
              ? val.performance?.dateFrom?.time
              : '') ?? '',
          position: val.zone?.name ?? '',
          restaurant: val?.product?.restaurant?.name ?? '',
          price: val.tickets?.[0]?.unitPrice
            ? +val.tickets?.[0]?.unitPrice
            : val.originalPrice ?? 0,
          imgPath: val.show?.image?.original ?? '',
          orgTickets: val.tickets ?? [],
          amount: val.amount ?? 0,
          dataBuyer: val.dataBuyer,
          isComboTicket: val.catalog?.mapVGS === 'S4',
          isComboDiffTicket:
            val.catalog?.mapVGS === 'S4' && val.park?.workstationId === 'DIF',
          useDateFrom: val.date?.datetimeDMY ?? '',
          numberAddDays: val.product?.version || 0,
          usedArea2Object: val?.product?.usedArea2Object,
          usedArea2Label: val?.show?.usedArea2Label,
        }));
      const mappedOrder = {
        _id: order.idHash,
        orderInfo: {
          orderId: `${order.id}`,
          amountOfTicket: order.totalTicket ?? 0,
          orderDate: order.orderdate.datetimeDMY,
          totalDiscountValue: order.totalDiscount ?? 0,
          totalPriceValue: order.totalPrice,
        },
        paymentInfo: {
          customerName: order.name ?? '',
          phoneNumber: order.phone ?? '',
          email: order.email ?? '',
        },
        ticketInfo: ticketInfo,
        ticketsInfo: tickets,
        giftsInfo,
        vouchers,
      };
      setIsShowDetail({
        isShow: true,
        ticket: mappedOrder,
      });
    } catch (error) {
    } finally {
      setIsGetingDetail(false);
    }
  }, []);

  return {
    showDetail,
    setIsShowDetail,
    onClickShowDetail,
    isGetingDetail,
  };
};

export default useListTicketLayout;
