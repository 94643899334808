import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'app/pages/App/slice/selectors';

interface ExitConfirmProps {
  enable?: boolean;
  message: string;
}

const ExitConfirm = (props: ExitConfirmProps) => {
  const { enable, message } = props;
  const translations = useSelector(selectTranslations);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
  const [lastLocation, updateLastLocation] = useState('');

  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();

  const handleShowPopupConfirm = (location: any) => {
    if (location.pathname === pathname) return false;

    if (enable && !confirmedNavigation) {
      updateLastLocation(location.pathname);
      onOpenDialogNoti({
        type: 'ERROR',
        isShow: true,
        title: '',
        content: message.replaceAll('\\n', ''),
        closable: false,
        buttons: [
          {
            text: translations ? translations['b2c.btn.cancel'] : '',
            bg: '#ffff',
            border: 'solid 1px #808080',
            color: '#808080',
            onClick: () => {
              onCloseDialogNoti();
            },
          },
          {
            text: translations ? translations['b2c.txt.confirm'] : '',
            bg: 'linear-gradient(79.97deg, #F06F24 -7.79%, #F8B117 107.61%)',
            color: 'white',
            onClick: () => {
              updateConfirmedNavigation(true);
              onCloseDialogNoti();
            },
          },
        ],
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!enable) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message, enable]);

  useEffect(() => {
    if (confirmedNavigation) {
      push(lastLocation);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, push]);

  return <Prompt when={enable} message={handleShowPopupConfirm} />;
};

export default ExitConfirm;
