/**
 *
 * styled
 *
 */
import { TABLET_WIDTH } from 'app/hooks/useWindowSize';
import styled from 'styled-components/macro';

export const CheckoutWrapper = styled.div`
  margin-bottom: 12px;
  .checkOut {
    .--title {
      padding-bottom: 6px;
      margin-bottom: 0.5rem;
      font-size: 14px;
      font-weight: 400;

      .--name {
        position: relative;

        i {
          position: absolute;
          top: 42%;
          transform: translate(0, -50%);
          right: 0;
          color: #9b9b9b;
          font-size: 18px;
          pointer-events: none;
        }
      }

      h1 {
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }

  .itemPaymentMethod {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: #ffffff;
    border: 2px solid #e0e0e0;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    transition: all 0.15s ease;
    margin-right: 8px;
    height: 85px;
    img {
      width: 144px;
      height: auto;
      object-fit: contain;
      max-height: 100%;
    }
    &:hover {
      border-color: var(--color-4);
    }

    &.active {
      color: var(--color-4);
      border-color: var(--color-4);
      font-weight: 600;
    }

    span {
      margin-left: 10px;
    }
  }

  .checkOutInfo {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 32px 30px;
    margin-bottom: 2rem;
    color: black;
    font-size: 14px;
    font-weight: 400;
    .--title {
      font-size: 20px;
      color: #000000;
      font-weight: 600;
    }
    .--btn-login {
      text-decoration: underline;
      color: #000000;
      margin-left: 6px;
      font-weight: 600;
    }
    .--form-info-user {
      margin-top: 20px;
    }
    .--segTitle {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .--infoBuyer {
      border-bottom: 1px dashed #ddd;
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      small {
        padding: 0.5rem 0;
        background: #fff;
        &:after {
          content: '';
          width: 1px;
          height: 160px;
          border: 1px dashed #ddd;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .btnPrimary {
        width: 160px;
      }
      @media (max-width: ${_ => TABLET_WIDTH}px) {
        small {
          &:after {
            display: none;
          }
        }
      }
    }
    .--infoPayment {
      margin-top: 40px;
      .--payment {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
      }
    }
    @media (max-width: ${_ => TABLET_WIDTH}px) {
      margin-bottom: 3rem;

      .form_info {
        flex-flow: column;
      }

      .--info {
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      .form-group {
        width: 100%;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .--payment {
        flex-flow: column;

        .--pay {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .itemCheckOutPolicy {
    width: 100%;
    margin-right: 12px;
    padding: 20px;
    background: var(--color-2);
    border-radius: 8px;
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    .--icon {
      width: 24px;
      margin-right: 12px;
      img {
        width: 100%;
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .checkOutPolicy {
  }

  .ant-spin-container {
    overflow: unset;
  }

  .bookticketsContent {
    .--dealsofday {
      .--title {
        color: var(--color-1);
        font-weight: 900;
        margin-bottom: 1rem;
      }

      .list_Coupons {
        .--img {
          height: 240px;
        }

        .col-md-4 {
          &:nth-child(n + 4) {
            margin-top: 24px;
          }
        }
      }
    }

    .--allservice {
      margin-top: 80px;

      .--top {
        margin-bottom: 20px;

        h4 {
          font-weight: 900;

          i {
            font-size: 14px;
          }
        }

        button {
          background: var(--color-2);
          border: 1px solid #e7e7e7;
          height: 32px;
          padding: 4px 16px 0;
          display: flex;
          align-items: center;
          border-radius: 100px;
          margin-left: 10px;
          transition: all 0.15s ease;

          &:hover,
          &.active {
            background: rgba(249, 178, 23, 0.1);
            border: 1px solid #f9b217;
          }

          &:focus,
          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .social {
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 20px;
    }

    @media (max-width: ${_ => TABLET_WIDTH}px) {
      padding: 3rem 0;
      .styled__OrderOverviewWrapper-sc-hyq2rn-0 {
        position: relative;
        bottom: unset;
        left: unset;
        transform: none;
      }
      .--nameproj {
        flex-flow: column;
        align-items: flex-start !important;

        .--name {
          margin-bottom: 12px;
        }
      }
    }
  }

  .bookticketspayContent {
    .totalorder {
      border: none;
      .--order {
        .--item {
          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }

    .--check {
      input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      label {
        font-size: 14px;
      }

      a {
        color: var(--color-3);
        transition: all 0.15s ease;

        &:hover {
          color: var(--color-4);
        }
      }
    }

    @media (max-width: ${_ => TABLET_WIDTH}px) {
      .--infopayment {
      }

      .--left {
        padding-right: 0 !important;
      }

      .--policy {
        flex-flow: column;

        .--item {
          width: 100%;
          margin-bottom: 1rem;
          margin-right: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .payconStatus {
    background: #598748;

    &.payfail {
      background: var(--color-7);
    }
  }

  .payConfirm {
    padding: 36px 0;

    .--img {
      img {
        max-width: 280px;
      }
    }

    .--txt {
      width: 60%;
      margin: auto;
    }

    &.payfail {
      h4 {
        color: var(--color-7);
      }

      .--des {
        margin-bottom: 0.5rem;
      }

      .--hotline {
        color: var(--color-3);
        text-decoration: none;
        font-weight: 900;
        margin-bottom: 28px;
        display: flex;
        justify-content: center;
        transition: all 0.15s ease;
        font-size: 18px;

        &:hover {
          color: var(--color-1);
        }
      }
    }

    h4 {
      color: #598748;
      font-weight: 900;
    }

    .--des {
      margin-bottom: 28px;
    }

    .bttn {
      .--button {
        height: 40px;
        font-family: var(--font-sunheavy);

        &:nth-child(1) {
          padding: 4px 30px 0;
          background: var(--color-2);
          border: 1px solid #e8e8e8;
          border-radius: 4px;
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }
    @media (max-width: ${_ => TABLET_WIDTH}px) {
      .--txt {
        width: 100%;
      }
    }
  }

  .payInfooder {
    padding: 3rem 0 80px;

    .Title {
      margin-bottom: 20px;

      span {
        padding: 4px 6px 2px;
        margin-left: 10px;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 4px;
        color: #b1b1b1;
        font-weight: 900;
      }
    }

    .infopayment {
      background: var(--color-2);
      border: 1px dashed #bdbdbd;
      border-radius: 8px;
      padding: 16px;

      h5 {
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          display: flex;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        span {
          &:nth-child(1) {
            width: 100px;
            flex: 0 0 auto;
            margin-right: 8px;
          }
          word-break: break-word;
        }

        &.--info {
          border-bottom: 2px solid #e7e7e7;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        &.--payment {
          li {
            &:nth-child(1) {
              span {
                &:nth-child(2) {
                  color: var(--color-1);
                  font-family: var(--font-sunheavy);
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: ${_ => TABLET_WIDTH}px) {
      .infopayment {
        margin-top: 1.5rem;
      }
    }
  }
  .custom-input .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 11px 11px;
    padding-left: 0px;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: none;
    border-bottom: 2px solid #f2f2f2;
    transition: all 0.3s;
    &:focus {
      background-color: #fff;
      background-image: none;
      border: none;
      border-bottom: 2px solid #f2f2f2;
    }
  }

  .custom-input .ant-input.ant-input::placeholder {
    font-style: italic;
    color: #808080;
    font-size: 16px;
    opacity: 1;
  }
  .custom-input .ant-input-focused,
  .ant-input:focus {
    border: none !important;
    border-bottom: 2px solid #f2f2f2 !important;
    box-shadow: none !important;
  }
  .custom-panel-header {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: -24px;
  }

  .custom-panel-title {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-left: 6px;
  }
  .custom-collapse .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    top: 15px;
  }
  .ant-collapse.ant-collapse-icon-position-start.custom-collapse {
    border: none;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content {
    border: none;
  }
  .custom-collapse .ant-collapse-header {
    background-color: #ffffff;
  }
  .number-other {
    margin-right: 18px;
    font-size: 26px;
    color: rgba(242, 228, 220, 0.8);
    margin-bottom: 20px;
    font-weight: 600;
    width: auto;
    min-width: 36px;
    white-space: nowrap;
  }
  .--sub-title {
    color: #000000;
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
  }
`;
