import { Col, Divider, Row, Tabs } from 'antd';
import useWindowSize, { DESKTOP_WIDTH } from 'app/hooks/useWindowSize';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { getPolicyPages } from 'app/services/appApi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  // Define your component's props here
}

const PolicyAndTermScreen: React.FC<Props> = () => {
  const location = useLocation();
  const translations = useSelector(selectTranslations);
  const [paymentPolicyHtml, setPaymentPolicyHtml] = useState('');
  const [deliveryPolicyHtml, setDelieryPolicyHtml] = useState('');
  const [returnRefundPolicyHtml, setReturnRefundPolicyHtml] = useState('');
  const [securityPolicyHtml, setSecurityPolicyHtml] = useState('');
  const [activeKeyTab, setActiveKeyTab] = useState<string>('payment-policy');
  const { device } = useWindowSize();

  useEffect(() => {
    const getPolicies = async () => {
      const { data } = await getPolicyPages();
      setPaymentPolicyHtml(data.policy.payment_content_policy);
      setDelieryPolicyHtml(data.policy.delivery_content_policy);
      setReturnRefundPolicyHtml(data.policy.give_back_content_policy);
      setSecurityPolicyHtml(data.policy.security_content_policy);
    };
    getPolicies();
  }, []);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const tab = search.get('tab');
    setActiveKeyTab(tab ?? 'payment-policy');
  }, []);
  return (
    <Row justify="center">
      <Col
        span={device === 'desktop' ? 18 : 22}
        style={{ padding: '0px 12px' }}
      >
        <StyledTabs
          tabPosition="top"
          // moreIcon={<ArrowDownIcon />}
          moreIcon={false}
          onChange={key => {
            setActiveKeyTab(key);
          }}
          activeKey={activeKeyTab}
        >
          <StyledTabs.TabPane
            tab={translations ? translations['b2c.txt.payment-policy'] : ''}
            key="payment-policy"
          >
            <Container>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: paymentPolicyHtml,
                }}
              ></div>
            </Container>
          </StyledTabs.TabPane>
          <StyledTabs.TabPane
            tab={translations ? translations['b2c.txt.delivery-policy'] : ''}
            key="delivery-policy"
          >
            <Container>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: deliveryPolicyHtml,
                }}
              ></div>
            </Container>
          </StyledTabs.TabPane>
          <StyledTabs.TabPane
            tab={
              translations
                ? translations[
                    'b2c.txt.product-inspection-and-return-refund-policy'
                  ]
                : ''
            }
            key="product-inspection-and-return-refund-policy"
          >
            <Container>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: returnRefundPolicyHtml,
                }}
              ></div>
            </Container>
          </StyledTabs.TabPane>
          <StyledTabs.TabPane
            tab={
              translations
                ? translations['b2c.txt.information-security-policy']
                : ''
            }
            key="information-security-policy"
          >
            <Container>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: securityPolicyHtml,
                }}
              ></div>
            </Container>
          </StyledTabs.TabPane>
        </StyledTabs>
      </Col>
    </Row>
  );
};

export default PolicyAndTermScreen;

const Container = styled.div`
  border-radius: 20px;
  background-color: #fff;
  /* width: 90vw; */
  padding: 24px 50px;
  box-shadow: 0px 20px 20px 0px #0000000d;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 18px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    margin: 24px 0px;
  }
  .content {
    font-size: 16px;
    font-weight: 300;
    line-height: 2rem;
    color: black;
    text-align: justify;
    hyphens: auto;
    strong {
      font-weight: 600;
    }
    width: 100%;
  }

  @media (max-width: ${_ => DESKTOP_WIDTH}px) {
    width: 100%;
    padding: 16px 16px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0px 0px 24px 0px;
  width: 100%;
  min-width: 100%;
  border-color: #d9d9d9;
`;

const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav::before {
    display: none;
  }
  & .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    font-weight: 600;
    font-size: 16px; /* Red text color for the active tab */
  }

  & .ant-tabs-tab {
    padding: 8px 0px 8px 0px;
  }

  & .ant-tabs-tab .ant-tabs-tab-btn {
    color: #808080;
    font-weight: 500;
    font-size: 18px; /* Red text color for the active tab */
  }

  & .ant-tabs-ink-bar {
    background-color: #e92127; /* Red bottom border color for the active tab */
  }
`;
