import { ProductInNew } from 'app/pages/NewAccount/types';
import { BaseXHR } from 'utils/axios';

export const getLayoutApi = () => BaseXHR.$get('b2c/page/layout');

export const getTranslationsApi = () => BaseXHR.$get('b2c/translator/listing');

export const getLanguageListApi = () => BaseXHR.$get('b2c/translator/langs');

export const getTopBestSellingTicketsApi = () =>
  BaseXHR.$get<{ data: ProductInNew[] }>('promotions/topSale');

export const getPolicyPages = () =>
  BaseXHR.$get<{
    policy: {
      delivery_content_policy: string;
      give_back_content_policy: string;
      payment_content_policy: string;
      security_content_policy: string;
      payment_detail_content_policy: string;
    };
  }>('b2c/page/policy');
