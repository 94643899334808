import { I_OptionsGetList } from 'types';

export enum TYPE_LOCAL_STORAGE {
  URL_REDIRECT = 'b2c-url-redirect',
  CART = 'b2c-cart',
  CART_TEMP = 'b2c-cart-temp',
  ORDER = 'b2c-order',
}

export enum TYPE_COOKIE {
  TOKEN = 'b2c-token',
  LANGUAGE = 'b2c-language',
}

export const LIMIT_PAGE_SIZE = 6;

export const default_options_get_list: I_OptionsGetList = {
  limit: LIMIT_PAGE_SIZE,
  page: 1,
  search: '',
  order_by: '',
  order: 'desc',
};

export const DEFAULT_LANGUAGE = 'vi';

export const PROVINCES = [
  { province: 'Hà Nội', code: '001' },
  { province: 'Hà Giang', code: '002' },
  { province: 'Cao Bằng', code: '004' },
  { province: 'Bắc Kạn', code: '006' },
  { province: 'Tuyên Quang', code: '008' },
  { province: 'Lào Cai', code: '010' },
  { province: 'Điện Biên', code: '011' },
  { province: 'Lai Châu', code: '012' },
  { province: 'Sơn La', code: '014' },
  { province: 'Yên Bái', code: '015' },
  { province: 'Hòa Bình', code: '017' },
  { province: 'Thái Nguyên', code: '019' },
  { province: 'Lạng Sơn', code: '020' },
  { province: 'Quảng Ninh', code: '022' },
  { province: 'Bắc Giang', code: '024' },
  { province: 'Phú Thọ', code: '025' },
  { province: 'Vĩnh Phúc', code: '026' },
  { province: 'Bắc Ninh', code: '027' },
  { province: 'Hải Dương', code: '030' },
  { province: 'Hải Phòng', code: '031' },
  { province: 'Hưng Yên', code: '033' },
  { province: 'Thái Bình', code: '034' },
  { province: 'Hà Nam', code: '035' },
  { province: 'Nam Định', code: '036' },
  { province: 'Ninh Bình', code: '037' },
  { province: 'Thanh Hóa', code: '038' },
  { province: 'Nghệ An', code: '040' },
  { province: 'Hà Tĩnh', code: '042' },
  { province: 'Quảng Bình', code: '044' },
  { province: 'Quảng Trị', code: '045' },
  { province: 'Thừa Thiên Huế', code: '046' },
  { province: 'Đà Nẵng', code: '048' },
  { province: 'Quảng Nam', code: '049' },
  { province: 'Quảng Ngãi', code: '051' },
  { province: 'Bình Định', code: '052' },
  { province: 'Phú Yên', code: '054' },
  { province: 'Khánh Hòa', code: '056' },
  { province: 'Ninh Thuận', code: '058' },
  { province: 'Bình Thuận', code: '060' },
  { province: 'Kon Tum', code: '062' },
  { province: 'Gia Lai', code: '064' },
  { province: 'Đắk Lắk', code: '066' },
  { province: 'Đắk Nông', code: '067' },
  { province: 'Lâm Đồng', code: '068' },
  { province: 'Bình Phước', code: '070' },
  { province: 'Tây Ninh', code: '072' },
  { province: 'Bình Dương', code: '074' },
  { province: 'Đồng Nai', code: '075' },
  { province: 'Bà Rịa - Vũng Tàu', code: '077' },
  { province: 'Hồ Chí Minh', code: '079' },
  { province: 'Long An', code: '080' },
  { province: 'Tiền Giang', code: '082' },
  { province: 'Bến Tre', code: '083' },
  { province: 'Trà Vinh', code: '084' },
  { province: 'Vĩnh Long', code: '086' },
  { province: 'Đồng Tháp', code: '087' },
  { province: 'An Giang', code: '089' },
  { province: 'Kiên Giang', code: '091' },
  { province: 'Cần Thơ', code: '092' },
  { province: 'Hậu Giang', code: '093' },
  { province: 'Sóc Trăng', code: '094' },
  { province: 'Bạc Liêu', code: '095' },
  { province: 'Cà Mau', code: '096' },
];

export const FUNCTION_KEYS = [
  'Backspace',
  'Enter',
  'Return',
  'Tab',
  'Escape',
  'PageUp',
  'PageDown',
  'End',
  'Home',
  'ArrowDown',
  'ArrowRight',
  'ArrowUp',
  'ArrowLeft',
  'Insert',
  'Delete',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
];

export enum TICKET_TYPES {
  CABLE_CAR = '9005',
}

export enum MAP_VGS {
  COMBO = 'S4',
}

// When scrolling on a mobile device, the screen size may change.
// This value determines whether the resize event is triggered or not (~10px)
export const RESOLUTION_DEVIATION = 10;

export const MAX_AMOUNT = 50;
