import styled from 'styled-components/macro';

export const FormContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper:not(.ant-form-item-has-error
      .ant-input-affix-wrapper-disabled) {
    /* background: none !important;
    border: none; */
    /* background: rgb(0 0 0 / 0.2);
    border: 1px solid rgba(255, 255, 255, 0.15); */
    border-radius: 2px;
    box-shadow: none !important;
    &:hover {
      /* border-color: rgb(255 255 255 / 0.5); */
    }
    &.ant-input-affix-wrapper-focused {
      border-color: #fff;
    }
  }

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover,
  .ant-form-item-has-error
    .ant-input-affix-wrapper:not(.ant-form-item-has-error
      .ant-input-affix-wrapper-disabled),
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    /* background: rgb(0 0 0 / 0.2);
    border-color: var(--color-red) !important; */
  }

  .ant-picker {
    &:hover {
      border-color: var(--color-4) !important;
    }
    &-focused {
      border-color: var(--color-4) !important;
      box-shadow: 0 0 0 4px rgb(249 178 23 / 15%);
    }
    input {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .ant-select {
    &:hover {
      border-color: var(--color-4) !important;
      .ant-select-selector {
        border-color: var(--color-4) !important;
      }
    }
    &-focused {
      .ant-select-selector {
        border-color: var(--color-4) !important;
        box-shadow: 0 0 0 4px rgb(249 178 23 / 15%) !important;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 12px;
    &:last-child {
      // margin-bottom: 0;
    }
    &-with-help {
      /* margin-bottom: 0px; */
    }
    /* &:nth-last-child(2) {
      margin-bottom: 24px;
    } */

    .ant-form-item-label {
      padding: 0 0 0.125rem 0;
      position: relative;
      z-index: 2;
      label {
        /*
        font-size: 0.75rem; */
        color: #000000;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 20px;
        margin-bottom: 2px;
      }
      label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
      label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
      }
    }
    .ant-form-item-control {
      input {
        transition: var(--transition-regular);
        &:hover {
          border-color: var(--color-4) !important;
        }
        &:focus {
          border-color: var(--color-4) !important;
          box-shadow: 0 0 0 4px rgb(249 178 23 / 15%);
        }
      }
      textarea {
        padding: 12px 16px;
        transition: var(--transition-regular);
        &:hover {
          border-color: var(--color-4) !important;
        }
        &:focus {
          border-color: var(--color-4) !important;
          box-shadow: 0 0 0 4px rgb(249 178 23 / 15%);
        }
      }
    }
    .ant-input-affix-wrapper {
      transition: var(--transition-regular);
      &:hover {
        border-color: var(--color-4) !important;
      }
      &-focused {
        border-color: var(--color-4) !important;
        box-shadow: 0 0 0 4px rgb(249 178 23 / 15%);
      }
    }
    .ant-input-suffix span {
      color: #000;
    }
    .ant-input {
      /* background: none !important; */
      /* border: none; */
    }

    .ant-form-item .ant-select .ant-select-selector {
      border-radius: 2px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
    background: #fff !important;
    background-color: #fff !important;
    -webkit-text-fill-color: #000 !important;
  }

  .ant-input,
  .ant-input-password,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    &-password {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-select-selection-item {
      line-height: 46px;
    }
    border: 1px solid #DBDBDB;
    border-radius: 10px !important;
    height: 46px;
    padding: 0 16px;
    color: #000;
    width: 100%;
    margin: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    overflow: hidden;
  }
  .ant-select-selection-placeholder {
    color: #dddddd;
    display: flex;
    align-items: center;
  }
  textarea.ant-input {
    height: auto;
  }
  .ant-input-password .ant-input {
    border: none !important;
  }
`;
