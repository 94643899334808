import { Col, Form, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Password from 'antd/lib/input/Password';
import ExitConfirm from 'app/components/ExitConfirm';
import { FormItemProps } from 'app/components/FormContainer';
import { LoadingContainer } from 'app/components/LoadingContainer';
import { FormContainerWrapper } from 'app/components/NewFormContainer/styled';
import StyledButton from 'app/components/StyledButton';
import useWindowSize from 'app/hooks/useWindowSize';
import {
  StyledVisibleOffIcon,
  StyledVisibleOnIcon,
} from 'app/pages/App/components/FormRegister';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import ChangePassOtpModal from 'app/pages/NewAccount/ChangePass/modals/ChangePassOtpModal';
import { sendOtpChangePassApi } from 'app/services/authApi';
import axios from 'axios';
import { objectType } from 'types';
import { Layout } from '../layouts';
import { ACCOUNT_MNG_MENU } from '../layouts/AccountManager';

const ChangePass = () => {
  const [loading, setLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [otpData, setOtpData] = useState<objectType>();
  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();
  const translations = useSelector(selectTranslations);
  const dispatch = useDispatch();
  const { device } = useWindowSize();

  const [form] = Form.useForm();

  const onTriggerSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const compareToFirstPassword = useCallback(
    (_, value: string) => {
      const { password } = form.getFieldsValue(true);
      if (value && value !== password) {
        return Promise.reject(
          new Error(translations ? translations['b2c.form.rePassValid'] : ''),
        );
      }
      return Promise.resolve();
    },
    [form, translations],
  );

  const onSubmitForm = async (values: objectType) => {
    setLoading(true);
    try {
      const payload = { ...values };

      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const { data } = await sendOtpChangePassApi(formData);

      setOtpData(payload);
      setIsShowOtp(true);

      // const { data } = await changePassApi(formData);

      // console.log('payload', payload, data);

      // onOpenDialogNoti({
      //   type: 'SUCCESS',
      //   isShow: true,
      //   title: translations?.['b2c.message.updatePersonInfoSuccess'] ?? '',
      //   content: '',
      //   closable: true,
      //   onCloseDialog: onCloseDialogNoti,
      //   maskClosable: false,
      // });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const resData = err.response?.data as {
          field?: string;
          message?: string;
        };
        if (
          err.response?.status === 400 &&
          resData?.field &&
          resData?.message
        ) {
          form.setFields([
            {
              name: 'current_password',
              errors: [resData?.message ?? ''],
            },
          ]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const LIST_FORM_FIELDS: FormItemProps[] = useMemo(
    () => [
      {
        label: translations ? translations['b2c.form.current_password'] : '',
        name: 'current_password',
        rules: [{ required: true }],
        validateFirst: true,
        validateTrigger: ['onBlur'],
        children: (
          <Password
            placeholder={
              translations ? translations['b2c.form.current_password'] : ''
            }
            maxLength={20}
            size="large"
            onPressEnter={onTriggerSubmit}
            iconRender={visible =>
              visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />
            }
          />
        ),
      },
      {
        label: translations ? translations['b2c.form.new-password'] : '',
        name: 'password',
        rules: [
          { required: true },
          { min: 8 },
          { max: 20 },
          {
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
            message: translations ? translations['b2c.form.passValid'] : '',
          },
        ],
        validateFirst: true,
        validateTrigger: ['onBlur'],
        children: (
          <Password
            placeholder={
              translations ? translations['b2c.form.new-password'] : ''
            }
            maxLength={20}
            size="large"
            onPressEnter={onTriggerSubmit}
            iconRender={visible =>
              visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />
            }
          />
        ),
      },
      {
        label: translations ? translations['b2c.form.re-new-password'] : '',
        name: 'password_repeat',
        rules: [
          { required: true },
          { max: 20 },
          {
            validator: compareToFirstPassword,
          },
        ],
        validateFirst: true,
        validateTrigger: ['onBlur'],
        children: (
          <Password
            placeholder={
              translations ? translations['b2c.form.re-new-password'] : ''
            }
            maxLength={20}
            size="large"
            onPressEnter={onTriggerSubmit}
            iconRender={visible =>
              visible ? <StyledVisibleOnIcon /> : <StyledVisibleOffIcon />
            }
          />
        ),
      },
    ],
    [compareToFirstPassword, onTriggerSubmit, translations],
  );

  return (
    <Layout
      title={translations?.['b2c.menu.change-pass'] ?? ''}
      menuActive={ACCOUNT_MNG_MENU.CHANGE_PASS}
    >
      <ExitConfirm
        message={translations?.['b2c.txt.confirmExists']}
        enable={isFormDirty}
      />
      <LoadingContainer loading={loading} minHeight="auto">
        <StyledFormContainerWrapper>
          <Form
            form={form}
            validateMessages={{
              required:
                '${label} ' +
                (translations ? translations['b2c.form.validate-require'] : ''),
              string: {
                len: '${label} phải có ${len} kí tự',
                min:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-min-chars-1']
                    : '') +
                  ' ${min} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                max:
                  '${label} ' +
                  (translations
                    ? translations['b2c.form.validate-max-chars-1']
                    : '') +
                  ' ${max} ' +
                  (translations ? translations['b2c.form.validate-chars'] : ''),
                range: '${label} must be between ${min} and ${max} kí tự',
              },
              types: {
                email:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                number:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                string:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                date:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                method:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                array:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                object:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                boolean:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                integer:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                float:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                regexp:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                url:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
                hex:
                  '${label}: ${type} ' +
                  (translations ? translations['b2c.form.invalid'] : ''),
              },
              number: {
                len: '${label} must equal ${len}',
                min: '${label} cannot be less than ${min}',
                max: '${label} cannot be greater than ${max}',
                range: '${label} must be between ${min} and ${max}',
              },
              array: {
                len: '${label} must be exactly ${len} in length',
                min: '${label} cannot be less than ${min} in length',
                max: '${label} cannot be greater than ${max} in length',
                range: '${label} must be between ${min} and ${max} in length',
              },
              pattern: {
                mismatch: '${label} does not match pattern ${pattern}',
              },
              enum: '${label} must be one of [${enum}]',
              whitespace: '${label} cannot be empty',
              date: {
                format: '${label} is invalid for format date',
                parse: '${label} could not be parsed as date',
                invalid: '${label} is invalid date',
              },
            }}
            onFinish={onSubmitForm}
            onValuesChange={() => setIsFormDirty(true)}
          >
            <Row gutter={device === 'desktop' ? 44 : 49}>
              {LIST_FORM_FIELDS.map((field, index) => {
                const { children, ...fieldProps } = field;
                return (
                  children && (
                    <Col span={24} key={index}>
                      <Form.Item
                        key={field.name || new Date().toString()}
                        {...fieldProps}
                      >
                        {children}
                      </Form.Item>
                    </Col>
                  )
                );
              })}
            </Row>
            <Row>
              <ActionButton>
                <StyledButton
                  textColor="#fff"
                  onClick={onTriggerSubmit}
                  disabled={loading}
                  style={{ fontSize: 16, minWidth: '160px', width: 'initial' }}
                >
                  {translations?.['b2c.btn.saveInfo']}
                </StyledButton>
              </ActionButton>
            </Row>
          </Form>
        </StyledFormContainerWrapper>
      </LoadingContainer>
      {otpData && (
        <ChangePassOtpModal
          isShow={isShowOtp}
          values={otpData}
          onCloseDialog={() => {
            setOtpData(undefined);
            setIsShowOtp(false);
          }}
        />
      )}
    </Layout>
  );
};

export default ChangePass;

const StyledFormContainerWrapper = styled(FormContainerWrapper)`
  .ant-input,
  .ant-picker,
  .ant-input-password,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0 !important;
    border-bottom: solid 1px #d9d9d9;
  }
  .ant-form-item-explain-error {
    margin-top: 4px;
  }
  .ant-form-item-explain-error,
  .ant-input {
    // font-style: italic;
  }
  .ant-input {
    &[disabled] {
      background: #fff;
    }
  }
  .ant-col {
    label {
      display: none;
    }
  }
`;

const ActionButton = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > *:not(:first-child) {
    margin-left: 20px;
    @media (max-width: 1279px) {
      margin-left: 38px;
    }
    @media (max-width: 540px) {
      margin-left: 20px;
    }
  }
  @media (max-width: 1279px) {
    margin-top: 8px;
    button {
      width: 50%;
    }
  }
`;
