import React, { CSSProperties, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PhotoView } from 'react-photo-view';
import { Modal } from 'antd';

import { ReactComponent as BlankArrow } from 'app/assets/img/blank_arrow.svg';
import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { MOBILE_WIDTH } from 'app/hooks/useWindowSize';

const GrandstandDiagram = (props: {
  grandstandData?: Record<string, any>;
  activeElm?: ReactElement;
  textCSS?: CSSProperties;
}) => {
  const [open, setOpen] = useState(false);
  const translations = useSelector(selectTranslations);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!props?.grandstandData?.image?.original) {
    return (
      <div>
        <div onClick={handleOpen}>
          {props.activeElm || (
            <Text style={props.textCSS}>
              {translations?.['b2c.text.grandstand-diagram']}{' '}
              <BlankArrow style={{ marginLeft: 7 }} />
            </Text>
          )}
        </div>
        <CustomModal
          visible={open}
          footer={null}
          onCancel={handleClose}
          width={416}
          closeIcon={<CloseIcon />}
          destroyOnClose
          centered
        >
          <StyledH4>{translations?.['b2c.text.grandstand-diagram']}</StyledH4>
          <div style={{ textAlign: 'center' }}>
            {translations ? translations['b2c.txt.MSG019'] : ''}
          </div>
        </CustomModal>
      </div>
    );
  }

  return (
    <PhotoView src={props?.grandstandData?.image?.original}>
      {props.activeElm || (
        <Text style={props.textCSS}>
          {translations?.['b2c.text.grandstand-diagram']}{' '}
          <BlankArrow style={{ marginLeft: 7 }} />
        </Text>
      )}
    </PhotoView>
  );
};

export default GrandstandDiagram;

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #f8b117;
  display: flex;
  align-items: center;
  margin-top: 13px;
  cursor: pointer;
  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 2px;
  }
`;

const StyledH4 = styled.h4`
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 36px;
`;

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 36px 24px 24px;
  }
`;
