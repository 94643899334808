import { Button, Carousel, CarouselProps } from 'antd';
import React, { useId, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CarouselRef } from 'antd/lib/carousel';

import LinkButton from 'app/components/LinkButton';
import StyledButton from 'app/components/StyledButton';
import { selectTranslations } from 'app/pages/App/slice/selectors';

import { ReactComponent as NextIcon } from 'app/assets/img/next_icon.svg';
import { ReactComponent as MoreDetailIcon } from 'app/assets/img/more_detail_icon.svg';
import useWindowSize from 'app/hooks/useWindowSize';
import { RESOLUTION_DEVIATION } from 'utils/constants';

interface StyledCarouselProps<ItemData> extends CarouselProps {
  items: {
    imgPath: string;
    title?: string;
    data?: ItemData;
  }[];
  title?: string;
  onClickDetailItem: (index: number, data?: ItemData) => void;
  onClickOrderTicket: (index: number, data?: ItemData) => void;
  disabledNextBtn?: boolean;
  disabledPrevBtn?: boolean;
  switchBtnColor?: string;
}

const CustomCarousel = styled(Carousel)`
  .slick-track {
    margin-left: 0;
  }
  .slick-slide {
    padding: 0 12px;
  }
  .slick-dots {
    margin-left: 0;
    margin-right: 0;
    z-index: 1;
    li {
      background: #c1c1c1;
      width: initial;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      overflow: hidden;
      button {
        opacity: 0 !important;
        height: 5px;
        width: 5px;
        background: #c1c1c1;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 25px;
        background: #fab317;
        border-radius: 99px;
      }
    }
  }
  .slick-dots-bottom {
    bottom: -30px;
  }
`;

const CarouselButton = styled(Button)<{ iconColor?: string }>`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${props => (props?.iconColor ? props.iconColor : '#e92127')};
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    border-color: ${props => (props?.iconColor ? props.iconColor : '#e92127')};
  }
  svg path {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    stroke: ${props => (props?.iconColor ? props.iconColor : '#e92127')};
  }
  &[disabled] {
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      border-color: #808080;
    }
    border-color: #808080;
    opacity: 1;
    svg {
      path {
        stroke: #808080;
      }
    }
  }
`;

const DEFAULT_BUY_TICKET_BTN_WIDTH = 'initial';

const StyledCarousel = <ItemData,>(props: StyledCarouselProps<ItemData>) => {
  const slideRef = useRef<CarouselRef>(null);
  const translations = useSelector(selectTranslations);
  const id = useId();
  const cachedWidth = useRef(0);

  const buyTicketBtnRef = useRef<HTMLButtonElement>(null);
  const [buyTicketBtnWidth, setBuyTicketBtnWidth] = useState(
    DEFAULT_BUY_TICKET_BTN_WIDTH,
  );
  const seeDetailBtnRef = useRef<HTMLDivElement>(null);
  const actionBlockRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const onSetBtnWidth = () => {
      if (
        !translations ||
        !buyTicketBtnRef.current ||
        !seeDetailBtnRef.current ||
        !actionBlockRef.current ||
        (cachedWidth.current >= window.innerWidth - RESOLUTION_DEVIATION &&
          cachedWidth.current <= window.innerWidth + RESOLUTION_DEVIATION)
      )
        return;

      setBuyTicketBtnWidth(DEFAULT_BUY_TICKET_BTN_WIDTH);
      cachedWidth.current = window.innerWidth;
      if (timer) clearInterval(timer);

      timer = setTimeout(() => {
        const { width: buyTicketBtnWidth } =
          buyTicketBtnRef.current!.getBoundingClientRect();
        const { width: buyTicketContentWidth } = buyTicketBtnRef
          .current!.querySelector('span')!
          .getBoundingClientRect();
        const { width: seeDetailBtnWidth } =
          seeDetailBtnRef.current!.getBoundingClientRect();
        const { width: actionBlockWidth } =
          actionBlockRef.current!.getBoundingClientRect();

        if (
          buyTicketBtnWidth - 20 < buyTicketContentWidth ||
          buyTicketBtnWidth + seeDetailBtnWidth + 20 >= actionBlockWidth
        ) {
          setBuyTicketBtnWidth('100%');
        } else {
          setBuyTicketBtnWidth(DEFAULT_BUY_TICKET_BTN_WIDTH);
        }
      }, 300);
    };

    onSetBtnWidth();

    window.addEventListener('resize', onSetBtnWidth);
    return () => {
      window.removeEventListener('size', onSetBtnWidth);
      if (timer) clearInterval(timer);
    };
  }, [translations]);

  const { device } = useWindowSize();

  return (
    <>
      <div
        style={{
          marginBottom: device === 'desktop' ? 35 : 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <StyledH4
          style={{
            marginBottom: 0,
            color: '#000000',
            textTransform: 'uppercase',
          }}
        >
          {props.title ?? ''}
        </StyledH4>
        {device === 'desktop' && (
          <div style={{ display: 'flex' }}>
            <CarouselButton
              onClick={() => slideRef.current?.prev()}
              icon={<NextIcon style={{ transform: 'rotate(180deg)' }} />}
              disabled={props.disabledPrevBtn}
              iconColor={props.switchBtnColor}
            />
            <CarouselButton
              onClick={() => slideRef.current?.next()}
              icon={<NextIcon />}
              disabled={props.disabledNextBtn}
              style={{ marginLeft: 30 }}
              iconColor={props.switchBtnColor}
            />
          </div>
        )}
      </div>
      <CustomCarousel
        ref={slideRef}
        dots={device !== 'desktop'}
        slidesToShow={props.slidesToShow ?? 3.5}
        initialSlide={0}
        slidesToScroll={Math.floor(props.slidesToShow ?? 3)}
        infinite={false}
        className={props.className + ' ' + id}
        beforeChange={(_currentIdx, nextIdx) => {
          if (nextIdx >= props.items.length - (props.slidesToShow ?? 3.5)) {
            document
              .querySelector('ul.slick-dots li:last-child')
              ?.classList.add('slick-active');
          } else {
            document
              .querySelector('ul.slick-dots li:last-child')
              ?.classList.remove('slick-active');
          }
        }}
        {...props}
      >
        {props.items.map((val, idx) => {
          return (
            <div key={`${idx}`}>
              <ItemContainer>
                <ImgWrapper>
                  {val.imgPath ? (
                    <StyledImg src={val.imgPath} alt="" />
                  ) : (
                    <DefaultImg />
                  )}
                </ImgWrapper>
                <div
                  style={{
                    margin: '16px 0px 16px 0px',
                    color: '#000000',
                    fontWeight: 500,
                    flex: 1,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minHeight: 44,
                  }}
                >
                  {val.title ?? ''}
                </div>
                <div
                  style={{
                    display:
                      buyTicketBtnWidth !== DEFAULT_BUY_TICKET_BTN_WIDTH
                        ? 'block'
                        : 'flex',
                    justifyContent: 'space-between',
                  }}
                  ref={actionBlockRef}
                >
                  <LinkButton
                    ref={seeDetailBtnRef}
                    endIcon={<MoreDetailIcon />}
                    onClick={() => props.onClickDetailItem(idx, val.data)}
                    textColor="#F8B117"
                    styleBtn={{ textWrap: 'nowrap' }}
                    styleContainer={{ display: 'inline-flex' }}
                  >
                    {translations?.['b2c.text.see-detail']}
                  </LinkButton>
                  <StyledButton
                    onClick={() => props.onClickOrderTicket(idx, val.data)}
                    textColor="white"
                    height="40px"
                    width={buyTicketBtnWidth}
                    style={{
                      textWrap: 'nowrap',
                      minWidth: 102,
                      marginTop:
                        buyTicketBtnWidth !== DEFAULT_BUY_TICKET_BTN_WIDTH
                          ? 10
                          : 0,
                    }}
                    ref={buyTicketBtnRef}
                  >
                    {translations?.['b2c.txt.buyTicket']}
                  </StyledButton>
                </div>
              </ItemContainer>
            </div>
          );
        })}
      </CustomCarousel>
    </>
  );
};

export default StyledCarousel;

const StyledH4 = styled.h4`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 1279px) {
    font-size: 18px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DefaultImg = styled.div`
  width: 100%;
  height: 100%;
  background: #f7dba1;
  background-image: linear-gradient(to bottom, #ffffff 70%, #f8b117);
  border-radius: 16px;
  border: solid 1px #d9d9d9;
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  &[src=''] {
    opacity: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  aspect-ratio: 29 / 20;
  overflow: hidden;
`;
