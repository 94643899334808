import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.home || initialState;

export const selectHome = createSelector([selectSlice], state => state);

export const selectIsLoading = createSelector(
  [selectHome],
  state => state.loading,
);

export const selectIsError = createSelector([selectHome], state => state.error);

export const selectIsSuccess = createSelector(
  [selectHome],
  state => state.success,
);

export const selectHomePage = createSelector([selectHome], state => state.home);

export const selectListPark = createSelector(
  [selectHome],
  state => state.listPark,
);
