import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';

interface Props {
  time: number;
  interval?: number;
  startTime?: number;
}

const useCountDown = (props: Props) => {
  const { time, interval = 1000, startTime = moment.now() } = props;
  const [timeRemaining, setTimeRemaining] = useState(time);
  const endTime = useRef(startTime + time);

  const resetCountDown = useCallback(
    (newTime?: number) => {
      endTime.current = moment.now() + (newTime ?? time);
      setTimeRemaining(newTime ?? time);
    },
    [time],
  );

  useEffect(() => {
    const runCountDown = () => {
      if (timeRemaining <= 0) return;
      const newTimeRemaining = endTime.current - moment.now();
      if (newTimeRemaining <= 0) return setTimeRemaining(0);
      setTimeRemaining(Math.round(newTimeRemaining / 1000) * 1000);
    };

    const timer = setTimeout(() => {
      runCountDown();
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [timeRemaining, interval, endTime]);

  return {
    timeRemaining,
    resetCountDown,
  };
};

export default useCountDown;
