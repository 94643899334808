import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.listingOrder || initialState;

export const selectListingOrder = createSelector([selectSlice], state => state);

export const selectIsLoading = createSelector(
  [selectListingOrder],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectListingOrder],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectListingOrder],
  state => state.success,
);

export const selectOptionsGetListOrder = createSelector(
  [selectListingOrder],
  state => state.optionsGetListOrder,
);

export const selectOrderCount = createSelector(
  [selectListingOrder],
  state => state.orderCount,
);

export const selectListOrder = createSelector(
  [selectListingOrder],
  state => state.listOrder,
);
