import { DatePicker, DatePickerProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import moment, { Moment } from 'moment';

import viVN from 'antd/es/date-picker/locale/vi_VN';
import enUS from 'antd/lib/date-picker/locale/en_US';
import 'moment/locale/vi';

import styles from './styles.module.css';

import { ReactComponent as CalendarIcon } from 'app/assets/img/calendar.svg';
import { ReactComponent as RightArrow } from 'app/assets/img/red_right_arrow.svg';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { selectTranslations } from 'app/pages/App/slice/selectors';

export interface DatePickerComponentProps
  extends Omit<DatePickerProps, 'picker'> {
  datesHighlight?: Moment[];
}

const DatePickerComponent = (props: DatePickerComponentProps) => {
  const translations = useSelector(selectTranslations);
  const { locale } = useGlobalFunction();
  const [panelValue, setPanelValue] = useState(moment(new Date()));

  useEffect(() => {
    if (props.value) setPanelValue(moment(props.value));
    else if (props.defaultPickerValue)
      setPanelValue(moment(props.defaultPickerValue));
  }, [props.defaultPickerValue, props.value]);

  const timeToShow = useMemo(() => {
    const month =
      locale === 'vi'
        ? `Tháng ${(panelValue.month() ?? 0) + 1}`
        : panelValue?.locale(locale).format('MMMM');
    return `${month} - ${panelValue?.locale(locale).format('YYYY')}`;
  }, [locale, panelValue]);

  const dateRender = (current: Moment) => {
    const isHighlight = props.datesHighlight?.some(date =>
      current.startOf('day').isSame(date.startOf('day')),
    );
    return (
      <div
        className={`ant-picker-cell-inner ${
          isHighlight ? styles.highlight : ''
        }`}
      >
        {current.date()}
      </div>
    );
  };

  return (
    <CustomCalendar
      popupStyle={{
        borderRadius: 20,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        overflow: 'hidden',
        zIndex: 1,
      }}
      dateRender={dateRender}
      locale={locale === 'vi' ? viVN : enUS}
      suffixIcon={<StyledCalendarIcon />}
      panelRender={panelNode => {
        return (
          <PanelContainer>
            <TitleWrapper className="d-flex align-items-center">
              <StyledCalendarIcon />
              <Title>{translations?.['b2c.txt.selectDate'] ?? ''}</Title>
            </TitleWrapper>
            <Time>{timeToShow}</Time>
            {panelNode}
          </PanelContainer>
        );
      }}
      showToday={false}
      nextIcon={
        <ButtonControl style={{ marginRight: 8 }}>
          <RightArrow />
        </ButtonControl>
      }
      prevIcon={
        <ButtonControl style={{ transform: 'rotate(180deg)', marginLeft: 8 }}>
          <RightArrow />
        </ButtonControl>
      }
      onPanelChange={value => {
        setPanelValue(value);
      }}
      {...props}
    />
  );
};

export default DatePickerComponent;

const CustomCalendar = styled(DatePicker)`
  border: none;
  box-shadow: none;
  border-bottom: solid 1px #ededed;
  border-radius: 0;
  padding-left: 0;
  width: 100%;
`;

const PanelContainer = styled.div`
  padding: 36px 27px 24px;
  background: #ffffff;
  .ant-picker-header-view {
    visibility: hidden;
  }
  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    height: auto;
  }
  .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-panel {
    border-bottom: none;
    .ant-picker-date-panel {
      width: auto;
      .ant-picker-header {
        border-bottom: none;
        padding: 0;
        & > button {
          min-width: auto;
        }
        .ant-picker-header-view {
          color: #000000;
        }
      }
      .ant-picker-body {
        padding: 0;
        .ant-picker-content {
          thead {
            th {
              color: #212121;
            }
          }
          tbody {
            tr {
              td {
                padding-top: 12px;
                padding-bottom: 0;
              }
            }
          }
        }
      }
      .ant-picker-cell {
        &.ant-picker-cell-disabled {
          &::before {
            background: none;
          }
        }
        &.ant-picker-cell-selected {
          .ant-picker-cell-inner {
            border-radius: 50%;
            color: #ffffff !important;
            background: #e92227;
          }
        }
        &.ant-picker-cell-in-view {
          &:not(.ant-picker-cell-disabled) {
            .ant-picker-cell-inner {
              color: #000000;
            }
          }
          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              &::before {
                display: none;
              }
            }
          }
        }
        .ant-picker-cell-inner {
          border-radius: 50%;
          color: #c1c1c1;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          margin: auto;
        }
      }
    }
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  path {
    stroke: #000000;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 22px;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0 8px 0;
`;

const ButtonControl = styled.div`
  height: 16px;
  width: 16px;
  border: solid 1px #e92227;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const Time = styled.span`
  color: #212121;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  top: 89px;
  left: 50%;
  transform: translateX(-50%);
`;
