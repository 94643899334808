/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { PhotoProvider } from 'react-photo-view';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import './datepicker.css';

import 'app/assets/fonts/fontawesome-pro-5.11.1-web/css/all.min.css';

// Import root app
import { App } from 'app/pages/App';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';

import 'react-photo-view/dist/react-photo-view.css';

export const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const container = (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
  >
    <Provider store={store}>
      <HelmetProvider>
        {/* <React.StrictMode> */}
        <PhotoProvider maskOpacity={0.7}>
          <App />
        </PhotoProvider>
        {/* </React.StrictMode> */}
      </HelmetProvider>
    </Provider>
  </GoogleReCaptchaProvider>
);

const root = ReactDOMClient.createRoot(MOUNT_NODE!);

root.render(container);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['app/pages/App', './locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
    root.unmount();
    root.render(container);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
