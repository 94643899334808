/**
 *
 * styled LoadingContainer
 *
 */
import styled from 'styled-components/macro';
import { FlexRowCenter } from 'styles/global-styles';

export const LoadingContainerWrapper = styled.div<{ minHeight?: string }>`
  width: 100%;
  height: 100%;
  min-height: ${props => props.minHeight ?? '300px'};
  .ant-spin {
    width: 100%;
    min-height: 300px;
    height: 100%;
    ${FlexRowCenter};
    color: #d1005c;
    &-container {
      width: 100%;
      height: 100%;
      // overflow: auto;
    }
    &-blur {
      &:after {
        display: none;
      }
    }
    &-nested-loading {
      width: 100%;
      height: 100%;
      > div > .ant-spin {
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }
  &.fullpage {
    min-height: 100vh;
    .ant-spin {
      height: 100vh;
    }
  }
`;
