import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import moment from 'moment';
import * as _ from 'lodash';

import styles from './styles.module.css';
import StyledButton from 'app/components/StyledButton';
import { formatNumber } from 'utils/helper';
import { createNewOrderApi } from 'app/services/checkoutApi';
import { LOCAL_STORAGE_KEY, getItem, setSessionItem } from 'utils/localStorage';
import {
  MAP_VGS,
  RESOLUTION_DEVIATION,
  TICKET_TYPES,
  TYPE_LOCAL_STORAGE,
} from 'utils/constants';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { generateTicketIdentify } from '../../utils';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';
import { DESKTOP_WIDTH } from 'app/hooks/useWindowSize';
import checkHaveTicketOnSelectedDate from '../TicketItem/partials/helper';
import { OnSetCart } from '../../hooks/useCartNew';
import { I_CartItem } from '../../slice/types';

interface PaymentInfoProps {
  listCart: I_CartItem[];
  isLoading: boolean;
  totalAmountSelected: number;
  totalPriceSelected: number;
  onGoToCatologPage: () => void;
  onSetCart: OnSetCart;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
}

const DEFAULT_BTN_COLUMN_SPAN = 12;

const PaymentInfo = (props: PaymentInfoProps) => {
  const {
    listCart,
    totalAmountSelected,
    totalPriceSelected,
    onGoToCatologPage,
    onSetCart,
    isLoading,
    setIsLoading,
    setIsSubmitted,
  } = props;

  const buyMoreBtnRef = useRef<HTMLButtonElement>(null);
  const payNowBtnRef = useRef<HTMLButtonElement>(null);
  const [btnColumnSpan, setBtnColumnSpan] = useState(DEFAULT_BTN_COLUMN_SPAN);
  const cachedWidth = useRef(0);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { onOpenDialogNoti, onCloseDialogNoti } = useGlobalFunction();

  const isSelectAnyItem = listCart.some(i => i.selected);

  const history = useHistory();

  const translations = useSelector(selectTranslations);

  const onCreateOrder = useCallback(async () => {
    if (isLoading || !isSelectAnyItem) return false;
    setIsLoading(true);
    setIsSubmitted(true);
    if (!executeRecaptcha) return;
    const tokenCaptcha = await executeRecaptcha('createOrder');
    const formData = new FormData();
    formData.append('captcha', tokenCaptcha);
    const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
    formData.append('cart_id', cartId ?? '');

    let index = 0;
    let isHasErrorData = false;
    let listCartClone = JSON.parse(JSON.stringify(listCart));
    let firstErrorId: string = '';

    const ticketsSelected = listCart.filter(item => item?.selected);
    ticketsSelected.forEach(ticket => {
      const isNotApplyUsedArea2 =
        ticket?.show?.materialType === TICKET_TYPES.CABLE_CAR;
      const isNotApplyZone =
        ticket?.show?.materialType === TICKET_TYPES.CABLE_CAR ||
        _.isNil(ticket?.zone) ||
        _.isEmpty(ticket?.zone);

      const isRestaurantTicket =
        ticket.show?.restaurants && ticket.show?.restaurants?.length > 0;

      const isComboDifTicket =
        ticket.catalog?.mapVGS === MAP_VGS.COMBO &&
        ticket.product?.park?.workstationId === 'DIF';

      const isHaveTicketOnSelectedDate = checkHaveTicketOnSelectedDate({
        selectedDate: moment(ticket.date, 'YYYY-MM-DD'),
        dateFrom: moment(ticket.show?.dateFrom?.dateOrigin, 'YYYY-MM-DD'),
        dateTo: moment(ticket.show?.dateTo?.dateOrigin, 'YYYY-MM-DD'),
        isComboDifTicket,
        weekend: ticket.show?.weekend,
      });

      if (
        !isHaveTicketOnSelectedDate ||
        ticket?.errorTicket ||
        ticket?.errorTime ||
        !+(ticket?.inventory ?? 0) ||
        (!ticket?.zone?.id && !isNotApplyZone) ||
        (!ticket?.usedArea2 && !isNotApplyUsedArea2) ||
        ((ticket?.usedAreas2 ?? []).length < 1 && !isNotApplyUsedArea2) ||
        (isRestaurantTicket && !ticket?.restaurant?.id)
      ) {
        isHasErrorData = true;
        if (!firstErrorId) firstErrorId = ticket.identify!;
      }

      if (!+ticket.amount) {
        isHasErrorData = true;
        if (!firstErrorId) firstErrorId = ticket.identify!;
        listCartClone = listCartClone.map(i => {
          if (i.identify !== ticket.identify) return i;
          return {
            ...i,
            errorTicket: translations?.['b2c.txt.plsChooseTicket'] ?? '',
          };
        });
      }
      formData.append(`items[${index}][show_id]`, ticket?.show?.id);
      formData.append(`items[${index}][amount]`, ticket.amount);
      formData.append(
        `items[${index}][used_area2]`,
        ticket?.usedArea2 ?? 'undefined',
      );
      formData.append(`items[${index}][date]`, ticket?.date);
      formData.append(`items[${index}][zone_id]`, ticket?.zone?.id);
      formData.append(`items[${index}][restaurant_id]`, ticket?.restaurant?.id);
      index++;
    });

    try {
      if (formData.entries().next().done || isHasErrorData) return false;
      setIsLoading(true);
      formData.append(
        'cart_id',
        getItem(LOCAL_STORAGE_KEY.anonymousCartId) ?? '',
      );
      const { data } = await createNewOrderApi(formData);
      if (data) {
        const ticketsRemaining = listCart.filter(ticket => !ticket?.selected);
        onSetCart(ticketsRemaining);
        setSessionItem(TYPE_LOCAL_STORAGE.ORDER, JSON.stringify(data.idHash));
        history.push('/checkout');
      }
    } catch (err: any) {
      if (err?.code === 400) {
        if (err?.action === 'popup') {
          onOpenDialogNoti({
            type: 'ERROR',
            isShow: true,
            title: '',
            content: err?.message ?? '',
            onCloseDialog: onCloseDialogNoti,
            maskClosable: false,
            closable: true,
          });
        }
        const dataInventory = err?.data?.dataInventory || [];
        if (_.isArray(dataInventory) && dataInventory.length > 0) {
          isHasErrorData = true;
          dataInventory?.forEach(i => {
            const errIdentify = generateTicketIdentify({
              showId: i?.show_id,
              performanceId: undefined,
              zoneId: i?.zone_id,
              date: i?.date,
              restaurantId: i?.restaurant_id,
              usedArea2: i?.used_area2,
            });
            if (!firstErrorId) firstErrorId = errIdentify;
            listCartClone = listCartClone.map(item => {
              if (item?.identify !== errIdentify) return { ...item };
              return {
                ...item,
                inventory: +(i?.inventory ?? 0),
                errorTicket: err?.message,
              };
            });
          });
        }
      }
    } finally {
      if (isHasErrorData) {
        onSetCart(listCartClone);
        const y =
          (document.getElementById(firstErrorId)?.getBoundingClientRect()
            ?.top ?? 0) +
          window.pageYOffset -
          160;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
      setIsLoading(false);
    }
  }, [
    executeRecaptcha,
    history,
    isLoading,
    isSelectAnyItem,
    listCart,
    onCloseDialogNoti,
    onOpenDialogNoti,
    onSetCart,
    setIsLoading,
    setIsSubmitted,
    translations,
  ]);

  useLayoutEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const onSetBtnColumnSpan = () => {
      if (
        !translations ||
        !buyMoreBtnRef.current ||
        !payNowBtnRef.current ||
        (cachedWidth.current >= window.innerWidth - RESOLUTION_DEVIATION &&
          cachedWidth.current <= window.innerWidth + RESOLUTION_DEVIATION)
      )
        return;

      setBtnColumnSpan(DEFAULT_BTN_COLUMN_SPAN);
      cachedWidth.current = window.innerWidth;
      if (timer) clearInterval(timer);

      timer = setTimeout(() => {
        const { width: buyMoreBtnWidth } =
          buyMoreBtnRef.current!.getBoundingClientRect();
        const { width: buyMoreContentWidth } = buyMoreBtnRef
          .current!.querySelector('span')!
          .getBoundingClientRect();

        const { width: payNowBtnWidth } =
          payNowBtnRef.current!.getBoundingClientRect();
        const { width: payNowContentWidth } = payNowBtnRef
          .current!.querySelector('span')!
          .getBoundingClientRect();

        if (
          buyMoreBtnWidth < buyMoreContentWidth + 30 ||
          payNowBtnWidth < payNowContentWidth + 30
        ) {
          setBtnColumnSpan(24);
        } else {
          setBtnColumnSpan(DEFAULT_BTN_COLUMN_SPAN);
        }
      }, 100);
    };

    onSetBtnColumnSpan();

    window.addEventListener('resize', onSetBtnColumnSpan);

    return () => {
      window.removeEventListener('resize', onSetBtnColumnSpan);
      if (timer) clearInterval(timer);
    };
  }, [translations]);

  return (
    <Container>
      <Payment>
        <h3 className="payment-title">
          {translations?.['b2c.txt.info-pay'] ?? ''}
        </h3>
        <div className="payment-content">
          <div
            className={`d-flex align-items-center justify-content-between ${styles.colorBlack} ${styles.fw500} ${styles.fs14}`}
          >
            <p>{translations?.['b2c.txt.numTicketSelected'] ?? ''}</p>
            <p>{`${formatNumber(totalAmountSelected).replaceAll(',', '.')}`}</p>
          </div>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.colorBlack} ${styles.fw500} ${styles.fs16}`}
          >
            <p>{translations?.['b2c.txt.total-amount'] ?? ''}</p>
            <p
              className={styles.colorRed}
              style={{ fontWeight: 600 }}
            >{`${formatNumber(totalPriceSelected).replaceAll(',', '.')} đ`}</p>
          </div>
          <p
            className={`${styles.colorRed} ${styles.fw400} ${styles.fs14}`}
            style={{ margin: 0 }}
          >
            (*){' '}
            <span className={`${styles.textItalic} ${styles.colorBlack}`}>
              {translations?.['b2c.txt.pressPayToShowOffer'] ?? ''}
            </span>
          </p>
        </div>
      </Payment>
      <Actions gutter={[16, 10]}>
        <Col span={btnColumnSpan}>
          <StyledButton
            textColor="#808080"
            border="solid 1px #808080"
            bgColor="#ffffff"
            width="100%"
            onClick={onGoToCatologPage}
            ref={buyMoreBtnRef}
          >
            {translations?.['b2c.btn.buyMore'] ?? ''}
          </StyledButton>
        </Col>
        <Col span={btnColumnSpan}>
          <StyledButton
            textColor="#ffffff"
            width="100%"
            onClick={onCreateOrder}
            style={{
              cursor: isLoading || !isSelectAnyItem ? 'not-allowed' : 'pointer',
            }}
            ref={payNowBtnRef}
          >
            {isLoading ? 'Loading' : translations?.['b2c.btn.payNow'] ?? ''}
          </StyledButton>
        </Col>
      </Actions>
    </Container>
  );
};

export default React.memo(
  PaymentInfo,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 160px;
  @media (max-width: ${DESKTOP_WIDTH - 1}px) {
    position: static;
    margin-top: 32px;
  }
`;

const Payment = styled.div`
  border-radius: 10px;
  background: #fab317;
  padding: 2px;
  h3.payment-title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 13px 10px;
    margin: 0;
  }
  .payment-content {
    padding: 24px 16px 31px;
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  p {
    margin-bottom: 9px;
  }
`;

const Actions = styled(Row)`
  margin-top: 24px;
`;
