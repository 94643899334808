/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import ModalContainer from 'app/components/ModalContainer';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
interface Props {
  open: boolean;
  message?: string;
  onClose: () => void;
}

const DialogWarning = memo(({ open, onClose, message }: Props) => {
  const translations = useSelector(selectTranslations);
  return (
    <ModalContainer
      isShow={open}
      onCloseDialog={onClose}
      CloseIcon={<CloseIcon width={12} height={12} />}
      styleSlots={{
        iconWrapperStyle: {
          margin: '-8px 0px 0px 28px',
          width: 12,
          height: 12,
        },
        modalContentStyle: {
          height: 'auto',
          width: 'auto',
          padding: '20px',
          borderRadius: '16px',
        },
        modalStyle: {
          width: 'auto',
          maxWidth: '380px',
        },
        bodyStyle: {
          width: 'auto',
        },
      }}
    >
      <div> {message || ''}</div>
    </ModalContainer>
  );
});

export default DialogWarning;
