import { useMemo } from 'react';

import { getUsedArea2 } from 'app/services/showApi';
import useFetchData, {
  CallbackOptions,
  Params as CommonParams,
  PublicExtendable,
} from './useFetchData';

interface TicketSpecification {
  applyUsedZone2: boolean;
  applyZone: boolean;
  isRestaurant: boolean;
}
interface IPayloadData {
  date: string;
  show_id: number;
  zone_id?: number;
  restaurant_id?: number;
}

interface Params
  extends TicketSpecification,
    PublicExtendable<{ data: UsedArea2[] }> {}

export type UsedArea2 = { value: string; label: string };

const useGetUsedArea2 = (params: Params) => {
  const {
    payloadData,
    applyUsedZone2,
    applyZone,
    isRestaurant,
    IIFE,
    onSuccess,
    onError,
    onFinally,
    onBefore,
    takeFirstRequest,
  } = params;
  const { show_id, date, zone_id, restaurant_id } = payloadData;
  const payload = useMemo(() => {
    const isEnoughInfoToFetchData = () => {
      if (
        !show_id ||
        !date ||
        (applyZone && !zone_id) ||
        (isRestaurant && !restaurant_id)
      )
        return false;
      return true;
    };

    const formData = new FormData();
    for (let key in payloadData) {
      formData.append(key, payloadData[key]);
    }

    return {
      actionApi: getUsedArea2,
      payloadData: formData,
      IIFE: applyUsedZone2 && isEnoughInfoToFetchData() && IIFE,
      onBefore,
      onSuccess,
      onError,
      onFinally,
      takeFirstRequest,
    } as CommonParams<{ data: UsedArea2[] }>;
  }, [
    IIFE,
    applyUsedZone2,
    applyZone,
    date,
    isRestaurant,
    onBefore,
    onError,
    onFinally,
    onSuccess,
    payloadData,
    restaurant_id,
    show_id,
    takeFirstRequest,
    zone_id,
  ]);

  const { isLoading, onFetchData, result } = useFetchData<{
    data: UsedArea2[];
  }>(payload);

  const onFetchUsedArea2 = (params: {
    payloadData: IPayloadData;
    callback?: CallbackOptions<{ data: UsedArea2[] }>;
  }) => {
    const {
      payloadData,
     callback,
    } = params;
    const formData = new FormData();
    for (let key in payloadData) {
      if (![null, undefined].includes(payloadData[key])) {
        formData.append(key, payloadData[key]);
      }
    }
    onFetchData({ payloadData: formData, callback: callback });
  };

  return {
    result: result?.data ?? [],
    isLoading,
    onFetchData: onFetchUsedArea2,
  };
};

export default useGetUsedArea2;
