/**
 *
 * styled
 *
 */
import { TABLET_WIDTH } from 'app/hooks/useWindowSize';
import styled from 'styled-components/macro';

export const CheckoutOrderOverviewWrapper = styled.div`
  // position: sticky;
  // top: 100px;
  padding: 32px 20px;
  border-radius: 20px;
  background-color: #fff;
  height: auto;
  box-shadow: 0px 20px 20px 0px #0000000d;
  .--title-total,
  .--title-discount {
    font-size: 14px;
    font-weight: 500;
  }
  .--title-payment {
    font-size: 16px;
    font-weight: 500;
  }
  .--dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #000000;
    margin-right: 9px;
  }
  .--time {
    font-size: 14px;
    font-weight: 600;
  }
  .--name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .container-list-item-card {
    max-height: 677px;
    overflow: auto;
    @media (max-width: ${_ => TABLET_WIDTH}px) {
      max-height: none;
      /* height: unset; */
      overflow: visible;
    }
    ::-webkit-scrollbar {
      width: 6px; /* Width of the scrollbar */
    }

    /* Define the track (the area behind the thumb) */
    ::-webkit-scrollbar-track {
      background: transparent; /* Color of the track */
    }

    /* Define the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background: #ececec; /* Color of the thumb */
      border-radius: 20px;
    }

    /* Define the scrollbar thumb on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #d6d6d6; /* Color of the thumb on hover */
    }
  }

  .--title-content,
  .--description {
    font-size: 14px;
    font-weight: 600;
  }

  .card .--price-discount,
  .total-price {
    color: #e92127;
    font-weight: 600;
    font-size: 16px;
  }

  .total-after-discount {
    color: #e92127;
    font-weight: 500;
    font-size: 14px;
  }

  .require {
    color: #e92127;
    margin-right: 4px;
  }

  .text-tax {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
  }

  .group-policy {
    margin-top: 32px;
    font-size: 14px;
    font-weight: 500;
    .--text-policy {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }

  .discount-price {
    color: #f8b117;
    font-weight: 500;
    font-size: 14px;
  }

  .card {
    height: 68px;
    border-radius: 5px;
    background-color: #fff8f8;
    padding: 10px 10px;
    position: relative;
    border: 1px solid #fdd7d7;
    padding: 14px 24px;
    margin: 14px 0px;
    display: flex;
    justify-content: center;
    .title-voucher {
      font-size: 14px;
      font-weight: 500;
      color: #132639;
      margin-right: 12px;
    }
  }

  .card::after {
    position: absolute;
    content: '';
    height: 16px;
    right: -8px;
    border-radius: 8px;
    z-index: 1;
    top: 26px;
    background-color: #ffffff;
    width: 16px;
    border-left: 1px solid #fdd7d7;
  }

  .card::before {
    position: absolute;
    content: '';
    height: 16px;
    left: -8px;
    border-radius: 8px;
    z-index: 1;
    top: 26px;
    background-color: #ffffff;
    width: 16px;
    border-right: 1px solid #fdd7d7;
  }
  .item-card {
    background-color: #fab317;
    border-radius: 10px;
    padding: 1px;
    margin: 24px 0px;
    .--title-card {
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      margin: 20px 11px;
      line-height: 26px;
    }
    .--content {
      padding: 24px 11px;
      background-color: #fff;
      border-radius: 10px;
    }
    .--price-card {
      color: #e92127;
    }
    .--row-key {
      font-size: 14px;
      font-weight: 400;
    }
    .--row-value {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }
    @media (max-width: ${_ => TABLET_WIDTH}px) {
      margin: 0px 8px;
    }
  }
  .form-inputNumber {
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: -6px;
    .ant-input-number-input {
      font-size: 14px;
      background-color: #fff;
    }
    .ant-input-number-group-addon {
      line-height: 22px;
      background: #ffffff;
      i {
        color: #d9d9d9 !important;
      }
    }
    .ant-input-number {
      max-width: 70px;
    }
  }
`;
