import { Checkbox, Divider, Row } from 'antd';
import ModalContainer from 'app/components/ModalContainer';
import StyledButton from 'app/components/StyledButton';
import useWindowSize from 'app/hooks/useWindowSize';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import ColPolicyInfo from 'app/pages/Checkout/components/ColPolicyInfo';
import { getPolicyPages } from 'app/services/appApi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DEFAULT_LANGUAGE, TYPE_COOKIE } from 'utils/constants';
import { getCookie } from 'utils/cookies';
import { escapeHTML } from 'utils/helper';

const PolicyModal = ({
  open,
  onClose,
  info,
  onSubmit,
}: {
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  info?: {
    email: string;
    phone: string;
    name: string;
  };
}) => {
  const [isAccept, setIsAccept] = useState(false);
  const translations = useSelector(selectTranslations);
  const [termTxt, setTermTxt] = useState('');
  const { device } = useWindowSize();
  useEffect(() => {
    const getPolicies = async () => {
      const { data } = await getPolicyPages();
      setTermTxt(data?.policy?.payment_detail_content_policy);
    };
    getPolicies();
  }, []);
  return (
    <ModalContainer
      isShow={open}
      onCloseDialog={onClose}
      maskClosable={false}
      modalProps={{
        width: 'auto',
      }}
      styleSlots={{
        modalContentStyle: {
          width:
            device === 'desktop'
              ? '636px'
              : device === 'tablet'
              ? '470px'
              : 'auto',
          maxWidth:
            device === 'desktop'
              ? '636px'
              : device === 'tablet'
              ? '470px'
              : 'auto',
          height: 'auto',
          padding: device === 'desktop' ? '20px' : '8px',
        },
        bodyStyle: {
          overflow: 'auto',
          padding: undefined,
          marginRight: '-42px',
          marginLeft: '-42px',
        },
      }}
    >
      <HeaderContainer>
        <Title>{translations ? translations['b2c.txt.policyTitle'] : ''}</Title>
      </HeaderContainer>
      <Description>
        {translations ? translations['b2c.form-policy.description'] : ''}
      </Description>
      <Row>
        <ColPolicyInfo.ColPolicyInfoKey>
          {translations ? translations['b2c.form.namePlaceholder'] : ''}
        </ColPolicyInfo.ColPolicyInfoKey>
        <ColPolicyInfo.ColPolicyInfoValue>
          {info?.name ?? ''}
        </ColPolicyInfo.ColPolicyInfoValue>
      </Row>
      <StyledDivider dashed />
      <Row>
        <ColPolicyInfo.ColPolicyInfoKey>Email</ColPolicyInfo.ColPolicyInfoKey>
        <ColPolicyInfo.ColPolicyInfoValue>
          {info?.email ?? ''}
        </ColPolicyInfo.ColPolicyInfoValue>
      </Row>
      <StyledDivider dashed />
      <Row>
        <ColPolicyInfo.ColPolicyInfoKey>
          {translations ? translations['b2c.txt.phone'] : ''}
        </ColPolicyInfo.ColPolicyInfoKey>
        <ColPolicyInfo.ColPolicyInfoValue>
          {info?.phone ?? ''}
        </ColPolicyInfo.ColPolicyInfoValue>
      </Row>
      <StyledPolicy>
        <Policy>
          <SubHeaderContainer>
            <div style={{ textTransform: 'capitalize' }}>
              {translations ? translations['b2c.txt.paymentPolicy'] : ''}
            </div>
          </SubHeaderContainer>
          <Description style={{ textAlign: 'justify', marginRight: 8 }}>
            {termTxt && (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: termTxt,
                }}
              ></div>
            )}
          </Description>
        </Policy>
      </StyledPolicy>
      <Checkbox
        style={{ margin: '12px 0px' }}
        onChange={e => {
          setIsAccept(!isAccept);
        }}
      >
        {translations ? translations['b2c.txt.accept-policy'] : ''}
      </Checkbox>
      <StyledDivider
        style={{
          margin: '20px 0px 25px 0px',
        }}
        dashed
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledButton
          bgColor="white"
          onClick={onClose}
          border="1px solid #808080"
          textColor="#808080"
          height={'44px'}
          style={{ marginRight: 16 }}
        >
          <span style={{ marginRight: 4 }}>
            <i className="fa-solid fa-arrow-left"></i>
          </span>
          {translations ? translations['b2c.txt.back'] : ''}
        </StyledButton>
        <StyledButton
          disabled={!isAccept}
          onClick={onSubmit}
          border="none"
          textColor="#ffffff"
          height={'44px'}
        >
          {translations ? translations['b2c.txt.confirm'] : ''}
        </StyledButton>
      </div>
    </ModalContainer>
  );
};

export default PolicyModal;

const Policy = styled.div`
  background: #ffffff;
  overflow: auto;
  height: 251px;
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Define the track (the area behind the thumb) */
  ::-webkit-scrollbar-track {
    background: transparent; /* Color of the track */
  }

  /* Define the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #ececec; /* Color of the thumb */
    border-radius: 20px;
  }

  /* Define the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d6d6d6; /* Color of the thumb on hover */
  }
`;

const StyledPolicy = styled.div`
  border-radius: 20px;
  background: #ffffff;
  height: 301px;
  padding: 24px 20px;
  border: 1px solid #d9d9d9;
  margin-top: 12px;
`;

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
`;

const Description = styled.p`
  font-weight: 400;
  color: #1c1b1f;
  font-size: 14px;
  text-align: left;
  margin-top: 14px;
  line-height: 1.7rem;
`;

const HeaderContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: center;
`;
const SubHeaderContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: 14px 0px 14px 0px;
  width: 100%;
  min-width: 100%;
`;
