import moment from 'moment';
import {
  DateFromTo,
  FormatType,
  IDateFromToBuilder,
  IDateFromToFunction,
  Locale,
} from 'utils/date-utils/types';
import { formatDate } from 'utils/time';

export class DateFromToUtils extends DateFromTo implements IDateFromToFunction {
  private constructor(
    public locale: Locale,
    public dateOutputFormat: FormatType,
    public fromDate?: moment.Moment,
    public toDate?: moment.Moment,
  ) {
    super(locale, dateOutputFormat, fromDate, toDate);
  }
  getDateFromTo = () => {
    const fromDateString = this.fromDate
      ? formatDate(
          this.fromDate,
          this.locale,
          (_dayOfWeek, date) =>
            `${_dayOfWeek}, ${date.format(this.dateOutputFormat)}`,
        )
      : '';
    const toDateString = this.toDate
      ? formatDate(
          this.toDate,
          this.locale,
          (_dayOfWeek, date) =>
            `${_dayOfWeek}, ${date.format(this.dateOutputFormat)}`,
        )
      : '';
    const outFromDateString = this.fromDate
      ? this.fromDate.format(this.dateOutputFormat)
      : '';
    const outToDateString = this.toDate
      ? this.toDate.format(this.dateOutputFormat)
      : '';
    if (fromDateString && toDateString) {
      const concatFromToDate =
        outFromDateString !== outToDateString
          ? `${outFromDateString} - ${outToDateString}`
          : outFromDateString;
      const concatFromToDateWithDay =
        fromDateString !== toDateString
          ? `${fromDateString} - ${toDateString}`
          : fromDateString;
      return {
        fromDateString: outFromDateString,
        toDateString: outToDateString,
        concatFromToDate,
        concatFromToDateWithDay,
      };
    }
    return {
      fromDateString: outFromDateString,
      toDateString: outToDateString,
      concatFromToDate: outFromDateString || outToDateString,
      concatFromToDateWithDay: fromDateString || toDateString,
    };
  };

  static get Builder() {
    return class Builder extends DateFromTo implements IDateFromToBuilder {
      setDateOutputFormat(dateOutputFormat: FormatType): IDateFromToBuilder {
        this.dateOutputFormat = dateOutputFormat;
        return this;
      }
      setLocale(locale: Locale): IDateFromToBuilder {
        this.locale = locale;
        return this;
      }
      setFromDate(fromDate?: moment.Moment): IDateFromToBuilder {
        this.fromDate = fromDate;
        return this;
      }
      setToDate(toDate?: moment.Moment): IDateFromToBuilder {
        this.toDate = toDate;
        return this;
      }

      build(): IDateFromToFunction {
        return new DateFromToUtils(
          this.locale,
          this.dateOutputFormat,
          this.fromDate,
          this.toDate,
        );
      }
    };
  }
}
