/**
 *
 * Checkout
 *
 */
import React, { memo } from 'react';

import { I_PaymentMethod } from '../slice/types';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import Images from 'app/assets/img';

interface Props {
  listPaymentMethod: I_PaymentMethod[];
  paymentMethodActive: number;
  setPaymentMethodActive: React.Dispatch<React.SetStateAction<number>>;
}

export const PaymentMethods = memo(
  ({
    paymentMethodActive,
    listPaymentMethod,
    setPaymentMethodActive,
  }: Props) => {
    const translations = useSelector(selectTranslations);

    return (
      <>
        <div className="--infoPayment">
          <h5 className="--segTitle">
            {translations ? translations['b2c.txt.paymentMethod'] : ''}
          </h5>
          <div className="d-flex align-items-center">
            {listPaymentMethod.map(item => (
              <button
                key={item.id}
                className={`itemPaymentMethod d-flex-inline ${
                  paymentMethodActive === item.id ? 'active' : ''
                }`}
                onClick={() => setPaymentMethodActive(item.id)}
              >
                {/* <img src={item.image?.original} alt="" /> */}
                <img src={item.image.original} alt="" />
                {/* <span>{item.name}</span> */}
              </button>
            ))}
          </div>
        </div>

        {listPaymentMethod
          .filter(item => item.id === paymentMethodActive)
          .map(item => (
            <article
              key={item.id}
              className="my-4"
              dangerouslySetInnerHTML={{
                __html: item.description || '',
              }}
            />
          ))}
      </>
    );
  },
);
