import { Pagination, PaginationProps } from 'antd';
import Images from 'app/assets/img';
import React from 'react';
import styled from 'styled-components';

const CustomPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  // Style for unselected pages
  .ant-pagination-item {
    border-color: transparent;
    border-radius: 50px;
    color: #808080;

    &:hover {
      border-color: #e92127;

      a {
        color: #e92127;
      }
    }
  }

  // Style for the selected page
  .ant-pagination-item-active {
    font-weight: bold;
    color: transparent;
    border-color: #e92127;
    border-radius: 50px;

    a {
      background-color: transparent;
      color: #e92127;
    }
  }
`;

const StyledPagination = (props: PaginationProps) => {
  const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: React.ReactNode,
  ) => {
    if (type === 'prev') {
      return (
        <div
          style={{
            padding: 1,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Images.PrevPageIcon} alt="" />
        </div>
      );
    } else if (type === 'next') {
      return (
        <div
          style={{
            padding: 1,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Images.NextPageIcon} alt="" />
        </div>
      );
    } else if (type === 'jump-prev' || type === 'jump-next') {
      return <div>. . .</div>;
    }
    return element; // For page numbers, return the original element
  };
  return <CustomPagination itemRender={itemRender} {...props} />;
};

export default StyledPagination;
