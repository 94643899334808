/**
 *
 * OrderOverview
 *
 */
import { ReactComponent as EmptyIcon } from 'app/assets/img/empty.svg';
import StyledButton from 'app/components/StyledButton';
import { selectTranslations } from 'app/pages/App/slice/selectors';
import { I_CheckoutCartItem } from 'app/pages/Cart/slice/types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatNumber } from 'utils/helper';
import { InputNumberContainer } from '../InputNumberContainer/Loadable';
import { CheckoutOrderOverviewWrapper } from './styled';
import useCountDown from 'utils/hooks/useCountDown';
import { getDateStringForValidDate, parseSecondsToMinutes } from 'utils/time';
import {
  GroupDatas,
  MATERRIAL_PARK_TYPE,
} from 'app/pages/Checkout/hooks/useCheckout';
import { Carousel, Divider } from 'antd';
import { CheckoutDetail } from 'app/pages/Checkout/slice/types';
import moment from 'moment';
import styled from 'styled-components';
import useWindowSize from 'app/hooks/useWindowSize';
import { LoadingOutlined } from '@ant-design/icons';
import { getPaymentMethodApi } from 'app/services/checkoutApi';
import { isNil } from 'lodash';
interface Props {
  listCart: I_CheckoutCartItem[];
  listGroupCart: GroupDatas[];
  totalAmount: number;
  totalPrice: number;
  totalPriceOriginal: number;
  totalDiscount: number;

  loadingCheckout?: boolean;
  onCheckout: () => void;
  onGoBack?: () => void;
  onTimeout?: () => void;
  orderCheckoutRef?: React.MutableRefObject<CheckoutDetail | undefined>;
  vouchers?: Partial<I_CheckoutCartItem>[];
}

const Countdown = ({
  onTimeout,
  orderCheckoutRef,
  timeCountdown,
}: {
  onTimeout?: () => void;
  orderCheckoutRef?: React.MutableRefObject<CheckoutDetail | undefined>;
  timeCountdown: number;
}) => {
  const translations = useSelector(selectTranslations);
  const expiredAt =
    moment(
      orderCheckoutRef?.current?.orderdate?.datetimeDMYHIS ?? undefined,
      'HH:mm:ss DD/MM/YYYY',
    ).valueOf() +
    timeCountdown * 1000;
  let time =
    expiredAt -
    moment(
      orderCheckoutRef?.current?.startTime?.datetimeDMYHIS ?? undefined,
      'HH:mm:ss DD/MM/YYYY',
    ).valueOf();
  time = time < 0 ? 0 : time;
  const { timeRemaining } = useCountDown({
    time,
    interval: 1000,
    startTime: moment(
      orderCheckoutRef?.current?.orderdate?.datetimeDMYHIS ?? undefined,
      'HH:mm:ss DD/MM/YYYY',
    ).valueOf(),
  });
  const { minutes, seconds } = parseSecondsToMinutes(timeRemaining / 1000);
  useEffect(() => {
    if (timeRemaining <= 0) {
      onTimeout && onTimeout();
    }
  }, [timeRemaining, onTimeout]);
  const antIcon = useMemo(
    () => <LoadingOutlined style={{ fontSize: 16, color: '#E92127' }} spin />,
    [],
  );
  return (
    <h5 className="--time mb-3">
      {translations ? translations['b2c.txt.payIn'] : ''}{' '}
      {timeRemaining === 15 * 60 * 1000 ? (
        antIcon
      ) : (
        <span style={{ color: '#E92127' }}>{`${
          minutes < 10 ? '0' : ''
        }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</span>
      )}
    </h5>
  );
};

export const CheckoutOrderOverview = memo(
  ({
    listGroupCart,
    totalAmount,
    totalPrice,
    totalDiscount,
    totalPriceOriginal,

    loadingCheckout = false,
    onTimeout,
    onCheckout,
    onGoBack,
    orderCheckoutRef,
    vouchers = [],
    listCart,
  }: Props) => {
    // const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
    const [isActivePanel, setIsActivePanel] = useState(false);

    const translations = useSelector(selectTranslations);
    const [timeCountdown, setTimeCountdown] = useState<number>();
    // const listCart = useSelector(selectListCart);
    const onToggleActivePanel = () => setIsActivePanel(prev => !prev);
    const { pathname } = useLocation();
    const { device } = useWindowSize();
    const getDate = (fromDate: string, toDate: string) => {
      if (fromDate === toDate) {
        return fromDate;
      }
      return `${fromDate} - ${toDate}`;
    };
    useEffect(() => {
      const fetchTimeCountdown = async () => {
        const data = await getPaymentMethodApi();

        setTimeCountdown(
          data.data?.expired_time_b2c
            ? parseInt(data.data?.expired_time_b2c)
            : undefined,
        );
      };
      fetchTimeCountdown();
    }, []);
    return (
      <CheckoutOrderOverviewWrapper className={isActivePanel ? 'active' : ''}>
        <div className="cartSummary">
          {listGroupCart && timeCountdown && listGroupCart.length > 0 && (
            <Countdown
              onTimeout={onTimeout}
              timeCountdown={timeCountdown}
              orderCheckoutRef={orderCheckoutRef}
            />
          )}
          <h5 className="--name mb-3" onClick={onToggleActivePanel}>
            {translations ? translations['b2c.txt.order'] : ''} ({totalAmount})
          </h5>
          <div className="container-item-card">
            {device === 'desktop' ? (
              <>
                {listGroupCart?.length > 0 ? (
                  <>
                    <div className="container-list-item-card">
                      {listGroupCart.map(itemCard => {
                        return (
                          <>
                            <div className="item-card">
                              <div className="--title-card">
                                {itemCard?.park?.name}
                              </div>
                              <div className="--content">
                                {Object.values(itemCard.catalogs).map(
                                  (val, idx) => (
                                    <>
                                      <div className="--title-content mb-4">
                                        {val?.catalog?.name}
                                      </div>
                                      {val.items
                                        .sort(
                                          (a, b) =>
                                            -(a.isGift ? 1 : 0) +
                                            (b.isGift ? 1 : 0),
                                        )
                                        .map((item, idx) => (
                                          <>
                                            <div className="--description mb-4">
                                              {item?.show?.name ?? ''}
                                            </div>
                                            <div className="d-flex justify-content-between mb-4">
                                              <div className="--row-key">
                                                {translations
                                                  ? translations[
                                                      'b2c.txt.date-use'
                                                    ]
                                                  : ''}
                                              </div>
                                              <div className="--row-value">
                                                {translations
                                                  ? translations[
                                                      'b2c.txt.use-from'
                                                    ]
                                                  : ''}{' '}
                                                {item.date}
                                              </div>
                                            </div>
                                            {!isNil(
                                              item?.product?.usedArea2Object,
                                            ) && (
                                              <div className="d-flex justify-content-between mb-4">
                                                <div className="--row-key">
                                                  {item?.show?.usedArea2Label ??
                                                    ''}
                                                </div>
                                                <div className="--row-value">
                                                  {
                                                    item?.product
                                                      ?.usedArea2Object?.label
                                                  }
                                                </div>
                                              </div>
                                            )}
                                            {item?.zone?.name && (
                                              <div className="d-flex justify-content-between mb-4">
                                                <div className="--row-key">
                                                  {translations
                                                    ? translations[
                                                        'b2c.txt.zone'
                                                      ]
                                                    : ''}
                                                </div>
                                                <div className="--row-value">
                                                  {item?.zone?.name}
                                                </div>
                                              </div>
                                            )}
                                            {item?.restaurant?.name && (
                                              <div className="d-flex justify-content-between mb-4">
                                                <div className="--row-key">
                                                  {translations
                                                    ? translations[
                                                        'b2c.text.restaurants'
                                                      ]
                                                    : ''}
                                                </div>
                                                <div className="--row-value">
                                                  {item?.restaurant?.name}
                                                </div>
                                              </div>
                                            )}
                                            <div className="d-flex justify-content-between mb-4">
                                              <div className="--row-key">
                                                {translations
                                                  ? translations[
                                                      'b2c.txt.price-ticket'
                                                    ]
                                                  : ''}
                                              </div>
                                              <div className="--price-card --row-value">{`${formatNumber(
                                                item.originalPrice,
                                              )}đ`}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-4 align-items-center">
                                              <div className="--row-key">
                                                {translations
                                                  ? translations[
                                                      'b2c.txt.total-amount'
                                                    ]
                                                  : ''}
                                              </div>
                                              <div className="form-inputNumber">
                                                <InputNumberContainer
                                                  disabled
                                                  value={+item?.amount || 0}
                                                  onChange={() => {}}
                                                />
                                              </div>
                                              <div className="--price-card --row-value">
                                                {' '}
                                                {`${formatNumber(item.price)}đ`}
                                              </div>
                                            </div>
                                            {idx < val.items.length - 1 && (
                                              <Divider dashed />
                                            )}
                                          </>
                                        ))}
                                      {idx <
                                        Object.values(itemCard.catalogs)
                                          .length -
                                          1 && <Divider dashed />}
                                    </>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="d-flex">
                      <div className="text-tax require">
                        {translations ? translations['b2c.txt.note-date'] : ''}
                      </div>
                    </div>
                    <div className="box-total">
                      {/* {pathname.includes('checkout') && (
                    <>
                      <div className="card">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="title-voucher">
                            Voucher giảm 100K cho đơn hàng từ 4 triệu
                          </div>
                          <div className="--price-discount">-100.000đ</div>
                        </div>
                      </div>
                    </>
                  )} */}
                      {vouchers.map((voucher, idx) => (
                        <div
                          key={
                            voucher.product?.id
                              ? `${voucher.product?.id}`
                              : `${idx}`
                          }
                          className="card"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="title-voucher">
                              {voucher.product?.name ?? ''}
                            </div>
                            {/* <div className="--price-discount">-100.000đ</div> */}
                          </div>
                        </div>
                      ))}
                      <div className="d-flex justify-content-between mt-4 mb-2">
                        <div className="--title-total">
                          {translations
                            ? translations['b2c.txt.total-amount']
                            : ''}
                        </div>
                        <div className="total-after-discount">
                          {formatNumber(totalPriceOriginal)}đ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="--title-discount">
                          {translations ? translations['b2c.txt.discount'] : ''}
                        </div>
                        <div className="discount-price">
                          {formatNumber(-totalDiscount)}đ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <div className="--title-payment">
                          {translations ? translations['b2c.txt.payment'] : ''}
                        </div>
                        <div className="total-price">
                          {formatNumber(totalPrice)}đ
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="require">{`(*)`}</div>
                        <div className="text-tax">
                          {translations
                            ? translations['b2c.txt.VAT-included']
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex mt-3 justify-content-between">
                        <div
                          className="d-flex justify-content-between align-items-center mr-2"
                          style={{ flex: 1, marginRight: 7, display: 'flex' }}
                        >
                          <StyledButton
                            textColor="#808080"
                            bgColor="white"
                            style={{ flex: 1, width: 'auto' }}
                            border="1px solid #808080"
                            onClick={onGoBack}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                            <span
                              className="--textBtn"
                              style={{
                                marginLeft: 8,
                              }}
                            >
                              {translations ? translations['b2c.txt.back'] : ''}
                            </span>
                          </StyledButton>
                          {/* <button
                        // disabled={loadingCheckout || !cartLocal.size}
                        className="btn btn-goBack"
                        // onClick={onCheckout}
                      >
                        
                      </button> */}
                        </div>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ flex: 1, marginLeft: 7 }}
                        >
                          <StyledButton
                            style={{ flex: 1, width: 'auto' }}
                            textColor="white"
                            onClick={onCheckout}
                          >
                            <span
                              className="--textBtnNext"
                              style={{
                                marginRight: 8,
                              }}
                            >
                              {translations
                                ? translations['b2c.txt.continue']
                                : ''}
                            </span>
                            <i className="fa-solid fa-arrow-right"></i>
                          </StyledButton>
                          {/* <button
                        // disabled={loadingCheckout || !cartLocal.size}
                        className="btn  btn-goCheckout"
                        onClick={onCheckout}
                      >
                        
                      </button> */}
                        </div>
                      </div>
                      <div className="group-policy">
                        <div
                          className="--text-policy"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {translations
                            ? translations['b2c.txt.policy-title']
                            : ''}
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-1-description']
                              : ''}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-2-description']
                              : ''}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-3-description']
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="--empty d-flex align-items-center justify-content-center mb-3">
                    <EmptyIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {listCart?.length > 0 ? (
                  <>
                    <div className="container-list-item-card">
                      <ListTicketCarousel
                        infinite={false}
                        dots={{ className: 'ticket-dots' }}
                      >
                        {listCart
                          .sort(
                            (a, b) => -(a.isGift ? 1 : 0) + (b.isGift ? 1 : 0),
                          )
                          .map((itemCard, index) => {
                            return (
                              <>
                                <div className="item-card" key={index}>
                                  <div className="--title-card">
                                    {itemCard?.park?.name}
                                  </div>
                                  <div className="--content">
                                    <div className="--title-content mb-4">
                                      {itemCard?.catalog?.name}
                                    </div>
                                    <>
                                      <div className="--description mb-4">
                                        {itemCard?.show?.name ?? ''}
                                      </div>
                                      <div className="d-flex justify-content-between mb-4">
                                        <div className="--row-key">
                                          {translations
                                            ? translations['b2c.txt.date-use']
                                            : ''}
                                        </div>
                                        <div className="--row-value">
                                          {translations
                                            ? translations['b2c.txt.use-from']
                                            : ''}{' '}
                                          {itemCard.date}
                                          {/* {itemCard.date} */}
                                        </div>
                                      </div>
                                      {!isNil(
                                        itemCard?.product?.usedArea2Object,
                                      ) && (
                                        <div className="d-flex justify-content-between mb-4">
                                          <div className="--row-key">
                                            {itemCard?.show?.usedArea2Label ??
                                              ''}
                                          </div>
                                          <div className="--row-value">
                                            {
                                              itemCard?.product?.usedArea2Object
                                                ?.label
                                            }
                                          </div>
                                        </div>
                                      )}
                                      {itemCard?.zone?.name && (
                                        <div className="d-flex justify-content-between mb-4">
                                          <div className="--row-key">
                                            {translations
                                              ? translations['b2c.txt.zone']
                                              : ''}
                                          </div>
                                          <div className="--row-value">
                                            {itemCard?.zone?.name}
                                          </div>
                                        </div>
                                      )}
                                      {itemCard?.restaurant?.name && (
                                        <div className="d-flex justify-content-between mb-4">
                                          <div className="--row-key">
                                            {translations
                                              ? translations[
                                                  'b2c.text.restaurants'
                                                ]
                                              : ''}
                                          </div>
                                          <div className="--row-value">
                                            {itemCard?.restaurant?.name}
                                          </div>
                                        </div>
                                      )}
                                      <div className="d-flex justify-content-between mb-4">
                                        <div className="--row-key">
                                          {translations
                                            ? translations[
                                                'b2c.txt.price-ticket'
                                              ]
                                            : ''}
                                        </div>
                                        <div className="--price-card --row-value">{`${formatNumber(
                                          itemCard.originalPrice,
                                        )}đ`}</div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-4 align-items-center">
                                        <div className="--row-key">
                                          {translations
                                            ? translations[
                                                'b2c.txt.total-amount'
                                              ]
                                            : ''}
                                        </div>
                                        <div className="form-inputNumber">
                                          <InputNumberContainer
                                            disabled
                                            value={+itemCard?.amount || 0}
                                            onChange={() => {}}
                                          />
                                        </div>
                                        <div className="--price-card --row-value">
                                          {' '}
                                          {`${formatNumber(itemCard.price)}đ`}
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </ListTicketCarousel>
                    </div>
                    <div className="box-total">
                      <div
                        className="d-flex justify-content-between mb-2"
                        style={{
                          marginTop: 36,
                        }}
                      >
                        <div className="text-tax require">
                          {translations
                            ? translations['b2c.txt.note-date']
                            : ''}
                        </div>
                      </div>
                      {/* {pathname.includes('checkout') && (
                    <>
                      <div className="card">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="title-voucher">
                            Voucher giảm 100K cho đơn hàng từ 4 triệu
                          </div>
                          <div className="--price-discount">-100.000đ</div>
                        </div>
                      </div>
                    </>
                  )} */}
                      {vouchers.map((voucher, idx) => (
                        <div
                          key={
                            voucher.product?.id
                              ? `${voucher.product?.id}`
                              : `${idx}`
                          }
                          className="card"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="title-voucher">
                              {voucher.product?.name ?? ''}
                            </div>
                            {/* <div className="--price-discount">-100.000đ</div> */}
                          </div>
                        </div>
                      ))}
                      <div className="d-flex justify-content-between mt-4 mb-2">
                        <div className="--title-total">
                          {translations
                            ? translations['b2c.txt.total-amount']
                            : ''}
                        </div>
                        <div className="total-after-discount">
                          {formatNumber(totalPriceOriginal)}đ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="--title-discount">
                          {translations ? translations['b2c.txt.discount'] : ''}
                        </div>
                        <div className="discount-price">
                          {formatNumber(-totalDiscount)}đ
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <div className="--title-payment">
                          {translations ? translations['b2c.txt.payment'] : ''}
                        </div>
                        <div className="total-price">
                          {formatNumber(totalPrice)}đ
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="require">{`(*)`}</div>
                        <div className="text-tax">
                          {translations
                            ? translations['b2c.txt.VAT-included']
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex mt-3 justify-content-between">
                        <div
                          className="d-flex justify-content-between align-items-center mr-2"
                          style={{ flex: 1, marginRight: 7 }}
                        >
                          <StyledButton
                            textColor="#808080"
                            bgColor="white"
                            style={{ flex: 1 }}
                            border="1px solid #808080"
                            onClick={onGoBack}
                          >
                            <i className="fa-solid fa-arrow-left"></i>
                            <span
                              className="--textBtn"
                              style={{
                                marginLeft: 8,
                              }}
                            >
                              {translations ? translations['b2c.txt.back'] : ''}
                            </span>
                          </StyledButton>
                          {/* <button
                        // disabled={loadingCheckout || !cartLocal.size}
                        className="btn btn-goBack"
                        // onClick={onCheckout}
                      >
                        
                      </button> */}
                        </div>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ flex: 1, marginLeft: 7 }}
                        >
                          <StyledButton
                            style={{ flex: 1 }}
                            textColor="white"
                            onClick={onCheckout}
                          >
                            <span
                              className="--textBtnNext"
                              style={{
                                marginRight: 8,
                              }}
                            >
                              {translations
                                ? translations['b2c.txt.continue']
                                : ''}
                            </span>
                            <i className="fa-solid fa-arrow-right"></i>
                          </StyledButton>
                          {/* <button
                        // disabled={loadingCheckout || !cartLocal.size}
                        className="btn  btn-goCheckout"
                        onClick={onCheckout}
                      >
                        
                      </button> */}
                        </div>
                      </div>
                      <div className="group-policy">
                        <div
                          className="--text-policy"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {translations
                            ? translations['b2c.txt.policy-title']
                            : ''}
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-1-description']
                              : ''}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-2-description']
                              : ''}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div className="--dot"></div>
                          <div>
                            {translations
                              ? translations['b2c.txt.policy-3-description']
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="--empty d-flex align-items-center justify-content-center mb-3">
                    <EmptyIcon />
                  </div>
                )}
              </>
            )}
          </div>

          {/* <div className="--btnGroup">
            <button
              disabled={loadingCheckout || !cartLocal.size}
              className="btn btnPrimary btn-goCheckout"
              onClick={onCheckout}
            >
              <span>{translations ? translations['b2c.txt.pay'] : ''}</span>
            </button>
          </div> */}
        </div>
      </CheckoutOrderOverviewWrapper>
    );
  },
);

const ListTicketCarousel = styled(Carousel)`
  .slick-dots {
    width: unset;
    /* button:before {
      content: '';
      width: 25px;
      height: 5px;
      border-radius: 10px;
      background: #fab317;
    } */
    /* .slick-active {
      button:before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #c1c1c1;
      }
    } */
  }
  .ticket-dots {
    margin-left: 0;
    margin-right: 0;
    li {
      background: #c1c1c1;
      width: initial;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      overflow: hidden;
      button {
        opacity: 0 !important;
        height: 5px;
        width: 5px;
        background: #c1c1c1;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        width: 25px;
        background: #fab317;
        border-radius: 99px;
      }
    }
  }
  .slick-dots-bottom {
    bottom: -30px;
    z-index: 1;
  }
`;
