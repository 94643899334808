/**
 *
 * PaymentCallback
 *
 */
import { Col, Row } from 'antd';
import { LoadingContainer } from 'app/components/LoadingContainer/Loadable';
import StyledButton from 'app/components/StyledButton';
import News from 'app/pages/Home/components/News';
import { PaymentSuccess } from 'app/pages/NewPaymentCallback/components/Loadable';
import { PaymentFail } from 'app/pages/NewPaymentCallback/components/PaymentFail';
import { getDetailOrderApi, updatePaymentApi } from 'app/services/checkoutApi';
import { getNewApi } from 'app/services/showApi';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { I_Order } from '../Account/ListOrder/slice/types';
import { selectIsLogged, selectTranslations } from '../App/slice/selectors';
import { PaymentCallbackWrapper } from './styled';
import useWindowSize from 'app/hooks/useWindowSize';

interface Props {}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const NewPaymentCallback = memo((props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const translations = useSelector(selectTranslations);
  const [listNew, setListNew] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(true);
  const [detailOrder, setDetailOrder] = useState<I_Order | undefined>(
    undefined,
  );

  // const isLogged = useSelector(selectIsLogged);
  const { device } = useWindowSize();

  const getDetailOrder = useCallback(async (orderId: string) => {
    setLoading(true);
    const { data } = await getDetailOrderApi(orderId, false);
    setDetailOrder(data);
    if (data?.payment?.code === 'ncb') {
      if (
        data?.orderState?.value === 'success' ||
        data?.orderState?.value === 'SyncVGS'
      ) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    }
    setLoading(false);
  }, []);

  const onGoHome = () => history.push('/');

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const orderId = search.get('vnp_TxnRef');
    const resultCode = search.get('vnp_ResponseCode');
    const bnPaymentType = search.get('bnPaymentType');
    if (bnPaymentType === 'Payoo') {
      const resultStatus = search.get('status');
      setIsSuccess(resultStatus === '1');
    } else if (bnPaymentType !== 'ncb') {
      setIsSuccess(resultCode === '00');
    }
    if (orderId) {
      getDetailOrder(orderId);
    }
    updatePaymentApi(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const getDataNews = async () => {
      const { data } = await getNewApi(null);
      setListNew(data?.data ?? []);
    };
    getDataNews();
  }, []);

  const clickSeeDetailNew = item => {
    if (item?.newLink) {
      window.open(item?.newLink, '_blank');
    } else {
      history.replace(`/catalog`);
    }
  };

  return (
    <LoadingContainer isFullPage loading={loading}>
      {detailOrder && (
        <>
          <Row justify="center">
            <Col span={device === 'desktop' ? 12 : 22}>
              {isSuccess !== null && (
                <PaymentCallbackWrapper id="pay" className="main">
                  {isSuccess ? (
                    <PaymentSuccess detailOrder={detailOrder} />
                  ) : (
                    <PaymentFail detailOrder={detailOrder} />
                  )}
                </PaymentCallbackWrapper>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StyledButton
                textColor="#808080"
                bgColor="white"
                style={{
                  width: 'auto',
                  fontSize: 16,
                  fontWeight: 600,
                  borderRadius: 5,
                }}
                border="1px solid #808080"
                onClick={onGoHome}
              >
                <i className="fa-solid fa-arrow-left"></i>
                <span className="--textBtn" style={{ marginLeft: 8 }}>
                  {' '}
                  {translations ? translations['b2c.txt.back-to-home'] : ''}
                </span>
              </StyledButton>
            </Col>
          </Row>
        </>
      )}
      <MapNNewsWrapper>
        <div
          style={{
            width: device === 'desktop' ? '75vw' : '90vw',
          }}
        >
          {listNew.length > 0 && (
            <News
              listNew={listNew}
              slidesToShow={device === 'desktop' ? 2 : 1}
            />
          )}
        </div>
      </MapNNewsWrapper>
    </LoadingContainer>
  );
});

const MapNNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;
