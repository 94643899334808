import { I_PayloadGetShows } from 'app/pages/Catalog/slice/types';
import { CancelToken } from 'axios';
import { BaseXHR } from 'utils/axios';

export const getShowApi = (payload: I_PayloadGetShows) =>
  BaseXHR.$get('show/listing', payload);
export const getShowNewApi = (payload: I_PayloadGetShows) =>
  BaseXHR.$get('show/list-show', payload);
export const getCatalogApi = (id: any) => BaseXHR.$get(`catalog/listing`);
export const getDetailShowApi = (id: number) =>
  BaseXHR.$get(`show/detail/${id}`);
export const getNewApi = (payload: any) =>
  BaseXHR.$get('news/listing', payload);
export const getPromotionParkApi = (payload: any) =>
  BaseXHR.$get(`promotions/ticket`, payload);
export const getPromotionApi = (payload: any) =>
  BaseXHR.$get('promotions/listing', payload);
export const getPriceCartApi = (data: FormData) =>
  BaseXHR.$post('cart/get-price', data);
export const getRestaurantPerformanceListApi = (data: FormData) =>
  BaseXHR.$post('show/performances-by-restaurant', data);

export const getUsedArea2 = (
  data: FormData,
  config?: { cancelToken: CancelToken },
) => BaseXHR.$post('show/get-used-area2', data, config);
