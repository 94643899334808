import { objectType } from 'types';
import { BaseXHR } from 'utils/axios';

export const getHomePageApi = (payload?: objectType) =>
  BaseXHR.$get('b2c/page/home', payload);

export const getListParkApi = (payload?: objectType) =>
  BaseXHR.$get('park/listing', payload);

export const getListShow = () => BaseXHR.$get(`show/listing`);

export const getDetailShowApi = (id: number) =>
  BaseXHR.$get(`show/default-layout/${id}`);

export const getValidZonesApi = (params: objectType) =>
  BaseXHR.$get(`zone/get-valid-zones`, params);

export const selectZoneApi = (params: objectType) =>
  BaseXHR.$get(`zone/get-layout`, params);

export const createCartApi = (data: FormData) =>
  BaseXHR.$post(`checkout/create-cart`, data);

export const lockSeatApi = (data: FormData) => BaseXHR.$post(`seat/lock`, data);

export const unlockSeatApi = (data: FormData) =>
  BaseXHR.$post(`seat/unlock`, data);

export const updatePaymentApi = (params: string) =>
  BaseXHR.$get(`payment/vnp-callback${params}`);
