/**
 *
 * Checkout
 *
 */
import { Divider, Form } from 'antd';
import { ReactComponent as CloseIcon } from 'app/assets/img/close_icon.svg';
import { LoadingContainer } from 'app/components/LoadingContainer';
import ModalContainer from 'app/components/ModalContainer';
import { CheckoutDetail } from 'app/pages/Checkout/slice/types';
import { getCheckoutDetailOrderApi } from 'app/services/checkoutApi';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { formatNumber } from 'utils/helper';
import {
  CountdownTimer,
  IntervalTimer,
  isTimeValid,
  parseSecondsToMinutes,
} from 'utils/time';
import { selectIsLogged, selectTranslations } from '../App/slice/selectors';
import { CheckoutWrapper } from './styled';
import useGetCountDownPayment from 'app/hooks/useGetCountDownPayment';
import { isNil, set } from 'lodash';
import moment from 'moment';
import { parse } from 'path';
import StyledButton from 'app/components/StyledButton';

interface Props {}

const replacePaymentWithSpan = (
  text: string,
  replaceText: string,
  replaceTo: string,
  style: React.CSSProperties = {},
) => {
  const parts = text?.split(replaceText);

  const newText = parts?.map((part, index) => (
    <>
      {part}
      {index < parts?.length - 1 && <span style={style}>{replaceTo}</span>}
    </>
  ));

  return <>{newText}</>;
};

export const NcbPayment = memo((props: Props) => {
  const translations = useSelector(selectTranslations);
  const isLogged = useSelector(selectIsLogged);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [order, setOrder] = useState<CheckoutDetail>();
  const [expiredHours, setExpiredHours] = useState<string>();
  const [qrImage, setQrImage] = useState<string>();
  const [isValidPaymentTime, setIsValidPaymentTime] = useState<boolean>(true);
  const intervalTimerRef = useRef<IntervalTimer>();
  const countDownTimerRef = useRef<CountdownTimer>();
  const { timeCountdown } = useGetCountDownPayment();
  const [countdownShow, setCountdownShow] = useState(false);
  const [countdownTime, setCountdownTime] = useState('');
  const history = useHistory();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const { id, qrImage: qrImageData } = location.state as {
          id: string;
          qrImage: string;
        };
        const { data: detailOrder } = await getCheckoutDetailOrderApi(id);
        const hourFrom = detailOrder?.payment?.validFrom;
        const hourTo = detailOrder?.payment?.validTo;
        const isValidPayment = isTimeValid(hourFrom, hourTo);
        setIsValidPaymentTime(!hourFrom && !hourTo ? true : isValidPayment);
        const formatValue = (value: number) => {
          return Number.isInteger(value) ? `${value}` : value.toFixed(1);
        };
        const expiredHours = detailOrder.payment?.expired_time
          ? formatValue(parseInt(detailOrder.payment?.expired_time) / 60 / 60)
          : undefined;
        setExpiredHours(expiredHours);
        setOrder(detailOrder);
        setQrImage(qrImageData);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [location.state]);

  useEffect(() => {
    if (isNil(timeCountdown) || isNil(order)) {
      return;
    }
    const minus =
      moment(
        order?.startTime?.datetimeDMYHIS ?? undefined,
        'HH:mm:ss DD/MM/YYYY',
      ).valueOf() -
      moment(
        order?.orderdate?.datetimeDMYHIS ?? undefined,
        'HH:mm:ss DD/MM/YYYY',
      ).valueOf();
    const countDownSecond = (timeCountdown * 1000 - minus) / 1000;

    if (countDownSecond <= 0) {
      setCountdownShow(true);
      return;
    }
    const ontickListener = (timeRemaining: number) => {
      const { minutes, seconds } = parseSecondsToMinutes(timeRemaining);
      setCountdownTime(
        `${minutes >= 10 ? '' : '0'}${minutes}:${
          seconds >= 10 ? '' : '0'
        }${seconds}`,
      );
    };
    const endListener = () => {
      setCountdownShow(true);
    };
    if (!countDownTimerRef.current) {
      countDownTimerRef.current = new CountdownTimer(
        parseInt(`${countDownSecond}`),
      );
      countDownTimerRef.current.start(1000, ontickListener);
    }
    countDownTimerRef.current.addListener(endListener);

    return () => {
      countDownTimerRef.current?.removeListener(endListener);
    };
  }, [timeCountdown, order]);

  useEffect(() => {
    return () => {
      countDownTimerRef.current?.stop();
    };
  }, []);

  useEffect(() => {
    const listener = async () => {
      const { id } = location.state as {
        id: string;
      };
      const { data: detailOrder } = await getCheckoutDetailOrderApi(id);
      if (detailOrder.orderState.value === 'paymentPending') {
        return;
      }
      intervalTimerRef.current?.stop();
      countDownTimerRef.current?.stop();
      if (detailOrder.orderState.value === 'success') {
        window.open(
          `/payment-callback?vnp_TxnRef=${detailOrder?.idHash}&bnPaymentType=${detailOrder.payment?.code}&result_code=1`,
          '_self',
        );
      } else {
        window.open(
          `/payment-callback?vnp_TxnRef=${detailOrder?.idHash}&bnPaymentType=${detailOrder.payment?.code}&result_code=0`,
          '_self',
        );
      }
    };
    if (!intervalTimerRef.current) {
      intervalTimerRef.current = new IntervalTimer(15000);
      intervalTimerRef.current.start();
    }
    intervalTimerRef.current.addListener(listener);

    return () => {
      intervalTimerRef.current?.removeListener(listener);
    };
  }, [location.state]);

  useEffect(() => {
    return () => {
      intervalTimerRef.current?.stop();
    };
  }, []);

  return (
    <CheckoutWrapper id="bookticketspay" className="main">
      <div className="bookticketspayContent bookticketsContent checkOut">
        <div className="container">
          <LoadingContainer isFullPage loading={loading}>
            <div className="row">
              <div className="col-xl-12">
                <>
                  <div className="checkOutInfo">
                    <HeaderContainer>
                      <div></div>
                      <Title>
                        {translations
                          ? translations[
                              'b2c.txt.create-order-number'
                            ]?.replaceAll('${number}', `${order?.id ?? ''}`)
                          : ''}
                      </Title>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'end',
                        }}
                      >
                        <h5
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {translations ? translations['b2c.txt.payIn'] : ''}{' '}
                          <span
                            style={{ color: '#E92127' }}
                          >{`${countdownTime}`}</span>
                        </h5>
                      </div>
                    </HeaderContainer>
                    <Description style={{ textAlign: 'center' }}>
                      <>
                        {translations
                          ? replacePaymentWithSpan(
                              translations['b2c.txt.ncb-payment-1'],
                              '${payment}',
                              order?.payment?.name ?? '',
                              { fontWeight: 'bold' },
                            )
                          : ''}{' '}
                        {translations
                          ? replacePaymentWithSpan(
                              translations['b2c.txt.ncb-payment-2'],
                              '${time}',
                              `${
                                Number.isInteger((timeCountdown ?? 0) / 60)
                                  ? `${(timeCountdown ?? 0) / 60}`
                                  : ((timeCountdown ?? 0) / 60).toFixed(1)
                              } ${
                                translations?.['b2c.txt.ncb-countdown-unit']
                              }`,
                              { color: '#E92127' },
                            )
                          : ''}
                      </>
                    </Description>
                  </div>
                  <div className="checkOutInfo">
                    {
                      <>
                        <HeaderContainer>
                          <div />
                          <Title style={{ textAlign: 'center' }}>
                            {translations
                              ? translations['b2c.txt.paymentGuide']
                              : ''}
                          </Title>
                          <div />
                        </HeaderContainer>
                        <Description>
                          {translations
                            ? replacePaymentWithSpan(
                                translations['b2c.txt.note-transfer-money-ncb'],
                                '${totalPrice}',
                                `${formatNumber(order?.totalPrice ?? 0)} đ` ??
                                  '',
                                { fontWeight: 'bold', color: '#E92127' },
                              )
                            : ''}
                        </Description>
                      </>
                    }
                    <div
                      className="--sub-title"
                      style={{ margin: '17px 0px', fontWeight: 'bold' }}
                    >
                      <span>{'('}</span>
                      <span style={{ color: 'red' }}>{'*'}</span>
                      <span>{')'}</span>{' '}
                      {translations
                        ? `${translations['b2c.txt.ncb-note-transfer-money']}`
                        : ''}
                    </div>
                    {
                      <>
                        <div className="d-flex txt-content">
                          {translations ? translations['b2b.txt.QR-code'] : ''}
                        </div>
                        <ImgWrapper>
                          <div className="d-flex justify-content-center">
                            <StyledImg src={qrImage} alt="" />
                          </div>
                        </ImgWrapper>
                      </>
                    }
                    {isLogged && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 16,
                        }}
                      >
                        <StyledButton
                          bgColor="white"
                          border="1px solid #808080"
                          textColor="#808080"
                          height={'44px'}
                          onClick={() => window.open('/account', '_self')}
                          style={{
                            marginRight: 16,
                            width: 'auto',
                            minWidth: 160,
                          }}
                        >
                          {translations
                            ? translations['b2c.txt.back-to-history']
                            : ''}
                        </StyledButton>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </LoadingContainer>
        </div>
      </div>
      <TimeoutModal
        open={countdownShow}
        onClose={() => {
          history.push('/payment-callback?vnp_TxnRef=' + order?.idHash);
        }}
      />
    </CheckoutWrapper>
  );
});

const TimeoutModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) => {
  const translations = useSelector(selectTranslations);
  return (
    <ModalContainer
      isShow={open}
      maskClosable={false}
      onCloseDialog={onClose}
      CloseIcon={<CloseIcon width={12} height={12} />}
      styleSlots={{
        iconWrapperStyle: {
          margin: '-8px 0px 0px 28px',
          width: 12,
          height: 12,
        },
        modalContentStyle: {
          height: 'auto',
          width: 'auto',
          padding: '38px 31px',
          borderRadius: '16px',
        },
        modalStyle: {
          width: 'auto',
          maxWidth: '280px',
        },
        bodyStyle: {
          width: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <div>{translations ? translations['b2c.txt.timeoutPayment'] : ''}</div>
      <div>{translations ? translations['b2c.txt.retry'] : ''}</div>
    </ModalContainer>
  );
};

const Policy = styled.div`
  background: #ffffff;
  overflow: auto;
  height: 600px;
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Define the track (the area behind the thumb) */
  ::-webkit-scrollbar-track {
    background: transparent; /* Color of the track */
  }

  /* Define the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #ececec; /* Color of the thumb */
    border-radius: 20px;
  }

  /* Define the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d6d6d6; /* Color of the thumb on hover */
  }
`;

const StyledPolicy = styled.div`
  border-radius: 20px;
  background: #ffffff;
  /* height: 301px; */
  padding: 24px 20px;
  border: 1px solid #d9d9d9;
  color: #000000;
  margin-top: 12px;
`;

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  color: #000000;
  font-size: 24px;
  text-transform: capitalize;
`;

const Description = styled.p`
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: left;
  margin-top: 14px;
  line-height: 1.7rem;
`;

const HeaderContainer = styled.div`
  margin: 10px 0px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  color: #000000;
`;
const SubHeaderContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: 14px 0px 14px 0px;
  width: 100%;
  min-width: 100%;
`;
const ImgWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`;
const StyledImg = styled.img`
  width: 250px;
  max-width: 100%;
  height: 'auto';
  display: 'flex';
  justify-content: 'center';
  object-fit: cover;
  &[src=''] {
    opacity: 0;
  }
`;
