import { appActions } from 'app/pages/App/slice';
import {
  selectDialogNotiState,
  selectIsShowDialogChangePass,
  selectIsShowDialogForgotPass,
  selectIsShowDialogLogin,
  selectIsShowDialogRegister,
  selectLanguage,
} from 'app/pages/App/slice/selectors';
import { DialogNotiState } from 'app/pages/App/slice/types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_COOKIE } from 'utils/constants';
import { setCookie } from 'utils/cookies';
import { LOCAL_STORAGE_KEY, setItem } from 'utils/localStorage';

export const useGlobalFunction = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLanguage);
  const isShowDialogLogin = useSelector(selectIsShowDialogLogin);
  const isShowDialogRegister = useSelector(selectIsShowDialogRegister);
  const isShowDialogForgotPass = useSelector(selectIsShowDialogForgotPass);
  const isShowDialogChangePass = useSelector(selectIsShowDialogChangePass);
  const dialogNotiState = useSelector(selectDialogNotiState);

  const onLogout = useCallback(() => {
    setItem(LOCAL_STORAGE_KEY.anonymousCartId, '');
    dispatch(appActions.logout());
  }, [dispatch]);

  const onChangeLanguage = useCallback(
    (language: 'vi' | 'en') => {
      dispatch(appActions.onChangeLanguage(language));
      window.location.reload();
    },
    [dispatch],
  );

  const onOpenDialogLogin = useCallback(() => {
    dispatch(appActions.openDialogLogin());
  }, [dispatch]);

  const onOpenDialogRegister = useCallback(() => {
    dispatch(appActions.openDialogRegister());
  }, [dispatch]);

  const onCloseDialogAuth = useCallback(() => {
    dispatch(appActions.closeDialogAuth());
  }, [dispatch]);

  const onLoginSuccess = useCallback(
    async token => {
      await setCookie(TYPE_COOKIE.TOKEN, token);
      dispatch(appActions.changeIsLogged({ isLogged: true }));
      onCloseDialogAuth();
    },
    [dispatch, onCloseDialogAuth],
  );

  const onOpenDialogForgotPass = useCallback(() => {
    dispatch(appActions.openDialogForgotPass());
  }, [dispatch]);

  const onOpenDialogChangePass = useCallback(() => {
    dispatch(appActions.openDialogChangePass());
  }, [dispatch]);

  const onOpenDialogNoti = useCallback(
    (newState: DialogNotiState) => {
      dispatch(appActions.openDialogNoti(newState));
    },
    [dispatch],
  );

  const onCloseDialogNoti = useCallback(() => {
    dispatch(appActions.closeDialogNoti());
  }, [dispatch]);

  return useMemo(
    () => ({
      locale,
      onChangeLanguage,

      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onCloseDialogAuth,
      isShowDialogForgotPass,
      isShowDialogChangePass,
      onOpenDialogForgotPass,
      onOpenDialogChangePass,
      onOpenDialogNoti,
      onCloseDialogNoti,
      dialogNotiState,
    }),
    [
      locale,
      onChangeLanguage,
      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onCloseDialogAuth,
      isShowDialogForgotPass,
      isShowDialogChangePass,
      onOpenDialogForgotPass,
      onOpenDialogChangePass,
      onOpenDialogNoti,
      onCloseDialogNoti,
      dialogNotiState,
    ],
  );
};
