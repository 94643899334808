import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import moment, { Moment } from 'moment';

import DatePickerComponent, {
  DatePickerComponentProps,
} from '../DatePickerComponent';
import { DateTimeObject, Weekend } from './types';
import { isValidDayOfWeek } from './helpers';

export interface Props extends DatePickerComponentProps {
  isComboDiffTicket: boolean;
  searchStartDate?: Moment;
  searchEndDate?: Moment;
  showDateFrom: DateTimeObject;
  showDateTo: DateTimeObject;
  weekend: Weekend | null;
  onDefaultValue?: (value: Moment) => void;
}

const TicketDateSelectable: React.FC<Props> = props => {
  const {
    isComboDiffTicket,
    searchStartDate,
    searchEndDate,
    showDateFrom,
    showDateTo,
    weekend,
    onDefaultValue,
    ...restProps
  } = props;
  const defaultValue = useRef<Moment | undefined>(undefined);
  const isInit = useRef(true);

  const minDateMoment = useMemo(() => {
    const possibleValues = [
      moment().startOf('day'),
      moment(showDateFrom.dateOrigin, 'YYYY-MM-DD').startOf('day'),
    ];
    return possibleValues.reduce((max, item) => {
      if (max.isBefore(item)) return item;
      return max;
    });
  }, [showDateFrom.dateOrigin]);

  const maxDateMoment = moment(
    isComboDiffTicket
      ? weekend?.holidays[0].holiday.dateOrigin
      : showDateTo.dateOrigin,
    'YYYY-MM-DD',
  ).startOf('day');

  const findValidDate = useCallback(
    (
      start: Moment,
      end: Moment,
      type: 'START_DATE' | 'END_DATE' = 'START_DATE',
    ) => {
      const isSearchStartDate = type === 'START_DATE';
      let dateToCheck = isSearchStartDate ? start.clone() : end.clone();
      do {
        if (!weekend) return dateToCheck;
        if (isValidDayOfWeek(dateToCheck, weekend)) return dateToCheck;
        isSearchStartDate
          ? dateToCheck.add(1, 'days')
          : dateToCheck.subtract(1, 'days');
      } while (dateToCheck.isBetween(start, end, 'day', '[]'));
    },
    [weekend],
  );

  const datesHighlight = useMemo(() => {
    if (!searchStartDate || !searchEndDate) return [];
    const isSearchStartDateInRange = searchStartDate
      .startOf('day')
      .isBetween(minDateMoment, maxDateMoment, 'day', '[]');
    const isSearchEndDateInRange = searchEndDate
      .startOf('day')
      .isBetween(minDateMoment, maxDateMoment, 'day', '[]');

    if (!isSearchStartDateInRange && !isSearchEndDateInRange) {
      if (
        searchStartDate.startOf('day').isBefore(minDateMoment) &&
        searchEndDate.startOf('day').isAfter(maxDateMoment)
      ) {
        const startDateValid = findValidDate(minDateMoment, maxDateMoment);
        const endDateValid = findValidDate(
          minDateMoment,
          maxDateMoment,
          'END_DATE',
        );
        if (startDateValid && endDateValid) {
          defaultValue.current = startDateValid;
          return [startDateValid, endDateValid];
        }
      }
      return [searchStartDate, searchEndDate];
    }
    let startDateValid: Moment | undefined = undefined;
    let endDateValid: Moment | undefined = undefined;
    switch (true) {
      case !isSearchStartDateInRange && isSearchEndDateInRange:
        startDateValid = findValidDate(minDateMoment, searchEndDate);
        endDateValid = findValidDate(minDateMoment, searchEndDate, 'END_DATE');
        break;
      case isSearchStartDateInRange && !isSearchEndDateInRange:
        startDateValid = findValidDate(searchStartDate, maxDateMoment);
        endDateValid = findValidDate(
          searchStartDate,
          maxDateMoment,
          'END_DATE',
        );
        break;
      case isSearchStartDateInRange && isSearchEndDateInRange:
      default:
        startDateValid = findValidDate(searchStartDate, searchEndDate);
        endDateValid = findValidDate(
          searchStartDate,
          searchEndDate,
          'END_DATE',
        );
    }
    if (startDateValid && endDateValid) {
      defaultValue.current = startDateValid;
      return [startDateValid, endDateValid];
    }
    return [searchStartDate, searchEndDate];
  }, [
    findValidDate,
    maxDateMoment,
    minDateMoment,
    searchEndDate,
    searchStartDate,
  ]);

  const disabledDate = (current: Moment) => {
    const isOutOfRange =
      current.isBefore(minDateMoment, 'day') ||
      current.isAfter(maxDateMoment, 'day');

    const isValidDate = !weekend ? true : isValidDayOfWeek(current, weekend);

    return isOutOfRange || !isValidDate;
  };

  const defaultPickerValue = useMemo(() => {
    if (defaultValue.current) return defaultValue.current;
    if (searchStartDate && searchEndDate) return searchStartDate;
  }, [searchEndDate, searchStartDate]);
  useEffect(() => {
    if (isInit.current && defaultValue.current && onDefaultValue) {
      defaultValue.current && onDefaultValue(defaultValue.current);
      isInit.current = false;
    }
  }, [onDefaultValue]);

  return (
    <DatePickerComponent
      allowClear={false}
      defaultValue={defaultValue.current}
      defaultPickerValue={defaultPickerValue}
      inputReadOnly
      disabledDate={disabledDate}
      datesHighlight={datesHighlight}
      format="DD/MM/YYYY"
      {...restProps}
    />
  );
};

export default React.memo(TicketDateSelectable);
