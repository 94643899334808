import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LocationIcon } from 'app/assets/img/location.svg';
import { ReactComponent as ClockIcon } from 'app/assets/img/clock.svg';

interface PromotionDetailProps {
  title: string;
  parkAddress: string;
  note?: string;
  description?: string;
}

const PromotionDetail = (props: PromotionDetailProps) => {
  const { title, parkAddress, note, description } = props;

  return (
    <div>
      <PromotionTitle>{title}</PromotionTitle>
      <div className="d-flex align-items-center flex-wrap">
        <div
          className="d-flex align-items-center"
          style={{ marginRight: 24, marginTop: 8 }}
        >
          <LocationIcon style={{ flexShrink: 0 }} />
          <PromotionAddressAndTime>{parkAddress}</PromotionAddressAndTime>
        </div>
        <div className="d-flex align-items-center" style={{ marginTop: 8 }}>
          <ClockIcon style={{ flexShrink: 0 }} />
          {note && <PromotionAddressAndTime>{note}</PromotionAddressAndTime>}
        </div>
      </div>
      {description && (
        <PromotionDescription>
          {description
            ?.replaceAll('\\n', '\n')
            ?.split('\n')
            ?.map(val => (
              <div>
                {val}
                <br />
              </div>
            ))}
        </PromotionDescription>
      )}
    </div>
  );
};

export default PromotionDetail;

const PromotionTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 46px);
`;

const PromotionAddressAndTime = styled.p`
  margin: 0 0 0 4px;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const PromotionDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  color: #000000;
  margin: 10px 0 0;
`;
