import {
  addCartApi,
  getCartApi,
  removeCartApi,
  updateCartApi,
  getPriceApi,
  mergeTicketApi,
  newUpdateCartApi,
} from 'app/services/cartApi';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_CART,
  GET_CART,
  GET_TICKET_PRICE,
  MERGE_TICKET,
  NEW_UPDATE_CART,
  REMOVE_CART,
  UPDATE_CART,
} from '.';

export function* getCart({ payload }: any) {
  try {
    const { onSuccess } = payload;
    const { data } = yield call(getCartApi);
    yield put(GET_CART.success(data));
    onSuccess?.(data);
  } catch (err) {
    yield put(GET_CART.failure());
  }
}

export function* updateCart({ payload }: any) {
  const { data, itemAddToCartID, callback } = payload;
  try {
    const { data: responseData } = yield call(updateCartApi, data);
    callback?.(responseData);
    yield put(UPDATE_CART.success({ ...responseData, itemAddToCartID }));
  } catch (err: any) {
    callback?.(err);
    yield put(UPDATE_CART.failure(err));
  }
}

export function* newUpdateCart({ payload }: any) {
  const { data, onSuccess, onError } = payload;
  try {
    const { data: responseData } = yield call(newUpdateCartApi, data);
    onSuccess?.(responseData);
    yield put(NEW_UPDATE_CART.success({ ...responseData }));
  } catch (err: any) {
    yield put(NEW_UPDATE_CART.failure(err));
    onError?.(err);
  }
}

export function* addCart({ payload }: any) {
  const { data, onSuccess, onError } = payload;
  try {
    const res = yield call(addCartApi, data);
    yield put(ADD_CART.success([res?.data]));
    onSuccess?.(res?.data);
  } catch (err) {
    yield put(ADD_CART.failure());
    onError?.(err);
  }
}

export function* removeCart({ payload }: any) {
  const { data, onSuccess, onError } = payload;
  try {
    const { data: responseData } = yield call(removeCartApi, data);
    yield put(REMOVE_CART.success(responseData));
    onSuccess?.(responseData);
  } catch (err) {
    yield put(REMOVE_CART.failure());
    onError?.(err);
  }
}

export function* getTicketPrice({ payload }: any) {
  const { data, ticketIdentify, onSuccess, onError, isCallUpdateCartApi } =
    payload;
  try {
    const { data: responseData } = yield call(getPriceApi, data);
    const currentCart = yield select(state => state.cart?.cart);
    yield put(
      GET_TICKET_PRICE.success({
        data: responseData,
        ticketIdentify,
        isCallUpdateCartApi,
      }),
    );
    onSuccess?.(responseData, currentCart);
  } catch (err: any) {
    yield put(GET_TICKET_PRICE.failure({ error: err, ticketIdentify }));
    onError?.(err);
  }
}

export function* mergeTicket({ payload }: any) {
  const { data, onSuccess, onError } = payload;
  try {
    const { data: responseData } = yield call(mergeTicketApi, data);
    const currentCart = yield select(state => state.cart?.cart);
    onSuccess?.(responseData, currentCart);
    yield put(MERGE_TICKET.success(responseData));
  } catch (err: any) {
    const currentCart = yield select(state => state.cart?.cart);
    onError?.(err, currentCart);
    yield put(MERGE_TICKET.failure(err));
  }
}

export function* cartSaga() {
  yield takeLatest(GET_CART.TRIGGER, getCart);
  yield takeLatest(ADD_CART.TRIGGER, addCart);
  yield takeLatest(REMOVE_CART.TRIGGER, removeCart);
  yield takeLatest(UPDATE_CART.TRIGGER, updateCart);
  yield takeLatest(GET_TICKET_PRICE.TRIGGER, getTicketPrice);
  yield takeLatest(MERGE_TICKET.TRIGGER, mergeTicket);
  yield takeLatest(NEW_UPDATE_CART.TRIGGER, newUpdateCart);
}
