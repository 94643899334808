import { call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_LIST_ORDER, listingOrderActions } from '.';
import { getListOrderApi } from 'app/services/authApi';
import { selectOptionsGetListOrder } from './selectors';
// import { listingOrderActions as actions } from '.';

export function* getListOrder({ payload }: any) {
  try {
    const params = yield select(selectOptionsGetListOrder);
    const { data } = yield call(getListOrderApi, { ...params, ...payload });
    yield put(GET_LIST_ORDER.success(data.data || []));
    yield put(
      listingOrderActions.onSetOrderCount(data.orderCount || undefined),
    );
  } catch (err) {
    yield put(GET_LIST_ORDER.failure());
  }
}

export function* listingOrderSaga() {
  yield takeLatest(GET_LIST_ORDER.TRIGGER, getListOrder);
}
