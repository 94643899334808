import { Divider } from 'antd';
import { LoadingContainer } from 'app/components/LoadingContainer';
import ModalContainer from 'app/components/ModalContainer';
import StyledButton from 'app/components/StyledButton';
import useWindowSize from 'app/hooks/useWindowSize';
import { appActions } from 'app/pages/App/slice';
import {
  selectInfoUser,
  selectIsLogged,
  selectTranslations,
} from 'app/pages/App/slice/selectors';
import { updateMeApi } from 'app/services/authApi';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { purifyHtml } from 'utils/helper';

const CollectInfoPolicyModal = () => {
  const translations = useSelector(selectTranslations);
  const user = useSelector(selectInfoUser);
  const isLogged = useSelector(selectIsLogged);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [termTxt, setTermTxt] = useState('');
  const { device } = useWindowSize();

  const updateAgreeTermsOfDataCollection = async () => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append('agreeTermsOfDataCollection', 'true');
      const { data } = await updateMeApi(payload);

      dispatch(appActions.setInfoUser(data));
    } catch (error) {
    } finally {
      dispatch(false);
    }
  };

  useEffect(() => {
    setTermTxt(
      purifyHtml(
        user?.agreeTermsOfDataCollection?.data_collection_policy ?? '',
      ),
    );
  }, [user?.agreeTermsOfDataCollection]);
  return (
    <ModalContainer
      isShow={
        isLogged &&
        Boolean(user?.agreeTermsOfDataCollection?.data_collection_policy)
      }
      maskClosable={false}
      closable={false}
      modalProps={{
        width: 'auto',
      }}
      styleSlots={{
        modalContentStyle: {
          width:
            device === 'desktop'
              ? '900px'
              : device === 'tablet'
              ? '470px'
              : 'auto',
          maxWidth:
            device === 'desktop'
              ? '1250px'
              : device === 'tablet'
              ? '470px'
              : 'auto',
          padding: device === 'desktop' ? '20px' : '8px',
          height: device === 'desktop' ? '90vh' : '85vh',
          maxHeight: device === 'desktop' ? '90vh' : '85vh',
        },
        bodyStyle: {
          overflow: 'auto',
          padding: undefined,
          marginRight: '-42px',
          marginLeft: '-42px',
          height: device === 'desktop' ? '90vh' : '85vh',
        },
      }}
    >
      <LoadingContainer loading={loading}>
        <StyledPolicy>
          <Policy>
            <Description style={{ textAlign: 'justify', marginRight: 8 }}>
              {termTxt && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: termTxt,
                  }}
                ></div>
              )}
            </Description>
          </Policy>
        </StyledPolicy>
        <StyledDivider
          style={{
            margin: '20px 0px 25px 0px',
          }}
          dashed
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledButton
            onClick={updateAgreeTermsOfDataCollection}
            border="none"
            textColor="#ffffff"
            height={'44px'}
          >
            {translations ? translations['b2c.txt.confirm-collect'] : ''}
          </StyledButton>
        </div>
      </LoadingContainer>
    </ModalContainer>
  );
};

export default CollectInfoPolicyModal;

const Policy = styled.div`
  background: #ffffff;
  overflow: auto;
  height: 60vh;
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Define the track (the area behind the thumb) */
  ::-webkit-scrollbar-track {
    background: transparent; /* Color of the track */
  }

  /* Define the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #ececec; /* Color of the thumb */
    border-radius: 20px;
  }

  /* Define the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d6d6d6; /* Color of the thumb on hover */
  }
`;

const StyledPolicy = styled.div`
  border-radius: 20px;
  background: #ffffff;
  padding: 24px 20px;
  border: 1px solid #d9d9d9;
  margin-top: 12px;
`;

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
`;

const Description = styled.p`
  font-weight: 400;
  color: #1c1b1f;
  font-size: 14px;
  text-align: left;
  margin-top: 14px;
  line-height: 1.7rem;
`;

const HeaderContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: center;
`;
const SubHeaderContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: 14px 0px 14px 0px;
  width: 100%;
  min-width: 100%;
`;
