import { Select } from 'antd';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 6px !important;
    height: 44px !important; /* Your desired height */
    line-height: 44px !important; /* Match line-height to your height */
    align-items: center;
    .ant-select-selection-placeholder {
      font-style: italic;
      color: #dcdcdc;
    }
  }
`;

export const MobileStyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 6px !important;
    height: 41px !important; /* Your desired height */
    line-height: 41px !important; /* Match line-height to your height */
    align-items: center;
    .ant-select-selection-placeholder {
      font-style: italic;
      color: #dcdcdc;
    }
  }
`;

export default StyledSelect;
