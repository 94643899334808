import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.cart || initialState;

export const selectCart = createSelector([selectSlice], state => state);

export const selectIsLoading = createSelector(
  [selectCart],
  state => state.loading,
);

export const selectIsError = createSelector([selectCart], state => state.error);

export const selectIsSuccess = createSelector(
  [selectCart],
  state => state.success,
);

export const selectListCart = createSelector([selectCart], state => state.cart);

export const selectLengthCart = createSelector(
  [selectListCart],
  state => state.length,
);

export const selectDatesSelectedToCheckout = createSelector(
  [selectCart],
  state => state.datesSelectedToCheckout,
);
export const isUpdateSuccess = createSelector(
  [selectCart],
  state => state.isUpdateSuccess,
);
export const getErrorWarning = createSelector(
  [selectCart],
  state => state.errorWarning,
);
export const getErrorTimeTicket = createSelector(
  [selectCart],
  state => state.errorTimeTicket,
);
export const getErrorTickets = createSelector(
  [selectCart],
  state => state.errorTickets,
);


export const selectError = createSelector([selectCart], state => state.error);
