import useListTicket from 'app/pages/NewAccount/hooks/useListTicket';
import ListTicketLayout from 'app/pages/NewAccount/layouts/ListTicketLayout';
import React, { forwardRef, useImperativeHandle } from 'react';
import { I_OptionsGetList } from 'types';

const ListPendingTicket = forwardRef<
  { search: (params: Partial<I_OptionsGetList>) => void },
  {
    activeKeyTab: string;
    loading: boolean;
    setLoading: (loading: boolean) => void;
  }
>(({ activeKeyTab, loading, setLoading }, ref) => {
  const {
    listTicket: listTicketSuccess,
    paginator,
    search,
  } = useListTicket({
    orderState: 'paymentPending',
    isActiveTab: activeKeyTab === 'tab-2',
    setLoading,
  });
  useImperativeHandle(ref, () => {
    return {
      search,
    };
  });
  return (
    <ListTicketLayout
      loading={loading}
      paginator={paginator}
      listTicket={listTicketSuccess}
      search={search}
      orderState="paymentPending"
    />
  );
});

export default ListPendingTicket;
