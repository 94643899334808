import moment from 'moment';
import { FormatType, IDateFormat } from 'utils/date-utils/types';

export class DateFormat implements IDateFormat {
  private initFormat: FormatType;
  private dateString?: string;

  private constructor(format: FormatType, dateString?: string) {
    this.initFormat = format;
    this.dateString = dateString;
  }

  static create(initFormat: FormatType, dateString?: string): DateFormat {
    return new DateFormat(initFormat, dateString);
  }

  getMoment(): moment.Moment | undefined {
    // Implement the logic to format the date based on the format string
    // ...
    if (this.dateString) {
      return moment(this.dateString, this.initFormat);
    }
    return undefined; // Placeholder return value
  }
}
