import { getHomePageApi, getListParkApi } from 'app/services/homeApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_HOME, GET_PARKS } from '.';

export function* getHome({ payload }: any) {
  try {
    const { data } = yield call(getHomePageApi, payload);
    yield put(GET_HOME.success(data));
  } catch (err) {
    yield put(GET_HOME.failure());
  }
}

export function* getListPark({ payload }: any) {
  try {
    const { data } = yield call(getListParkApi, {
      ...payload,
      limit: 50,
      page: 1,
    });
    yield put(GET_PARKS.success(data.data));
  } catch (err) {
    yield put(GET_PARKS.failure());
  }
}

export function* homeSaga() {
  yield takeLatest(GET_HOME.TRIGGER, getHome);
  yield takeLatest(GET_PARKS.TRIGGER, getListPark);
}
