import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.checkout || initialState;

export const selectCheckout = createSelector([selectSlice], state => state);

export const selectIsLoading = createSelector(
  [selectCheckout],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectCheckout],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectCheckout],
  state => state.success,
);

export const selectListPaymentMethod = createSelector(
  [selectCheckout],
  state => state.listPaymentMethod,
);
export const infoOder = createSelector([selectCheckout], state => state.order);
