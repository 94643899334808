import { objectType } from 'types';
import { BaseXHR } from 'utils/axios';
import { LOCAL_STORAGE_KEY, getItem } from 'utils/localStorage';

export const getCartApi = () => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  return BaseXHR.$get('b2c/cart/get?cart_id=' + cartId);
};

export const updateCartApi = (data: FormData) => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  data.append('cart_id', cartId ?? '');
  return BaseXHR.$post('cart/add-to-card', data);
};

export const addCartApi = (data: FormData) => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  data.append('cart_id', cartId ?? '');
  return BaseXHR.$post('b2c/cart/add', data);
};

export const removeCartApi = (data: FormData) => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  data.append('cart_id', cartId ?? '');
  return BaseXHR.$post('b2c/cart/remove', data);
};

export const getPriceApi = (data: objectType) => {
  return BaseXHR.$post('cart/get-price', data);
};

export const mergeTicketApi = (data: FormData) => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  data.append('cart_id', cartId ?? '');
  return BaseXHR.$post('b2c/cart/merge-new-item', data);
};

export const createAnonymousCartApi = () =>
  BaseXHR.$post('b2c/cart/create-cart');

export const newUpdateCartApi = (data: FormData) => {
  const cartId = getItem(LOCAL_STORAGE_KEY.anonymousCartId);
  data.append('cart_id', cartId ?? '');
  return BaseXHR.$post('b2c/cart/update', data);
};
