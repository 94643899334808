import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Paragraph from 'antd/es/typography/Paragraph';

import { ReactComponent as CalendarIcon } from 'app/assets/img/calendar.svg';
import useWindowSize from 'app/hooks/useWindowSize';

interface NewItemsProps {
  imgUrl: string;
  title: string;
  href: string;
  time: string;
  description: string;
  translations: any;
  slidesToShow: number;
}

const NewItems = (props: NewItemsProps) => {
  const { title, imgUrl, description, time, href, translations, slidesToShow } =
    props;

  const history = useHistory();
  const { device } = useWindowSize();

  const isDesktopScreen = useMemo(() => device === 'desktop', [device]);

  const clickSeeDetail = item => {
    if (item) window.open(item, '_blank');
    else history.push('/catalog');
  };

  return (
    <Container slidesToShow={slidesToShow}>
      <Col
        span={24}
        style={{
          display: 'flex',
          flexDirection: isDesktopScreen ? 'row' : 'column',
          height: isDesktopScreen ? 216 : 450,
        }}
      >
        <div>
          <img
            style={{
              width: isDesktopScreen ? 266 : '100%',
              height: isDesktopScreen ? 216 : 270,
              objectFit: 'cover',
              borderRadius: 16,
            }}
            src={imgUrl}
            alt=""
          />
        </div>
        <TextWrapper
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
          <Title>{title}</Title>
          <div className="d-flex align-items-center" style={{ marginTop: 8 }}>
            <CalendarIcon style={{ transform: 'scale(0.7)' }} />
            <Time>{time}</Time>
          </div>
          <Description
            ellipsis={{
              rows: isDesktopScreen ? 4 : 2,
              onEllipsis: () => {},
            }}
          >
            {description}
          </Description>
          <ViewDetail onClick={() => clickSeeDetail(href)}>
            <div>{translations ? translations[`b2c.text.see-detail`] : ''}</div>
            <div style={{ marginLeft: 4 }}>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </ViewDetail>
        </TextWrapper>
      </Col>
    </Container>
  );
};

export default NewItems;

const Container = styled(Row)<{ slidesToShow: number }>`
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
  height: 216px;
  margin-right: ${props => (props.slidesToShow === 1 ? '0px' : '12px')};
  @media (max-width: 1199px) {
    height: auto;
  }
`;

const TextWrapper = styled.div`
  padding: 8px 44px 8px 40px;
  border-top-right-radius: 16px;
  @media (max-width: 1199px) {
    padding: 16px;
  }
`;

const Title = styled.h5`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const Time = styled.p`
  margin: 0;
  color: #808080;
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
`;

const Description = styled(Paragraph)`
  margin: 0;
  font-size: 12px;
  margin-top: 8px;
  color: #000000;
  line-height: 20px;
  font-weight: 400;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  word-break: break-word;
`;

const ViewDetail = styled.div`
  cursor: pointer;
  color: #f8b117;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  flex: 1;
  margin-top: 4px;
  display: flex;
  align-items: baseline;
  &:hover {
    color: #f8b117;
  }
`;
