/**
 *
 * Asynchronously loads the component for CatalogDetailShow
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CatalogDetailShow = lazyLoad(
  () => import('./index'),
  module => module.CatalogDetailShow,
);
