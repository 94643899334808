import ImgBanner from './img_banner.png';
import CloseIcon from './close_icon.svg';
import SuccessIcon from './success_icon.svg';
import ErrorIcon from './error_icon.svg';
import HistoryIcon from './history_icon.svg';
import UserIcon from './user_icon.svg';
import LogoutIcon from './logout_icon.svg';
import SearchIcon from './search_icon.svg';
import NextPageIcon from './next_page_icon.svg';
import PrevPageIcon from './prev_page_icon.svg';
import TicketItemBG from './ticket_item_bg.svg';
import TicketDemoImg from './ticket_demo_img.png';
import PrintIcon from './print_icon.svg';
import PdfViewIcon from './pdf_view_icon.svg';
import DetailTicketIcon from './detail_ticket_icon.svg';
import ImgDemoTopSale1 from './img_demo_top_sale_1.png';
import ImgDemoTopSale2 from './img_demo_top_sale_2.png';
import ImgDemoTopSale3 from './img_demo_top_sale_3.png';
import ImgDemoTopSale4 from './img_demo_top_sale_4.png';
import DetailViewIcon from './detail_view_icon.svg';
import NextCarouselIcon from './next_carousel_icon.svg';
import PrevCarouselIcon from './prev_carousel_icon.svg';
import BgCoupon from './bg-coupon.svg';
import SelectServiceIcon from './select-service-icon.svg';
import PaymentIcon from './payment-icon.svg';
import DoneIcon from './done-icon.svg';
import ProgressLineEnable from './progress-line-enable.svg';
import ProgressLineDisable from './progress-line-disable.svg';
import MenuIcon from './menu-icon.svg';
import FlagUK from './flag-uk.jpg';
import FlagVN from './flag-vi.jpg';
import PhoneIcon from './phone-icon.svg';
import UserProfile from './user-profile.svg';
import VnPayDemo from './vnpay-demo-img.png';
import icon_Prev_Carousel from './icon_Prev_Carousel.svg';
import icon_Next_Carousel from './icon_Next_Carousel.svg';
import icon_map_zone from './icon_map_zone.svg';
import SunImg from './sun-logo.png';
import FooterImg from './footer-img.png';
import ColorfulLoader from './colorful_loader.gif';
import LoginIcon from './login-icon.svg';
import RegisterIcon from './register-icon.svg';
import AddIcon from './add-icon.svg';
import MinusIcon from './minus-icon.svg';
import ChangePassIcon from './change-pass-icon.svg';

const Images = {
  ImgBanner,
  CloseIcon,
  SuccessIcon,
  ErrorIcon,
  HistoryIcon,
  UserIcon,
  LogoutIcon,
  SearchIcon,
  NextPageIcon,
  PrevPageIcon,
  TicketItemBG,
  TicketDemoImg,
  PrintIcon,
  PdfViewIcon,
  DetailTicketIcon,
  ImgDemoTopSale1,
  ImgDemoTopSale2,
  ImgDemoTopSale3,
  ImgDemoTopSale4,
  DetailViewIcon,
  NextCarouselIcon,
  PrevCarouselIcon,
  BgCoupon,
  SelectServiceIcon,
  PaymentIcon,
  DoneIcon,
  ProgressLineEnable,
  ProgressLineDisable,
  MenuIcon,
  FlagUK,
  FlagVN,
  PhoneIcon,
  UserProfile,
  VnPayDemo,
  icon_Prev_Carousel,
  icon_Next_Carousel,
  icon_map_zone,
  SunImg,
  FooterImg,
  ColorfulLoader,
  LoginIcon,
  RegisterIcon,
  AddIcon,
  MinusIcon,
  ChangePassIcon,
};
export default Images;
