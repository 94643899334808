export const LOCAL_STORAGE_KEY = {
  anonymousCartId: 'anonymousCartId',
};

const getItem = (key: string) => localStorage.getItem(key);

const getSessionItem = (key: string) => sessionStorage.getItem(key);

const setItem = <T extends string>(key: T, value: T) => {
  if (key && value) localStorage.setItem(key, value);
};

const setSessionItem = <T extends string>(key: T, value: T) => {
  if (key && value) sessionStorage.setItem(key, value);
};

const removeItem = <T extends string>(key: T) => localStorage.removeItem(key);

const clearAllLS = () => {
  localStorage.clear();
};

export {
  getItem,
  setItem,
  removeItem,
  clearAllLS,
  getSessionItem,
  setSessionItem,
};
