import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { isNil } from 'lodash';

type NumberControlProps = {
  number?: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  disabledMinus?: boolean;
  disablePlus?: boolean;
  onChange?: (value: number) => void;
  onBlur?: (e) => void;
  inputReadOnly?: boolean;
  min?: number;
  max?: number;
};

const NumberControl = forwardRef<
  { setInternalValue: (value: number) => void },
  NumberControlProps
>((props, ref) => {
  const {
    number,
    onDecrement,
    onIncrement,
    disablePlus,
    disabledMinus,
    onChange,
    onBlur,
    inputReadOnly,
    min,
    max,
    ...rest
  } = props;

  const [value, setValue] = useState(number);

  useImperativeHandle(
    ref,
    () => ({
      setInternalValue: value => {
        setValue(value);
      },
    }),
    [],
  );

  return (
    <Container>
      <Button
        onClick={() => {
          if (!disabledMinus) {
            onDecrement?.();
            if (!isNil(value)) setValue(value - 1);
          }
        }}
        disabledBtn={disabledMinus}
      >
        <i className={`fa-solid fa-minus`} style={{ fontSize: 12 }} />
      </Button>
      <div style={{ position: 'relative' }}>
        <StyledInputNumber
          value={value}
          onValueChange={(value, sourceInfo) => {
            setValue(value.floatValue);
            if (sourceInfo.source === 'event') {
              onChange?.(value.floatValue ?? min ?? 0);
            }
          }}
          inputMode="numeric"
          pattern="[0-9]*"
          onBlur={e => {
            if (!isNil(value)) {
              if (!isNil(min) && value < min) setValue(min);
              else if (!isNil(max) && value > max) setValue(max);
            } else {
              setValue(min);
            }
            onBlur?.(e);
          }}
          onPaste={e => {
            e.preventDefault();
          }}
          disabled={inputReadOnly}
          decimalScale={0}
          allowNegative={false}
          {...rest}
        />
      </div>
      <Button
        onClick={() => {
          if (!disablePlus) {
            onIncrement?.();
            if (!isNil(value)) setValue(value + 1);
          }
        }}
        disabledBtn={disablePlus}
      >
        <i className={`fa-solid fa-plus`} style={{ fontSize: 12 }} />
      </Button>
    </Container>
  );
});

export default NumberControl;

const Container = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #f2f2f2;
  border-radius: 3px;
  height: 35px;
  width: 125px;
`;

const Button = styled.button<{ disabledBtn?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: transparent;
  height: 100%;
  width: 35px;
  flex-shrink: 0;
  i {
    color: ${props =>
      props.disabledBtn ? 'rgb(165, 164, 164)' : 'rgb(233, 33, 39)'};
  }
`;

const StyledInputNumber = styled(NumericFormat)`
  height: 100%;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  background: transparent;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  width: 56px;
  text-align: center;
  outline: none;
  &.ant-input-number-focused {
    box-shadow: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    text-align: center;
  }
`;
