import { getPaymentMethodApi } from 'app/services/checkoutApi';
import { useEffect, useState } from 'react';

const useGetCountDownPayment = () => {
  const [timeCountdown, setTimeCountdown] = useState<number>();
  useEffect(() => {
    const fetchTimeCountdown = async () => {
      const data = await getPaymentMethodApi();

      setTimeCountdown(
        data.data?.expired_time_b2c
          ? parseInt(data.data?.expired_time_b2c)
          : undefined,
      );
    };
    fetchTimeCountdown();
  }, []);

  return {
    timeCountdown,
  };
};

export default useGetCountDownPayment;
