import moment, { Moment } from 'moment';

import { Weekend } from './types';

export const getStartOfDay = (
  day: Moment | string | undefined,
  format: string = 'YYYY-MM-DD',
) => {
  if (!day) return null;
  if (typeof day === 'string') {
    const momentResult = moment(day, format);
    return momentResult.isValid() ? momentResult.startOf('day') : null;
  }
  return day.isValid() ? day.startOf('day') : null;
};

export const getHolidays = (holidays: Weekend['holidays']) => {
  return holidays.map(holiday =>
    moment(holiday.holiday.dateOrigin, 'YYYY-MM-DD').startOf('day'),
  );
};

export const isValidDayOfWeek = (day: Moment, weekend: Weekend | null) => {
  if (!weekend) return true;
  const daysOfWeekData = [
    weekend?.sunday,
    weekend?.monday,
    weekend?.tuesday,
    weekend?.wednesday,
    weekend?.thursday,
    weekend?.friday,
    weekend?.saturday,
  ];
  const isApplyHoliday = weekend.applyHoliday;
  if (!daysOfWeekData[day.day()]) {
    if (isApplyHoliday) {
      const holidays = getHolidays(weekend.holidays);
      return holidays.some(holiday => holiday.isSame(day, 'day'));
    }
    return false;
  } else {
    if (!isApplyHoliday) {
      const holidays = getHolidays(weekend.holidays);
      return !holidays.some(holiday => holiday.isSame(day, 'day'));
    }
    return true;
  }
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date: any[] = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(moment(startDate));
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
};
