import { CheckoutDetail, ErrorCheckout } from 'app/pages/Checkout/slice/types';
import { BaseXHR } from 'utils/axios';

export const checkoutAddressApi = (form: FormData) =>
  BaseXHR.$post(`checkout/address`, form);

export const checkoutCommitApi = (form?: FormData) =>
  BaseXHR.$post(`checkout/commit`, form);

export const getDetailOrderApi = (
  id: string,
  isLogged: boolean,
  idItem?: string,
) => {
  const suffix = idItem ? `?idItem=${idItem}` : '';
  return BaseXHR.$get(
    isLogged
      ? `v1/order/detail/${id}` + suffix
      : `v1/order/check/${id}` + suffix,
  );
};

export const downloadPdfApi = (
  id: string,
  isLoading: (load: boolean) => void,
  isOpenNewTab?: boolean,
) => {
  isLoading && isLoading(true);
  BaseXHR.$get(`v1/order/download-pdf/${id}`).then((res: any) => {
    const blob = new Blob([res.data], { type: 'application/pdf' });
    window.location.href = URL.createObjectURL(blob);
    isLoading && isLoading(false);
  });
};

export const createOrderApi = (form: FormData) =>
  BaseXHR.$post(`b2c/checkout/create-order`, form);
export const createNewOrderApi = (form: FormData) =>
  BaseXHR.$post(`b2c/checkout/create-order`, form);

export const getPaymentMethodApi = () =>
  BaseXHR.$get(`b2c/payment/method-listing`);

export const updatePaymentApi = (params: string) =>
  BaseXHR.$get(`payment/vnp-callback${params}`);

export const getCheckoutDetailOrderApi = (id: string) =>
  BaseXHR.$get<CheckoutDetail>(`b2c/checkout/get-order/${id}`);

export const createCheckoutPaymentApi = (form: FormData) =>
  BaseXHR.$post(`b2c/checkout/payment`, form);

export const validateCheckoutPaymentApi = (form: FormData) =>
  BaseXHR.$post<ErrorCheckout>(`b2c/checkout/validate-payment`, form);

export const updateCheckoutPaymentApi = (form: FormData) =>
  BaseXHR.$post(`v1/order/update`, form);
