import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { checkoutSaga } from './saga';
import { CheckoutState, I_PaymentMethod } from './types';

export const GET_LIST_PAYMENT_METHOD = createRoutine(
  'checkout/getListPaymentMethod',
);
export const GET_CREATE_NEW_ORDER = createRoutine('checkout/createNewOrder');

export const initialState: CheckoutState = {
  loading: false,
  success: false,
  error: false,
  listPaymentMethod: [],
  order: null,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    /**
     * ! GET LIST PAYMENT METHOD
     */
    [GET_LIST_PAYMENT_METHOD.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_LIST_PAYMENT_METHOD.SUCCESS]: (
      state,
      action: PayloadAction<I_PaymentMethod[]>,
    ) => {
      state.listPaymentMethod = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_LIST_PAYMENT_METHOD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! CREATE_ORDER
     */
    [GET_CREATE_NEW_ORDER.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_CREATE_NEW_ORDER.SUCCESS]: (state, action: any) => {
      state.order = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_CREATE_NEW_ORDER.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: checkoutActions } = slice;

export const useCheckoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: checkoutSaga });
  return { actions: slice.actions };
};
