import { isValidDayOfWeek } from 'app/components/TicketDateSelectable/helpers';
import { Weekend } from 'app/components/TicketDateSelectable/types';
import moment, { Moment } from 'moment';

type CheckHaveTicketOnSelectedDate = (params: {
  selectedDate: Moment;
  dateFrom: Moment;
  dateTo: Moment;
  isComboDifTicket: boolean;
  weekend: Weekend | null;
}) => boolean;
export const checkHaveTicketOnSelectedDate: CheckHaveTicketOnSelectedDate =
  params => {
    const { selectedDate, dateFrom, dateTo, isComboDifTicket, weekend } =
      params;
    const selectedDateMoment = selectedDate.startOf('day');
    const dateFromMoment = dateFrom.startOf('day');
    const dateToMoment = moment(
      isComboDifTicket
        ? moment(weekend?.holidays[0].holiday.dateOrigin, 'YYYY-MM-DD').startOf(
            'day',
          )
        : dateTo.startOf('day'),
      'YYYY-MM-DD',
    ).startOf('day');
    if (
      !selectedDateMoment.isBetween(dateFromMoment, dateToMoment, 'day', '[]')
    )
      return false;
    return isValidDayOfWeek(moment(selectedDateMoment, 'YYYY-MM-DD'), weekend);
  };

export default checkHaveTicketOnSelectedDate;
