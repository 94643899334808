import { Col, ColProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const ColPolicyInfoKey = (props: ColProps) => {
  const StyledCol = styled(Col)`
    font-size: 14px;
    font-weight: 400;
  `;
  return <StyledCol span={8} {...props} />;
};

const ColPolicyInfoValue = (props: ColProps) => {
  const StyledCol = styled(Col)`
    font-size: 14px;
    font-weight: 500;
  `;
  return <StyledCol span={16} {...props} />;
};

const ColPolicyInfo = {
  ColPolicyInfoKey,
  ColPolicyInfoValue,
};

export default ColPolicyInfo;
