import { useState, useEffect } from 'react';

export const MOBILE_WIDTH = 540;
export const TABLET_WIDTH = 992;
export const DESKTOP_WIDTH = 1200;

function getDeviceConfig(width: number) {
  if (width <= MOBILE_WIDTH) {
    return 'mobile';
  } else if (width >= DESKTOP_WIDTH) {
    return 'desktop';
  } else {
    return 'tablet';
  }
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [device, setDevice] = useState<'mobile' | 'tablet' | 'desktop'>(
    getDeviceConfig(window.innerWidth),
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setDevice(getDeviceConfig(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowSize, device };
}

export default useWindowSize;
