import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import TicketItem from '../TicketItem';
import { I_CartItem } from '../../slice/types';
import { FormCustomContext } from '../..';

interface TicketListByParkProps {
  title: string;
  tickets: I_CartItem[];
}

const TicketListByPark = (props: TicketListByParkProps) => {
  const { title, tickets } = props;

  const {
    isSubmitted,
    onSetCart,
    onMergeTicket,
    onRemoveTicketList,
    ticketsMergedNeedUpdateAmount,
  } = useContext(FormCustomContext);

  return (
    <TicketContainer>
      <Row>
        <TitleBlock>
          <StyledH4>{title}</StyledH4>
        </TitleBlock>
      </Row>
      <TicketBlock>
        {tickets.map((ticket, idx) => {
          return (
            <SticketContainer
              borderBottom={idx === tickets.length - 1}
              key={ticket?.origin?.identify ?? ticket.identify}
            >
              <TicketItem
                isSubmitted={isSubmitted}
                onSetCart={onSetCart}
                onMergeTicket={onMergeTicket}
                ticketsMergedNeedUpdateAmount={ticketsMergedNeedUpdateAmount}
                onRemoveTicketList={onRemoveTicketList}
                {...ticket}
              />
            </SticketContainer>
          );
        })}
      </TicketBlock>
    </TicketContainer>
  );
};

export default TicketListByPark;

const TicketContainer = styled.div``;

const TicketBlock = styled.div`
  margin-top: 24px;
`;

const TitleBlock = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledH4 = styled.h4`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
`;

const SticketContainer = styled.div<{ borderBottom: boolean }>`
  border-top: dashed 2px #d9d9d9;
  border-bottom: ${props =>
    props.borderBottom ? 'dashed 2px #D9D9D9' : 'none'};
`;
