import { GET_LANGS, GET_TRANS } from './index';
import { logoutApi } from './../../../services/authApi';
import { getMeApi } from 'app/services/authApi';
import { take, select, fork, put, call, takeLatest } from 'redux-saga/effects';
import { TYPE_COOKIE } from 'utils/constants';
import { getCookie, removeCookie } from 'utils/cookies';
import { appActions, GET_LAYOUT, GET_ME, LOGIN } from '.';
import { clearAllLS } from 'utils/localStorage';
import {
  getLanguageListApi,
  getLayoutApi,
  getTranslationsApi,
} from 'app/services/appApi';

export function* watchAndLog() {
  while (true) {
    const styleConsole =
      'background: yellow; font-weight: bold; color: #40a9ff8c;';
    const action = yield take('*');
    const state = yield select();
    if (
      action.type !== '@@router/LOCATION_CHANGE' &&
      action.type !== 'app/updateMediaUploadingProgress' &&
      action.type !== 'reapop/upsertNotification' &&
      action.type !== 'reapop/dismissNotification'
    ) {
      // console.group(action.type);
      // console.log('%cBefore State', styleConsole, state);
      // console.info('%cDispatching', styleConsole, action);
      // console.log('%cNext State', styleConsole, state);
      // console.groupEnd();
    }
  }
}

function* watchIsLogged() {
  while (true) {
    yield take(LOGIN.SUCCESS);
    yield put(appActions.changeIsLogged({ isLogged: true }));
  }
}

export function* getMe() {
  try {
    const { data } = yield call(getMeApi);
    yield put(GET_ME.success(data));
  } catch {
    yield put(GET_ME.failure());
  }
}

export function* getLayout() {
  try {
    const response = yield call(getLayoutApi);
    yield put(GET_LAYOUT.success(response.data));
  } catch {
    yield put(GET_LAYOUT.failure());
  }
}

export function* getTranslations() {
  try {
    const response = yield call(getTranslationsApi);
    yield put(GET_TRANS.success(response.data));
  } catch {
    yield put(GET_TRANS.failure());
  }
}

export function* getLanguageList() {
  try {
    const response = yield call(getLanguageListApi);
    yield put(GET_LANGS.success(response.data));
  } catch {
    yield put(GET_LANGS.failure());
  }
}

function* logout() {
  const token = yield call(getCookie, TYPE_COOKIE.TOKEN);
  if (token) yield call(logoutApi);
  yield call(removeCookie, TYPE_COOKIE.TOKEN);
  yield call(clearAllLS);
  yield put(appActions.changeIsLogged({ isLogged: false }));
}

export function* appSaga() {
  if (process.env.NODE_ENV === 'development') yield fork(watchAndLog);
  yield fork(watchIsLogged);
  yield takeLatest(GET_ME.TRIGGER, getMe);
  yield takeLatest(GET_LAYOUT.TRIGGER, getLayout);
  yield takeLatest(GET_TRANS.TRIGGER, getTranslations);
  yield takeLatest(GET_LANGS.TRIGGER, getLanguageList);
  yield takeLatest(appActions.logout.type, logout);
}
