import styled, { createGlobalStyle, css } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  /* @font-face {
    font-family: 'Sun World';
    src: url('/static/fonts/SunWorld-Regular.eot');
    src: url('/static/fonts/SunWorld-Regular.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/SunWorld-Regular.woff2') format('woff2'),
      url('/static/fonts/SunWorld-Regular.woff') format('woff'),
      url('/static/fonts/SunWorld-Regular.ttf') format('truetype'),
      url('/static/fonts/SunWorld-Regular.svg#SunWorld-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sun World';
    src: url('/static/fonts/SunWorld-Bold.eot');
    src: url('/static/fonts/SunWorld-Bold.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/SunWorld-Bold.woff2') format('woff2'),
      url('/static/fonts/SunWorld-Bold.woff') format('woff'),
      url('/static/fonts/SunWorld-Bold.ttf') format('truetype'),
      url('/static/fonts/SunWorld-Bold.svg#SunWorld-Bold') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sun World';
    src: url('/static/fonts/SunWorld-Light.eot');
    src: url('/static/fonts/SunWorld-Light.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/SunWorld-Light.woff2') format('woff2'),
      url('/static/fonts/SunWorld-Light.woff') format('woff'),
      url('/static/fonts/SunWorld-Light.ttf') format('truetype'),
      url('/static/fonts/SunWorld-Light.svg#SunWorld-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Sun World';
    src: url('/static/fonts/SunWorld-Heavy.eot');
    src: url('/static/fonts/SunWorld-Heavy.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/SunWorld-Heavy.woff2') format('woff2'),
      url('/static/fonts/SunWorld-Heavy.woff') format('woff'),
      url('/static/fonts/SunWorld-Heavy.ttf') format('truetype'),
      url('/static/fonts/SunWorld-Heavy.svg#SunWorld-Heavy') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  } */

  font-family: "Be Vietnam Pro", sans-serif;

  .--link {
    cursor: pointer;
  }

  :root {
    --color-1: #EA2127;
    --color-2: #F2F2F2;
    --color-3: #2C2C2C;
    --color-4: #F9B217;
    --color-5: #8F2623;
    --color-6: #F6DB5F;
    --color-7: #852221;
    --color-8: #B1B1B1;
    --font-body: 'Be Vietnam Pro';
    --transition-regular: all 0.15s ease-out;
    --transition-smooth: all 0.5s cubic-bezier(0.04, 1, 0.6, 0.97);
  }

  body {
    font-size: 16px;
    font-family: var(--font-body);
    color: var(--color-3);
    line-height: 20px;
    background: #f5f5f55c;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  sup.priceUnit {
    top: 0;
    margin-left: 2px;
    text-decoration: underline;
  }
  .disabled-btn-reSend{
    color: gray !important;
    cursor: not-allowed !important;
  }
  .modal {
    --bs-modal-zindex: 1000;
    &-backdrop {
      --bs-backdrop-zindex: 1000;
    }
  }

  .img_hover {
    overflow: hidden;

    .--img {
      img {
        transition: all .5s ease;
      }
    }

    &:hover {
      .--img {
        img {
          transform: scale(1.3);
        }
      }
    }
  }

  .img_hover1 {
    overflow: hidden;

    img {
      transition: all .5s ease;
    }

    &:hover {
      img {
        transform: scale(1.3);
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute !important;
    display: table !important;
    border: 2px solid #E92127 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    height: 12px;
}
.ant-checkbox-wrapper:focus-within .ant-checkbox-inner {  
  border-color: #d9d9d9 !important; 
  box-shadow: none;  
}  
  .ant-checkbox-wrapper {
    .ant-checkbox {
      top: 6px;
      &-inner {
        border-radius: 4px;
        width: 22px;
        height: 22px;
        &:after {
          left: 24%;
          border-color: #E92127 !important;
          box-shadow: none !important
        }
        &:hover {
          border-color: #E92127 !important;
          box-shadow: none !important
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: #E92127 !important;
        }
      }
      &-checked {
        .ant-checkbox-inner {
          background: #FFFFFF;
          border-color: #E92127 !important;
          box-shadow: none !important
        }
        &:after {
          border-color: #E92127 !important;
        }
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #E92127!important;
      }
    }
  }

  .main {
    background: #F8F8F8;
    padding-top: 16px;

    @media (max-width: 768px) {
      padding-top: 16px;
    }
  }

  .cta {
    position: fixed;
    z-index: 9;
    bottom: 40px;
    right: 20px;

    a {
      width: 50px;
      height: 50px;
      margin-top: 8px;
      border-radius: 50%;
      background: #FFF;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);

      &:nth-child(1) {
        background: var(--color-4);
      }

      img {
        width: 28px;
      }

      i {
        font-size: 18px;
        color: var(--color-4);
      }
    }
  }

  .arrow {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius: 50%;
    cursor: pointer;

    i {
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--color-4) !important;

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      background-image: url(static/img/bgarrow.svg);
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 32px;
      height: 32px;
      opacity: 0;
      transition: all .15s ease;
    }
  }

  .form-inputNumber {
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    padding: 2px;
    line-height: 1;
    background: #fff;
    i {
      cursor: pointer;
      color: #5E5E5E;

      &.disabled {
        opacity: .5;
        cursor: unset;
      }
    }
    .ant-input-number-group-addon{
      border: none;
      padding: 0;
      line-height: 1;
      background: #fff;
      width: 22px;
      /* line-height: 28px; */
      border-radius:   4px!important;
      transition: all 0.15s ease-out;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
    .ant-input-number{
      width: auto;
      border: none;
      max-width: 40px;
    }
    .ant-input-number-input{
      border: none;
      padding: 0 !important;
      text-align: center;
      font-weight: 900;
      height: auto;
      font-size: 18px;
    }
  }

  .titleCount {
    margin-left: 12px;
    padding: 2px 8px;
    border: 1px solid var(--color-8);
    border-radius: 4px;
    color: var(--color-8);
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
  }

  .btn {
    border-radius: 6px;
    height: 36px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-regular);
    &:hover {
      border-color: transparent;
    }
    > * {
      z-index: 2;
    }
    &White {
      background: #fff;
      border: 1px solid #e7e7e7;
      &:hover {
        border-color: var(--color-8);
      }
    }
    &AmbientOrange {
      background: rgba(249,178,23,0.1);
      border: 1px solid var(--color-4);
      color: var(--color-4);
      &:hover {
        background: var(--color-4);
        color: #333;
      }
    }
    &Grey {
      background: #f2f2f2;
      // border: 1px solid var(--color-4);
      // color: var(--color-4);
      &:hover {
        // background: var(--color-4);
        // color: #333;
      }
    }
    &Primary {
      height: 48px;
      font-size: 18px;
      font-weight: 900;
      position: relative;
      border: none!Important;
      padding: 0 1.5rem;
      &:after {
        content: '';
        background: var(--color-4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s cubic-bezier(0.23, 2.08, 0.6, 0.97);
        border-radius: 6px;
        z-index: 0;
      }
      &:hover {
        &:after {
          transform: scale(1.05);
        }
      }
      &[disabled] {
      }
    }
  }

  .header {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 99;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
    left: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #FFF;
    height: 80px;

    .logo {
      margin-right: 2.5rem;
      cursor: pointer;
      img {
        max-width: 180px;
        max-height: 70px;
      }
    }

    .search {
      position: relative;
      height: max-content;
      width: 40%;

      svg {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 12px;
      }

      input {
        background: var(--color-2);
        border-radius: 100px;
        width: 100%;
        border: none;
        padding: 0 12px 0 40px;
        height: 40px;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .login {
      &.active {
        .login_inactive {
          display: none !important;
        }

        .login_active {
          display: flex !important;
        }
        // .--noti {
        //   display: none;
        // }
      }

      .login_inactive {
        .--icon {
          width: 40px;
          height: 40px;
          display: flex;
          background: var(--color-2);
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
        }
      }

      .bttn {
        text-decoration: none;
        line-height: 1;
        background: none;
        border: none;
        text-align: left;
        padding: 0;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .bttn_regis {
        color: var(--color-3);
        margin-bottom: 4px;
      }

      .bttn_login {
        color: var(--color-3);
        font-weight: 900;
      }
    }

    .login_active {
      display: none;

      .--item {
        position: relative;
        margin-left: 1rem;
        background: none;
        border: none;
        padding: 0;

        span {
          position: absolute;
          font-size: 13px;
          background: var(--color-1);
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          padding-top: 3px;
          top: -4px;
          right: -6px;
        }
      }
    }

    @media (max-width: 768px) {
      .logo {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: max-content;
      }
    }
  }

  .appMenu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 6px rgb(0 0 0 / 3%);
    .--wrapper {
      height: 52px;
    }
  }
  .itemAppMenu {
    position: relative;
    &:hover {
      background: #eee;
      border-radius: 4px;
    }
    .--icon {
      font-size: 1.25rem;
    }
    .--number {
      background: var(--color-1);
      color: #fff;
      border-radius: 8px;
      position: absolute;
      padding: 0px 7px;
      right: 29px;
      top: 2px;
      border: 2px solid #fff;
    }
  }

  .headAuth {
    .--avatar {
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .headCart {
    position: relative;
    margin-left: 1rem;
    background: none;
    border: none;
    padding: 0px;
    .--icon {
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
      display: flex;
      background: var(--color-2);
      border-radius: 50%;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }
    span {
      position: absolute;
      font-size: 13px;
      background: var(--color-1);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      padding-top: 3px;
      top: -4px;
      right: -6px;
    }
  }

  .headLang {
    margin-left: 1.5rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: calc(100% + 12px);
      border-left: 1px solid #E7E7E7;
      height: 32px;
      transform: translate(0, -50%);
    }
    // .css-hj91ck-ValueContainer{
    //   display: flex;
    //   background: none;
    //   padding: 0 0.75rem;
    //   height: 32px;
    // }

    .select-filter__value-container {
      padding: 0;
    }

    .select-filter__menu {
      .--icon {
        width: 24px;
        height: 24px;
      }
    }

    .select-filter__single-value{
      display: flex;
      align-items: center;
      /* height: 32px; */
      justify-content: center;
    }
    .select-filter__input-container{
      height: 0 !important;
    }
    .select-filter__control{
      min-height: unset;
      background: none;
      /* width: 54px; */
      display: flex;
      align-items: center;
    }
    select {
      border: none;
      background: none;
      font-size: 16px;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }
    }
    .--icon {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50%;
      background: #eee;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media (max-width: 768px) {
      &:before {
        display: none;
      }
    }
  }

  .Banner {
    .slider_Banner {
      position: relative;

      .list_img {
        .--img {
          width: 100%;
          height: 80vh;
          clip-path: ellipse(106% 100% at 50% 0%);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .--img {
        height: 390px !important;
      }

    }
  }

  .Title {
    h1 {
      font-weight: 900;
      line-height: 56px;
      margin-bottom: 0;
    }
  }

  .listEcosystem {
    .--img {
      width: 100%;
      height: 360px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        background: linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 19.76%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.4) 100%);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }

    .--item {
      border-radius: 8px;
      position: relative;

      &:hover {
        .--event {
          max-height: 200px;
          opacity: 1;
          margin-top: 1rem;
        }
        .--img{
          &::after{
            background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ;
          }
        }
      }
    }

    .--txt {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 12px 20px;
    }

    .--hours {
      color: #FFF;
    }

    .--content {
      color: #FFF;

      span {
        opacity: .8;
        font-size: 14px;
      }

      h4 {
        font-weight: 900;
        p{
          color: #FFF;
        }
      }

      h6 {
        font-size: 18px;
        color: var(--color-4);
        font-weight: 900;
      }

      .--viewdetal {
        text-transform: uppercase;
        margin-top: 20px;

        &:hover {
          color: var(--color-4);
        }

        i {
          font-size: 14px;
        }
      }

      .--event {
        max-height: 0;
        opacity: 0;
        transition: all .75s ease;
      }
    }

    @media (max-width: 768px) {
      .--item {
        width: 270px;
        .--event {
          margin-top: 1rem;
          opacity: 1 !important;
          max-height: unset !important;
        }
        .--img{
          &::after{
            background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ;
          }
        }
      }
    }
  }

  .itemProduct {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 100%;
    border: 3px solid transparent;
    transition: all 0.15s ease-out;
    display: flex;
    flex-flow: column;
    // &:hover {
    //   border-color: var(--color-1);
    // }
    &.--selected {
      border-color: var(--color-4);
    }
    .--badgeDiscount {
      clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0 70%, 0 0);
      background: linear-gradient(45deg, #d00808, #ff5555);
      color: #fff;
      width: 48px;
      height: 40px;
      display: flex;
      justify-content: center;
      padding: 10px 0 0 0;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      small {}
    }
    .--check {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    .--img {
      width: 100%;
      height: 180px;
      clip-path: ellipse(94% 100% at 50% 0%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .--bot {
      flex: 1;
      display: flex;
      flex-flow: column;
    }
    .--txt {
      padding: 12px;
      height: 100%;
      h6 {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .--note {
      flex: 1;
      height: 100%;
      .--itemnote {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
        .--icon {
          display: flex;
          margin-right: 10px;
          color: #666;
          width: 20px;
        }
        span {
          color: var(--color-3);
          opacity: 0.6;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
    .--priceOld {
      color: var(--color-3);
      opacity: 0.6;
      text-decoration: line-through;
      font-size: 0.9rem;
    }

    .--priceNew {
      font-size: 16px;
      color: var(--color-1);
      display: flex;
      align-items: baseline;

      span {
        font-size: 16px;
        color: var(--color-3);
      }
    }

    .--bill {
      padding-top: 12px;
      margin-top: 12px;
      border-top: 1px solid #E7E7E7;

      span {
        font-size: 18px;
        font-weight: 900;
        color: var(--color-1);
      }
    }
    @media (max-width: 768px) {
      margin: 0 4px 4px;
    }
  }

  .cartSummaryCost {
    margin: 16px 0 0 0;
    padding: 12px;
    background: #F2F2F2;
    border-radius: 8px;

    .--item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      height: 20px;
      align-items: center;

      span {
        line-height: 1;
      }

      &:last-child {
        margin-bottom: 0;
        display: flex;
        align-items: flex-end;

        span {
          &:nth-child(2) {
            font-size: 18px;
            color: var(--color-1);
            font-weight: 900;
          }
        }
      }
    }
  }

  .button_hover {
    position: relative;
    transition: all 0.5s ease 0s;
    z-index: 9;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: #FFF;
      z-index: -1;
      transition: all 0.5s ease 0s;
      transform: scale(0);
      border-radius: 6px;
      opacity: 0;
      border: 1px solid var(--color-4);
    }

    &:hover {
      color: var(--color-4);
      border: none !important;
      background: none !important;

      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }


  .listInfooder {
    .--item {
      background: #FBFBFB;
      border: 1px solid #E7E7E7;
      border-radius: 8px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .--title {
      padding: 12px 20px;
      border-bottom: 1px solid #E7E7E7;

      h4 {
        font-weight: 900;
        margin-bottom: 0;
      }

      .--code {
        color: #0F74EB;
      }
    }

    .--time {
      text-align: right;
    }

    .list-cardticket {
      padding: 12px;
    }

    .usedtime {
      display: flex;
      flex-flow: column;
      margin-bottom: 12px;

      span {
        &:nth-child(1) {
          color: #B1B1B1;
        }

        &:nth-child(2) {
          font-weight: 900;
        }
      }
    }

    .card-ticket {
      padding: 12px;
      margin-bottom: 12px;
      border: 1px solid #E7E7E7;
      border-radius: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .--name-ticket {
        span {
          color: #646464;
        }

        h6 {
          font-weight: 900;
        }

        .--img {
          width: 48px;
          flex: 0 0 auto;
          height: 48px;
          border-radius: 6px;
          margin-right: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .totalmoney {
        padding-top: 16px;

        .--money {
          width: 282px;

          span {
            &:nth-child(2) {
              color: var(--color-1);
              font-size: 18px;
            }
          }
        }

        .view {
          color: var(--color-4);
          display: flex;

          i {
            margin-left: 6px;
            background: rgb(249 178 23 / 40%);
            width: 15px;
            height: 15px;
            color: var(--color-4);
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .--ticket {
        .--itemticket {
          padding: 16px 0;
          border-bottom: 1px solid #E7E7E7;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .--view {
          margin-right: 20px;
          width: max-content;
          font-size: 14px;
          .--view-btn{
            padding: 4px 8px;
            svg{
              margin-right: 4px;
            }
          }
          img {
            margin-right: 8px;
          }

          &-btn {
            cursor: pointer;
            border: 1px solid var(--color-4);
            border-radius: 4px;
            color: var(--color-4);
            text-decoration: none;
            display: flex;
            align-items: center;
            padding: 0 8px;
          }
        }

        .--name {
          width: calc(100% - 324px);

          h6 {
            font-size: 18px;
          }

          span {
            font-size: 16px;
          }
        }

        .--price {
          .--fare {
            width: 110px;
            text-align: right;
          }

          .--money {
            width: auto !important;
            margin-right: 20px;

            .priceold {
              color: #ABABAB;
              font-size: 14px;
              text-decoration: line-through;
            }
          }

          .--number {
            width: 40px;
            text-align: right;
            margin-right: 20px;
          }
        }
      }

      .form-inputNumber{
        border: none;
        .--money{
          align-items: flex-end;
          .--priceOld{
            margin-right: 4px;
          }
        }
      }
    }

    .payment {
      padding: 0 12px 12px;

      .card-bill {
        min-width: 320px;
        border-left: 1px dashed #C9C9C9;
        padding: 0 12px 0 24px;
        width: max-content;
      }

      .--info {
        background: #FBFBFB;
        border: 1px solid #E7E7E7;
        padding: 4px 12px;
        min-width: 280px;
        border-radius: 6px;
        transition: all .3s ease;

        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 4px;
          max-height: 0;
          opacity: 0;
          font-size: 14px;
          transition: all .3s ease;
          pointer-events: none;

          li {
            display: flex;
          }

          span {
            &:nth-child(1) {
              width: 100px;
              margin-right: 8px;
              display: flex;
            }
          }
        }

        h6 {
          font-weight: 900;
          margin-bottom: 0;
          line-height: 1;

          .fa-angle-down {
            display: none;
          }
        }

        &.active {
          ul {
            max-height: 400px;
            opacity: 1;
            pointer-events: auto;
          }

          .fa-angle-down {
            display: block;
          }

          .fa-angle-up {
            display: none;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .--title{
        flex-flow: column;
        align-items: flex-start !important;
      }
      .--itemticket{
        flex-flow: column;
        align-items: flex-start !important;
      }
      .card-ticket {
        .--ticket{
          .--name{
            width: 100%;
          }
          .--money{
            margin-right: 0 !important;
          }
          .--view{
            margin-top: 8px;
          }
        }
        .--price{
          width: 100%;
          justify-content: space-between;
        }
        .card-bill{
          min-width: unset;
          padding: 0 12px;
        }
        .form-inputNumber{
          padding: 0;
          .--money{
            flex-flow: column;
            align-items: flex-start !important;
            justify-content: flex-end !important;
          }
          .--priceOld{
            margin-right: 0 !important;
          }
          .--number{
            margin-right: 0 !important;
          }
        }
        .--price{
          margin-top: 8px;
        }
      }
      .payment{
        .card-bill{
          min-width: unset;
          width: 100%;
          border-left:unset ;
          padding: 0 12px;
        }
      }

    }
  }

  .cardaccount {
    padding: 20px;
    background: #FFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    position: sticky;
    top: 100px;

    h4 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 18px;
    }

    .--tab {}

    .itemAccountTab {
      margin-bottom: 8px;
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      border-radius: 8px;
      padding: 12px 12px 8px;
      text-align: left;
      cursor: pointer;
      transition: var(--transition-regular);

      &.active {
        background: var(--color-4);
        border-color: transparent;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    @media (max-width: 768px){
      box-shadow: unset;
      padding: 0 12px;
      .--tab{
        overflow-x: auto;
        flex-direction: unset !important;
        width: 100%;
        .itemAccountTab {
          flex: 0 0 auto;
          margin-right: 8px;
          margin-bottom: 0;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      h4{
        display: none;
      }
    }
  }

  .card-bill {
    .--item-bill {
      &:first-child {
        .--money {
          color: var(--color-1);
        }
      }

      &:last-child {
        .--money {
          color: var(--color-1);
          font-weight: 900;
        }
      }
    }
  }

  .modal-ticket {
    .--content{
      border-top:1px dashed #000 ;
      padding-top: 8px;
      margin-top: 8px;
      .--info{
        span{
          text-transform: uppercase;
          font-weight: 900;
        }
      }
    }
    .modal-dialog {
      max-width: 80%;
    }

    .modal-content {
      position: relative;
    }

    .modal-list {
      border-bottom: 1px solid #E7E7E7;
      padding-bottom: 24px;
    }

    .modal-body {
      padding: 40px 20px 20px;
    }

    .slick-track {
      display: flex;
      margin-left: 0;
      width: 100%;
      .slick-slide {
        width: 100%;
      }
    }

    .--item-modal {
      padding: 0 20px;

    }
    .footer-ticket{
      .--infofooter-modal{
        li{
          margin-bottom: 0;
          &:nth-child(2){
            font-weight: 900;
          }
          &:nth-child(1){
            font-weight: 900;
            text-transform: uppercase;
          }
        }
      }
    }
    .--item-modal {
      padding: 0 16px;
      .--logo {
        max-width: calc(100% - 152px);
        display: flex;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 60px;
          object-fit: contain;
        }
      }
      .--title {
        text-transform: uppercase;
        font-weight: 600;
      }
      article {
        margin-bottom: 1.5rem;
      }
      .--qr {
        width: max-content;
        img {
          width: 100px;
        }
      }
      .--hotline {
        text-align: center;
        margin-bottom: 8px;
        font-weight: 600;
        padding-bottom: 8px;
        border-bottom: 3px solid #000;
      }
      .info-ticket {
        padding-left: 0;
        padding: 10px 0;
        list-style: none;
        border-bottom:1px dashed #000 ;
        margin-bottom: 8px;
        li {
          display: flex;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          text-transform: uppercase;
          font-weight: 900;
        }
      }
      .usermanual{
        .--name{
          font-weight: 900;
          margin-bottom: 16px;
        }
      }
    }

    .slick-active {
      .--item-modal {
        border-right: 1px solid #E7E7E7;
      }
    }

    .close {
      width: 32px;
      height: 32px;
      z-index: 1;
      position: absolute;
      top: 0;
      background: none;
      border: none;
      right: 0;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        margin-bottom: 8px;
        line-height: 20px;
      }
    }

    .--qr {
      img {
        width: 140px;
      }
    }

    .button {
      margin-top: 20px;

      .--btn {
        height: 32px;
        padding: 0 10px;

        &:nth-child(1) {
          background: #F2F2F2;
          border-radius: 5px;
          border: none;
        }

        &:nth-child(2) {
          border: 1px solid #F9B217;
          border-radius: 5px;
          background: #FFF;
        }
      }
    }

    .arrow_modal {
      display: flex;

      .arrow {
        border: 1px solid #E7E7E7;
        width: 32px;
        height: 32px;
        display: flex;
        cursor: pointer;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        position: relative;

        &.prevarrow {
          margin-right: 8px;
        }
      }
    }
    @media (max-width: 768px){
      .modal-dialog{
        max-width: 100%;
      }
      .slick-active{
        .--item-modal{
          border-right:unset ;
        }
      }
      .--item-modal{
        padding: 0;
        .info-ticket {
          span{
            font-size: 20px;
          }
        }
      }      
    }
  }

  .modal_confirm {
    .modal-header {
      // background: var(--color-1);
        justify-content: flex-end;
        border: none;
      padding: 8px 1rem;
      .--title {
        color: #000000;
        i {
          font-size: 20px;
          margin-right: 8px;
        }
        h3 {
          // text-transform: uppercase;
        }
      }
      .close {
        background: none;
        border: none;
        width: 40px;
        height: 40px;
        i {
          font-size: 24px;
        }
      }
    }
    .modal-title {
      color: inherit;
      font-weight: 600;
    }
    .modal-body {
      .--title {
        color: #000000;
        text-transform: uppercase;
        margin-top: -30px;
      }
    }
    .--note {
      span {
        color: var(--color-1);
      }
    }
    .modal-content {
      border: none;
    }
    .modal-footer {

    }
    .--btnGroup {
      button {
        font-size: 16px;
        height: 40px;
        width: 120px;
      }
    }
    .--check {
      a {
        color: var(--color-4);
      }
    }
    .--policy {
      margin-top: 1.5rem;
      line-height: 1.5;
      border: 2px solid #dedede;
      padding: 1rem;
      border-radius: 6px;
      overflow-y: auto;
      max-height: 300px;
      margin-bottom: 20px;
      font-size: 16px;
      display: none;
      &.active {
        display: block;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--color-1);
    }
    .--info {
      padding-left: 0;
      list-style: none;
      margin-bottom: 1.5rem;
      li {
        display: flex;
        margin-bottom: 0.5rem;
        p {
          margin-bottom: 0;
          line-height: 1;
          &:nth-child(1) {
            width: 90px;
            margin-right: 24px;
          }
          &:nth-child(2) {
            font-weight: 900;
          }
        }
      }
    }
    .--view {
      // font-size: 18px;
      color: var(--color-1);
      padding: 16px 0;
      border-top: 1px dashed var(--color-1);
      border-bottom: 1px dashed var(--color-1);
      margin-top: 20px;
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;
      i {
        font-size: 12px;
        margin-right: 8px;
      }
    }
    .dot {
      border: none;
      border-top: 2px dashed  #D9D9D9;
      color: #fff;
      background-color: #fff;
      height: 1px;
    }
    .btn-confirmPolicy {
      background: #f17922;
      background-image: linear-gradient(to right, #f06f24, #F8B117);
      height: 48px !important;
      width: 170px !important;
      border-radius: 12px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600
    }
    .btn-cancelPolicy {
      border: 1px solid #808080;
      border-radius: 12px;
      height: 48px !important;
      width: 170px !important;
      color: #808080;
      font-size: 16px;
      font-weight: 600
    }
  }

  .form_info {
    .form-group {
      width: calc(100%/3 - 8px);
    }

    label {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 4px;
    }

    input {
      border: 1px solid #E7E7E7;
      border-radius: 4px;
      height: 44px;
      padding: 0 12px;

      &::placeholder {
        color: #B1B1B1;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #4361EE;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .buttonall {
    background: var(--color-4);
    border-radius: 6px;
    border: none;
    padding: 4px 26px 0;
    font-weight: 900;
    display: flex;
    align-items: center;
    height: 48px;
    width: max-content;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .social {
    .--item {
      display: flex;
      flex-flow: column;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: none;
      font-weight: 900;
      color: var(--color-3);

      &:hover {
        color: var(--color-1);
      }
    }

    span {
      &:nth-child(1) {
        color: var(--color-8);
      }

      &:nth-child(2) {
        font-weight: 900;
      }
    }
  }

  .expiry {
    padding: 6px 12px;
    background: var(--color-2);
    border-radius: 8px;

    .--icon {
      margin-right: 24px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: calc(100% + 12px);
        border-left: 1px solid rgb(155 155 155 / 50%);
        height: 30px;
      }
    }

    span {
      &:nth-child(1) {
        color: #B1B1B1;
        font-size: 12px;
      }

      &:nth-child(2) {
        font-weight: 900;
      }
    }

    select {
      background: none;
      border: none;
      font-size: 18px;
      font-weight: 900;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  footer {
    margin-top: 110px;
    @media (max-width: 1199px) {
      margin-top: 0;
    }
    .container {
      max-width: 1520px;
    }
    .footer_info {
      background: #F8F8F8;
      padding: 28px 0 44px;
      /* box-shadow: 0px 4px 40px rgb(0 0 0 / 5%); */
      border-radius: 12px 12px 0 0;

      .--info {
        width: 80%;
        align-items: center;
        .--logo {
          margin-right: 74px;
          height: 100%;
          @media (max-width: 1280px) {
            margin-right: 50px;
          }
        }
        .--item {
          h6 {
            font-weight: 500;
            margin-bottom: 16px;
          }
          a{
            color: #2C2C2C;
            font-weight: 400;
            font-size: 14px;
            :hover {
              color: #E92127;
            }
          }
          &:nth-child(2n+1) {
            margin-right: 40px;
          }

          .--icon {
            margin-right: 12px;
          }
        }
       
      }
    }

    .footer_link {
      display: flex;
      align-items: center;
      margin-left: 28px;
      a {
        text-decoration: none;
      }

      .phone {
        font-weight: 900;
        color: var(--color-3);
        transition: all .15s ease;

        &:hover {
          color: var(--color-1);
        }
      }

      .--follow {
        a,
        .--link {
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .--policy-term {
      text-align: justify;
      margin-left: 28px;
      margin-bottom: 20px;
      a {
        color: #E92127;
        font-size: 16px;
        font-weight: 500;
        :hover {
          color: #f5232a;
        }
      }

      @media (max-width: 1199px) {
        justify-content: center;
        margin-left: 0px;
      }
    }

    .footer_coppy {
      position: relative;
      background: var(--color-7);
      height: 60px;
      display: flex;
      align-items: center;

      .--bot {
        width: 100%;
      }

      span {
        color: #FFF;
        opacity: .8;
        transition: all .15s ease;
      }

      a {
        text-decoration: none;
        margin-right: 1rem;
        position: relative;

        &:hover {
          span {
            color: var(--color-4);
            opacity: 1;
          }
        }

        &::after {
          top: 50%;
          content: "";
          position: absolute;
          height: 12px;
          left: calc(100% + 8px);
          border-right: 1px solid rgb(255 255 255 / 80%);
          transform: translate(0, -50%);
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          max-width: 115px;
          max-height: 40px;
        }
      }
    }

    @media (max-width: 1199px) {
      padding-bottom: 0px;
      .footer_info {
        padding: 24px 0;

        .--content {
          /* flex-flow: column; */

          .--info {
            width: 100%;
            flex-flow: column;
            margin-bottom: 1.5rem;

            .--logo {
              margin-right: 0px;
              margin-bottom: 32px;
            }

            .--item {
              margin-right: 0;
              margin-bottom: 1rem;
              padding: 0px 30px;
              width: 100%;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .footer_link {
            justify-content: center;
            margin-left: 0;
          }
          .--policy-term {
            a {
              margin-left: 0px;
              color: #E92127;
            }
          }
        }

      }

      .footer_coppy {
        height: auto;
        padding: 32px 0 8px;

        .logo {
          position: relative;
          top: unset;
          left: unset;
          transform: none;
          margin-bottom: 2rem;
        }

        .--content {
          flex-flow: column;
          align-items: flex-center !important;

          .--bot {
            display: flex;
            flex-flow: column-reverse;
            width: 100%;
            align-items: center;
          }
        }

        .--security {
          margin-bottom: 8px;
          width: 100%;
          justify-content: center;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        }
      }
    }
  }

  .modal_login {
    .modal-dialog {
      max-width: 800px;
    }

    &.modal_register{
      .modal-dialog{
        max-width: 1000px;
      }
      .--img{
        width: 100%;
      }
      .--txt{
        width: 60%;
      }
      .ant-form{
        display: flex;
        flex-wrap: wrap;
      }
      .ant-form-item{
        width: calc(50% - 4px);
        &:nth-child(2n+1){
          margin-right: 8px;
        }
        /* &:nth-last-child(2) {
          margin-right: 0;
          width: 100%;
        } */
        &:last-child{
          margin-right: 0;
          width: 100%;
        }
      }
    }

    .modal-content {
      overflow: hidden;
      border-radius: 20px;
      border: none;
      .ant-form-item .ant-form-item-label label {
        font-size: 14px;
        height: auto;
        margin-bottom: 0;
        font-weight: 900;
      }
    }

    .--title {
      text-align: center;
      margin-bottom: 16px;

      h4 {
        font-weight: 900;
      }

      span {
        font-size: 14px;
      }
    }

    .--login {
      display: flex;
      justify-content: space-between;

      a {
        width: calc(100%/3 - 4px);

        img {
          width: 100%;
        }
      }
    }

    .--txt {
      padding: 3rem 3rem 12px;
    }

    .line {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;

      p {
        width: calc(50% - 30px);
        height: 1px;
        background: #D9D9D9;
        margin-bottom: 0;
      }
    }

    .--img {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .form {
      .form-group {
        display: flex;
        flex-flow: column;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          margin-bottom: 6px;
          font-weight: 900;
        }

        input {
          border: 1px solid #E7E7E7;
          border-radius: 4px;
          height: 44px;
          padding: 0 12px;

          &::placeholder {
            color: var(--color-8);
          }

          &:focus {
            outline: none;
            box-shadow: 0 0 0 1px #4361EE;
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .--howtologin {
      margin-top: 12px;
      font-size: 14px;

      input {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }

      a {
        text-decoration: none;
        color: #1877F2;

      }
    }

    .--question {
      text-align: center;

      span {
        color: var(--color-4);
      }
    }

    .--btnGroup {
      margin: 2rem auto 3rem;
      button {
        height: 36px;

      }
    }

    button {
      &.buttonall {

      }

      &.close {
        background: none;
        border: none;
        padding: 0;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        font-size: x-large;
      }
    }

    .--txt {
      width: 50%;
      position: absolute;
      right: 0;
      height: 100%;
      backdrop-filter: blur(40px);
      border-radius: 20px
    }

    @media (max-width: 768px) {
      .modal-body {
        flex-flow: column;
      }

      .--txt {
        width: 100%;
        padding: 24px;
      }

      .--img {
        display: none;
      }

      button {
        &.buttonall {
          margin: 2rem auto;
        }
      }
    }
  }

  .modal_Condition {
    .modal-content{
      border: none;
      overflow: hidden;
    }
    .modal-body {
     padding: 0;
    }
    .--top{
      display: flex;
      justify-content: space-between;
      background: var(--color-1);
      padding: 12px 24px;
      h4{
        color: #FFF;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      button.close {
        background: none;
        border: none;
        font-size: 20px;
        color: #FFF;
      }
    }
    .--bot{
      padding: 24px;
      span{
        font-size: 20px;
        color: var(--color-1);
        font-weight: 900;
        margin-right: 8px;
      }
      .--name{
        font-size: 18px;
      }
      article{
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
        a{
          background: var(--color-4);
          color: #FFF;
          line-height: 1;
          display: flex;
          padding: 6px 12px;
          border-radius:6px ;
          width: max-content;
        }
      }
    }

    @media (max-width: 768px) {
    }
  }

  .check {
    display: none;

    i {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 3px solid #FFF;
      background: var(--color-4);
      color: #fff;
      font-size: 12px;
    }
  }

  .modal_Condition {
    .modal-content{
      border: none;
      overflow: hidden;
    }
    .modal-body {
     padding: 0;
    }
    .--top{
      display: flex;
      justify-content: space-between;
      background: var(--color-1);
      padding: 12px 24px;
      h4{
        color: #FFF;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      button.close {
        background: none;
        border: none;
        font-size: 20px;
        color: #FFF;
      }
    }
    .--bot{
      padding: 24px;
      span{
        font-size: 20px;
        color: var(--color-1);
        font-weight: 900;
        margin-right: 8px;
      }
      .--name{
        font-size: 18px;
      }
      article{
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
        a{
          background: var(--color-4);
          color: #FFF;
          line-height: 1;
          display: flex;
          padding: 6px 12px;
          border-radius:6px ;
          width: max-content;
        }
      }
    }

    @media (max-width: 768px) {
    }
  }
  .hide-scroll-bar {
    ::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
      }

      /* Define the track (the area behind the thumb) */
      ::-webkit-scrollbar-track {
        background: transparent; /* Color of the track */
      }

      /* Define the scrollbar thumb */
      ::-webkit-scrollbar-thumb {
        background: #ececec; /* Color of the thumb */
        border-radius: 20px;
      }

      /* Define the scrollbar thumb on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #d6d6d6; /* Color of the thumb on hover */
      }
  }
  @media (max-width: 1200px) { /* adjust this value to match your mobile/tablet breakpoint */
    .grecaptcha-badge {
      visibility: hidden;
    }
  }
  overscroll-behavior: none;

`;

export const Flex = css`
  display: flex;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = css`
  ${Flex};
  justify-content: center;
  align-items: center;
`;

export const FlexRowStart = css`
  ${Flex};
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowEnd = css`
  ${Flex};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowBetween = css`
  ${Flex}
  justify-content: space-between;
  align-items: center;
`;

export const FlexColCenter = css`
  ${FlexCol};
  ${FlexRowCenter}
`;

export const FlexColStart = css`
  ${FlexCol};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexColEnd = css`
  ${FlexCol};
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FlexColBetween = css`
  ${FlexCol};
  justify-content: space-between;
  align-items: baseline;
`;

export const BackgroundCover = css<{ bgUrl: string | null | undefined }>`
  background-image: url(${props => props.bgUrl && props.bgUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundContain = css`
  ${BackgroundCover};
  background-size: contain;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FlexRowCenter};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
`;
