import { createNewOrderApi, getPaymentMethodApi } from 'app/services/checkoutApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_CREATE_NEW_ORDER, GET_LIST_PAYMENT_METHOD } from '.';
// import { checkoutActions as actions } from '.';

export function* getListPaymentMethod({ payload }: any) {
  try {
    const { data } = yield call(getPaymentMethodApi);
    yield put(GET_LIST_PAYMENT_METHOD.success(data.data));
  } catch (err) {
    yield put(GET_LIST_PAYMENT_METHOD.failure());
  }
}
export function* createOrder({ payload }: any) {
  try {
    const { data } = yield call(createNewOrderApi, payload);
    yield put(GET_CREATE_NEW_ORDER.success(data.cart));
  } catch (err) {
    yield put(GET_CREATE_NEW_ORDER.failure());
  }
}
export function* checkoutSaga() {
  yield takeLatest(GET_LIST_PAYMENT_METHOD, getListPaymentMethod);
  yield takeLatest(GET_CREATE_NEW_ORDER, createOrder);
}
