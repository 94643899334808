import React, {
  CSSProperties,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from 'react';
import styled from 'styled-components';

interface LinkButtonWithIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  textColor?: string;
  styleContainer?: CSSProperties;
  styleBtn?: CSSProperties;
}

const StyledButton = styled.button<{ textColor?: string }>`
  color: ${props => props.textColor ?? '#3988FF'};
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0px;
  padding: 0px;
`;

const LinkButton = forwardRef(
  (
    {
      onClick,
      startIcon,
      endIcon,
      styleContainer,
      styleBtn,
      ...props
    }: LinkButtonWithIconProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          ...styleContainer,
        }}
        onClick={onClick as MouseEventHandler<HTMLDivElement> | undefined}
      >
        {startIcon && startIcon}
        <StyledButton
          style={{
            marginRight: endIcon ? '8px' : 0,
            marginLeft: startIcon ? '8px' : 0,
            ...styleBtn,
          }}
          {...props}
        />
        {endIcon && endIcon}
      </div>
    );
  },
);

export default LinkButton;
