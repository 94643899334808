import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { homeSaga } from './saga';
import { HomeState, I_Home } from './types';
import { I_Park } from 'types';

export const GET_HOME = createRoutine('Home/getHome');
export const GET_PARKS = createRoutine('Home/getParks');

export const initialState: HomeState = {
  loading: false,
  success: false,
  error: false,
  home: undefined,
  listPark: [],
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    /**
     * ! GET HOME
     */
    [GET_HOME.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_HOME.SUCCESS]: (state, action: PayloadAction<I_Home>) => {
      state.home = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_HOME.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! GET PARKS
     */
    [GET_PARKS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_PARKS.SUCCESS]: (state, action: PayloadAction<I_Park[]>) => {
      state.listPark = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_PARKS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions: homeActions } = slice;

export const useHomeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: homeSaga });
  return { actions: slice.actions };
};
