import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { DatePickerProps } from 'antd';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from 'app/assets/img/calendar.svg';
import { useGlobalFunction } from 'app/pages/App/hooks/useGlobalFunction';

import TicketDateSelectable, {
  Props as TicketDateSelectableProps,
} from 'app/components/TicketDateSelectable';

interface CustomDatePickerProps extends TicketDateSelectableProps {}

export const VI_DAY = [
  'Chủ nhật',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];

export const EN_DAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const valueToParse = props.value ?? moment();
  const { locale } = useGlobalFunction();
  const popupContainer = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const handleOpenChange: DatePickerProps['onOpenChange'] = open => {
    setOpen(open);
  };

  return (
    <Container>
      <PopupContainer ref={popupContainer} />
      <DatePickerWrapper>
        <TicketDateSelectable
          open={open}
          onOpenChange={handleOpenChange}
          {...props}
        />
      </DatePickerWrapper>
      <ViewContainer>
        <TextView>
          {locale === 'vi'
            ? VI_DAY[valueToParse.day()] + valueToParse.format(' (DD/MM/YYYY)')
            : EN_DAY[valueToParse.day()] + valueToParse.format(' (DD/MM/YYYY)')}
        </TextView>
        <CalendarIcon />
      </ViewContainer>
    </Container>
  );
};

export default CustomDatePicker;

const Container = styled.div`
  position: relative;
`;

const PopupContainer = styled.div`
  & > div:first-child {
    z-index: 1;
  }
`;

const ViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #ededed;
  padding-bottom: 14px;
`;

const TextView = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;
